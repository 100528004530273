import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAPIResponse, ICommonDropDownData, IRegistration, IRoleData, ISaveCommonDropDownData } from 'src/app/view-models/common.model';
import { IEmployeeData } from 'src/app/view-models/employee-data-model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { CustomHttpClientService } from '../common/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  companyId!: any;
  siteData!: any;
  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  /** edit data saved type */
  typeOfSavedData = new BehaviorSubject('');
  private collapsed = new BehaviorSubject<any>({});
  naviagtaionCollapsed = this.collapsed.asObservable();
  constructor(private http: CustomHttpClientService) { }

  setNavigationCollapsed(val: boolean = false) {
    this.collapsed.next(val);
  }
  /**
   * Save Admin Data
   *
   * @param {ISaveCommonDropDownData} reqBody
   * @param {string} saveDataType
   * @return {*}  {Observable<any>}
   * @memberof SaveDataService
   */
  saveData(reqBody: ISaveCommonDropDownData, saveDataType: string): Observable<ICommonDropDownData> {
    return this.http.Post(`Create${saveDataType}`, reqBody, this.reqOpt).pipe() as Observable<ICommonDropDownData>;
  }

  /**
   * update admin data
   *
   * @param {ISaveCommonDropDownData} reqBody
   * @param {string} saveDataType
   * @return {*}  {Observable<any>}
   * @memberof AdminService
   */
  updateData(reqBody: ISaveCommonDropDownData, saveDataType: string, itemId: number): Observable<ICommonDropDownData> {
    return this.http.Put(`${saveDataType}/Update${saveDataType}/${itemId}`, reqBody, this.reqOpt).pipe() as Observable<ICommonDropDownData>;
  }

  deleteData(saveDataType: string, itemId: number): Observable<ICommonDropDownData> {
    return this.http.Delete(`${saveDataType}/Delete${saveDataType}/${itemId}`, this.reqOpt).pipe() as Observable<ICommonDropDownData>;
  }

  /**
   * get data category, location & status for admin
   *
   * @param {HttpParams} params
   * @param {string} getDataType
   * @return {*}  {Observable<ICommonDropDownData[]>}
   * @memberof AdminService
   */
  getData(getDataType: string, reqBody: any): Observable<IAPIResponse<any>> {
    this.siteData = JSON.parse(sessionStorage.getItem('siteData') || '{}');
    this.companyId = this.siteData['companyId']
    return this.http.Post(`Company/${this.companyId}/GetAll${getDataType}Paged`, reqBody, this.reqOpt) as Observable<any>;
  }

  /**
   * get employee data
   *
   * @param {HttpParams} params
   * @param {string} getDataType
   * @return {*}  {Observable<IAPIResponse<ICommonDropDownData[]>>}
   * @memberof AdminService
   */
  getEmployeeData(params: HttpParams, getDataType: string): Observable<IAPIResponse<IEmployeeData[]>> {
    return this.http.GetExtended(`${getDataType}/GetAll${getDataType}Paged`, {
      params,
      showLoadingIndicator: true
    }).pipe() as Observable<IAPIResponse<IEmployeeData[]>>;
  }

  getRole(): Observable<IRoleData[]> {
    return this.http.Get('GetAllRoles').pipe() as Observable<IRoleData[]>
  }

  signUp(data: IRegistration): Observable<IRegistration> {
    this.siteData = JSON.parse(sessionStorage.getItem('siteData') || '{}');
    this.companyId = this.siteData['companyId']
    return this.http.Post(`Company/${this.companyId}/customRegistration`, data, this.reqOpt).pipe() as Observable<IRegistration>
  }
}
