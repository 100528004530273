<mat-accordion multi>
  <mat-expansion-panel *ngIf="hasAccessToModule(ASSET_MODULE)" disabled class="sideBarNavClass assetRadius" expanded [routerLink]="['assets']"
    routerLinkActive="active-nav-item" (click)="collapseSideNavigation('assets')">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faForklift">
        </fa-icon><span *ngIf="!isDisable" class="ml-3">Assets</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="hasAccessToModule(STOCKITEM_MODULE)" disabled class="sideBarNavClass" expanded [routerLink]="['stock-items']"
    routerLinkActive="active-nav-item" (click)="collapseSideNavigation('stock-items')">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faBoxesStacked">
        </fa-icon><span *ngIf="!isDisable" class="ml-3">Stock Items</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="hasAccessToModule(MATERIALS_MODULE)" disabled class="sideBarNavClass" expanded [routerLink]="['materials']"
    routerLinkActive="active-nav-item" (click)="collapseSideNavigation('materials')">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faPipeValve">
        </fa-icon><span *ngIf="!isDisable" class="ml-3">Materials</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['groups']"
    routerLinkActive="active-nav-item" (click)="collapseSideNavigation('groups')">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faObjectGroup">
        </fa-icon><span *ngIf="!isDisable" class="ml-3">Groups</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <!-- <mat-expansion-panel *ngIf="hasAccessToModule(DOCUMENT_MODULE)" disabled class="sideBarNavClass" expanded [routerLink]="['documents']"
    routerLinkActive="active-nav-item" (click)="collapseSideNavigation('documents')" [hidden]="!isDocumentTabVisible">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faFileLines">
        </fa-icon><span *ngIf="!isDisable" class="ml-3">Documents</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel> -->

  <mat-expansion-panel *ngIf="hasAccessToModule(DOCUMENT_V2_MODULE)" disabled class="sideBarNavClass" expanded [routerLink]="['documents-v2']"
  routerLinkActive="active-nav-item" (click)="collapseSideNavigation('documents-v2')" [hidden]="!isDocumentV2TabVisible">
  <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
    <mat-panel-title class="font-medium sideNavContent">
      <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faFileLines">
      </fa-icon><span *ngIf="!isDisable" class="ml-3">Documents</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
</mat-expansion-panel>

  <mat-expansion-panel disabled class="sideBarNavClass" expanded routerLinkActive="active-nav-item"
    [routerLink]="['cart']" (click)="collapseSideNavigation('cart')">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <mat-icon class="pr-2 navigation-icon-font-size cartIcon" matBadge={{selectedRow}}>shopping_cart </mat-icon><span *ngIf="!isDisable"
          class="ml-3">Cart</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['transactions']"
    routerLinkActive="active-nav-item" (click)="collapseSideNavigation('transactions')">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faArrowUpArrowDown">
        </fa-icon><span *ngIf="!isDisable" class="ml-3">Transactions</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['map']"
    routerLinkActive="active-nav-item" (click)="collapseSideNavigation('map', true)">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faLocationDot">
        </fa-icon><span *ngIf="!isDisable" class="ml-3">Maps</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['inspections']"
    routerLinkActive="active-nav-item" (click)="collapseSideNavigation('inspections', false)">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
      <mat-panel-title class="font-medium sideNavContent">
        <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faClipboardListCheck">
        </fa-icon><span *ngIf="!isDisable" class="ml-3">Inspections</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>


<mat-expansion-panel *ngIf="hasAccessToModule(MATERIALS_MODULE)" disabled class="sideBarNavClass" expanded [routerLink]="['osd']"
routerLinkActive="active-nav-item" (click)="collapseSideNavigation('osd', false)" [hidden]="!isOsdTabVisible">
<mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
  <mat-panel-title class="font-medium sideNavContent">
    <fa-icon class="pr-2 navigation-icon-font-size" [icon]="faClipboardCheck">
    </fa-icon><span *ngIf="!isDisable" class="ml-3">OSD</span>
  </mat-panel-title>
</mat-expansion-panel-header>
</mat-expansion-panel>
</mat-accordion>
