import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { Observable, Subscription, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { CartService } from 'src/app/services/cart/cart.service';
import { CommonDropDownService } from 'src/app/services/common/common-dropdown.service';
import { LoaderService } from 'src/app/services/common/loader.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { ICartDataModel } from 'src/app/view-models/cart-data-model';
import { ICommonDropDownData } from 'src/app/view-models/common.model';
import { IEmployeeData } from 'src/app/view-models/employee-data-model';
import { ISiteModel } from 'src/app/view-models/site-model';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { SnackBarService } from 'src/app/services/common/snackbar.service';
import { IAssetDataModel } from 'src/app/view-models/asset-data-model';

interface modalData {
  headerTitle: string,
  returnValue: any;
  assetList: any[];
}

@Component({
  selector: 'app-check-in-check-out-selection-fields',
  templateUrl: './check-in-check-out-selection-fields.component.html'
})
export class CheckInCheckOutSelectionFieldsComponent implements OnInit {

  faChevronDown = faChevronDown;

  commentFC!: FormControl;

  /** site data */
  siteData!: ISiteModel;

  headerTitle!: string;

  /** first form group */
  firstFormGroup!: FormGroup;

  /**form group for status  */
  secondFormGroup!: FormGroup;

  /** loading status for data */
  isStatusLoading = true;
  isLocationLoading = true;
  isCustodianLoading = true;

  /** status data */
  statusData: ICommonDropDownData[] = [];
  filteredStatusData: ICommonDropDownData[] = [];

  /** location data */
  locationData: ICommonDropDownData[] = [];
  filteredLocationData: ICommonDropDownData[] = [];

  /** employee data */
  custodianData: IEmployeeData[] = [];
  filteredCustodianData: IEmployeeData[] = [];

  /** subscription handler */
  subs!: Subscription;

  reqBody!: ICartDataModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: modalData,
    public dialogRef: MatDialogRef<CheckInCheckOutSelectionFieldsComponent>,
    public loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private commonDropDownService: CommonDropDownService,
    private cartService: CartService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
  ) {
    this.subs = new Subscription();
    this.commentFC = new FormControl(null);
    this.reqBody = {
      assetList: [],
    }
  }

  ngOnInit(): void {
    if (!this.siteData) {
      this.siteData = JSON.parse(sessionStorage.getItem('siteData') as string);
    }

    this.headerTitle = this.modalData.headerTitle;

    this.firstFormGroup = this.formBuilder.group({
      custodianId: [null],
      locationId: [null]
    });

    this.secondFormGroup = this.formBuilder.group({
      statusId: [null]
    });

    this.getEmployeeData();
    this.getLocationData();
    this.getStatusData();

  }

  /**
   * get employee data
   *
   * @memberof CheckInCheckOutSelectionFieldsComponent
   */
  getEmployeeData(): void {
    const getCustodianData = this.employeeService.getEmployeeData(+this.siteData.companyId).subscribe(res => {
      this.isCustodianLoading = false;
      this.custodianData = res;
      this.filteredCustodianData = res;
    });
    this.subs.add(getCustodianData);
  }

  /**
 * get location data
 *
 * @memberof CheckInCheckOutSelectionFieldsComponent
 */
  getLocationData(): void {
    const getLocationData = this.commonDropDownService.getDropDownData('location', +this.siteData.companyId).subscribe(res => {
      this.isLocationLoading = false;
      this.locationData = res;
      this.filteredLocationData = res;
    });
    this.subs.add(getLocationData);
  }

  /**
    * get satus data
    *
    * @memberof CheckInCheckOutSelectionFieldsComponent
    */
  getStatusData(): void {
    const getStatusData = this.commonDropDownService.getDropDownData('status', +this.siteData.companyId).subscribe(res => {
      this.isStatusLoading = false;
      this.statusData = res;
      this.filteredStatusData = res;
    });
    this.subs.add(getStatusData);
  }

  /**
  * display selected id's description in dropdown
  *
  * @param {ICommonDropDownData} dropDownData
  * @return {*}  {string}
  * @memberof CheckInCheckOutSelectionFieldsComponent
  */
  display(dropDownData: ICommonDropDownData | IEmployeeData): string {
    return (dropDownData as ICommonDropDownData)?.description || (dropDownData as IEmployeeData)?.employeeName || '';
  }

  submit() {
    var findCustodian = this.filteredCustodianData.findIndex(c => c.id == +this.firstFormGroup?.value?.custodianId?.id);
    var findLocation = this.filteredLocationData.findIndex(l => l.id == +this.firstFormGroup?.value?.locationId?.id);

    if (this.modalData.assetList.length) {
      const cartAssetDate: any[] = [];
      this.modalData.assetList.forEach(data => {
        cartAssetDate?.push({ id: data.id, isHiring: +data.hiringAndRate > 0 ? true : false, rate: data.hiringAndRate || 0, quantity: !data.availableQuantity ? data.availableQuantity = 0 : data.availableQuantity })
      })
      const firstFormData = this.firstFormGroup.getRawValue();
      const secondFormData = this.secondFormGroup.getRawValue();

      if (findCustodian >= 0 || findLocation >= 0) {

        if (cartAssetDate.length > 0) {

          const requestBody = {
            action: this.headerTitle == 'Check-In' ? 0 : 1,
            assetList: cartAssetDate,
            stockItemList: [],
            locationId: firstFormData.locationId?.id,
            custodianId: firstFormData.custodianId?.id,
            statusId: secondFormData.statusId?.id,
            comment: this.commentFC.value,
          }

          const cartAction = this.cartService.cartAction(+this.siteData.id, requestBody).pipe(
            switchMap(() => this.emptyCartData(true, requestBody))
          ).subscribe();
          this.subs.add(cartAction);
        }
      }else{
        this.snackBarService.showMessage(`At least one of the options among Custodian and location is required`, 'errorSnack');
      }
    }
  }

  /**
  * empty cart data
  *
  * @return {*}
  * @memberof CheckInCheckOutSelectionFieldsComponent
  */
  emptyCartData(askForConfirmation: boolean = false, requestBody?: any): Observable<boolean[]> {

    if (askForConfirmation) {
      const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: 'auto',
        width: 'fit-content',
        disableClose: true
      });

      confirmDialogRef.componentInstance.confirmMessage = `Transaction Completed.\nDo you want to empty the cart?`;

      const dialogClosedSub = confirmDialogRef.afterClosed().pipe(
        tap((res) => {
          if (res !== true) {
            let prevScannedData = this.setReturnValue(requestBody);
            localStorage.setItem('scannedAssetData', JSON.stringify(prevScannedData))
          } else {
            this.setReturnValue(requestBody);
            localStorage.removeItem('scannedAssetData');
          }
          this.commentFC.reset();
          this.dialogRef.close(this.modalData.returnValue);
        })).subscribe();
      this.subs.add(dialogClosedSub);
    } else {

    }
    return of([]);
  }

  setReturnValue(requestBody?: any): void{
    let prevScannedData = JSON.parse(localStorage.getItem('scannedAssetData') as any);

    const custodian = !!requestBody?.custodianId ? this.custodianData.find(custodian => custodian.id == +requestBody?.custodianId) : null;
    const location = !!requestBody?.locationId ? this.locationData.find(location => location.id == +requestBody?.locationId) : null;
    const status = !!requestBody?.statusId ? this.statusData.find(status => status.id == +requestBody?.statusId) : null;

    prevScannedData.map((x: IAssetDataModel) => {
      x.availableQuantity = this.headerTitle == 'Check-In' ? 1 : 0;

      x.custodianId = !!requestBody?.custodianId ? requestBody?.custodianId : null;
      x.custodianName = custodian?.employeeName;

      x.locationId = !!requestBody?.locationId ? requestBody?.locationId : x.locationId;
      x.locationDescription = !!requestBody?.locationId ? location?.description : x.locationDescription;

      x.statusId = !!requestBody?.statusId ? requestBody?.statusId : x.statusId;
      x.statusDescription = !!requestBody?.statusId ? status?.description : x.statusDescription;
      
    })
    this.modalData.returnValue = prevScannedData;

    return prevScannedData
  }

  searchChange(){
    const custodianValueChanged = this.firstFormGroup.get('custodianId')?.valueChanges.pipe(
      tap(() => this.filteredCustodianData = this.custodianData)
    ).subscribe((val: string | IEmployeeData) => {
      const custodianName = typeof val === 'string' ? val : val?.employeeName;
      this.filteredCustodianData = this.custodianData.filter(custodian => custodian.employeeName.toLowerCase().includes(custodianName?.toLowerCase().trim()))
    });
    this.subs.add(custodianValueChanged);

    const locationValueChanged = this.firstFormGroup.get('locationId')?.valueChanges.pipe(
      tap(() => this.filteredLocationData = this.locationData)
    ).subscribe((val: string | ICommonDropDownData) => {
      const locationName = typeof val === 'string' ? val : val?.description;
      this.filteredLocationData = this.locationData.filter(location => location.description.toLowerCase().includes(locationName?.toLowerCase().trim()))
    });
    this.subs.add(locationValueChanged);

    const statusValueChanged = this.secondFormGroup.get('statusId')?.valueChanges.pipe(
      tap(() => this.filteredStatusData = this.statusData)
    ).subscribe((val: string | ICommonDropDownData) => {
      const statusName = typeof val === 'string' ? val : val?.description;
      this.filteredStatusData = this.statusData.filter(status => status.description.toLowerCase().includes(statusName?.toLowerCase().trim()))
    });
    this.subs.add(statusValueChanged);
  }

  validateSelectedValues(): boolean {
    var findCustodian = this.filteredCustodianData.findIndex(c => c.id == +this.firstFormGroup?.value?.custodianId?.id);
    var findLocation = this.filteredLocationData.findIndex(l => l.id == +this.firstFormGroup?.value?.locationId?.id);

    if(!!this.firstFormGroup?.value?.locationId && !!this.firstFormGroup?.value?.custodianId){
      return (findCustodian >= 0 && findLocation >= 0) ? false : true;
    }else{
      return (findCustodian >= 0 || findLocation >= 0) ? false : true;
    }
  }

  validateStatus(): boolean{
    var findStatus = this.filteredStatusData.findIndex(s => s.id == +this.secondFormGroup?.value?.statusId?.id);
    if(!!this.secondFormGroup?.value?.statusId){
      return findStatus >= 0 ? false : true;
    }else{
      return false;
    }
  }
}