<ng-container *ngIf="isCartData">
    <div class="h-full">
        <mat-card class="mat-elevation-z6 bg-01dp mx-2 mb-2" fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button matTooltip="Empty Cart" color="warn" (click)="clearCartData()">
                <mat-icon>remove_shopping_cart</mat-icon>
            </button>
        </mat-card>

        <mat-stepper linear #stepper class="mx-2">
            <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
                <form fxLayout="column" [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Select Action</ng-template>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field>
                            <mat-select placeholder="Type of Action" formControlName="action"
                                (selectionChange)="actionSelectionChanged($event.value)">
                                <mat-option value="0"
                                    [disabled]="!assetRowData.length && !stockRowData.length || materialRowData.length > 0 || groupRowData.length > 0 || documentV2RowData.length > 0">
                                    Check In
                                </mat-option>
                                <mat-option value="1"
                                    [disabled]="!assetRowData.length && !stockRowData.length || materialRowData.length > 0 || groupRowData.length > 0 || documentV2RowData.length > 0">
                                    Check Out
                                </mat-option>
                                <mat-option value="addToGroup"
                                    [disabled]="!assetRowData.length && !stockRowData.length && !materialRowData.length">
                                    Add to Group
                                </mat-option>
                                <mat-option value="bulkUpdateAsset" [disabled]="!assetRowData.length">
                                    Bulk Update Asset
                                </mat-option>
                                <mat-option value="bulkUpdateStockItem" [disabled]="!stockRowData.length">
                                    Bulk Update StockItem
                                </mat-option>
                                <mat-option value="bulkUpdateMaterial" [disabled]="!materialRowData.length">
                                    Bulk Update Material
                                </mat-option>
                                <mat-option value="bulkUpdateGroup" [disabled]="!groupRowData.length">
                                    Bulk Update Group
                                </mat-option>
                                <mat-option value="bulkUpdateStatus" *ngIf="!!assetRowData.length || !!stockRowData.length || !!materialRowData.length || !!groupRowData.length">
                                    Bulk Update Status
                                 </mat-option>
                                 <mat-option value="updateLocation" *ngIf="!!assetRowData.length || !!stockRowData.length || !!materialRowData.length || !!groupRowData.length">
                                    Bulk Update Location
                                </mat-option>
                                <mat-option value="bulkTransfer" *ngIf="!!assetRowData.length || !!stockRowData.length || !!materialRowData.length || !!groupRowData.length || !!documentRowData.length">
                                    Bulk Transfer
                                </mat-option>
                                <mat-option value="createOSDMaterials" [disabled]="!materialRowData.length" >
                                    Create OSD Materials
                                </mat-option>
                                <!-- <mat-option value="addToDocument"
                                    [disabled]="!materialRowData.length">
                                    Add to Document
                                </mat-option> -->
                                <mat-option value="addToDocumentV2" *ngIf="!!assetRowData.length || !!stockRowData.length || !!materialRowData.length || !!groupRowData.length">
                                    Add to Document
                                </mat-option>
                                <mat-option value="actionDocumentV2" [disabled]="!documentV2RowData.length">
                                    Action Document(s)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="label-text mt-1" *ngIf="selectedActionMessage">NOTE:
                            {{selectedActionMessage}}</span>
                    </div>
                    <div>
                        <button class="actionButton" mat-raised-button matStepperNext (click)="loadDropdownData()"
                            [disabled]="firstFormGroup.invalid || (!assetRowData?.length && !stockRowData?.length && !materialRowData?.length && !groupRowData?.length && !documentV2RowData?.length) || errorMessage !== '' || osdValidationMessage !== '' || osdFieldMandMessage !== '' || selectedGroupMessage !== '' ||  selectedDocumentMessage !== '' || activeOsdErrorMesage !== ''">Next</button>
                        <span *ngIf="errorMessage !== ''" class="cartErrorMessage">{{errorMessage}}</span>
                        <span *ngIf="osdValidationMessage !== ''" class="cartErrorMessage">{{osdValidationMessage}}</span>
                        <span *ngIf="activeOsdErrorMesage !== ''" class="cartErrorMessage">{{activeOsdErrorMesage}}</span>
                    </div><br>
                    <div *ngIf="!isCheckGroup || !isCheckDocument">
                        <span class="label-text mt-1" *ngIf="selectedGroupMessage">NOTE:
                            {{selectedGroupMessage}}</span><br>
                        <span class="label-text mt-1" *ngIf="selectedDocumentMessage">NOTE:
                            {{selectedDocumentMessage}}</span><br>
                        <button class="actionButton addGroupToCart" mat-raised-button (click)="addGroupToCart()">
                            <ng-container [ngSwitch]="true">
                                <ng-container *ngSwitchCase="selectedGroupMessage !== '' && selectedDocumentMessage !== '' "> Add Document/Group To Cart </ng-container>
                                <ng-container *ngSwitchCase="selectedGroupMessage === '' && selectedDocumentMessage !== ''">Add Document To Cart</ng-container>
                                <ng-container *ngSwitchDefault> Add Group To Cart </ng-container>
                              </ng-container> 
                        </button>
                    </div>
                    <br>
                    <div>
                        <span class="label-text mt-1 mandatory-label-text" *ngIf="osdFieldMandMessage !== ''">{{osdFieldMandMessage}}</span>
                    </div>
                </form>
            </mat-step>
            <mat-step [editable]="isEditable">
                <ng-template matStepLabel>Fill in details</ng-template>
                <form *ngIf="this.firstFormGroup.value.action == '0' || this.firstFormGroup.value.action == '1'"
                    [formGroup]="secondFormGroup">
                    <mat-error class="mt-2 mb-2">*At least one of the options among Custodian and location is required.
                    </mat-error>

                    <mat-form-field class="cart-controls">
                        <mat-label>Custodian</mat-label>
                        <input #CustodianScrollControl type="text" placeholder="Custodian" matInput formControlName="custodianId"
                            [matAutocomplete]="custodianMenu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #custodianMenu="matAutocomplete"
                            [displayWith]="display">
                            <mat-option *ngIf="isCustodianLoading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredCustodianData.length && !isCustodianLoading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredCustodianData.length && !isCustodianLoading">
                                <mat-option *ngFor="let custodian of filteredCustodianData" [value]="custodian"
                                    matTooltip="{{ custodian.employeeName }}" matTooltipPosition="right">
                                    {{ custodian.employeeName }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="cart-controls">
                        <mat-label>Location</mat-label>
                        <input #LocationScrollControl type="text" placeholder="Location" matInput formControlName="locationId"
                            [matAutocomplete]="locationMenu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #locationMenu="matAutocomplete" [displayWith]="display">
                            <mat-option *ngIf="isLocationLoading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredLocationData.length && !isLocationLoading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredLocationData.length && !isLocationLoading">
                                <mat-option *ngFor="let location of filteredLocationData" [value]="location"
                                    matTooltip='{{location.description}}  -  {{location.companyName}}'
                                    matTooltipPosition="right">
                                    {{ location.description}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field [formGroup]="thirdFormGroup" class="cart-controls">
                        <mat-label>Status</mat-label>
                        <input #CommonScrollControl type="text" placeholder="Status" matInput formControlName="statusId"
                            [matAutocomplete]="statusMenu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #statusMenu="matAutocomplete" [displayWith]="display">
                            <mat-option *ngIf="isStatusLoading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredStatusData.length && !isStatusLoading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredStatusData.length && !isStatusLoading">
                                <mat-option *ngFor="let status of filteredStatusData" [value]="status" 
                                    matTooltip="{{ status.description}}" matTooltipPosition="right">
                                    {{ status.description}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button
                            [disabled]="!secondFormGroup.value.custodianId && !secondFormGroup.value.locationId || (!assetRowData?.length && !stockRowData?.length && !materialRowData?.length)"
                            (click)="setCartMessage()">Next</button>
                    </div>
                </form>

                <form *ngIf="this.firstFormGroup.value.action === 'addToGroup'" [formGroup]="groupFormGroup">
                    <ng-template matStepLabel>Fill in details</ng-template>
                    <mat-form-field class="cart-controls">
                        <mat-label>Group</mat-label>
                        <input #CommonScrollControl type="text" placeholder="Group" matInput formControlName="groupId"
                            [matAutocomplete]="groupMenu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #groupMenu="matAutocomplete" [displayWith]="display">
                            <mat-option *ngIf="isGroupLoading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredGroupData.length && !isGroupLoading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredGroupData.length && !isGroupLoading">
                                <mat-option *ngFor="let group of filteredGroupData" [value]="group"
                                    matTooltip="{{ group.groupName }}" matTooltipPosition="right">
                                    {{ group.groupName }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button [disabled]=" !groupFormGroup.controls['groupId']?.value?.id "
                        (click)="setCartMessage()">Next</button>
                    </div>
                </form>

                <form *ngIf="this.firstFormGroup.value.action === 'addToDocument'" [formGroup]="documentFormGroup">
                    <ng-template matStepLabel>Fill in details</ng-template>
                    <mat-form-field class="cart-controls">
                        <mat-label>Document</mat-label>
                        <input #CommonScrollControl type="text" placeholder="Document" matInput formControlName="documentId"
                            [matAutocomplete]="documentMenu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #documentMenu="matAutocomplete" [displayWith]="display">
                            <mat-option *ngIf="isDocumentLoading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredDocumentData.length && !isDocumentLoading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredDocumentData.length && !isDocumentLoading">
                                <mat-option *ngFor="let document of filteredDocumentData" [value]="document" 
                                    matTooltip="{{ document.documentName}}" matTooltipPosition="right">
                                    {{ document.documentName }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button [disabled]="!documentFormGroup.dirty "
                            (click)="setCartMessage()">Next</button>
                    </div>
                    </form>

                <form *ngIf="this.firstFormGroup.value.action === 'addToDocumentV2'" [formGroup]="documentV2FormGroup">
                    <ng-template matStepLabel>Fill in details</ng-template>
                    <mat-form-field class="cart-controls">
                        <mat-label>Document</mat-label>
                        <input #CommonScrollControl type="text" placeholder="Document" matInput formControlName="documentV2Id"
                            [matAutocomplete]="documentV2Menu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #documentV2Menu="matAutocomplete" [displayWith]="display">
                            <mat-option *ngIf="isDocumentV2Loading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredDocumentV2Data.length && !isDocumentV2Loading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredDocumentV2Data.length && !isDocumentV2Loading">
                                <mat-option *ngFor="let document of filteredDocumentV2Data" [value]="document"
                                 matTooltip="{{document.documentName}}" matTooltipPosition="right">
                                    {{ document.documentName }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button [disabled]="!documentV2FormGroup.controls['documentV2Id']?.value?.id"
                        (click)="setCartMessage()">Next</button>
                    </div>
                </form>

                <form *ngIf="this.firstFormGroup.value.action === 'actionDocumentV2'" [formGroup]="actionDocumentV2FormGroup">
                    <ng-template matStepLabel>Fill in details</ng-template>
                    <mat-form-field class="cart-controls">
                        <mat-label>Document Status</mat-label>
                        <input #CommonScrollControl type="text" placeholder="Document Status" matInput formControlName="documentV2StatusId"
                            [matAutocomplete]="documentV2Menu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #documentV2Menu="matAutocomplete" [displayWith]="display">
                            <mat-option *ngIf="isDocumentV2StatusLoading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredDocumentV2StatusData.length && !isDocumentV2StatusLoading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredDocumentV2StatusData.length && !isDocumentV2StatusLoading">
                                <mat-option *ngFor="let document of filteredDocumentV2StatusData" [value]="document"
                                    matTooltip="{{ document.description }}" matTooltipPosition="right" >
                                    {{ document.description }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-checkbox formControlName="isLocked">Finalise and lock the document</mat-checkbox>
                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button [disabled]="!actionDocumentV2FormGroup.controls['documentV2StatusId']?.value?.id"
                           (click)="setCartMessage()">Next</button>
                    </div>
                </form>

                <form *ngIf="this.firstFormGroup.value.action === 'bulkUpdateStatus'" [formGroup]="bulkUpdateStatusFormGroup">
                    <ng-template matStepLabel>Fill in details</ng-template>
                    <mat-form-field class="cart-controls">
                        <mat-label>Status</mat-label>
                        <input #CommonScrollControl type="text" placeholder="Status" matInput formControlName="statusId"
                            [matAutocomplete]="statusMenu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #statusMenu="matAutocomplete" [displayWith]="display">
                            <mat-option *ngIf="isStatusLoading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredStatusData.length && !isStatusLoading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredStatusData.length && !isStatusLoading">
                                <mat-option *ngFor="let status of filteredStatusData" [value]="status"
                                    matTooltip="{{ status.description }}" matTooltipPosition="right">
                                    {{ status.description}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button [disabled]="!bulkUpdateStatusFormGroup.controls['statusId']?.value?.id "
                            (click)="setCartMessage()">Next</button>
                    </div>
                </form>

                <form *ngIf="this.firstFormGroup.value.action === 'updateLocation'" [formGroup]="updateLocationFormGroup">
                    <ng-template matStepLabel>Fill in details</ng-template>
                    <mat-form-field class="cart-controls">
                        <mat-label>Location</mat-label>
                        <input #CommonScrollControl type="text" placeholder="Location" matInput formControlName="locationId"
                            [matAutocomplete]="statusMenu">
                        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                        <mat-autocomplete autoActiveFirstOption #statusMenu="matAutocomplete" [displayWith]="display">
                            <mat-option *ngIf="isLocationLoading">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </mat-option>
                            <mat-option *ngIf="!filteredLocationData.length && !isLocationLoading">No Results Found.
                            </mat-option>
                            <ng-container *ngIf="filteredLocationData.length && !isLocationLoading">
                                <mat-option *ngFor="let location of filteredLocationData" [value]="location"
                                    matTooltip="{{ location.description }}" matTooltipPosition="right">
                                    {{ location.description}}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                
                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button [disabled]="!updateLocationFormGroup.controls['locationId']?.value?.id "
                            (click)="setCartMessage()">Next</button>
                    </div>
                </form>

                <div fxLayout="column" *ngIf="this.firstFormGroup.value.action === 'bulkTransfer'">
                    <form [formGroup]="bulkTransferFormGroup">
                        <mat-form-field class="cart-controls">
                            <mat-label>Site</mat-label>
                            <input #CommonScrollControl type="text" placeholder="Site" matInput formControlName="siteId"
                                [matAutocomplete]="auto">
                            <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"
                                (click)="siteChanged = true;filteredSiteData = allSitesInfo"></fa-icon>
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngIf="!filteredSiteData?.length">No Results Found.
                                </mat-option>
                                <ng-container *ngIf="!!filteredSiteData?.length">
                                    <mat-option *ngFor="let option of filteredSiteData" [value]="option.siteName"
                                        matTooltip="{{ option.siteName}}" matTooltipPosition="right">
                                        {{option.siteName}}
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </form>
                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button matStepperNext
                            *ngIf="this.firstFormGroup.value.action === 'bulkTransfer'" (click)="setCartMessage()"
                            [disabled]="siteData?.siteName === bulkTransferFormGroup.get('siteId')?.value || bulkTransferFormGroup.get('siteId')?.value?.trim() == '' || !filteredSiteData?.length || bulkTransferFormGroup.invalid || osdValidationMessage !== '' || osdFieldMandMessage !== '' || (!assetRowData?.length && !stockRowData?.length && !materialRowData?.length && !groupRowData?.length && !documentRowData?.length)">Next</button>

                            <span *ngIf="osdValidationMessage !== ''" class="cartErrorMessage">{{osdValidationMessage}}</span>
                        </div>
                        <br>
                        <div>
                            <span class="label-text mt-1 mandatory-label-text" *ngIf="osdFieldMandMessage !== ''">{{osdFieldMandMessage}}</span>
                        </div>    
                </div>

                <form fxLayout="column"
                    *ngIf="this.firstFormGroup.value.action === 'bulkUpdateAsset' || this.firstFormGroup.value.action === 'bulkUpdateStockItem' || this.firstFormGroup.value.action === 'bulkUpdateMaterial' || this.firstFormGroup.value.action === 'bulkUpdateGroup' " [formGroup]="bulkUpdateActionFieldsFormGroup">
                    <ng-template matStepLabel>Fill in details</ng-template>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div fxflex="20">
                        <mat-form-field class="w-48">
                            <mat-select id="bulkUpdateSelect" [formControl]="fieldMultiCtrl"
                                (selectionChange)="addOrRemoveFields($event.value)" placeholder="Choose Field(s)"
                                [multiple]="true" #multiSelect>
                                <mat-option>
                                    <!-- <ngx-mat-select-search [placeholderLabel]="'Search Fields'"
                                        [noEntriesFoundLabel]="'No matching field found'" [showToggleAllCheckbox]="true"
                                        [toggleAllCheckboxIndeterminate]="toggleAllCheckboxIndeterminate"
                                        [toggleAllCheckboxChecked]="toggleAllCheckboxChecked"
                                        (toggleAll)="toggleSelectAll($event)" [formControl]="fieldMultiFilterCtrl">
                                    </ngx-mat-select-search> -->
                                    <ngx-mat-select-search [placeholderLabel]="'Search Fields'"
                                        [noEntriesFoundLabel]="'No matching field found'" [showToggleAllCheckbox]="true"
                                        [toggleAllCheckboxIndeterminate]="toggleAllCheckboxIndeterminate"
                                        [toggleAllCheckboxChecked]="toggleAllCheckboxChecked"
                                        (toggleAll)="toggleSelectAll($event)" [formControl]="fieldMultiFilterCtrl">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <div [ngClass]="{'h-40': searchedFieldsAvailable, 'h-32': !searchedFieldsAvailable}"
                                    class="overflow-auto">
                                    <mat-option *ngFor="let field of availableFields | async" [value]="field">
                                        {{field.fieldDisplayName}}
                                    </mat-option>
                                </div>
                                <div class="fields-ok-btn border-solid border-t pt-1 pb-1 mr-1" fxFlex
                                    fxLayoutAlign="end center">
                                    <button (click)="multiSelect.close();" [disabled]="noFieldsSelected"
                                        class="actionButton" matTooltip="Add field(s) to update" type="button"
                                        mat-raised-button>
                                        OK
                                    </button>
                                    <!-- <mat-icon class="save-column mr-1 ml-1" matTooltip="Reset Column Layout" [ngClass]="{'disabled-icon': noFieldsSelected}"
                                        (click)="multiSelect.close();" matTooltip="Add field(s) to update">
                                        OK
                                    </mat-icon> -->
                                    <!-- <mat-icon (click)="multiSelect.close();" class="cancel-icon" matTooltip="Cancel">
                                        close
                                    </mat-icon> -->
                                    <!-- <button (click)="multiSelect.close();"
                                        style="margin-left: 10px; vertical-align: middle; padding-left:10px; padding-right:10px;"
                                        mat-raised-button color="warn">
                                        Cancel
                                    </button> -->
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10">
                        <span fxFlex="20" *ngIf="qtyErrorMessage !== ''" class="cartErrorMessage">{{qtyErrorMessage}}</span>
                        <span fxFlex="30" *ngIf="dateErrorMessage !== ''" class="cartErrorMessage">{{dateErrorMessage}}</span>
                    </div>
                </div>

                    <ng-container *ngFor="let i = index; let updateField of fieldsToUpdate">
                        <div class="py-2 pl-2 rounded-sm label-text cart-bulk-update-border" *ngIf="i === 0;">
                            <span fxFlex="20">Field</span>
                            <span fxFlex="20">Value</span>
                            <span fxFlex>Clear Field</span>
                        </div>
                        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" class="ml-2">
                            <div fxFlex="20" class="label-text">{{updateField.fieldDisplayName}}</div>
                            <div fxFlex="20">
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'category'" class="cart-controls" label="Category" formControlName="categoryId"  [isLoading]="isCategoryLoading" [optionsData]="categoryData" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'status'" class="cart-controls" label="Status" formControlName="statusId"  [isLoading]="isStatusLoading" [optionsData]="statusData" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'manufacturer'" class="cart-controls" label="Manufacturer" formControlName="manufacturerId"  [isLoading]="isManufacturerLoading" [optionsData]="manufacturerData" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'custodian'" class="cart-controls" label="Custodian" formControlName="custodianId"  [isLoading]="isCustodianLoading" [optionsData]="custodianData" displayField="employeeName" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'location'" class="cart-controls" label="Location" formControlName="locationId"  [isLoading]="isLocationLoading" [optionsData]="locationData" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'workOrder'" class="cart-controls" label="Work Order" formControlName="workOrderId"  [isLoading]="isWorkorderLoading" [optionsData]="workorderData" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'supplier'" class="cart-controls" label="Supplier" formControlName="supplierId"  [isLoading]="isSupplierLoading" [optionsData]="supplierData" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'condition'" class="cart-controls" label="Condition" formControlName="conditionId"  [isLoading]="isConditionLoading" [optionsData]="conditionData" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'groupTemplate'" class="cart-controls" label="GroupTemplate" formControlName="groupTemplateId"  [isLoading]="isGroupTemplateLoading" [optionsData]="groupTemplatedata" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-autocomplete-dropdown *ngIf="updateField.fieldName == 'groupType'" class="cart-controls" label="GroupType" formControlName="groupTypeId"  [isLoading]="isGroupTypeLoading" [optionsData]="groupTypedata" displayField="description" [matTooltipDisabled]="false"></app-autocomplete-dropdown>
                                <app-field-controls *ngIf="updateField.fieldName !== 'workOrder' &&
                                                           updateField.fieldName !== 'condition' &&
                                                           updateField.fieldName !== 'manufacturer' &&
                                                           updateField.fieldName !== 'status' &&
                                                           updateField.fieldName !== 'custodian' &&
                                                           updateField.fieldName !== 'category' &&
                                                           updateField.fieldName !== 'location' &&
                                                           updateField.fieldName !== 'supplier' &&
                                                           updateField.fieldName !== 'groupType' &&
                                                           updateField.fieldName !== 'groupTemplate'" [fieldType]="updateField.fieldType"
                                    [fieldName]="updateField.fieldName" [clearSelected]="updateField.clear"
                                    (changedValue)="valueChangedData(updateField.fieldName, $event)">
                                </app-field-controls>
                            </div>
                            <div fxFlex="10">
                                <mat-checkbox *ngIf="updateField.fieldType !== 'bit'"
                                    (change)="clearChanged(updateField.fieldName, $event.checked);updateField.clear = $event.checked"
                                    name="emptyField" matTooltip="Empty the field"></mat-checkbox>
                            </div>
                            <button class="ml-3 align-middle" color="warn" (click)="removeField(updateField)"
                                matTooltip="Remove field" matTooltipPosition="right" mat-flat-button>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </ng-container>

                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                        <button class="actionButton" mat-raised-button matStepperNext
                            [disabled]="!fieldsToUpdate.length || (!dynamicLatitudeValid || !dynamicLongitudeValid) || qtyErrorMessage !== '' || dateErrorMessage !== '' || isNegative == true || !isFormValid"
                            (click)="setCartMessage()">Next</button>
                    </div>
                </form>

                <form *ngIf="this.firstFormGroup.value.action === 'createOSDMaterials'" [formGroup]="osdFormGroup">
                    <ng-template matStepLabel>Fill in details</ng-template>
                   

                    <mat-form-field class="cart-controls">
                        <input type="text" placeholder="Docket Number" matInput [formControl]="docketNumber">
                    </mat-form-field>
                    <mat-form-field class="cart-controls">
                        <input type="text" placeholder="PO Number" matInput [formControl]="poNumber">
                    </mat-form-field>
                    <mat-form-field class="cart-controls">
                        <input type="text" placeholder="Creator Phone" matInput [formControl]="creatorPhone">
                    </mat-form-field>

                    <mat-form-field class="cart-controls">
                        <input type="text" placeholder="Supplier Company" matInput [formControl]="supplierCompany">
                    </mat-form-field>
                    <mat-form-field class="cart-controls">
                        <input type="text" placeholder="Supplier Contact Person" matInput
                            [formControl]="supplierContactPerson">
                    </mat-form-field>
                    <mat-form-field class="cart-controls">
                        <input type="text" placeholder="Supplier Phone" matInput [formControl]="supplierPhone">
                    </mat-form-field>
                    <mat-form-field class="cart-controls">
                        <input type="text" placeholder="Supplier Contact Email" matInput
                            [formControl]="supplierContactEmail">
                    </mat-form-field>
                   
                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <button class="actionButton" mat-raised-button matStepperPrevious (click)="onBack()">Back</button>
                        <button class="actionButton" mat-raised-button
                        [disabled]="disablesaveButton || osdValidationMessage !== '' || osdFieldMandMessage !== ''" 
                            (click)="setCartMessage()">Next</button>
                       <span *ngIf="osdValidationMessage !== ''" class="cartErrorMessage">{{osdValidationMessage}}</span>
                    </div>
                    <br>
                    <div>
                        <span class="label-text mt-1 mandatory-label-text" *ngIf="osdFieldMandMessage !== ''">{{osdFieldMandMessage}}</span>
                    </div>
                </form>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Finalise</ng-template>
                <p class="mat-step-message" [fxShow]="this.firstFormGroup.value.action === '0' || this.firstFormGroup.value.action === '1'">The following assets and/or stock items, materials are {{cartMessage}}.</p>
                <mat-form-field class="w-3/12 mapSearchBar">
                    <mat-label>Comment</mat-label>
                    <textarea [formControl]="commentFC" matInput autocomplete="off"></textarea>
                </mat-form-field>
                <div fxLayout="row wrap" fxLayoutGap="15px">
                    <button class="actionButton" mat-raised-button matStepperPrevious>Back</button>
                    <button class="actionButton" mat-raised-button (click)="submitData()" [disabled]="(!assetRowData?.length && !stockRowData?.length && !materialRowData?.length && !groupRowData?.length && !documentRowData?.length && !documentV2RowData?.length) || errorMessage !== '' || osdValidationMessage !== '' || osdFieldMandMessage !== '' || gpsErrorMessage !== ''  ||!reqBody">Submit</button> 
                    <span *ngIf="osdValidationMessage !== ''" class="cartErrorMessage">{{osdValidationMessage}}</span>
                    <span *ngIf="gpsErrorMessage !== ''" class="cartErrorMessage">{{gpsErrorMessage}}</span>
                </div>
                <br>
                <div>
                    <span class="label-text mt-1 mandatory-label-text" *ngIf="osdFieldMandMessage !== ''">{{osdFieldMandMessage}}</span>
                </div>
        </mat-step>
        </mat-stepper>

        <div class="mx-2 mt-3 rounded-lg" *ngIf="assetRowData.length!==0">
            <div class="pb-2 ml-1 gridCartTitle text-base font-semibold">Assets List</div>
            <ag-grid-angular
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid mat-elevation-z6 rounded-md" [rowData]="assetRowData" [defaultColDef]="defaultColDef" domLayout='autoHeight'
                [suppressDragLeaveHidesColumns]="true" [columnDefs]="assetDefaultColDef" [statusBar]="statusBar" [frameworkComponents]="frameworkComponents" (cellEditingStarted)="onCellEditingStarted($event)"
                [headerHeight]="40" (gridReady)="onAssetGridReady($event)">
            </ag-grid-angular>
        </div>

        <div class="mx-2 mt-12 rounded-lg" *ngIf="stockRowData.length!==0">
            <div class="pb-2 ml-1 gridCartTitle text-base font-semibold">Stock Items List</div>
            <ag-grid-angular
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid mat-elevation-z6 rounded-md" [rowData]="stockRowData" [defaultColDef]="defaultColDef"
                [suppressDragLeaveHidesColumns]="true" [columnDefs]="stockDefaultColDef" [statusBar]="statusBar" [frameworkComponents]="frameworkComponents" (cellEditingStarted)="onCellEditingStarted($event)"
                [headerHeight]="40" (gridReady)="onStockGridReady($event)" domLayout='autoHeight'>
            </ag-grid-angular>
        </div>

            <div class="osdAttachment" *ngIf="isAttachmentAllowed == true">
                <button mat-flat-button class="actionButton" *ngIf="this.firstFormGroup.value.action === 'createOSDMaterials'" (click)="osdAttachment();" [disabled]="osdAttachmentData.length == 5">
                    Upload Attachments
                </button>
                <span [fxHide]="osdAttachmentData.length < 5" class="text-red-600 ml-2 mt-4"><u>Only a maximum of 5 photos are allowed per OSD</u></span>
            </div>
            <div class="mx-2 mt-8 rounded-lg" *ngIf="osdAttachmentData.length!==0 && isAttachmentAllowed == true">
                <ag-grid-angular
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid mat-elevation-z6 rounded-md" [rowData]="osdAttachmentData" [defaultColDef]="defaultColDef"
                [suppressDragLeaveHidesColumns]="true" [columnDefs]="osdAttachmentDefaultColDef" [statusBar]="statusBar"
                [headerHeight]="40" (gridReady)="onOSDAttachmentGridReady($event)" domLayout='autoHeight'>
            </ag-grid-angular>
          </div>
           <div class="mx-2 mt-12 rounded-lg" *ngIf="materialRowData.length!==0">   
            <div class="pb-2 ml-1 gridCartTitle text-base font-semibold">Material List</div>
           <ag-grid-angular
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid mat-elevation-z6 rounded-md" [rowData]="materialRowData" [defaultColDef]="defaultColDef"
                [suppressDragLeaveHidesColumns]="true" [columnDefs]="materialDefaultColDef" [statusBar]="statusBar"
                [headerHeight]="40" (gridReady)="onMaterialGridReady($event)" [gridOptions]="materialGridOptions">
            </ag-grid-angular>
        </div>
        <div class="mx-2 mt-12 rounded-lg" *ngIf="groupRowData.length!==0">
            <div class="pb-2 ml-1 gridCartTitle text-base font-semibold">Group List</div>
            <ag-grid-angular
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid mat-elevation-z6 rounded-md" [rowData]="groupRowData" [defaultColDef]="defaultColDef"
                [suppressDragLeaveHidesColumns]="true" [columnDefs]="groupColDef" [statusBar]="statusBar" [frameworkComponents]="frameworkComponents" 
                [headerHeight]="40" (gridReady)="onGroupGridReady($event)" domLayout='autoHeight'>
            </ag-grid-angular>
        </div>
        <div class="mx-2 mt-12 rounded-lg" *ngIf="documentRowData.length!==0">
            <div class="pb-2 ml-1 gridCartTitle text-base font-semibold">Document List</div>
            <ag-grid-angular
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid mat-elevation-z6 rounded-md" [rowData]="documentRowData" [defaultColDef]="defaultColDef"
                [suppressDragLeaveHidesColumns]="true" [columnDefs]="documentColDef" [statusBar]="statusBar" [frameworkComponents]="frameworkComponents" 
                [headerHeight]="40" (gridReady)="onDocumnetGridReady($event)" domLayout='autoHeight'>
            </ag-grid-angular>
        </div>
        <div class="mx-2 mt-12 rounded-lg" *ngIf="documentV2RowData.length!==0">
            <div class="pb-2 ml-1 gridCartTitle text-base font-semibold">Document List</div>
            <ag-grid-angular
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid mat-elevation-z6 rounded-md" [rowData]="documentV2RowData" [defaultColDef]="defaultColDef"
                [suppressDragLeaveHidesColumns]="true" [columnDefs]="documentV2ColDef" [statusBar]="statusBar" [frameworkComponents]="frameworkComponents" 
                [headerHeight]="40" (gridReady)="onDocumnetV2GridReady($event)" domLayout='autoHeight'>
            </ag-grid-angular>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isCartData">
    <div class="h-full" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
        <mat-icon class="mat-step-message mr-12 mt-2 text-6xl cartIconBadge" matBadgeSize="large" matBadge='0'>
            shopping_cart
        </mat-icon>
        <div fxLayout="column" fxLayoutAlign="center center" class="mat-step-message font-medium text-lg">
            <span>There are no items in the cart.</span>
            <span>Your cart is Empty.</span>
        </div>
    </div>
</ng-container>
