import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IAPIResponse } from 'src/app/view-models/common.model';
import { environment } from '../../../environments/environment';
import { IRequestOptions } from '../../view-models/request.options';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class CustomHttpClientService {
  post<T>(arg0: string, assetColumn: any, options: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  apiUrl: string;

  constructor(private http: HttpClient, private loaderService: LoaderService) {
    this.apiUrl = environment.apiUrl + "/";
  }

  public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    if (options && options.showLoadingIndicator) {
      this.loaderService.show();
    }
    const fullPath = (options && options.params && options.fullPath) ? endPoint : this.apiUrl + endPoint;
    return this.http.get<T>(fullPath, options).pipe(
      finalize(() => {
        if (options?.showLoadingIndicator) {
          this.loaderService.hide()
        }
      })
    );
  }

  public GetBlob(endPoint: string, options: IRequestOptions): Observable<Blob> {
    if (options && options.showLoadingIndicator) {
      this.loaderService.show();
    }
    const fullPath = (options && options.params && options.fullPath) ? endPoint : this.apiUrl + endPoint;
    return this.http.get<Blob>(fullPath, {
        headers: options.headers,
        observe: 'body',
        responseType: 'blob' as 'json',
        reportProgress: options.reportProgress,
        params: options.params,
        withCredentials: options.withCredentials
      }).pipe(finalize(()=>{
        if (options?.showLoadingIndicator) {
              this.loaderService.hide()
            }
      }))
  }

  public GetExtended<T>(endPoint: string, options?: IRequestOptions): Observable<IAPIResponse<T>> {
    if (options && options.showLoadingIndicator) {
      this.loaderService.show();
    }
    const fullPath = (options && options.params && options.fullPath) ? endPoint : this.apiUrl + endPoint;

    return this.http.get<IAPIResponse<T>>(fullPath, options).pipe(finalize(() => this.loaderService.hide()));
  }

  public Post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    if (options && options.showLoadingIndicator) {
      this.loaderService.show();
    }
    const fullPath = options?.fullPath ? endPoint : this.apiUrl + endPoint;
    return this.http.post<T>(fullPath, params, options).pipe(finalize(() => this.loaderService.hide()));
  }

  public Put<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    if (options && options.showLoadingIndicator) {
      this.loaderService.show();
    }
    return this.http.put<T>(this.apiUrl + endPoint, params, options).pipe(finalize(() => this.loaderService.hide()));
  }

  public Delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    this.loaderService.show();
    return this.http.delete<T>(this.apiUrl + endPoint, options).pipe(finalize(() => this.loaderService.hide()));
  }
}
