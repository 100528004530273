import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: 'input[numbersOnly]'
})

export class NumberDirective {

  @Input() numbersOnly!: boolean;

  constructor(private _element: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: { stopPropagation: () => void; }) {
    const initialVal = this._element.nativeElement.value;
    if (this.numbersOnly) {
      this._element.nativeElement.value = initialVal.replace(/[^0-9]*/g, '');
      if (initialVal !== this._element.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }
}
