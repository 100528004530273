import { Subscription } from 'rxjs';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ISiteModel } from 'src/app/view-models/site-model';

interface ICellParams {
  displayGroup?: boolean;
  displayInspection?: boolean;
  displayDocument?: boolean;
  displayDocumentV2?: boolean;
  displayOsdId?: boolean;
  siteData: ISiteModel
}

@Component({
  selector: 'app-display-group-name',
  templateUrl: './display-group-name.component.html'
})
export class DisplayGroupNameComponent implements ICellRendererAngularComp, OnInit {

  /** groups data */
  groupsData!: {
    groupId: number;
    groupDescription: string;
  }[];

  /** documents data */
  documentsData!: {
    documentId: number;
    documentName: string;
  }[];

  /** documents data */
  documentsV2Data!: {
    documentId: number;
    documentName: string;
  }[];

  templateMapping!: {
    templateId: number;
    title: string;
  };

  osdData!: {
    osdId: number
  }[];
  /** subscription handler */
  subs!: Subscription;

  /** display group and inspection template */
  displayGroup = false;
  displayDocument = false;
  displayInspection = false;
  displayDocumentV2 = false;
  displayOsd = false;
  siteData! : ISiteModel;

  constructor() {
    this.subs = new Subscription();
  }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams & ICellParams): void {
    this.groupsData = params.data.groups;
    this.documentsData = params.data.documents;
    this.documentsV2Data = params.data.documentV2;
    this.templateMapping = params.data.templateMapping;
    this.osdData = params.data.materialOSDDetails;
    this.displayOsd = params.displayOsdId as boolean;
    this.displayGroup = params.displayGroup as boolean;
    this.displayDocument = params.displayDocument as boolean;
    this.displayDocumentV2 = params.displayDocumentV2 as boolean;
    this.displayInspection = params.displayInspection as boolean;
    this.siteData = params.siteData 
  }

  refresh(): boolean {
    return false;
  }

  /**
   * get group href
   *
   * @param {string} groupDescription
   * @return {*}  {string}
   * @memberof DisplayGroupNameComponent
   */
  getGrpHref(groupDescription: string | number, flag: boolean = false): string {
    if (flag) {
      return `#/inspections/create-edit-inspections/${groupDescription}?flag=${flag}?data=${JSON.stringify(this.siteData)}`;
    } else {
      return `#/groups/${groupDescription}?data=${JSON.stringify(this.siteData)}`;
    }
  }

  getDocHref(documentName: string | number, isDocumentV2 = false): string{
    return isDocumentV2 !== true ? `#/documents/${documentName}` : `#/documents-v2/${documentName}?data=${JSON.stringify(this.siteData)}`;
  }

  getOsdHref(osdId : string | number): string{
    return `#/osd/${osdId}?data=${JSON.stringify(this.siteData)}`
  }
}
