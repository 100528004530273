import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  currYear!: number;
  version!: string;

  constructor() {
    this.currYear = new Date().getFullYear();
    this.version = '2022.5.9.1';
   }

  ngOnInit(): void {
  }

}
