<mat-card class="mat-elevation-z6 bg-01dp mx-2 mb-2" fxLayout="row" [fxLayoutAlign]="!checkUserAccess() ? 'end center' : 'space-between center'">
  <div fxLayout="row" *ngIf="checkUserAccess()">
    <button class="grid-search-icon mr-10px" mat-raised-button matTooltip="Create Asset"
      (click)="assetService.setSearchedAsset(searchAssetsFC.value)" [routerLink]="['create-edit-assets','0']">
      <mat-icon>add</mat-icon>
    </button>
    <button class="grid-search-icon mr-10px" mat-raised-button matTooltip="Add to Cart" (click)="addAssetsToCart()"
      [disabled]="!isCartCheck">
      <mat-icon>shopping_cart</mat-icon>
    </button>
    <app-csvbox [importType]="'asset'" (closeCsv)="getSearchedAssetData()"></app-csvbox>
    <button class="grid-search-icon mr-10px" mat-raised-button matTooltip="Export all Assets" (click)="exportData()" [disabled]="exportDisabled">
        <mat-icon *ngIf="loadExportIcon">cloud_download</mat-icon>
        <mat-spinner *ngIf="!loadExportIcon" class="ml-2" [diameter]="15"></mat-spinner>
    </button>
    <button *ngIf="checkAdminRole()" class="grid-search-icon mr-10px" color="warn" mat-raised-button matTooltip="Delete Asset(s)" (click)="deleteAsset()" [disabled]="!isCartCheck">
      <mat-icon>delete</mat-icon>
    </button>    
  </div>

  <div class="searchBarDiv" fxLayout="row">
    <app-search-bar class="w-[475px]" [searchFormControl]="searchAssetsFC"  (keyup)="onKeyUp($event)"  placeholder="Search by Asset Number, Barcode or Description" (searchChangeEvent)="getSearchedAssetData()"></app-search-bar>

    <div
      [ngClass]="{'text-white': loaderService.themeToggleSubject.value === 'dark', 'text-black':  loaderService.themeToggleSubject.value === 'light'}"
      class="ml-3" fxLayout="row" fxLayoutAlign="end center">     
      <mat-icon class="reset-column mr-1 ml-1" matTooltip="Reset Column Layout" (click)="assetService.saveOrResetSub.next('reset')">settings_backup_restore</mat-icon>
      <mat-icon class="save-column" matTooltip="Save Column Layout" (click)="assetService.saveOrResetSub.next('save');">
        save
      </mat-icon>
    </div>
  </div>
</mat-card>

<app-assets-details [checkUserAccess]="checkUserAccess()" [assetSearched]="assetSearched" [clearSelectedRows]="clearSelectedRows" [startRow]="startRow" [endRow]="endRow" [totalCount]="totalCount"
  (clearSearchedAssetValue)="clearSearchedValue()" (exportIconLoader)="exportIconLoader($event)" [assetsDetails]="assetsDetails" (columnMoved)="columnMovedEvent($event)"></app-assets-details>
