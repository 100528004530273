import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CartService } from 'src/app/services/cart/cart.service';
import { LoaderService } from 'src/app/services/common/loader.service';

@Component({
  selector: 'app-numeric-value-only',
  templateUrl: './numeric-value-only.component.html',
  styles: [
  ]
})
export class NumericValueOnlyComponent implements OnInit {

  @ViewChild('input', { static: false }) input!: ElementRef<HTMLInputElement>;
  ngOnInit(): void {
  }

  constructor(public loaderService: LoaderService, private cartService: CartService) { }

  private params: any;
  public value!: number;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
  }

  getValue(): any {
    return this.value as Number;
  }

  isPopup(): boolean {
    return false;
  }

  ngAfterViewInit() {
    this.cartService.selectedValueOfCellEditor$.subscribe(res => {
      if (res == true) {
        this.input.nativeElement.focus();
      }
    })
  }
}
