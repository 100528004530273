import { Subscription } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-field-controls',
  templateUrl: './field-controls.component.html'
})
export class FieldControlsComponent implements OnInit {

  /** field control type */
  @Input() fieldType!: string;

  /** clear value is selected or not */
  @Input() clearSelected!: boolean;

  /** field name */
  @Input() fieldName!: string;

  /** Form Controls */
  dateFieldFC!: FormControl;
  numberFieldFC!: FormControl;
  stringFieldFC!: FormControl;
  booleanFieldFC!: FormControl;

  /** output variables */
  @Output() changedValue = new EventEmitter();

  /** subscription handler */
  subs!: Subscription;

  errorMessage!: string;

  constructor() {
    this.dateFieldFC = new FormControl('');
    this.numberFieldFC = new FormControl('', Validators.min(0));
    this.stringFieldFC = new FormControl('');
    this.booleanFieldFC = new FormControl('');
    this.subs = new Subscription();
  }

  ngOnChanges(): void {
    if (this.clearSelected && this.fieldType === 'datetimeoffset') {
      this.dateFieldFC.setValue('');
      this.dateFieldFC.disable();
    } else {
      this.dateFieldFC.enable();
    }

    if (this.clearSelected && (this.fieldType === 'int' || this.fieldType === 'bigint' || this.fieldType === 'decimal')) {
      this.numberFieldFC.setValue('');
      this.numberFieldFC.disable();
    } else {
      this.numberFieldFC.enable();
    }

    if (this.clearSelected && (this.fieldType === 'varchar' || this.fieldType === 'nvarchar')) {
      this.stringFieldFC.setValue('');
      this.stringFieldFC.disable();
    } else {
      this.stringFieldFC.enable();
    }
  }

  ngOnInit(): void {
    if (this.fieldName === 'gpsLatitude' ) {
      this.numberFieldFC.setValidators([Validators.min(-90),Validators.max(90)])
      this.errorMessage = "Latitude should be between -90 to 90"
    }
    if (this.fieldName === 'gpsLongitude' ) {
      this.numberFieldFC.setValidators([Validators.min(-180),Validators.max(180)])
      this.errorMessage = "Longitude should be between -180 to 180"
    }

    const dateFieldChanged = this.dateFieldFC.valueChanges.subscribe(val => this.changedValue.emit({fieldVal: val, fieldType: 'date'}));
    this.subs.add(dateFieldChanged);

    const numericFieldChanged = this.numberFieldFC.valueChanges.subscribe(val =>{
      this.changedValue.emit({fieldVal: val, fieldType: 'number'})
      if (val < 0) {
         this.errorMessage = 'Negative value is not allowed'
      } 
    });
    this.subs.add(numericFieldChanged);
   
    const stringFieldChanged = this.stringFieldFC.valueChanges.subscribe(val => this.changedValue.emit({fieldVal: val, fieldType: 'string'}));
    this.subs.add(stringFieldChanged);

    const booleanFieldChanged = this.booleanFieldFC.valueChanges.subscribe(val => this.changedValue.emit({fieldVal: val, fieldType: 'boolean'}));
    this.subs.add(booleanFieldChanged);
  }

  handleKeyPress(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
