import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IAPIResponse, IGridPagination, IGetFile } from 'src/app/view-models/common.model';
import { IMaterials, IOSDDetailData, IPagination, ISaveMaterial, ISplitMaterialReq, ISplitReq } from 'src/app/view-models/materials-model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { IResolveOSDMaterialModel } from 'src/app/view-models/resolve-osd-material-model';
import { CustomHttpClientService } from '../common/custom-http-client.service';
import { environment } from 'src/environments/environment';
import { IMapModel } from 'src/app/view-models/map-model';

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {
  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  /** searched material value */
  searchedMaterial!: string;

  /** material grid pagination data */
  materialsPaginationData: BehaviorSubject<IGridPagination> = new BehaviorSubject({ pageNumber: 1, pageSize: 8 });

  /** save/reset column layout */
  saveResetColumnLayout = new BehaviorSubject('');

  refreshOSDGrid = new BehaviorSubject('');

  /** refresh grid */
  refreshMaterial = new BehaviorSubject('');
  
  materialRowData = new Subject()

  deselectMaterial = new Subject();

  constructor(private http: CustomHttpClientService) { }

  /**
   * set searched material value
   *
   * @param {string} searchedValue
   * @memberof MaterialsService
   */
  setSearchedMaterial(searchedValue: string): void {
    this.searchedMaterial = searchedValue;
  }

  /**
   * get searched material value
   *
   * @return {*}  {string}
   * @memberof MaterialsService
   */
  getSearchedMaterial(): string {
    return this.searchedMaterial;
  }

  /**
   * get materials searched data
   *
   * @param {number} siteId
   * @param {HttpParams} [params]
   * @return {*}  {Observable<IAPIResponse<IMaterials[]>>}
   * @memberof MaterialsService
   */

  getSearchedMaterials(siteId: number, reqBody: IPagination): Observable<any> {
    return this.http.Post(`site/${siteId}/searchMaterials`, reqBody, this.reqOpt) as Observable<any>;
  }

  /**
   * get material by id
   *
   * @param {number} materialId
   * @return {*}  {Observable<IMaterials>}
   * @memberof MaterialsService
   */
  getSearchedMaterialsById(materialId: number): Observable<IMaterials> {
    return this.http.Get(`Material/${materialId}`, this.reqOpt).pipe() as Observable<IMaterials>;
  }
  /**
   * create material
   *
   * @param {number} siteId
   * @param {ISaveMaterial} reqBody
   * @return {*}  {Observable<IMaterials>}
   * @memberof MaterialsService
   */
  saveMaterial(siteId: number, reqBody: ISaveMaterial): Observable<IMaterials> {
    return this.http.Post(`site/${siteId}/createMaterial`, reqBody, this.reqOpt).pipe() as Observable<IMaterials>;
  }

  /**
   * update material
   *
   * @param {number} materialId
   * @param {number} siteId
   * @param {ISaveMaterial} reqBody
   * @return {*}  {Observable<ISaveMaterial>}
   * @memberof MaterialsService
   */
  updateMaterial(materialId: number, siteId: number, reqBody: FormData): Observable<ISaveMaterial> {
    return this.http.Put(`site/${siteId}/updateMaterial/${materialId}`, reqBody, this.reqOpt).pipe() as Observable<ISaveMaterial>;
  }

  /**
   * archive Material
   *
   * @param {*} materialId
   * @return {*}  {Observable<any>}
   * @memberof MaterialsService
   */
  archiveMaterial(materialId: number): Observable<any> {
    return this.http.Delete<any>(`Material/${materialId}`)
  }

  /**
 * get all Materials for a site
 *
 * @param {number} siteId
 * @return {Observable<any[]}  {Observable<any[]}
 * @memberof MaterialService
 */
  getAllMaterials(siteId: number): Observable<any[]> {
    return this.http.Get(`site/${siteId}/GetAllMaterials`, {
      withCredentials: true
    }) as Observable<any[]>
  }

  /**
 * osd material detail
 * @param siteId  site id
 * @returns
 */
  getOSDMaterialDetails(siteId: number, params?: HttpParams): Observable<IOSDDetailData[]> {
    return this.http.Get(`site/${siteId}/GetOSDMaterialDetail`, {
      showLoadingIndicator: true,
      params: params
    }) as Observable<IOSDDetailData[]>;
  }

  requestCsvExport(siteId: number): Observable<any> {
    return this.http.Post(`site/${siteId}/RequestMaterialExport`, {}, this.reqOpt)
  }

  resolveOSDMaterial(reqBody: IResolveOSDMaterialModel): Observable<any> {
    return this.http.Put(`Material/ResolveOSDMaterial`, reqBody, this.reqOpt) as Observable<any>
  }

  printOsd(osdId: number): Observable<any> {
    var options: IRequestOptions = {
      showLoadingIndicator: true,
      fullPath: true,
      params: {
        osdId: osdId,
        code: environment.printFunction.key,
        clientId: environment.printFunction.clientId
      }
    }
    return this.http.GetBlob(`${environment.printFunction.apiUrl}/PrintOsd`, options)
  }

  /**
   * get materials searched data
   *
   * @param {number} siteId
   * @param {HttpParams} [params]
   * @return {*}  {Observable<IAPIResponse<IOSDDetailData[]>>}
   * @memberof MaterialsService
   */
  getOSDSearchedMaterials(siteId: number, params?: HttpParams): Observable<IAPIResponse<IOSDDetailData[]>> {
    return this.http.GetExtended(`site/${siteId}/searchOSDMaterials`, {
      showLoadingIndicator: true,
      params: params
    }) as Observable<IAPIResponse<IOSDDetailData[]>>;
  }

  /**
   * get gps details for materials
   *
   * @param {number} siteId
   * @return {*}  {Observable<IMapModel[]>}
   * @memberof AssetService
   */
  getGpsDetailsMaterials(siteId: number): Observable<IMapModel[]> {
    return this.http.Get(`site/${siteId}/GetGpsDetailsForMaterial`, this.reqOpt) as Observable<IMapModel[]>;
  }

   /**
   * create split material
   *
   * @param {number} siteId
   * @param {ISplitMaterialReq} reqBody
   * @return {*}  {Observable<ISplitReq>}
   * @memberof MaterialsService
   */
    saveSplitMaterial(siteId: number, reqBody: ISplitReq): Observable<ISplitReq> {
      return this.http.Post(`site/${siteId}/splitMaterial`, reqBody, this.reqOpt).pipe() as Observable<ISplitReq>;
    }
  
}
