import { IAPIResponse, IGridPagination, IFilterModel } from './../../view-models/common.model';
import { IForceCheckInModel, IItemFieldChangeModel, ITransactionItemDataModel, ITransactions, ITransactionsDataModel } from './../../view-models/transactions-model';
import { CustomHttpClientService } from './../common/custom-http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private http: CustomHttpClientService) { }

  /**
   * get all transactions data
   *
   * @return {*}  {Observable<ITransaction[]>}
   * @memberof TransactionsService
   */
  getAllTransactions(siteId: number): Observable<IAPIResponse<ITransactions[]>> {
    return this.http.GetExtended<ITransactions[]>(`site/${siteId}/GetTransactionsList`, {
      showLoadingIndicator: true
    });
  }

  getPagedTransactions(siteId: number, reqBody: any): Observable<any> {
      return this.http.Post(`site/${siteId}/GetPagedTransactionsList`,reqBody,{
        showLoadingIndicator : true,
      }) as Observable<ITransactionItemDataModel>

  }

  getItemTransactions(itemId: number, moduleId: number, companyId : number,reqBody: any): Observable<IAPIResponse<any>>{
    return this.http.Post(`GetItemTransactions`, reqBody , {
      showLoadingIndicator: true,
      params: {
        itemId: itemId,
        moduleId: moduleId,
        companyId: companyId
      }
    });
  }

  getTransactionFieldChanges(transactionItemId:number): Observable<IItemFieldChangeModel[]>{
    return this.http.Get<IItemFieldChangeModel[]>(`GetTransactionFieldChanges/${transactionItemId}`,{
      showLoadingIndicator: true
    });
  }

  forceCheckIn(siteId: number, cartData: IForceCheckInModel): Observable<IForceCheckInModel> {
    return this.http.Post(`site/${siteId}/ForceCheckIn`, cartData, {
      showLoadingIndicator: true
    }) as Observable<IForceCheckInModel>;
  }
  
  requestCsvExport(siteId: number) : Observable<any> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.http.Post(`site/${siteId}/RequestTransactionExport?timeZone=${timeZone}`, {
      showLoadingIndicator: true
    })
  }
}
