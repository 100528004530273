import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/common/config.service';
@Injectable({

  providedIn: 'root'

})
export class AzureMonitoringService {

  companyName: string = '';
  siteName: string = '';
  userName: string = '';
  userEmail: string = '';

  private appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: this.configService.getAppInsightsInstrumentationKey()
    }
  });
  
  constructor(private configService: ConfigService) {
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }


  trackEvent(name: string, siteName: string, properties?: any ) {
    this.companyName = JSON.parse(sessionStorage.getItem('siteData') as string)?.companyName;
    this.siteName = JSON.parse(sessionStorage.getItem('siteData') as string)?.siteName;
    this.userName = localStorage.getItem('name') as string;
    this.userEmail = localStorage.getItem('userEmail') as string;

    properties['userName'] = this.userName ? this.userName : '';
    properties['userEmail'] = this.userEmail ? this.userEmail : '';
    properties['company'] = this.companyName ? this.companyName : '';
    properties['site'] = this.siteName ? this.siteName : siteName;

    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
