import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { IEmployeeData } from 'src/app/view-models/employee-data-model';
import { CustomHttpClientService } from '../common/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  cachedEmployee$?: Observable<IEmployeeData[]>;  

  constructor(private http: CustomHttpClientService) { }

  /**
   * get status data
   *
   * @param {number} companyId
   * @return {*}  {Observable<IEmployeeData[]>}
   * @memberof EmployeeService
   */
  getEmployeeData(companyId: number): Observable<IEmployeeData[]> {

    if(!this.cachedEmployee$) {
      this.cachedEmployee$ = this.http.Get<IEmployeeData[]>(`Company/${companyId}/GetAllEmployeeWithoutPaging`).pipe(map((result) => {
        return result;
      }), shareReplay(1));
    }

    return this.cachedEmployee$;    
  }
}
