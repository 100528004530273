

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAPIResponse } from 'src/app/view-models/common.model';
import { IReportModule, IReportSummary } from 'src/app/view-models/report-model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { CustomHttpClientService } from '../common/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ReportServiceService {
  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }
  /** report module subject */
  reportModuleSub = new BehaviorSubject('');
  constructor(private http: CustomHttpClientService) { }


  /**
   * get materials searched data
   *
   * @param {number} siteId
   * @param {HttpParams} [params]
   * @return {*}  {Observable<IAPIResponse<IMaterials[]>>}
   * @memberof MaterialsService
   */
  getReportModule(moduleId: number): Observable<IAPIResponse<IReportModule[]>> {
    return this.http.GetExtended(`module/${moduleId}/GetAllReportWithoutPaging`, {
      showLoadingIndicator: true,
    }) as Observable<IAPIResponse<IReportModule[]>>;
  }

  /**
   * get assey summary details
   *
   * @param {number} siteId
   * @param {HttpParams} params
   * @return {*}  {Observable<IAPIResponse<IReportSummary[]>>}
   * @memberof ReportServiceService
   */
  getAssetSummaryReport(siteId: number, params: HttpParams): Observable<IAPIResponse<IReportSummary[]>> {
    return this.http.GetExtended(`site/${siteId}/GetAssetReportSummary`, {
      showLoadingIndicator: true,
      params: params
    }) as Observable<IAPIResponse<IReportSummary[]>>;
  }

  /**
   * get available assets
   *
   * @param {IServerSideGetRowsRequest} gridRequest
   * @param {number} siteId
   * @return {*}  {Observable<any>}
   * @memberof ReportServiceService
   */
  getAvailableAsset(gridRequest: IServerSideGetRowsRequest, siteId: number): Observable<any> {
    const options: IRequestOptions = {
      params: {
        showLoadingIndicator: true,
      }
    }
    return this.http.Post(`site/${siteId}/GetAllAvailableAssets`, gridRequest, options)
  }

  /**
   * get recent check out assets
   *
   * @param {IServerSideGetRowsRequest} gridRequest
   * @param {number} siteId
   * @return {*}  {Observable<any>}
   * @memberof ReportServiceService
   */
  getRecentAssetCheckOut(gridRequest: IServerSideGetRowsRequest, siteId: number): Observable<any> {
    const options: IRequestOptions = {
      params: {
        showLoadingIndicator: true,
      }
    }
    return this.http.Post(`site/${siteId}/GetRecentAssetCheckOutTransactionsList`, gridRequest, options)
  }

  /**
   * get check out assets
   *
   * @param {IServerSideGetRowsRequest} gridRequest
   * @param {number} siteId
   * @return {*}  {Observable<any>}
   * @memberof ReportServiceService
   */
  getCheckOutAsset(gridRequest: IServerSideGetRowsRequest, siteId: number): Observable<any> {
    const options: IRequestOptions = {
      params: {
        showLoadingIndicator: true,
      }
    }
    return this.http.Post(`site/${siteId}/GetCheckOutAssetsList`, gridRequest, options)
  }

  /**
   * get asset utilisation data
   *
   * @param {IServerSideGetRowsRequest} gridRequest
   * @param {number} siteId
   * @return {*}  {Observable<any>}
   * @memberof ReportServiceService
   */
  getAssetUtilisation(gridRequest: IServerSideGetRowsRequest, fromDate: string, toDate: string, siteId: number): Observable<any> {
    const options: IRequestOptions = {
      showLoadingIndicator: true,
    }
    return this.http.Post(`site/${siteId}/GetAssetUtilisationList?fromDate=${fromDate}&toDate=${toDate}`, gridRequest, options)
  }
}


