import { IMapModel, IMapPingDetails } from 'src/app/view-models/map-model';
import { IAssetDataModel, ICheckFieldSubscription, IInspectionItemDataModel, INotificationSubscription, IPagination } from '../../view-models/asset-data-model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { CustomHttpClientService } from '../common/custom-http-client.service';
import { IAPIResponse } from 'src/app/view-models/common.model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  companyId: number = 1;

  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  /** searched asset value */
  searchedAssetValue!: string;

  /** save/reset column layout */
  saveOrResetSub = new BehaviorSubject('');

  /** refresh grid */
  refreshAsset = new BehaviorSubject('');

  deselectAsset = new Subject();

  AssetRowData = new Subject();

  /**
    * set searched asset value
    *
    * @param {string} searchedValue
    * @memberof assetService
    */
  setSearchedAsset(searchedValue: string): void {
    this.searchedAssetValue = searchedValue;
  }

  /**
  * get searched asset value
  *
  * @return {*}  {string}
  * @memberof assetService
  */
  getSearchedAsset(): string {
    return this.searchedAssetValue;
  }

  constructor(private http: CustomHttpClientService) {
  }

  getSearchAsset(siteId: number, reqBody:IPagination ): Observable<any> {
    return this.http.Post(`site/${siteId}/searchAssets`, reqBody, this.reqOpt) as Observable<any>;
  }

  createAsset(assetData: IAssetDataModel): Observable<IAssetDataModel[]> {
    var siteId = (JSON.parse(localStorage.getItem('siteData') as string)).id;

    return this.http.Post(`site/${siteId}/createAsset`, assetData, this.reqOpt) as Observable<IAssetDataModel[]>;
  }

  getUpdateAsset(id: number): Observable<IAssetDataModel> {

    return this.http.Get(`Asset/${id}`, this.reqOpt) as Observable<IAssetDataModel>;
  }

  updateAsset(assetId: number, assetData: FormData, siteId: number): Observable<IAssetDataModel[]> {

    return this.http.Put(`site/${siteId}/updateAsset/` + assetId, assetData, this.reqOpt)
  }

  archiveAsset(id: any): Observable<any> {
    return this.http.Delete<any>(`Asset/${id}`)
  }

  /**
   * get gps details
   *
   * @param {number} siteId
   * @return {*}  {Observable<IMapModel[]>}
   * @memberof AssetService
   */
  getGpsDetails(siteId: number): Observable<IMapModel[]> {
    return this.http.Get(`site/${siteId}/GetGpsDetails`, this.reqOpt) as Observable<IMapModel[]>;
  }

  /**
   * get GPS ping details
   *
   * @param {number} siteId
   * @param {HttpParams} params
   * @return {*}  {Observable<any>}
   * @memberof AssetService
   */
  getGpsPingDetails(siteId: number, params: HttpParams): Observable<IMapPingDetails[]> {
    return this.http.Get(`site/${siteId}/GetModulePingDetails`, {
      showLoadingIndicator: true,
      params: params
    }) as Observable<IMapPingDetails[]>
  }

  /**
   * get all Assets for a site
   *
   * @param {number} siteId
   * @return {Observable<IAssetDataModel[]>}  {Observable<IAssetDataModel[]}
   * @memberof AssetService
   */
  getAllAssets(siteId: number): Observable<IAssetDataModel[]> {
    return this.http.Get(`site/${siteId}/GetAllAssets`, {
      withCredentials: true
    }) as Observable<IAssetDataModel[]>
  }

  /**
   * Get all inspection search items
   *
   * @param {number} siteId
   * @param {number} [itemNumber]
   * @return {*}  {Observable<IAPIResponse<IInspectionItemDataModel[]>>}
   * @memberof AssetService
   */
  getInspectionSearchItem(siteId: number, moduleId: number, itemNumber?: number, inspectionTemplateId?: number): Observable<IAPIResponse<IInspectionItemDataModel[]>> {
    let params = new HttpParams();

    if (itemNumber) {
      params = params.set('Search', itemNumber).set('ModuleId', moduleId);
    }
    params = params.append('InspectionTemplateId', inspectionTemplateId ?? "")
    
    return this.http.GetExtended(`site/${siteId}/GetAllInspectionSearchItems`, {
      showLoadingIndicator: true,
      params: params
    }) as Observable<IAPIResponse<IInspectionItemDataModel[]>>;
  }

  /**
   * check field subscription
   *
   * @param {number} siteId
   * @param {HttpParams} params
   * @return {*}  {Observable<IAPIResponse<ICheckFieldSubscription[]>>}
   * @memberof AssetService
   */
  checkFieldSubscription(siteId: number, params: HttpParams): Observable<ICheckFieldSubscription[]> {

    return this.http.Get(`site/${siteId}/CheckFieldSubscription`, {
      showLoadingIndicator: true,
      params: params
    }) as Observable<ICheckFieldSubscription[]>;
  }

  /**
   * send notification subscription
   *
   * @param {number} siteId
   * @param {INotificationSubscription} reqBody
   * @return {*}  {Observable<any[]>}
   * @memberof AssetService
   */
  notificationSubscription(siteId: number, reqBody: INotificationSubscription): Observable<any[]> {
    return this.http.Post(`site/${siteId}/CreateNotificationSubscription`, reqBody, this.reqOpt) as Observable<any[]>
  }


  requestCsvExport(siteId:number) : Observable<any> {
    return this.http.Post(`site/${siteId}/RequestAssetExport`,{},this.reqOpt)
  }
}
