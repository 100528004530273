import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigModel } from 'src/app/view-models/config-model';
import { AppConfigurationClient } from '@azure/app-configuration'
import { BehaviorSubject } from 'rxjs';
import { FeatureFlags } from 'src/app/common/utilities';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config!: ConfigModel;

  endTime: any;
  constructor() { } 
  checkMaintenanceSub =  new BehaviorSubject(false);
  private getConfig(): ConfigModel {
    if (!this.config) {
      this.config = environment;
    }
    return this.config;
  }

  getAppInsightsInstrumentationKey(): string {
    let conf = this.getConfig();
    return conf.appInsights.instrumentationKey;
  }

  getProduction(): boolean {
    let conf = this.getConfig();
    return conf.production;
  }
  
  async getFeatureFlag(featureName: string): Promise<any> {
      let conf = this.getConfig();
      const conn = conf.azureFeatureFlagConnectionString;
      const client = new AppConfigurationClient(conn);
      var featureFlag : any = await client.getConfigurationSetting({ key: `.appconfig.featureflag/${featureName}` });
      var result = JSON.parse(featureFlag.value ?? '');   

      if(featureName == FeatureFlags.MaintenanceFlag){
        localStorage.setItem('maintenance-end-time',JSON.stringify(result.conditions?.client_filters[0]?.parameters?.End))
        localStorage.setItem('maintenance-start-time',JSON.stringify(result.conditions?.client_filters[0]?.parameters?.Start))
        if(result.enabled && result.conditions?.client_filters.length > 0){
          var timeWindowFilter = result.conditions.client_filters.find((x : any) => x.name == "Microsoft.TimeWindow")
        if(timeWindowFilter){
          var params = timeWindowFilter.parameters
          const currentTime = new Date().getTime();
          const endTime = new Date(params.End);
          const startTime = new Date(params.Start);
          if (currentTime > startTime.getTime() && currentTime < endTime.getTime()) {
            localStorage.setItem('last-maintenance-flag', 'true');
            return {enabled : true, endTime : endTime}
          }
          return { enabled: false };
          }
        }
      } 
    return result
  }

  async getFeatureFlagFilters(featureFlag: string){
    let conf = this.getConfig();
    const conn = conf.azureFeatureFlagConnectionString;
    const client = new AppConfigurationClient(conn);
    var val = await client.getConfigurationSetting({ key: `.appconfig.featureflag/${featureFlag}` });    
    var result = JSON.parse(val.value ?? '').conditions.client_filters;

    return result;
  }
}
