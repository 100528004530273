import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPowerBiService } from 'ngx-powerbi';
import { IEmbedConfiguration, models } from 'powerbi-client';
import { moduleEnum } from 'src/app/common/utilities';
import { LoaderService } from 'src/app/services/common/loader.service';
import { SnackBarService } from 'src/app/services/common/snackbar.service';
import { ReportsService } from 'src/app/services/reports/reports.service';

@Component({
  selector: 'app-pbi-reports',
  templateUrl: './pbi-reports.component.html',
  styleUrls: ['./pbi-reports.component.css'],

})

export class PbiReportComponent implements OnInit {

  private powerBiService: NgxPowerBiService;
  private pbiContainerElement!: HTMLElement;

  /** user id */
  userId!: string;

  constructor(
    private reportService: ReportsService,
    private route: ActivatedRoute,
    private router: Router,
    public loaderService: LoaderService,
    private snackBarService: SnackBarService
  ) {
    this.powerBiService = new NgxPowerBiService();
  }

  ngOnInit(): void {
    let moduleName: string;

    this.route.queryParams.subscribe(params => {
      this.pbiContainerElement = <HTMLElement>(document.getElementById('reportContainer'));
      this.userId = localStorage.getItem('userId') as string;

      this.reportService.getAllUserPbiReports(this.userId).subscribe(r => {
        let reports = [];
        if (this.router.url.includes('assets')) {
          reports = r.filter(el => el.isDashboard == false && (+el.moduleId === +moduleEnum.asset || el.moduleId === null) && el.powerBIReportGuid == params['reportId']);
          moduleName = "Asset"
        } else if (this.router.url.includes('stock-items')) {
          reports = r.filter(el => el.isDashboard == false && (+el.moduleId === +moduleEnum.stockitem || el.moduleId === null) && el.powerBIReportGuid == params['reportId']);
          moduleName = "StockItem"
        } else if (this.router.url.includes('materials')) {
          reports = r.filter(el => el.isDashboard == false && (+el.moduleId === +moduleEnum.material || el.moduleId === null) && el.powerBIReportGuid == params['reportId']);
          moduleName = "Material"
        } else if (this.router.url.includes('groups')) {
          reports = r.filter(el => el.isDashboard == false && (+el.moduleId === +moduleEnum.group || el.moduleId === null) && el.powerBIReportGuid == params['reportId']);
          moduleName = "Group"
        }
        if (reports.length > 0) {
          const companyName = localStorage.getItem('companyName') as string;
          this.reportService.getEmbedReport(params['reportId'], companyName, moduleName).then(re => {
            const config: IEmbedConfiguration = {
              type: 'report',
              id: re.id,
              embedUrl: re.embedUrl,
              accessToken: re.embedToken.token,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    visible: false
                  }
                }
              },
            };
            this.powerBiService.embed(this.pbiContainerElement, config);
            this.loaderService.hide();
          })
        } else {
          this.loaderService.hide();
          const message = `You are not authorized to access this page.`;
          this.snackBarService.showMessage(message, 'errorSnack');
        }
      })
    })
  }
}
