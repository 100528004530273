import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ConfigService } from 'src/app/services/common/config.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit{
  maintenanceEndTime: string = "2024-10-03T01:00:00Z";
  formattedTime: any;

  constructor(private configService: ConfigService) {
    this.formattedTime = configService.endTime
  }
  ngOnInit(): void {
    var endTime = new Date(JSON.parse(localStorage.getItem('maintenance-end-time') as string))
    this.formattedTime =  endTime ? moment(endTime).format("DD/MM/YYYY hh:mm:ss A") : ''
  }
}
