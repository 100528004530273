import { ViewFilesComponent } from './view-files/view-files.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon'
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component'
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DecimalToFixedPipe } from './pipes/decimal-tofixed.pipe';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileDeleteComponent } from './file-delete/file-delete.component';
import { DisplayGroupNameComponent } from './display-group-name/display-group-name.component';
import { CommonModule } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CsvboxComponent } from './csvbox/csvbox.component';
import { CSVBoxAngularModule } from '@csvbox/angular';
import {MatRadioModule} from '@angular/material/radio';
import { FieldControlsComponent } from './field-controls/field-controls.component';
import { NumberDirective } from './directives/numbers-only.directive';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { InputTextboxDialogComponent } from './input-textbox-dialog/input-textbox-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { AutocompleteDropdownComponent } from './autocomplete-dropdown/autocomplete-dropdown.component';
import { DisplayOsdComponent } from './display-osd/display-osd.component';
import { RestrictDotPasteDirective } from './directives/restrict-dot.directive';
import { ListComponent } from './list/list.component';
import { CheckInCheckOutComponent } from './check-in-check-out/check-in-check-out.component';
import { DeleteCheckInCheckOutComponent } from './check-in-check-out/delete-check-in-check-out/delete-check-in-check-out.component';
import { CheckInCheckOutSelectionFieldsComponent } from './check-in-check-out/check-in-check-out-selection-fields/check-in-check-out-selection-fields.component';
import { SplitMaterialComponent } from './split-material/split-material.component';
import { GpsDeviceInfoComponent } from './gps-device-info/gps-device-info.component';

const materialModule = [
  ReactiveFormsModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatButtonModule,
  MatMenuModule,
  MatCardModule,
  MatExpansionModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  FormsModule,
  FontAwesomeModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  ReactiveFormsModule,
  FormsModule,
  FlexLayoutModule,
  MatSelectModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatStepperModule,
  MatAutocompleteModule,
  MatRippleModule,
  MatDialogModule,
  NgxTrimDirectiveModule,
  NgxMatSelectSearchModule,
  MatCheckboxModule,
  MatRadioModule,
  MatTabsModule,
  ChartsModule,
]

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    ViewFilesComponent,
    FileUploadComponent,
    NoDataFoundComponent,
    FileDeleteComponent,
    DisplayGroupNameComponent,
    DecimalToFixedPipe,
    CsvboxComponent,
    FieldControlsComponent,
    NumberDirective,
    InputTextboxDialogComponent,
    MessageDialogComponent,
    SearchBarComponent,
    AutocompleteDropdownComponent,
    DisplayOsdComponent,
    RestrictDotPasteDirective,
    ListComponent,
    CheckInCheckOutComponent,
    DeleteCheckInCheckOutComponent,
    CheckInCheckOutSelectionFieldsComponent,
    SplitMaterialComponent,
    GpsDeviceInfoComponent
  ],
  imports: [
    materialModule,
    AgGridModule.withComponents([]),
    CommonModule,
    CSVBoxAngularModule
  ],
  exports: [
    materialModule,
    AgGridModule,
    NoDataFoundComponent,
    DecimalToFixedPipe,
    CsvboxComponent,
    FieldControlsComponent,
    NumberDirective,
    AutocompleteDropdownComponent,
    SearchBarComponent,
    RestrictDotPasteDirective
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})

export class SharedModule { }
