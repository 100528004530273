import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {

    auth: {
        clientId: environment.clientId,
        authority: environment.authority,
        knownAuthorities: environment.knownAuthorities,
        redirectUri: environment.redirectUrl
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
    // system: {
    //     loggerOptions: {
    //         loggerCallback(logLevel: LogLevel, message: string) {
    //         },
    //         logLevel: LogLevel.Verbose,
    //         piiLoggingEnabled: false
    //     }
    // }
}
export const loginRequest = {
    scopes: environment.scopes
};