<!-- Date Control -->

<mat-form-field *ngIf="fieldType === 'datetimeoffset'" class="cart-controls">
    <input placeholder="Field Date" [formControl]="dateFieldFC" (click)="fieldDate.open()" (focus)="fieldDate.open()" matInput [matDatepicker]="fieldDate" autocomplete="off" >
    <mat-datepicker-toggle matSuffix [for]="fieldDate"></mat-datepicker-toggle>
    <mat-datepicker #fieldDate></mat-datepicker>
</mat-form-field>

<!-- Number (int/bigInt/decimal) Control -->

<mat-form-field *ngIf="fieldType === 'int' || fieldType === 'bigint' || fieldType === 'decimal'" class="cart-controls">
    <input type="number" [formControl]="numberFieldFC" autocomplete="off" placeholder="Field Value" matInput [min]="fieldName === 'gpsLatitude' || fieldName === 'gpsLongitude' ? '' : 0"
    [numbersOnly]="fieldType === 'int' ? true : false" (keypress)="handleKeyPress($event)"/>
    <mat-error>{{errorMessage}}</mat-error>
</mat-form-field>

<!-- String Control -->

<mat-form-field *ngIf="fieldType === 'varchar' || fieldType === 'nvarchar'" class="cart-controls">
    <input [formControl]="stringFieldFC" placeholder="Field Value" autocomplete="off" matInput (keypress)="handleKeyPress($event)" />
</mat-form-field>

<!-- Boolean Control -->

<mat-slide-toggle *ngIf="fieldType === 'bit'" class="cart-controls" [formControl]="booleanFieldFC"></mat-slide-toggle>
