import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { ICommonDropDownData } from 'src/app/view-models/common.model';
import { CustomHttpClientService } from '../common/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  cachedSupplier$?: Observable<ICommonDropDownData[]>;  

  constructor(private http: CustomHttpClientService) { }

  /**
   * get supplier data
   *
   * @param {number} companyId
   * @return {*}  {Observable<ICommonDropDownData[]>}
   * @memberof SupplierService
   */
  getSupplierData(companyId: number): Observable<ICommonDropDownData[]> {

    if(!this.cachedSupplier$) {
      this.cachedSupplier$ = this.http.Get<ICommonDropDownData[]>(`Company/${companyId}/GetAllSuppliersWithoutPaging`).pipe(map((result) => {
        return result;
      }), shareReplay(1));
    }

    return this.cachedSupplier$;
  }
}
