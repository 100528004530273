<div class="loginPageBackground">
</div>
<div class="login-container">
    <form class="ng-untouched ng-pristine ng-valid">
        <div class="header-align">
            <img src="https://trackemprod.blob.core.windows.net/companylogo/Logo-Trackem-White.png" class="logo">
        </div>
        <div class="header-label"> Login to your sdfdfsdfsd account </div>
        <div class="header-align">
            <input name="userId" placeholder="Email address" type="text"
                class="input-label ng-dirty ng-touched ng-invalid">
        </div>
        <div class="header-align password-container">
            <input name="password" placeholder="Password" type="password"
                class="password-input ng-touched ng-dirty ng-invalid">
            <mat-icon role="img" class="mat-icon notranslate password-toggle material-icons mat-icon-no-color"
                aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon>
        </div>

        <div class="help-label"><span class="help-link">Forgot Password?</span></div>

        <div class="signin-label"> Sign In </div>
        <div class="help-label"> Need help? Contact <a href="mailto:helpdesk@trackem.com.au" class="help-link">
                Track'em helpdesk</a>
        </div>
        <div class="error-message hidden"> </div>
    </form>
</div>