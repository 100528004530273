<mat-toolbar class="mat-elevation-z6 bg-16dp navigationMenu">
    <mat-toolbar-row>
        <img src="https://trackemprod.blob.core.windows.net/companylogo/Logo-Trackem-White.png"
            class="trackem-logo">

        <div class="ml-10">
            <a class="text-white px-5 navbar-header text-base" *ngIf="isAdmin || isTrackemAdmin" [routerLink]="['assets']" (click)="setUserData('home',true)" [fxHide]="checkActivePage() === 'home'">
                <fa-icon class="pr-2" [icon]="faHome">
                </fa-icon>
                <span>Home</span>
            </a>    
    
            <a class="text-white px-5 navbar-header text-base" [routerLink]="['geofence']" [fxHide]="checkMap()">
                <fa-icon class="pr-2" [icon]="faLocation">
                </fa-icon>
                <span>Geofence</span>
            </a>   
            <a class="text-white px-5 navbar-header text-base" *ngIf="isAdmin || isTrackemAdmin" [routerLink]="['admin']" (click)="setUserData('admin')" [fxHide]="checkActivePage() === 'admin'">
                <fa-icon class="pr-2" [icon]="faUserShield">
                </fa-icon>
                <span>Admin</span>
            </a>
            <a class="text-white px-5 navbar-header text-base" (click)="navigateToDashboard(activatedThemeId)" [fxHide]="checkDashboardModule()">
                <fa-icon class="pr-2" [icon]="faDashboard">
                </fa-icon>
                <span>Dashboard</span>
            </a>
            <a class="text-white px-5 navbar-header text-base" [matMenuTriggerFor]="menu" [fxHide]="checkReportsModule() || !reportAccess ">
                <fa-icon class="pr-2" [icon]="faChartSimple">
                </fa-icon>
                <span>Reports</span>
            </a>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="navigateToReport(item, activatedThemeId)" *ngFor="let item of reportModule">{{item.name}}</button>
            </mat-menu>
        </div>

        <span class="fill-remaining-space"></span>

        <mat-form-field class="text-sm font-medium mt-2" fxFlex="10" floatLabel="never"[fxHide]="checkActivePage() === 'admin'">
            <fa-icon matPrefix [icon]="faFileContract" class="mr-2"></fa-icon>
            <input #projectControl type="text" placeholder="Select Project" matInput [formControl]="projectFC" [matAutocomplete]="projectMenu" (focus)="siteBlurred(siteControl, $event)" (blur)="siteBlurred(siteControl, $event)"
            [matTooltip]="projectFC.value" matTooltipPosition="below">
            <fa-icon matSuffix [icon]="faChevronDown" class="ml-2" (click)="projectChanged = true; filteredProjectData = projectData"></fa-icon>
            <mat-autocomplete autoActiveFirstOption #projectMenu="matAutocomplete" [displayWith]="displayProject">
                <mat-option *ngIf="isProjectLoading"><mat-spinner [diameter]="20"></mat-spinner></mat-option>
                <mat-option *ngIf="!filteredProjectData.length && !isProjectLoading">No Results Found.</mat-option>
                <ng-container *ngIf="filteredProjectData.length && !isProjectLoading">
                    <mat-option *ngFor="let project of filteredProjectData" [value]="project"
                    (onSelectionChange)="filterSites($event, 'project')" [matTooltip]="project"
                    matTooltipPosition="left">
                        {{ project }}
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="text-sm font-medium mt-2" fxFlex="10" floatLabel="never"[fxHide]="checkActivePage() === 'admin'">
            <fa-icon matPrefix [icon]="faBuilding" class="mr-2"></fa-icon>
            <input #siteControl type="text" placeholder="Select Site" matInput [formControl]="siteIdFC" [matAutocomplete]="siteMenu" 
                [matTooltip]="siteIdFC.value.siteName" matTooltipPosition="below">
            <fa-icon matSuffix [icon]="faChevronDown" class="ml-2" (click)="siteChanged = true; filterSites($event, 'site')"></fa-icon>
            <mat-autocomplete autoActiveFirstOption #siteMenu="matAutocomplete" [displayWith]="display">
                <mat-option *ngIf="isSiteLoading"><mat-spinner [diameter]="20"></mat-spinner></mat-option>
                <mat-option *ngIf="!filteredSiteData?.length && !isSiteLoading" value="">No Results Found.</mat-option>
                <ng-container *ngIf="filteredSiteData?.length && !isSiteLoading">
                    <mat-option *ngFor="let site of filteredSiteData" [value]="site"
                        (onSelectionChange)="selectedSiteName(site)" [matTooltip]="site.siteName"
                        matTooltipPosition="left">
                        {{ site.siteName }}
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
        <small class="w-4">
            <mat-icon *ngIf="activatedTheme === 'Dark'" class="text-xl align-sub mt-0.5 cursor-pointer" (click)="themeToggle()">
                light_mode
            </mat-icon>
            <fa-icon *ngIf="activatedTheme === 'Light'" [icon]="faMoon" (click)="themeToggle()"></fa-icon>
        </small>
        &nbsp;
        <div>
            <a class="text-white" mat-button [matMenuTriggerFor]="userMenu">
                <span class="navigation-username">{{UserName}}</span>
                <fa-icon [icon]="faChevronDown">
                </fa-icon>
            </a>
            <mat-menu #userMenu="matMenu" class="user-menu" yPosition="above">
                <div>
                    <a mat-button (click)="showAboutDialog()">
                        <fa-icon [icon]="faInfoCircle">
                        </fa-icon>
                        <span class="ml-3">About</span>

                      </a>
                  </div>
                <div *ngIf="isTrackemAdmin">
                    <a mat-button (click)="navigateToCompanyModule()">
                          <fa-icon [icon]="faPlus"></fa-icon>
                          <span class="ml-2">Company</span>
                    </a>
                  </div>
                <div>
                    <a mat-button (click)="logout()">
                        <fa-icon [icon]="faLogout">
                        </fa-icon>
                        <span class="ml-3">Logout</span>
                    </a>
                </div>
            </mat-menu>
        </div>

    </mat-toolbar-row>
</mat-toolbar>

<!-- <div class="background"></div> -->
<!-- <label for="toggle" class="title">Toggle dark mode</label> -->
