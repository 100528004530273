<div class="text-center">
    <div mat-dialog-header fxLayoutAlign="space-between center">
        <span class="text-lg"> {{headerTitle}} </span>
        <mat-icon mat-ripple class="closeIcon" [mat-dialog-close]="false"> close </mat-icon>
    </div>
    <div mat-dialog-content fxLayout="column">
        <mat-form-field fxFlex="100" class="mt-2">
            <mat-label >{{fieldLabel}}</mat-label>
            <input *ngIf="istemplate" type="text" placeholder="Template Name" matInput [formControl]="inputFC" (input)="inputChange()">
            <mat-error *ngIf="istemplate && !isTemplateValid">Template name already exists</mat-error>

            <textarea *ngIf="!istemplate" matInput rows="5" autocomplete="off" required (input)="inputChange()" [formControl]="inputFC"></textarea>
        </mat-form-field>
    </div>
    <div mat-dialog-actions fxLayoutGap="20px" fxLayoutAlign="end">
        <span *ngIf="isForceCheckIn" class="label-text mt-1 noteText">NOTE: Changing this will not affect Group's Asset Quantity</span>
        <button mat-flat-button class="actionButton" (click)="save()" [disabled]="inputFC.invalid">Submit </button>
    </div>
</div>