import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { SnackBarService } from 'src/app/services/common/snackbar.service';
import { MaterialsService } from 'src/app/services/materials/materials.service';
import { ISiteModel } from 'src/app/view-models/site-model';

@Component({
  selector: 'app-osd-attachment-photos',
  templateUrl: './osd-attachment-photos.component.html',
  styles: [
  ]
})
export class OsdAttachmentPhotosComponent implements OnInit {

  inputFileCtrl!: HTMLInputElement;

  fileCount!: number;

  /**List of Extensions */
  extensionArray: string[] = ['image/jpg', 'image/jpeg', 'image/png'];

  /** faUpload */
  faUpload = faUpload;

  siteData!: ISiteModel;

  attachmentArray: any = [];

  constructor(private snackBarService: SnackBarService, private materialService: MaterialsService,
    public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.siteData = JSON.parse(localStorage.getItem('siteData') as string);
    this.fileCount = this.data.attachments?.length;
  }

  /**
   * file selection changed
   *
   * @param {*} event
   * @memberof DocumentUploadComponent
   */
  
  onFileSelected(event: any) {
    this.inputFileCtrl = event.target;
    const files = event.target.files;
    
    const remainingFilesCanBeUploaded = files.length + this.data.attachments?.length;
    if (remainingFilesCanBeUploaded <= 5) {
       for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileAlreadyUploaded = this.data.attachments.findIndex((doc: any) => doc.name === file.name);        
        if (fileAlreadyUploaded < 0) {
          if (this.extensionArray.includes(file.type)) {
            if (file.size / (1024 * 1024) <= 20) {
              this.attachmentArray.push(file)
            } else {
              this.snackBarService.showMessage('Please select a attachment less than or equal to 20 MB', 'errorSnack');
              event.target.value = '';
            }
          } else {
            this.snackBarService.showMessage('Please select jpg, jpeg, png files only.', 'errorSnack');
            event.target.value = '';
          }
        } else {
          this.snackBarService.showMessage('File already exist.', 'errorSnack');
          event.target.value = '';
        }
      }
      this.closeDialogRef();
    } else {
      this.snackBarService.showMessage('Only 5 Attachment can be uploaded for an OSD.', 'errorSnack');
      event.target.value = '';
    }
  }
 
  closeDialogRef(){
    this.dialogRef.close({files: this.attachmentArray});
  }
}