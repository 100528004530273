export enum moduleCompanyMappingIdEnum {
    moduleCompanyMappingId1 = 1, //asset
    moduleCompanyMappingId2 = 2, //stockitem
    moduleCompanyMappingId3 = 3, //group
    moduleCompanyMappingId4 = 4, //material
    moduleCompanyMappingId5 = 5, //inspection
    moduleCompanyMappingId7 = 7, //document
    moduleCompanyMappingId13 = 13, //documentV2
}

export enum moduleEnum {
  asset = 1,
  stockitem = 2,
  group = 3,
  material = 4,
  inspection = 6,
  document = 8,
  documentV2 = 10  
}

export enum userRolesEnum {
    adminRoleId = 1,
    moderatorRoleId = 2,
    viewerRoleId = 3,
    trackemAdminRoleId = 4,
    MultiCompanyAccessRoleId = 5,
}

export enum inspectionEnum {
  checkbox = 'CheckBoxes',
  radiobutton = 'RadioButtons',
  text = 'Text'
}

export enum typeEnum {
  assets = 'Assets',
  materials = 'Materials',
  stockitems = 'Stock Items',
  document = "Document"
}

export enum monthEnum {
  one =  'January',
  two =  'February',
  three = 'March',
  four =  'April',
  five = 'May',
  six =  'June',
  seven = 'July',
  eight =  'August',
  nine = 'September',
  ten ='October',
  eleven =  'November',
  twelve = 'December' 
}

export const storeNames = [
  'assetTable',
  'stockItemsTable', 
  'materialsTable',
  'groupsTable',
  'documentTable',
  'documentsV2Table'
]

export enum FeatureFlags {
  Osd = "Osd",
  Document = "Document",
  DocumentV2 = 'DocumentV2'
}
