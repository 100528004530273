<div>
    <mat-form-field [style.width.%]="errorMessage !== '' ? 50 : 100" fxFlex [floatLabel]="'never'" class="osdType-cart-dropdown" >
        <mat-select [(value)]="selectedOsdType" placeholder="OSD Type" required (selectionChange)="actionSelectionChanged($event.value)">
            <mat-option value="1">
                Over
            </mat-option>
            <mat-option value="2">Short</mat-option>
            <mat-option value="3">
                Damaged
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-icon *ngIf="errorMessage !== ''"  class="errorIcon" matTooltip="Please select other OSD Type">error</mat-icon>
    <mat-icon *ngIf="errorMessage == ''"></mat-icon>
</div>
