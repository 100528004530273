import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Subscription } from 'rxjs';
import { CompanyService } from 'src/app/services/common/company.service';
import { LoaderService } from 'src/app/services/common/loader.service';
import { UserListService } from 'src/app/services/user-list/user-list.service';

@Component({
  selector: 'app-multi-company-dialog',
  templateUrl: './multi-company.component.html',
})
export class MultiCompanyDialogComponent implements OnInit {
  faChevronDown = faChevronDown;
  faTimes = faTimes;
  
  companyIdFC!: FormControl;
  isCompanyLoading: boolean = true;
  
  /** Company data */
  companyData!: any[];
  filteredCompanyData: any[] = []
  
  /** subscription handler */
  subs!: Subscription;

  selectedCompany: any;
  userId: string;

  isCompanySelected: boolean = true;
  
  constructor( private comapnyService: CompanyService, private userServie: UserListService,
    public loaderService: LoaderService,) {
    this.companyIdFC = new FormControl('', [Validators.required]);
    this.userId = localStorage.getItem('uId') as string;
    this.getCompanyData();
   }

  ngOnInit(): void {
  }
 
  onSelectionChange(event: any) {
    this.selectedCompany = '';
    this.selectedCompany = event.option._value;
    this.isCompanySelected = false;

  }

  submit(){
    localStorage.setItem('companyId', this.selectedCompany.companyCode);
    localStorage.setItem('companyName', this.selectedCompany.name);
    this.userServie.setCompanySelected(true);
    localStorage.setItem('isCompanySelected', String(true));
  }

  /**
  * get company data
  *
  * @memberof MultiCompanyDialogComponent
  */
   getCompanyData(): void {

     const getCompanyData = this.comapnyService.getUserCompanies(this.userId).subscribe((res) => {
        this.isCompanyLoading = false;
        this.filteredCompanyData = res;
        this.companyData = res;
       });
   }

   display(dropDownData: any): string {
    return ((dropDownData as any)?.name || '' );
  }
}
