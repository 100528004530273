import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { faAngleDoubleRight, faAngleDoubleLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { LoaderService } from './services/common/loader.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AzureMonitoringService } from './common/logging/logging.service';
import { environment } from 'src/environments/environment';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ActivatedRoute, Router } from '@angular/router';
import { UserListService } from './services/user-list/user-list.service';
import { ConfigService } from './services/common/config.service';
import { FeatureFlags } from './common/utilities';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'trackem-ert-ng-app';
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  isLoading: boolean = false;
  private subs!: Subscription;
  isDisable: boolean = false;
  faAngleDouble = faAngleDoubleLeft;
  tableContent = {
    marginLeft: true,
    marginLeftAfterExpansion: false
  };
  sideNavCss = {
    width: false
  };
  angleIcon = {
    angleIcon: true,
    angleIconPosition: false
  };

  /** active page */
  activePage = 'home';

  /** faTimes icon */
  faTimes = faTimes;

  isCompanySelected: boolean = false;
  maintenanceFlag : boolean = false;

  constructor(public loaderService: LoaderService, private cd: ChangeDetectorRef,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,private dbService: NgxIndexedDBService,
    private authService: MsalService, private broadcastService: MsalBroadcastService,
    private msalBroadcastService: MsalBroadcastService, private appInsights: AzureMonitoringService,
    private router: Router, private userService: UserListService,private configService: ConfigService) {
    this.subs = new Subscription();
    if (!localStorage.getItem('activePage')) {
      localStorage.setItem('activePage', 'home');
    }
    if (localStorage.getItem('activePage')) {
      loaderService.activePage.next( this.router.url.includes('admin') ? 'admin' : 'home' as string);
    }
  }

  ngOnInit() {
    this.userService.isCompanySelected.subscribe(res => {
       this.isCompanySelected = res;
       this.loginDisplay = res;
       if (window.location.hash === "#/multi-company")
       this.reloadCurrentRoute();
    })
    if(localStorage.getItem('isCompanySelected') === 'true') {
      this.isCompanySelected = true;
    }

    const broadcastService = this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe((res) => {
        this.setLoginDisplay();
      });
    this.subs.add(broadcastService);
    var userId;
    const msalBroadcastService = this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: any) => {
        if(result.payload?.idTokenClaims?.isForgotPassword)
        {
          this.logout();
        }
        else
        {
          userId = result.payload.idTokenClaims.extension_UserId;
          this.loaderService.themeToggleSubject.next(result.payload?.idTokenClaims.extension_Theme ? 'light' : 'dark');
          localStorage.setItem('selectedTheme', result.payload?.idTokenClaims.extension_Theme ? 'light' : 'dark');
          localStorage.setItem('companyId', result.payload?.idTokenClaims.extension_CCUID);
          localStorage.setItem('userId', result.payload?.uniqueId);
          localStorage.setItem('uId', result.payload?.idTokenClaims.extension_UserId);
          localStorage.setItem('name', result.payload?.idTokenClaims.name);
          localStorage.setItem('token', result.payload?.accessToken);
          localStorage.setItem('accessTokenExpiry',result.payload?.expiresOn);
          localStorage.setItem('userEmail', result.payload?.idTokenClaims.emails[0]);
          this.appInsights.trackEvent('UserLogin','',{});

          if(!result.payload?.idTokenClaims.extension_HasMultiCompanyAccess)
          {
            this.isCompanySelected = true;
            this.loginDisplay = true;
            localStorage.setItem('isCompanySelected',String(true))
            this.router.navigate(['']);
          }
        }
      });
    this.subs.add(msalBroadcastService);
    const loaderStateSub = this.loaderService.loaderState.subscribe(s => {
      this.isLoading = s.show;
      this.cd.detectChanges();
    });
    this.subs.add(loaderStateSub);

    if(localStorage.getItem('selectedTheme'))
    {
      if (localStorage.getItem('selectedTheme') === 'dark' ) {
        document.getElementById('body')?.classList.remove('light-theme');
        document.getElementById('body')?.classList.add('dark-theme');
      } else {
        document.getElementById('body')?.classList.remove('dark-theme');
        document.getElementById('body')?.classList.add('light-theme');
      }
    }

    const themeToggeledSub = this.loaderService.themeToggleSubject.subscribe(value => {
      if (value === 'dark') {
        document.getElementById('body')?.classList.remove('light-theme');
        document.getElementById('body')?.classList.add('dark-theme');
      } else {
        document.getElementById('body')?.classList.remove('dark-theme');
        document.getElementById('body')?.classList.add('light-theme');
      }
    });
    this.subs.add(themeToggeledSub);

    const activePageChanged = this.loaderService.activePage.pipe(
      filter(res => !!res)
    ).subscribe(res => this.activePage = res);
    this.subs.add(activePageChanged);

    if (!!localStorage.getItem('selectedTheme')) {
      this.loaderService.themeToggleSubject.next(localStorage.getItem('selectedTheme') as string);
    }

    this.configService.checkMaintenanceSub.subscribe((res) =>{
      if(res){
        this.refreshAfterEndTime()
      }
    })
  }

  reloadCurrentRoute() {
    this.router.navigate(['assets'])
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    const sub = this.msalBroadcastService.inProgress$
      .pipe(filter((status:InteractionStatus)=> status === InteractionStatus.None))
      .subscribe(()=>{
        this.authService.logoutRedirect({
          postLogoutRedirectUri: environment.redirectUrl,
          onRedirectNavigate: (url)=>{
            localStorage.clear();
          }
        });
    
        this.subs.add(this.dbService.deleteDatabase().subscribe());
      })

    this.subs.add(sub)
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    localStorage.setItem('loginDisplay', JSON.stringify(this.loginDisplay));
    if (!this.loginDisplay)
      this.login();
  }

  expansionToggle(event: boolean = false) {
    this.isDisable = event;
    if (!this.isDisable) {
      this.tableContent.marginLeft = true;
      this.tableContent.marginLeftAfterExpansion = false;
      this.sideNavCss.width = false;
      this.faAngleDouble = faAngleDoubleLeft;
      this.angleIcon.angleIcon = true;
      this.angleIcon.angleIconPosition = false;
    }
    else {
      this.tableContent.marginLeft = false;
      this.tableContent.marginLeftAfterExpansion = true;
      this.sideNavCss.width = true;
      this.faAngleDouble = faAngleDoubleRight;
      this.angleIcon.angleIcon = false;
      this.angleIcon.angleIconPosition = true;
    }
  }
checkMaintenanceMode(){

  const lastCallTime = JSON.parse(localStorage.getItem('last-call-time') || '0');
  const lastFlagStored = Boolean(JSON.parse(localStorage.getItem('last-maintenance-flag') || 'false'));
  const currentTime = new Date();
  const lastCallDate = new Date(lastCallTime);
   if (lastCallDate.getMinutes() < currentTime.getMinutes() || lastFlagStored) {
    this.configService.getFeatureFlag(FeatureFlags.MaintenanceFlag).then((res) =>{
      this.maintenanceFlag = res.enabled
      })
   }
  localStorage.setItem('last-call-time', JSON.stringify(currentTime.getTime()));
  localStorage.setItem('last-maintenance-flag', JSON.stringify(this.maintenanceFlag));

}
 
  refreshAfterEndTime() {
    this.checkMaintenanceMode();
    const storedEndTime = localStorage.getItem('maintenance-end-time');
    const storedStartDateTime = localStorage.getItem('maintenance-start-time');
    if (storedEndTime && storedStartDateTime) {
      const targetEndTime = new Date(storedEndTime);
      const targetStartTime = new Date(storedStartDateTime);
      const now = new Date();
      if(now.getTime() > targetStartTime.getTime() && now.getTime() < targetEndTime.getTime()){
        const interval = setInterval(() => {
          const currentTime = new Date().getTime();
          if(currentTime > targetEndTime.getTime()){
            this.maintenanceFlag = false
            clearInterval(interval);
          }
        }, 6000);
      }
    }
  }
  /**
   * unsubscribe all subscription
   *
   * @memberof AppComponent
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
