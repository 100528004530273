import { Injectable } from "@angular/core";
import { CustomHttpClientService } from "../common/custom-http-client.service";
import { BehaviorSubject, Observable, Subject, map } from "rxjs";
import { IAPIResponse, ICommonDropDownData } from "src/app/view-models/common.model";
import { IRequestOptions } from "src/app/view-models/request.options";
import { CreateDocumentV2Request, IDocumentV2, IDocumentV2Items, IDocumentV2ItemsDataModel, IDocumentsV2DataList, IDocumnetV2Template } from "src/app/view-models/documents-v-two-models";
import { IDocumentDataList } from "src/app/view-models/documents-model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class DocumentsV2Service {
    
  refreshDocumentVTwoSubGrid = new BehaviorSubject('');

  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  /** save/reset column layout */
  saveResetColumnLayout = new BehaviorSubject('');

  /** searched document value */
  private searchedDocumentVTwoValue!: string;

  private isValid = new BehaviorSubject<boolean>(true);
  isValidForm = this.isValid.asObservable();

  documentRowData = new Subject()

  deselectDocument = new Subject();
  
  edit(isValid:any = true){
    this.isValid.next(isValid); 
  }

  constructor(private http: CustomHttpClientService) { }

  /**
   * set searched value
   *
   * @param {string} searchedValue
   * @memberof DocumentsVTwo
   */
  setSearchDocumentVTwoValue(searchedValue: string): void {
    this.searchedDocumentVTwoValue = searchedValue;
  }

  /**
   * get searched document value
   *
   * @return {*}  {string}
   * @memberof DocumentsVTwo
   */
  getSearchedValue(): string {
    return this.searchedDocumentVTwoValue;
  }
  
  createDocumentV2( reqBody: CreateDocumentV2Request, siteId: number): Promise<void> {
    return this.http.Post<void>(`site/${siteId}/createDocumentV2`, reqBody, this.reqOpt).pipe(
      map(() => {
        return;
      })
    ).toPromise();
  }

  getDocumentTemplate(companyId: number): Observable<IDocumnetV2Template[]> {
    return this.http.Get(`DocumentV/GetDocumentTemplate/${companyId}`) as Observable<IDocumnetV2Template[]>
  }

  updateDocument(documentId: number, reqBody: CreateDocumentV2Request, siteId: number): Observable<IDocumentV2> {
    return this.http.Put(`site/${siteId}/updateDocumentV2/${documentId}`, reqBody, this.reqOpt) as Observable<IDocumentV2>;
  }

  getDocumentV2DataById(documentId: number): Observable<IDocumentV2> {
    return this.http.Get(`DocumentV/${documentId}`, this.reqOpt) as Observable<IDocumentV2>;
  }

  getDocumentV2Data(siteId: number, reqBody: any): Observable<IAPIResponse<any>> {
    return this.http.Post(`site/${siteId}/GetAllDocuments`, reqBody, this.reqOpt) as Observable<IAPIResponse<IDocumentsV2DataList[]>>;
  }

  getAllDocumentV2Data(siteId: number):  Observable<IDocumentDataList[]> {
    return this.http.Get(`site/${siteId}/GetAllDocumentsWithoutPaging`) as Observable<IDocumentDataList[]>;
  }

  AddDocumentV2ItemsMapping(documentId: number, reqBody: IDocumentV2ItemsDataModel): Observable<IDocumentV2ItemsDataModel> {
    return this.http.Post(`DocumentV/${documentId}/AddDocumentV2ItemsMapping`, reqBody, this.reqOpt) as Observable<IDocumentV2ItemsDataModel>;
  }
  
  DocumentV2UpdateStatus(siteId: number, reqBody: any) {
    return this.http.Post(`site/${siteId}/DocumentV2UpdateStatus`, reqBody, this.reqOpt) as Observable<any>
  }

  getDocumentV2Items(documentId: number): Observable<IDocumentV2Items> {
    return this.http.Get(`DocumentV/GetDocumentV2Items/${documentId}`) as Observable<IDocumentV2Items>;
  }

  /**
   * delink document item mapping by id
   * 
   * @param {number} id 
   * @returns 
   */
  delinkDocumentItemsMappingById(id: number): Observable<any> {
    return this.http.Put(`DocumentV/DelinkDocumentItemMapping/${id}`, this.reqOpt)
  }

  printDocument(documentId: number, userId: string): Observable<any> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var options: IRequestOptions = {
      responseType: 'text' as 'json',
      showLoadingIndicator: true,
      fullPath: true,
      params: {
        documentId: documentId,
        userId: userId,
        timeZone: timeZone,
        code: environment.printFunction.key
      }
    }
    return this.http.GetBlob(`${environment.printFunction.apiUrl}/PrintDocumentV2`, options)
  }

  getAllDocumentV2TypeData(companyId: number, typeData: string): Observable<ICommonDropDownData[]> {
   return this.http.Get(`company/${companyId}/GetAllDocumentV2${typeData}`, this.reqOpt) as Observable<ICommonDropDownData[]>
  }

  deleteDocumentV2(id: number): Observable<any> {
    return this.http.Delete(`DocumentV/DeleteDocumentV2/${id}`) as Observable<any>;
  }
}