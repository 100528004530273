import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBulkTransferItem } from 'src/app/view-models/cart-data-model';

@Component({
  selector: 'app-bulk-transfer',
  templateUrl: './bulk-transfer.component.html'
})
export class BulkTransferComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public modalData: {data: any, bulkData: IBulkTransferItem}) {
  }

  ngOnInit(): void {
  }

}
