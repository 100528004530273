import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { TransactionDetailsDialogComponent } from '../transaction-details-dialog/transaction-details-dialog.component';
import { ITransactionItemDataModel } from 'src/app/view-models/transactions-model';

@Component({
  selector: 'app-transaction-action-cell-renderer',
  templateUrl: './transaction-action-cell-renderer.component.html',
})
export class TransactionActionCellRendererComponent implements OnInit {

  faSquareInfo = faSquareInfo

  rowData!:ITransactionItemDataModel
  itemNumber!:string

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams){
    this.rowData = params.data
  }

  onShowInfo(){
    this.dialog.open(TransactionDetailsDialogComponent, {
      height: 'auto',
      width: '800px',
      data: this.rowData,
    })
  }
}
