import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {

  isTrackemAdmin: boolean = false;
  constructor() { }

  ngOnInit(): void {
    localStorage.getItem('userRole') == 'TrackemAdmin' ? this.isTrackemAdmin = true : '';
  }

}
