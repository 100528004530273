import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MultiCompany, UserRoleGuard } from './common/guards/user-role-guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { PbiReportComponent } from './components/pbi-reports/pbi-reports.component';
import { MultiCompanyDialogComponent } from './components/multi-company/multi-company.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';

const routes: Routes = [

  { path: '', redirectTo: 'assets', pathMatch: 'prefix', canActivate: [MultiCompany] },
  {
    path: 'multi-company', 
    component: MultiCompanyDialogComponent
  },
  {
    path: 'assets',
    loadChildren: () => import('./components/assets/assets.module').then(m => m.AssetsModule),
    canActivate: [MultiCompany]
  },
  {
    path: 'stock-items',
    loadChildren: () => import('./components/stock-items/stock-items.module').then(m => m.StockItemsModule)
    // canLoad: [MsalGuard],
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'cart',
    // canActivate: [MsalGuard],
    loadChildren: () => import('./components/cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./components/groups/groups.module').then(m => m.GroupsModule)
  },
  // {
  //   path: 'documents',
  //   loadChildren: () => import('./components/documents/documents.module').then(m => m.DocumentsModule)
  // },
  {
    path: 'documents-v2',
    loadChildren: () => import('./components/documents-v-two/documents-v-two.module').then(m => m.DocumentsVTwoModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import('./components/transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./components/map/map.module').then(m => m.MapModule)
  },
  {
    path: 'inspections',
    loadChildren: () => import('./components/inspections/inspections.module').then(m => m.InspectionsModule)
  },
  {
    path: 'admin',
    canActivate: [UserRoleGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'materials',
    loadChildren: () => import('./components/materials/materials.module').then(m => m.MaterialsModule)
  },
  {
    path: 'assets/reports',
    loadChildren: () => import('./components/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'assets/dashboard', component: DashboardComponent,
  },
  {
    path: ':module/pbiReport', component: PbiReportComponent,
  },
  {
    path: 'osd',
    loadChildren: () => import('./components/osd-materials/osd-material/osd-material.module').then(m => m.OsdMaterialModule)
  },
  {
    path: 'geofence',
    loadChildren: () => import('./components/geofence/geofence.module').then(m => m.GeofenceModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
