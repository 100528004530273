import { userRolesEnum } from './../../common/utilities';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUserModel, IUserRolePermissions } from 'src/app/view-models/common.model';
import { CustomHttpClientService } from '../common/custom-http-client.service';

interface roleObj {
  [moduleId: number]: number
}

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  /** user roles data */
  private userRoleData: roleObj = {};

  private userPermissionsSubject = new BehaviorSubject<IUserRolePermissions[]|null>(null)

  userPermissions = this.userPermissionsSubject.asObservable()

  /** module mapping ids enum */
  userRoles = userRolesEnum;

  /** is user the Admin */
  isAdmin = false;

  isTrackemAdmin = false;

  constructor(private http: CustomHttpClientService) { }

  /**
   * get user role permissions
   *
   * @param {HttpParams} params
   * @return {*}  {Observable<IUserRolePermissions[]>}
   * @memberof UserRolesService
   */
  getUserRolePermissions(params: HttpParams): Observable<IUserRolePermissions[]> {
    return this.http.Get(`User/GetUserPermissions`, {
      params
    }).pipe() as Observable<IUserRolePermissions[]>;
  }

  /**
   * set user roles data
   *
   * @param {roleObj} userRolesObj
   * @memberof UserRolesService
   */
  setUserRolesData(userRolesObj: roleObj): void {
    this.userRoleData = userRolesObj;  
  }

  /**
   * set is site admin value
   *
   * @param {boolean} isAdmin
   * @memberof UserRolesService
   */
  setIsAdmin(isAdmin: boolean): void {
    this.isAdmin = isAdmin;
  }

  setIsTrackemAdmin(isTrackemAdmin:boolean){
    this.isTrackemAdmin = isTrackemAdmin;
  }

  setUserPermission(roles: IUserRolePermissions[]) {
    this.userPermissionsSubject.next(roles)
  }

  /**
   * check whether the user has access of current module
   *
   * @param {number} moduleId
   * @return {*}  {boolean}
   * @memberof UserRolesService
   */
  checkModuleAccess(moduleId: number): boolean {
    if (this.isAdmin || this.isTrackemAdmin) {
      return true;
    } else if (!isEmpty(this.userRoleData)) {
      return this.userRoleData[moduleId] === this.userRoles.moderatorRoleId;
    }
    return false;
  }

  checkAdminRole (){
    if (this.isAdmin || this.isTrackemAdmin) {
      return true;
    } 
    return false;
  }
  /**
   * get user selected theme
   *
   * @param {string} userId
   * @return {*}  {Observable<any>}
   * @memberof LoaderService
   */
   getTheme(userId: string): Observable<number> {
    return this.http.Get(`User/GetTheme/${userId}`).pipe() as Observable<number>;
  }

  /**
   * update theme
   *
   * @param {string} userId
   * @param {*} reqBody
   * @return {*}  {Observable<any>}
   * @memberof LoaderService
   */
  updateTheme(userId: string, reqBody: number): Observable<any> {
    return this.http.Put(`User/updateTheme/${userId}`, reqBody).pipe() as Observable<any>;
  }

  getAllUsers(companyId: number): Observable<IUserModel[]> {
    return this.http.Get<IUserModel[]>(`Company/${companyId}/GetAllUsers`).pipe() as Observable<IUserModel[]>;
  }
}
