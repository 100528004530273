<div class="user-select-company">
    <div class="multi-company-select" fxLayout="column" fxLayoutGap="25">
        <div class="multi-company-logo" fxLayoutAlign="center center">
            <img src="https://trackemprod.blob.core.windows.net/companylogo/Logo-Trackem-White.png"
                class="trackem-logo" />
        </div>
        <div class="choose-company">
            <mat-icon>domain</mat-icon><span class="ml-3">Choose Company</span>
        </div>
        <div class="mat-selection-list1 bg-01dp">
            <ng-container *ngIf="!isCompanyLoading; else isLoading">
                <mat-selection-list [multiple]="false" (selectionChange)="onSelectionChange($event)">
                    <mat-list-option *ngFor="let company of filteredCompanyData" [value]="company"
                        fxLayoutAlign="space-between center" fxLayout="row">
                        <div class="company-item">
                            <span> {{ company.name }} </span>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </ng-container>
 
            <ng-template #isLoading>
                <ng-container class="overlay">
                    <div class="companyLoader">
                        <mat-spinner></mat-spinner>
                        <div class="loadingIcon cursor-pointer">
                            <fa-icon [icon]="faTimes" (click)="loaderService.hide()"></fa-icon>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </div>
        <div class="mb-5 mr-3" fxLayoutAlign="end">
            <button mat-flat-button [disabled]="isCompanySelected" class="actionButton" (click)="submit()">
                Submit
            </button>
        </div>
    </div>
</div>