<div class="create-edit-dialog">
    <div mat-dialog-header fxLayoutAlign="space-between center">
        <span class="text-lg"> {{headerTitle}} </span>
        <mat-icon mat-ripple class="closeIcon" [mat-dialog-close]="false"> close </mat-icon>
    </div>
    <div mat-dialog-content fxLayout="column">
        <form [formGroup]="firstFormGroup">
            <mat-error class="mt-2 mb-2">*At least one of the options among Custodian and location is required.
            </mat-error>

            <mat-form-field class="cart-controls">
                <mat-label>Custodian</mat-label>
                <input type="text" placeholder="Custodian" matInput formControlName="custodianId"
                    [matAutocomplete]="custodianMenu" (input)="searchChange()">
                <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                <mat-autocomplete autoActiveFirstOption #custodianMenu="matAutocomplete" [displayWith]="display">
                    <mat-option *ngIf="isCustodianLoading">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </mat-option>
                    <mat-option *ngIf="!filteredCustodianData.length && !isCustodianLoading">No Results Found.
                    </mat-option>
                    <ng-container *ngIf="filteredCustodianData.length && !isCustodianLoading">
                        <mat-option *ngFor="let custodian of filteredCustodianData" [value]="custodian">
                            {{ custodian.employeeName }}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="cart-controls">
                <mat-label>Location</mat-label>
                <input type="text" placeholder="Location" matInput formControlName="locationId"
                    [matAutocomplete]="locationMenu" (input)="searchChange()">
                <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                <mat-autocomplete autoActiveFirstOption #locationMenu="matAutocomplete" [displayWith]="display">
                    <mat-option *ngIf="isLocationLoading">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </mat-option>
                    <mat-option *ngIf="!filteredLocationData.length && !isLocationLoading">No Results Found.
                    </mat-option>
                    <ng-container *ngIf="filteredLocationData.length && !isLocationLoading">
                        <mat-option *ngFor="let location of filteredLocationData" [value]="location"
                            matTooltip='{{location.description}}  -  {{location.companyName}}'
                            matTooltipPosition="left">
                            {{ location.description}}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field [formGroup]="secondFormGroup" class="cart-controls">
                <mat-label>Status</mat-label>
                <input type="text" placeholder="Status" matInput formControlName="statusId"
                    [matAutocomplete]="statusMenu" (input)="searchChange()">
                <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
                <mat-autocomplete autoActiveFirstOption #statusMenu="matAutocomplete" [displayWith]="display">
                    <mat-option *ngIf="isStatusLoading">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </mat-option>
                    <mat-option *ngIf="!filteredStatusData.length && !isStatusLoading">No Results Found.
                    </mat-option>
                    <ng-container *ngIf="filteredStatusData.length && !isStatusLoading">
                        <mat-option *ngFor="let status of filteredStatusData" [value]="status">
                            {{ status.description}}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-content fxLayout="column">
        <mat-form-field class="mapSearchBar">
            <mat-label>Comment</mat-label>
            <textarea [formControl]="commentFC" matInput autocomplete="off"></textarea>
        </mat-form-field>
    </div>
    <div mat-dialog-actions fxLayoutGap="20px" fxLayoutAlign="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button class="confirm-button disabled:pointer-events-none"
        [disabled]="validateSelectedValues() || validateStatus()"
            (click)="submit()">{{headerTitle}}</button>
    </div>
</div>