import { ICommonDropDownData } from 'src/app/view-models/common.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';
import { CustomHttpClientService } from '../common/custom-http-client.service';
@Injectable({
  providedIn: 'root'
})
export class CommonDropDownService {

  /** hide side navigation menu */
  hideSideNavigation = new BehaviorSubject(false);
  cachedCategory$?: Observable<ICommonDropDownData[]>;  
  cachedStatus$?: Observable<ICommonDropDownData[]>;  
  cachedCondition$?: Observable<ICommonDropDownData[]>;  
  cachedManufacturer$?: Observable<ICommonDropDownData[]>;  
  cachedLocation$?: Observable<ICommonDropDownData[]>;  
  cachedResult$?: Observable<ICommonDropDownData[]>;

  constructor(private http: CustomHttpClientService) { }

  /**
   * get status data
   *
   * @param {number} companyId
   * @return {*}  {Observable<ICommonDropDownData[]>}
   * @memberof StatusService
   */
  getDropDownData(requiredDataType: string, companyId: number): Observable<ICommonDropDownData[]> {
    let endPoint;
    switch(requiredDataType) {
        case 'status':
            endPoint = 'GetAllStatusWithoutPaging';

            if(!this.cachedStatus$) {
              this.cachedStatus$ = this.http.Get<ICommonDropDownData[]>(`Company/${companyId}/${endPoint}`).pipe(map((result) => {
                return result;
              }), shareReplay(1));
            }

            this.cachedResult$ = this.cachedStatus$;

            break;

        case 'condition':
          endPoint = 'GetAllConditionWithoutPaging';

          if(!this.cachedCondition$) {
            this.cachedCondition$ = this.http.Get<ICommonDropDownData[]>(`Company/${companyId}/${endPoint}`).pipe(map((result) => {
              return result;
            }), shareReplay(1));
          }

          this.cachedResult$ = this.cachedCondition$;

          break;

        case 'manufacturer':
            endPoint = 'GetAllManufacturer';

            if(!this.cachedManufacturer$) {
              this.cachedManufacturer$ = this.http.Get<ICommonDropDownData[]>(`Company/${companyId}/${endPoint}`).pipe(map((result) => {
                return result;
              }), shareReplay(1));
            }
  
            this.cachedResult$ = this.cachedManufacturer$;

            break;

        case 'location':
            endPoint = 'GetAllLocationsWithoutPaging';

            if(!this.cachedLocation$) {
              this.cachedLocation$ = this.http.Get<ICommonDropDownData[]>(`Company/${companyId}/${endPoint}`).pipe(map((result) => {
                return result;
              }), shareReplay(1));
            }
  
            this.cachedResult$ = this.cachedLocation$;

            break;

        case 'category':
            endPoint = 'GetAllCategoryWithoutPaging';

            if(!this.cachedCategory$) {
              this.cachedCategory$ = this.http.Get<ICommonDropDownData[]>(`Company/${companyId}/${endPoint}`).pipe(map((result) => {
                return result;
              }), shareReplay(1));
            }

            this.cachedResult$ = this.cachedCategory$;
            break;
    }

    return this.cachedResult$ as Observable<ICommonDropDownData[]>;
    // return this.http.Get(`Company/${companyId}/${endPoint}`).pipe() as Observable<ICommonDropDownData[]>;
  }
}
