import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { IPbiReport } from 'src/app/view-models/pbi-report-model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { CustomHttpClientService } from '../common/custom-http-client.service';
import { IReportModule } from 'src/app/view-models/report-model';
import { moduleEnum } from 'src/app/common/utilities';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  moduleReportSub = new BehaviorSubject(moduleEnum.asset);

  constructor(private http: CustomHttpClientService) { }

  getAllUserPbiReports(userId: string): Observable<IPbiReport[]> {
    return this.http.Get(`user/${userId}/GetAllUserPbiReports`, this.reqOpt).pipe() as Observable<IPbiReport[]>;
  }

  getAllUserReports(userId: string): Observable<IReportModule[]> {
    return this.http.Get(`user/${userId}/GetAllUserReports`,this.reqOpt).pipe() as Observable<IReportModule[]>;
  }

  getAllReports(): Observable<any[]> {
    return this.http.Get(`GetAllReports`, this.reqOpt).pipe() as Observable<any[]>;
  }

  getEmbedReport(reportId: string, companyName: string, moduleName: string){
    var options: IRequestOptions = {
      params: { showLoadingIndicator: true, reportId: reportId, companyName: companyName, moduleName: moduleName }
    };
    return firstValueFrom(this.http.Get<any>("EmbedReport", options));
  }

  updateUserReportAccess(userId: number, data: any[]): Observable<any>{
    return this.http.Put(`user/${userId}/UpdateUserReportAccess`, data, this.reqOpt).pipe() as Observable<any>;
  }
}
