import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-delete-check-in-check-out',
  templateUrl: './delete-check-in-check-out.component.html'
})
export class DeleteCheckInCheckOutComponent implements OnInit {
  faTrash = faTrash;

  /** agGrid Params */
  private params!: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  agInit(params: any): void {    
    this.params = params;
  }

  rowClicked(event: any) {
    this.params.clicked(this.params.data.id)
  }

}
