import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartService } from 'src/app/services/cart/cart.service';
import { forkJoin, Subscription } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AssetService } from 'src/app/services/assets/asset.service';
import { ReportServiceService } from 'src/app/services/report/report-service.service';
import { faForklift, faFileLines, faBoxesStacked, faObjectGroup, faPipeValve, faLocationDot, faArrowUpArrowDown, faClipboardListCheck, faCubesStacked, faClipboardCheck } from '@fortawesome/pro-solid-svg-icons';
import { AdminService } from 'src/app/services/admin-services/admin.service';
import { ConfigService } from 'src/app/services/common/config.service';
import { FeatureFlags, moduleEnum } from '../utilities';
import { storeNames } from '../utilities';
@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html'
})
export class SideNavigationComponent implements OnInit {
  faForklift = faForklift;
  faBoxesStacked = faBoxesStacked;
  faObjectGroup = faObjectGroup;
  faPipeValve = faPipeValve;
  faLocationDot = faLocationDot;
  faArrowUpArrowDown = faArrowUpArrowDown;
  faClipboardListCheck = faClipboardListCheck;
  faClipboardCheck = faClipboardCheck;
  faCubesStacked = faCubesStacked;
  faFileLines = faFileLines;
  ASSET_MODULE = moduleEnum.asset;
  STOCKITEM_MODULE = moduleEnum.stockitem;
  MATERIALS_MODULE = moduleEnum.material;
  DOCUMENT_MODULE = moduleEnum.document;
  DOCUMENT_V2_MODULE = moduleEnum.documentV2;


  @Input() isDisable = false;

  /** collapse side navigation */
  @Output() collapseSideNavigationMenu = new EventEmitter<boolean>();

  selectedRow!: string;

  /** OSD Tab visible */  
  isOsdTabVisible: boolean = false;

  /** Document Tab visible */  
  isDocumentTabVisible: boolean = false;

  /** Document Tab visible */  
  isDocumentV2TabVisible: boolean = false;


  /** subscription handler */
  subs!: Subscription;

  constructor(private cartService: CartService, private dbService: NgxIndexedDBService, private router: Router, private reportService: ReportServiceService, private adminService:AdminService, 
    private configService: ConfigService) {
    this.subs = new Subscription();
  }
 
  ngOnInit(): void {
    this.configService.getFeatureFlag(FeatureFlags.Osd).then(result => {
      this.isOsdTabVisible = result;
    });

    this.configService.getFeatureFlag(FeatureFlags.Document).then(result => {
      this.isDocumentTabVisible = result;
    });

    this.configService.getFeatureFlag(FeatureFlags.DocumentV2).then(result => {
      this.isDocumentV2TabVisible = result;
    });

    const cartService = this.cartService.cart.subscribe(res => {
      const cartCount = res;
      this.selectedRow = cartCount > 999 ? '1k+': cartCount.toString();
    });
    this.subs.add(cartService);

    if (localStorage.getItem('cartCount') as string) {
      const cartCount = JSON.parse(localStorage.getItem('cartCount') as string);
      this.selectedRow = cartCount > 999 ? '1k+' : cartCount;
    } else {
      const storeNames$ = storeNames.map(element => this.dbService.count(element));
      this.subs.add(forkJoin(storeNames$).subscribe((res: number[]) => {
        const totalCartCount = res.reduce((prev, curr) => prev + curr, 0);
        this.cartService.cart.next(totalCartCount);
        localStorage.setItem('cartCount', JSON.stringify(totalCartCount));
      }));
    }

    this.adminService.naviagtaionCollapsed.subscribe((value) => {
      this.collapseSideNavigationMenu.emit(value);
    })
    if(window.location.href.includes('map')){
      this.collapseSideNavigationMenu.emit(true);
    }else{
      this.collapseSideNavigationMenu.emit(false);
    }
    }
    
    ngOnDestroy(): void {
      this.subs.unsubscribe();
    }

  /**
   * collapse side navigation
   *
   * @param {string} module
   * @param {boolean} [collapse=false]
   * @memberof SideNavigationComponent
   */
  collapseSideNavigation(module: string, collapse = false): void {
    this.reportService.reportModuleSub.next(module);
    this.collapseSideNavigationMenu.emit(collapse);
  }

  hasAccessToModule(module: moduleEnum) {    
    var activeModules = localStorage.getItem('activeModules');
    var modules = JSON.parse(activeModules as string);    

    if(modules && modules.length > 0){
      return modules.includes(module);
    }

    return true;
  }
}
