import { moduleEnum, userRolesEnum } from './../utilities';
import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { faDashboard, faHome, faInfoCircle, faMoon, faSignOutAlt, faSun, faUserShield, faChartSimple, faShieldCheck, faUserPlus, faPlus, faFileContract, faL, faLocation } from '@fortawesome/pro-solid-svg-icons';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { SitesService } from '../../services/sites/sites.service';
import { LoaderService } from '../../services/common/loader.service';
import { Subject, Subscription, tap, switchMap, of, filter, forkJoin } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AssetService } from 'src/app/services/assets/asset.service';
import { environment } from 'src/environments/environment';
import { ISiteModel } from 'src/app/view-models/site-model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StockItemsService } from 'src/app/services/stock-items/stock-items.service';
import { AssetsSearchComponent } from 'src/app/components/assets/assets-search/assets-search.component';
import { CartComponent } from 'src/app/components/cart/cart.component';
import { CommonDropDownService } from 'src/app/services/common/common-dropdown.service';
import { FooterComponent } from '../footer/footer.component';
import { HttpParams } from '@angular/common/http';
import { UserRolesService } from 'src/app/services/user-roles/user-roles.service';
import { isEmpty } from 'lodash';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { IReportModule } from 'src/app/view-models/report-model';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { ReportServiceService } from 'src/app/services/report/report-service.service';
import { IPbiReport } from 'src/app/view-models/pbi-report-model';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { AdminService } from 'src/app/services/admin-services/admin.service';
import { WorkorderService } from 'src/app/services/workorder/workorder.service';
import { SupplierService } from 'src/app/services/supplier/supplier.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { AzureMonitoringService } from '../logging/logging.service';
import { CompanyService } from 'src/app/services/common/company.service';
import { GridService } from 'src/app/services/common/grid.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  faHome = faHome;
  faLocation = faLocation;
  faBuilding = faBuilding;
  faFileContract = faFileContract
  faInfoCircle = faInfoCircle;
  faChevronDown = faChevronDown;
  faLogout = faSignOutAlt;
  faChartSimple = faChartSimple;
  UserName!: string | null;
  reportAccess: boolean = false;

  /** site data */
  allSitesInfo!: ISiteModel[];
  filteredSiteData!: ISiteModel[];

  /** site id form control */
  siteIdFC!: FormControl;

  /** project */
  projectFC!: FormControl;
  projectData: any[] = [];
  isProjectLoading = true;
  filteredProjectData: any[] = [];
  projectChanged = false;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  activatedTheme!: string;
  faSun = faSun;
  faMoon = faMoon;

  /** isSiteLoading the data */
  isSiteLoading = true;

  /** subscription handler */
  subs!: Subscription;

  /** admin icon */
  faUserShield = faUserShield;

  faDashboard = faDashboard;

  /** role enum */
  userRoles = userRolesEnum;

  /** check if user is admin or not */
  isAdmin = false;

  /** check if user is TrackemAdmin or not */
  isTrackemAdmin = false;

  /** user registration */
  faShieldCheck = faShieldCheck;

  /** company information */
  faPlus = faPlus

  /** user id */
  userId!: string;

  /** site changing indicator */
  siteChanged = false;

  /** modules */
  reportModule: IReportModule[] = [];

  reportsLoaded: boolean = false;

  /** module  */
  moduleType!: string;

  moduleId!: number;

  selectedModule!: string;
  assetDashboardAccess: boolean = true;

  activatedThemeId!: number;

  pbiReports: IPbiReport[] = []

  selectedPbiReports!: IPbiReport

  reports: IReportModule[] = []


  constructor(public assets: AssetsSearchComponent, private sitesService: SitesService, private router: Router,
    private loaderService: LoaderService, private dialog: MatDialog, private commonDropDownService: CommonDropDownService, public employeeService: EmployeeService,
    private authService: MsalService, public assetService: AssetService, private dbService: NgxIndexedDBService, public workOrderService: WorkorderService, public supplierService: SupplierService,
    private stockItemService: StockItemsService, private cartComponent: CartComponent, private userRolesService: UserRolesService, private reportService: ReportServiceService,
    private userReportService: ReportsService, private msalBroadcastService: MsalBroadcastService, private adminService: AdminService, private appInsights: AzureMonitoringService,
    private companyService: CompanyService, private gridService: GridService, private route: ActivatedRoute) {
    this.subs = new Subscription();
    this.siteIdFC = new FormControl('');
    this.projectFC = new FormControl('');
  }

  ngOnInit(): void {
    this.loginDisplay = JSON.parse(localStorage.getItem('loginDisplay') as string);
    const companyCode = localStorage.getItem('companyId') as string;
    this.getCompanyActiveModules(companyCode);
    this.isSiteLoading = true;
    this.UserName = localStorage.getItem("name");
    this.userId = localStorage.getItem('userId') as string;
    this.getUserSelectedTheme();

    const getProjects = this.companyService.getActiveProjects(companyCode).subscribe(projects => {
      this.projectData = projects
      this.isProjectLoading = false;
    });
    this.subs.add(getProjects);

    const getSites = this.sitesService.getSites(companyCode).subscribe(sites => {
      this.isProjectLoading = false;

      localStorage.setItem('allSitesInfo', JSON.stringify(sites));
      this.isSiteLoading = false;
      const selectedSiteData = JSON.parse(sessionStorage.getItem('siteData') as string);
      this.allSitesInfo = sites;
      if (selectedSiteData) {
        sessionStorage.setItem('siteData', JSON.stringify(selectedSiteData));
        localStorage.setItem('companyName', selectedSiteData.companyName);
        this.siteIdFC.setValue(selectedSiteData);
        this.projectFC.setValue(selectedSiteData.project)
      } else {
        var siteData: any;
        this.route.queryParams.subscribe((params : any) =>{
          try{
            siteData =  JSON.parse(params['data']) as ISiteModel
          }catch(ex){
            siteData =  sites[0]
          }
         });
        sessionStorage.setItem('siteData', JSON.stringify(siteData));
        localStorage.setItem('companyName', siteData.companyName);
        this.siteIdFC.setValue(siteData);
        this.projectFC.setValue(siteData.project)

      }
      this.getUserRolePermissions();
    });
    this.subs.add(getSites);

    this.userReportService.moduleReportSub.subscribe((res) =>{
      this.loadReports();
    })

    const siteValueChanged = this.siteIdFC.valueChanges
      .pipe(
        tap(() => (this.filteredSiteData = this.allSitesInfo))
      ).subscribe((val: string | ISiteModel) => {
        this.siteChanged = false;
        const siteName = typeof val === 'string' ? val : val?.siteName;
        if (this.projectFC.value !== null && !!this.projectFC.value && this.projectFC.value !== '') {
          (this.filteredSiteData = this.allSitesInfo?.filter((site) =>
            site.siteName.toLowerCase().includes(siteName.toLowerCase().trim()) &&
            site.project === this.projectFC.value
          ))
        } else {
          (this.filteredSiteData = this.allSitesInfo?.filter((site) =>
            site.siteName.toLowerCase().includes(siteName.toLowerCase().trim())
          ))
        }
      }
      );
    this.subs.add(siteValueChanged);

    const projectValueChanges = this.projectFC.valueChanges
      .pipe(
        tap(() => (this.filteredProjectData = this.projectData))
      ).subscribe((val: string | any) => {
        this.projectChanged = false;
        const projectName = typeof val === 'string' ? val : val;
        if (projectName !== null && !!projectName) {
          (this.filteredProjectData = this.projectData?.filter((project) =>
            project?.toLowerCase().includes(projectName.toLowerCase().trim())
          ))
        }
      }
      );
    this.subs.add(projectValueChanges);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.subs.unsubscribe();
  }

  getCompanyActiveModules(companyId: string) {
    this.companyService.getActiveModules(companyId).subscribe(modules => {
      localStorage.setItem('activeModules', JSON.stringify(modules));
    })
  }

  /**
 * site blurred event handled
 *
 * @param {HTMLInputElement} target
 * @param {FocusEvent} event
 * @memberof NavigationComponent
 */
  siteBlurred(target: HTMLInputElement, event: FocusEvent): void {
    const sites = JSON.parse(localStorage.getItem('allSitesInfo') as string);
    if (this.projectFC.value !== "" && this.projectFC.value !== null) {
      this.filteredSiteData = sites?.filter((site: any) => site.project !== null &&
        site.project.toLowerCase() === this.projectFC.value.toLowerCase().trim());
    } else {
      this.filteredSiteData = sites;
    }
  }

  /**
   * select site name
   *
   * @param {ISiteModel} site
   * @memberof NavigationComponent
   */
  selectedSiteName(site: ISiteModel) {

    localStorage.removeItem('selectedAssetsList');
    localStorage.removeItem('selectedStockItemsList');
    localStorage.removeItem('selectedMaterialsList');
    localStorage.removeItem('selectedDocumentsList');
    localStorage.removeItem('selectedGroupsList');

    const siteData = JSON.parse(sessionStorage.getItem('siteData') as string);
    if (site.id !== siteData.id) {
      let message = 'Changing the site will remove all unsaved data.';

      JSON.parse(localStorage.getItem('cartCount') as string) ? this.router.url === '/cart' ? message = `${message} It will discard all your changes.` : `${message}.` : null;
      this.siteChanged = true;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: 'auto',
        width: 'fit-content',
        disableClose: true
      });
      dialogRef.componentInstance.confirmMessage = message;
      dialogRef.componentInstance.instructionMessage = `Please confirm to proceed ahead.`;

      const dialogClosed = dialogRef.afterClosed().pipe(
        switchMap(res => {
          if (res) {
            this.clearCache();
            localStorage.removeItem('scannedAssetData')
            this.siteIdFC.setValue(site);
            this.projectFC.setValue(site.project)
            sessionStorage.setItem('siteData', JSON.stringify(site));
            localStorage.setItem('companyName', site.companyName);
            this.assetService.setSearchedAsset('');
            this.stockItemService.setSearchedStockItem('');
            this.getUserRolePermissions();
            this.commonDropDownService.hideSideNavigation.next(false);
            if (this.router.url === '/assets') {
              this.assetService.saveOrResetSub.next('resetData')
            } else {
              this.router.navigate([`assets`]);
            }
            return this.cartComponent.emptyCartData();
          } else {
            this.siteIdFC.setValue(siteData);
            this.projectFC.setValue(siteData.project)
          }
          this.siteChanged = false;
          return of([]);
        })
      ).subscribe();
      this.subs.add(dialogClosed);
    }
  }

  /**
   * filter sites on project change
   *
   * @param {ISiteModel} site
   * @memberof NavigationComponent
   */
  filterSites(event: any, filterType: string) {
    const sites = JSON.parse(localStorage.getItem('allSitesInfo') as string);
    const selectedSite = JSON.parse(sessionStorage.getItem('siteData') as string);
    if (filterType == 'project') {
      if (event.isUserInput) {
        this.projectFC.setValue(event.source.value)
        if (event.source.value !== "" && event.source.value !== null) {
          this.filteredSiteData = sites?.filter((site: any) => site.project !== null &&
            site.project.toLowerCase() === event.source.value.toLowerCase().trim());

          if (selectedSite.project !== event.source.value) {
            this.siteIdFC.setValue('');
          } else {
            this.siteIdFC.setValue(selectedSite);
          }
        }
      }
    } else if (filterType == 'site') {
      if (this.projectFC.value !== "" && this.projectFC.value !== null) {
        this.filteredSiteData = sites?.filter((site: any) => site.project !== null &&
          site.project.toLowerCase() === this.projectFC.value.toLowerCase().trim());
      } else {
        this.filteredSiteData = sites;
      }
    }
  }

  clearCache() {
    this.gridService.clearAssetUserProfileCache();
    this.gridService.clearStockItemUserProfileCache();
    this.gridService.clearMaterialUserProfileCache();
    this.gridService.clearGroupUserProfileCache();
    this.gridService.clearDocumentUserProfileCache();
    this.gridService.clearDocumentV2UserProfileCache();
  }

  /**
   *  Navigate to company module
   *
   * @memberof NavigationComponent
   */
  navigateToCompanyModule() {
    this.adminService.setNavigationCollapsed(false);
    this.loaderService.activePage.next('admin');
    localStorage.setItem('activePage', 'admin');

    this.checkActivePage();
    this.router.navigate([`admin/company`])
  }

  logout() {
    const sub = this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.authService.logoutRedirect({
          postLogoutRedirectUri: environment.redirectUrl,
          onRedirectNavigate: (url) => {
            localStorage.clear();
            sessionStorage.clear()
          }
        });

        this.subs.add(this.dbService.deleteDatabase().subscribe());
      })

    this.subs.add(sub)
  }

  /**
   * display selected site data
   *
   * @param {ISiteModel} siteData
   * @return {*}  {string}
   * @memberof NavigationComponent
   */
  display(siteData: ISiteModel): string {
    return siteData?.siteName || '';
  }

  /**
   * display about dialog
   *
   * @memberof NavigationComponent
   */
  showAboutDialog(): void {
    this.dialog.open(FooterComponent, {
      height: 'auto',
      width: '25%',
      disableClose: false
    });
  }

  /**
   * set user data
   *
   * @memberof NavigationComponent
   */
  setUserData(activePage: string, isHome?: boolean): void {
    this.adminService.setNavigationCollapsed(false);
    this.loaderService.activePage.next(activePage);
    localStorage.setItem('activePage', activePage);
    if (isHome) {
      this.commonDropDownService.cachedCategory$ = undefined;
      this.commonDropDownService.cachedCondition$ = undefined;
      this.commonDropDownService.cachedLocation$ = undefined;
      this.commonDropDownService.cachedManufacturer$ = undefined;
      this.commonDropDownService.cachedStatus$ = undefined;
      this.workOrderService.cachedWorkOrder$ = undefined;
      this.supplierService.cachedSupplier$ = undefined;
      this.employeeService.cachedEmployee$ = undefined;
    }
  }

  /**
   * get user role permissions
   *
   * @memberof NavigationComponent
   */
  getUserRolePermissions(): void {
    const siteId = JSON.parse(sessionStorage.getItem('siteData') as string).id;
    const params = new HttpParams().set('siteId', this.siteIdFC.value.id || siteId);
    this.subs.add(this.userRolesService.getUserRolePermissions(params).subscribe(res => {
      let userRolePermissionObj: {
        [moduleId: number]: number
      } = {};

      if (res.length) {
        const trackemRole = res.findIndex(permissionData => permissionData.roleId === this.userRoles.trackemAdminRoleId);
        const adminRole = res.findIndex(permissionData => permissionData.roleId === this.userRoles.adminRoleId);
        const viewerRole = res.findIndex(permissionData => permissionData.roleId === this.userRoles.viewerRoleId);
        const moderatorRole = res.findIndex(permissionData => permissionData.roleId === this.userRoles.moderatorRoleId);
        const MultiCompanyAccessRole = res.findIndex(permissionData => permissionData.roleId === this.userRoles.MultiCompanyAccessRoleId);

        if (trackemRole > -1) {
          this.isTrackemAdmin = true;
          this.userRolesService.setIsTrackemAdmin(true);
          localStorage.setItem('userRole', 'TrackemAdmin');
        } else if (adminRole > -1 || MultiCompanyAccessRole > -1) {
          localStorage.setItem('userRole', 'Admin');
          this.isAdmin = true;
          this.userRolesService.setIsAdmin(true);
        } else {
          res.forEach(element => {
            userRolePermissionObj[element.moduleId] = element.roleId
          });
          if (viewerRole > -1 && moderatorRole > -1) {
          } else {
            if (viewerRole > -1) {
              localStorage.setItem('userRole', 'Viewer');
            } else if (moderatorRole > -1) {
              localStorage.setItem('userRole', 'Moderator');
            }
          }
        }
      }

      if (!isEmpty(userRolePermissionObj)) {
        this.userRolesService.setUserRolesData(userRolePermissionObj);
      }

      this.userRolesService.setUserPermission(res)
    }));
  }

  /**
   * check active page
   *
   * @return {*}  {boolean}
   * @memberof NavigationComponent
   */
  checkActivePage(): string {
    if (localStorage.getItem('activePage') === 'home' && this.loaderService.activePage.value === 'home') {
      return 'home';
    } else {
      return this.router.url.includes('admin') ? 'admin' : 'home';
    }
  }

  /**
   * get user selected theme
   *
   * @memberof NavigationComponent
   */
  getUserSelectedTheme(): void {
    if (localStorage.getItem('selectedTheme')) {
      this.activatedTheme = localStorage.getItem('selectedTheme') ?? '';
      this.activatedThemeId = this.activatedTheme === 'dark' ? 0 : 1;
    }

    const themeSub = this.userRolesService.getTheme(this.userId).subscribe(res => {
      this.activatedTheme = res ? 'Light' : 'Dark';
      this.activatedThemeId = res ? 1 : 0;
      this.loaderService.themeToggleSubject.next(res ? 'light' : 'dark');
    });
    this.subs.add(themeSub);
  }

  /**
   * toggle theme
   *
   * @memberof NavigationComponent
   */
  themeToggle() {
    if (this.activatedTheme === 'Dark') {
      this.loaderService.themeToggleSubject.next('light');
      this.activatedTheme = 'Light';
      this.activatedThemeId = 1;
    } else {
      this.loaderService.themeToggleSubject.next('dark');
      this.activatedTheme = 'Dark';
      this.activatedThemeId = 0;
    }

    const theme = this.activatedTheme === 'Light' ? 1 : 0;
    this.subs.add(this.userRolesService.updateTheme(this.userId, theme).subscribe((res) => localStorage.setItem('selectedTheme', theme == 0 ? 'dark' : 'light')
    ));
  }

  checkUserAccess(): boolean {
    return this.userRolesService.checkModuleAccess(moduleEnum.asset);
  }

  checkDashboardModule(): boolean {
    if (this.router.url.includes('assets')) {
      this.selectedModule = "assets"
      return this.assetDashboardAccess;
    }
    else {
      this.selectedModule = ""
      return true
    }
  }

  navigateToDashboard(theme: any) {
    this.loaderService.show();
    this.router.navigate(['/dashboard'], { queryParams: { module: this.selectedModule } });
  }

  checkReportsModule(): boolean {
    if (this.router.url.includes('/assets')) {
      return false;
    } else if (this.router.url.includes('/stock-items')) {
      return false
    } else if (this.router.url.includes('/materials')) {
      return false
    } else if (this.router.url.includes('/groups')) {
      return false
    } else {
      return true
    }
  }

  getAllReports() {
    return forkJoin({
      pbiReports: this.userReportService.getAllUserPbiReports(this.userId),
      reports: this.userReportService.getAllUserReports(this.userId)
    });
  }

  loadReports() {
    let siteInfo = JSON.parse(sessionStorage.getItem('siteData') as string);
    this.getAllReports().subscribe({
      next: (results) => {
        this.reports = results.reports;
        this.pbiReports = results.pbiReports;
        this.assetDashboardAccess = true
        
          if (this.router.url.includes('/assets')) {
              this.reportModule = [];
              this.reportModule = this.reports.filter(el => +el.moduleId === +moduleEnum.asset);
              this.reportsLoaded = true;

              if(this.pbiReports.length > 0 && this.pbiReports.some(x => x.isDashboard == true)){
                this.assetDashboardAccess = false
              }
              let reports = this.pbiReports.filter(el => (+el.moduleId === +moduleEnum.asset || el.moduleId === null) && el.isDashboard == false);
          
              if(reports.length > 0 || this.reportModule.length > 0)
                {
                this.reportAccess =   true;
                reports.forEach(r => {
                  this.reportModule.push({
                    id: r.id,
                    moduleId: r.moduleId,
                    powerBIReportGuid: r.powerBIReportGuid,
                    name: r.name,
                    module: 'assets'
                  })
                })
                this.appInsights.trackEvent('ViewAssetReport', siteInfo?.siteName, {})
              }else{
                this.reportAccess = false;
            }
          }
          else if (this.router.url.includes('/stock-items')) {
            let reports = this.pbiReports.filter(el => (+el.moduleId === +moduleEnum.stockitem || el.moduleId === null) && el.isDashboard == false);
            this.reportModule = []
            if(reports.length > 0)
              {
                this.reportAccess =   true;
                reports.forEach(r => {
                  this.reportModule.push({
                    id: r.id,
                    moduleId: r.moduleId,
                    powerBIReportGuid: r.powerBIReportGuid,
                    name: r.name,
                    module: 'stock-items'
                  })
                  this.appInsights.trackEvent('ViewStockItemsReport', siteInfo.siteName, {})
                })
              }else{
                this.reportAccess = false;
              }
            } else if (this.router.url.includes('/materials')) {
            let reports = this.pbiReports.filter(el => (+el.moduleId === +moduleEnum.material || el.moduleId === null) && el.isDashboard == false);
            this.reportModule = []
            if(reports.length > 0)
              {
                this.reportAccess =   true;
                reports.forEach(r => {
                  this.reportModule.push({
                    id: r.id,
                    moduleId: r.moduleId,
                    powerBIReportGuid: r.powerBIReportGuid,
                    name: r.name,
                    module: 'materials'
                  })
                  this.appInsights.trackEvent('ViewMaterialsReport', siteInfo.siteName, {})
                })
              }else{
                this.reportAccess = false;
              }
            } else if (this.router.url.includes('/groups')) {
            let reports = this.pbiReports.filter(el => (el.moduleId === +moduleEnum.group || el.moduleId === null) && el.isDashboard == false);
            this.reportModule = []
            if(reports.length > 0)
              {
                this.reportAccess =   true;
                reports.forEach(r => {
                  this.reportModule.push({
                    id: r.id,
                    moduleId: r.moduleId,
                    powerBIReportGuid: r.powerBIReportGuid,
                    name: r.name,
                    module: 'groups'
                  })
                })
              }else{
                this.reportAccess = false;
              }
          }
      }})
          
  }
  
  navigateToReport(report: any, theme: any) {
    if (report.powerBIReportGuid !== undefined) {
      this.selectedPbiReports = report;
      this.loaderService.show();
      this.router.navigate([`${report.module}/pbiReport`], { queryParams: { reportId: report.powerBIReportGuid } });
    } else {
      if (report.id == 1) {
        this.router.navigate([`/reports`]);
      } else {
        this.router.navigate([`/reports/utilisationReport`]);
      }
    }
  }

  /**
 * display selected project name
 *
 * @param {any} project
 * @return {*}  {string}
 * @memberof NavigationComponent
 */
  displayProject(project: any): string {
    return project || '';
  }

  checkMap(){
    if(this.router.url === '/map' || this.router.url === '/geofence'){
      return false;
    } else {
      return true;
    }
  }
}
