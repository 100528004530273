<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content >
    <div class="full-width">
        <mat-card class="mat-elevation-z4 bg-01dp mr-2 my-2" fxLayout="row">
            <input type="file" class="hidden" #fileUpload (change)="onFileSelected($event)" multiple accept="image/jpg, image/jpeg, image/png"/>
            <button [disabled]="fileCount >= 5" mat-flat-button fxFlex="30" (click)="fileUpload.click()"><fa-icon [icon]="faUpload" class="mr-3"></fa-icon>SELECT ATTACHMENT</button>
            <span [fxHide]="fileCount < 5" class="text-red-600 ml-2 mt-6"><u>A folder can only contain maximum 5 records.</u></span>
        </mat-card>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close color="warn">Cancel</button>
</mat-dialog-actions>
