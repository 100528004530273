import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, of, shareReplay } from "rxjs";
import { IGridColumns, IUserProfileGetGridCols } from "src/app/view-models/common.model";
import { IRequestOptions } from "src/app/view-models/request.options";
import { CustomHttpClientService } from "./custom-http-client.service";
import { moduleEnum } from "src/app/common/utilities";

@Injectable({
    providedIn: 'root'
})
export class GridService {

    /** request options */
    reqOpt: IRequestOptions = {
        showLoadingIndicator: true
    }

    cacheUserProfileAssetValue$: Observable<IUserProfileGetGridCols> = of();
    cacheUserProfileStockItemValue$: Observable<IUserProfileGetGridCols> = of();
    cacheUserProfileMaterialValue$: Observable<IUserProfileGetGridCols> = of();
    cacheUserProfileDocumentValue$: Observable<IUserProfileGetGridCols> = of();
    cacheUserProfileGroupValue$: Observable<IUserProfileGetGridCols> = of();
    cacheUserProfileDocumentV2Value$: Observable<IUserProfileGetGridCols> = of();
    userProfileAssetValue: any = null;
    userProfileMaterialValue: any = null;
    userProfileStockItemValue: any = null;
    userProfileGroupValue: any = null;
    userProfileDocumentValue: any = null;
    userProfileDocumenV2Value: any = null;

    constructor(private http: CustomHttpClientService) { }

    clearAssetUserProfileCache(): void {
        this.userProfileAssetValue = null;        
    }

    clearMaterialUserProfileCache(): void {
        this.userProfileMaterialValue = null;
    }

    clearStockItemUserProfileCache(): void {
        this.userProfileStockItemValue = null;
    }

    clearGroupUserProfileCache(): void {
        this.userProfileGroupValue = null;
    }

    clearDocumentUserProfileCache(): void {
        this.userProfileDocumentValue = null;
    }

    clearDocumentV2UserProfileCache(): void {
        this.userProfileDocumenV2Value = null;
    }

    /**
     * update grid column
     *
     * @param {IGridColumns} column
     * @return {*}  {Observable<IUserProfileGetGridCols>}
     * @memberof AssetService
     */
    updateGridColumn(siteId: number, column: IGridColumns): Observable<IUserProfileGetGridCols> {
        return this.http.Post<IUserProfileGetGridCols>(`site/${siteId}/createUserProfile`, column);
    }

    /**
     * get grid columns
     *
     * @return {*}  {Observable<any>}
     * @memberof AssetService
     */
    getGridColumn(siteId: number, params: HttpParams): Observable<IUserProfileGetGridCols> {
        this.reqOpt['params'] = params;
        this.reqOpt['showLoadingIndicator'] = false;
        var mappingId = params.get("moduleId");                
        if(mappingId == moduleEnum.asset.toString()) {
            if(!this.userProfileAssetValue) {           
                this.userProfileAssetValue = mappingId;
                this.cacheUserProfileAssetValue$ = this.http.Get<IUserProfileGetGridCols>(`site/${siteId}/getUserProfile`, this.reqOpt).pipe(map((result) => {
                    return result;
                }), shareReplay(1));
            }        
    
            return this.cacheUserProfileAssetValue$;
        }        
        else if(mappingId == moduleEnum.stockitem.toString()) {                        
            if(!this.userProfileStockItemValue) {           
                this.userProfileStockItemValue = mappingId;
                this.cacheUserProfileStockItemValue$ = this.http.Get<IUserProfileGetGridCols>(`site/${siteId}/getUserProfile`, this.reqOpt).pipe(map((result) => {
                    return result;
                }), shareReplay(1));
            }        
    
            return this.cacheUserProfileStockItemValue$;
        }        
        else if(mappingId == moduleEnum.group.toString()) {
            if(!this.userProfileGroupValue) { 
                this.userProfileGroupValue = mappingId;          
                this.cacheUserProfileGroupValue$ = this.http.Get<IUserProfileGetGridCols>(`site/${siteId}/getUserProfile`, this.reqOpt).pipe(map((result) => {
                    return result;
                }), shareReplay(1));
            }        
    
            return this.cacheUserProfileGroupValue$;
        }   
        else if(mappingId == moduleEnum.document.toString()) {
            if(!this.userProfileDocumentValue){
                this.userProfileDocumentValue = mappingId;
                this.cacheUserProfileDocumentValue$ = this.http.Get<IUserProfileGetGridCols>(`site/${siteId}/getUserProfile`, this.reqOpt).pipe(map((result) => {
                    return result;
                }), shareReplay(1));
            }    
    
            return this.cacheUserProfileDocumentValue$;
        }    
        else if(mappingId == moduleEnum.documentV2.toString()) { {
            if(!this.userProfileDocumenV2Value){
                this.userProfileDocumenV2Value = mappingId;
                this.cacheUserProfileDocumentV2Value$ = this.http.Get<IUserProfileGetGridCols>(`site/${siteId}/getUserProfile`, this.reqOpt).pipe(map((result) => {
                    return result;
                }), shareReplay(1));
            }
          } 
            
           return this.cacheUserProfileDocumentV2Value$;
        }
        else 
            if(!this.userProfileMaterialValue) {   
                this.userProfileMaterialValue = mappingId;        
                this.cacheUserProfileMaterialValue$ = this.http.Get<IUserProfileGetGridCols>(`site/${siteId}/getUserProfile`, this.reqOpt).pipe(map((result) => {
                    return result;
                }), shareReplay(1));
            }        
    
            return this.cacheUserProfileMaterialValue$;
    }

    /**
     * reset grid columns
     *
     * @return {*}  {Observable<any>}
     * @memberof AssetService
     */
    resetGridColumn(siteId: number, reqBody: IGridColumns): Observable<IUserProfileGetGridCols> {
        if(reqBody.moduleId == moduleEnum.material)
            this.clearMaterialUserProfileCache();
        if(reqBody.moduleId == moduleEnum.asset)
            this.clearAssetUserProfileCache();
        if(reqBody.moduleId == moduleEnum.stockitem)
            this.clearStockItemUserProfileCache();
        if(reqBody.moduleId == moduleEnum.group)
            this.clearGroupUserProfileCache();
        if(reqBody.moduleId == moduleEnum.documentV2)
            this.clearDocumentV2UserProfileCache();
        
        return this.http.Put<IUserProfileGetGridCols>(`site/${siteId}/resetUserProfile`, reqBody)
    }
}
