import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigModel } from 'src/app/view-models/config-model';
import { AppConfigurationClient } from '@azure/app-configuration'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config!: ConfigModel;

  constructor() { }

  private getConfig(): ConfigModel {
    if (!this.config) {
      this.config = environment;
    }
    return this.config;
  }

  getAppInsightsInstrumentationKey(): string {
    let conf = this.getConfig();
    return conf.appInsights.instrumentationKey;
  }

  getProduction(): boolean {
    let conf = this.getConfig();
    return conf.production;
  }
  
  async getFeatureFlag(featureFlag: string){
    let conf = this.getConfig();
    const conn = conf.azureFeatureFlagConnectionString;
    const client = new AppConfigurationClient(conn);
    var val = await client.getConfigurationSetting({ key: `.appconfig.featureflag/${featureFlag}` });
    var result = JSON.parse(val.value ?? '').enabled;

    return result;
  }

  async getFeatureFlagFilters(featureFlag: string){
    let conf = this.getConfig();
    const conn = conf.azureFeatureFlagConnectionString;
    const client = new AppConfigurationClient(conn);
    var val = await client.getConfigurationSetting({ key: `.appconfig.featureflag/${featureFlag}` });    
    var result = JSON.parse(val.value ?? '').conditions.client_filters;

    return result;
  }
}
