import { Component, OnInit, Input, OnDestroy, Self, Optional, ViewChild, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { FormControl, ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
})
export class AutocompleteDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
  
  faChevronDown = faChevronDown;

  @Input() label!: string;
  
  @Input() isLoading!: boolean;
  
  @Input() optionsData!: any[];
  
  @Input() displayField!: string;

  @Input() required!: boolean;
  
  @Input() isDisable!: boolean;

  @Input() matTooltipDisabled: boolean = true;

    /** output variables */
  @Output() changedValue = new EventEmitter();

  autocompleteControl: FormControl = new FormControl();
  
  filteredOptions!: any[];
  
  filterValue!: any;
  
  subs: Subscription = new Subscription;

  onTouched: Function = ()=>{};

  onChange: any = ()=>{};

  showRequiredError = false

  @ViewChild('siteControl', { read: MatAutocompleteTrigger })
  autoComplete!: MatAutocompleteTrigger;

  constructor(@Self() @Optional() private ngControl: NgControl, private cd: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
   }

  ngOnInit(): void {
    this.cd.detectChanges();
    const controlSub = this.autocompleteControl.valueChanges.subscribe((value: any)=>{
      this.changedValue.emit(value);
      this.onChange(value)
      this.filterValue = typeof value === 'string' ? value : (value ? value[this.displayField] : null)
      this.filteredOptions = this.optionsData?.filter(option => !this.filterValue || (option[this.displayField].toLowerCase().includes(this.filterValue?.toLowerCase().trim())))
    })
 
    this.isDisable === true ? this.autocompleteControl.disable() : this.autocompleteControl.enable();
    this.subs.add(controlSub)
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (): void => {
    this.autoComplete.updatePosition();
  };

  ngAfterViewInit() {
    this.setDisabledState(this.isDisable)
  }

  ngOnChanges(){
    if (this.optionsData){
      this.filteredOptions = this.optionsData.filter(option => !this.filterValue || option[this.displayField].toLowerCase().includes(this.filterValue?.toLowerCase().trim()))
    }

    var control = this.ngControl?.control
    if (control){
      this.autocompleteControl.setValidators(control.validator)
      if (control.hasValidator(Validators.required)) {
        this.autocompleteControl.addValidators(Validators.required)
      }
    }
    this.setDisabledState(this.isDisable);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  displayFn(data:any) {
    return data ? data[this.displayField] : data || ""
  }

  writeValue(value: any): void {
    this.autocompleteControl.setValue(value,{ emitEvent : true })
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.autocompleteControl.disable() : this.autocompleteControl.enable()
  }

  registerOnChange(onChange: any): void {
    if(!!onChange){
      this.onChange = onChange
    }
  }

  registerOnTouched(onTouched: Function): void {
    this.onTouched = onTouched
  }

  onInputChange(){
    if (this.autocompleteControl.value.trim() === '') {
      this.autocompleteControl.setValue(this.autocompleteControl.value.trim())
    }
  }
}
