import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CSVBoxMethods } from '@csvbox/angular';
import { Subscription } from 'rxjs';
import { FileUploadService } from 'src/app/services/common/file-upload.service';
import { ISiteModel } from 'src/app/view-models/site-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-csvbox',
  templateUrl: './csvbox.component.html'
})
export class CsvboxComponent implements OnInit, CSVBoxMethods {

  licenseKey = '';
  importUrl = '';

  /** subscription handler */
  subs!: Subscription;

  /** site data */
  siteData!: ISiteModel;

  /** user id */
  userId!: string;

  /** import type */
  @Input() importType!: string;

  /** output variables */
  @Output() closeCsv = new EventEmitter();

  constructor(private fileUploadService: FileUploadService, private router: Router) {
    this.subs = new Subscription();
  }

  ngOnInit(): void {
    setInterval(() => {
      if (!this.siteData) {
        this.siteData = JSON.parse(localStorage.getItem('siteData') as string);
      }
    }, 1000);
    this.userId = localStorage.getItem('uId') as string;

    switch(this.importType){
      case("asset"):
        this.licenseKey = environment.import.assetSheetKey
        this.importUrl = environment.import.assetUrl
        break
      case("material"):
        this.licenseKey = environment.import.materialSheetKey
        this.importUrl = environment.import.materialUrl
        break
      case("stockItem"):
        this.licenseKey = environment.import.stockItemSheetKey
        this.importUrl = environment.import.stockItemUrl
        break
      case("employee"):
        this.licenseKey = environment.import.employeeSheetKey
        this.importUrl = environment.import.employeeUrl
        break  
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onData(result: boolean, data: any) {
    if(result) {
      this.siteData = JSON.parse(localStorage.getItem('siteData') as string);
    
      const params = new HttpParams()
        .set('siteId', data.sheet_name == "ERT Employee Import" ? this.siteData.companyId : this.siteData.id)
        .set('userId', this.userId);
        
      const rows = data.rows;
      const fileImportedSub = this.fileUploadService.importItems(params, rows, this.importUrl).subscribe((res) => 
      {
        !!res ? this.closeCsv.emit(true) : '';
          if (res.result){
            const fileName = `RemainingRows`;
            const link = document.createElement('a');
            link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.result;
            link.download = fileName + '.csv';
            link.click();
          }
        });
     
      this.subs.add(fileImportedSub);
    }else {
      console.log("There was some problem uploading the sheet");
    }
  }

}
