import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

interface modalData {
  headerTitle: string,
  label: string,
  returnValue: string;
  istemplate: boolean;
  templates?: any
}

@Component({
  selector: 'app-input-textbox-dialog',
  templateUrl: './input-textbox-dialog.component.html'
})
export class InputTextboxDialogComponent implements OnInit, OnDestroy {

  istemplate: boolean = false;
  isTemplateValid: boolean = true;

  /** header title */
  headerTitle!: String;

  /** Field Label */
  fieldLabel!: String;

  /** description formcontrol */
  inputFC!: FormControl;

  /** subscription handler */
  subs!: Subscription;

  assetId!: number;

  isForceCheckIn: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public modalData: modalData, public dialogRef: MatDialogRef<InputTextboxDialogComponent>) {
    this.subs = new Subscription();
    this.inputFC = new FormControl('', [Validators.required]);
  }

  ngOnInit(): void {
    this.headerTitle = this.modalData.headerTitle;
    this.fieldLabel = this.modalData.label;
    this.istemplate = this.modalData.istemplate;

    if (this.modalData.headerTitle.includes('Force Check-In')) {
      this.isForceCheckIn = true
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  inputChange() {    
    if (this.inputFC.value.trim() === "") {
      this.inputFC.setErrors({ 'incorrect': true });
      this.modalData.returnValue = "";
    } else {
      if(!this.isForceCheckIn && this.inputFC.value.trim() !== ""){
        let checkTemplateName = this.modalData.templates.findIndex((t: any) => t.name.toLowerCase().trim() == this.inputFC.value.toLowerCase().trim());
        this.isTemplateValid = checkTemplateName >=0 ? false : true;
        if(checkTemplateName >= 0){
          this.inputFC.setErrors({ 'incorrect': true });
        }else{
          this.modalData.returnValue = this.inputFC.value.trim();
        }
      }else{
        this.modalData.returnValue = this.inputFC.value.trim();
      }
    }
  }

  save() {
    this.dialogRef.close(true);
  }
}
