import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

interface ISnackBarConfigurations {
    duration: number;
    horizontalPosition: MatSnackBarHorizontalPosition;
    verticalPosition: MatSnackBarVerticalPosition;
    panelClass?: string[]
}

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  
    /** snack bar configurations */
    private snackBarConfig: ISnackBarConfigurations = {
      duration: 3000,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    }   

    constructor(private snackBar: MatSnackBar) { }

    /**
     * show snackbar message
     *
     * @param {string} message
     * @param {string} messageType
     * @memberof SnackBarService
     */
    showMessage(message: string, messageClass: string) {
        this.snackBarConfig['panelClass'] = [messageClass];
        this.snackBar.open(message, 'X', this.snackBarConfig)
    }
}
