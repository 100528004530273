import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
})
export class SearchBarComponent implements OnInit {

  faSearch = faSearch;

  @Input()
  placeholder:any;

  @Input()
  searchFormControl!: FormControl;

  @Output()
  searchChangeEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onClearClick(){
    this.searchFormControl.reset()
    this.searchChanged()
  }

  searchChanged(){
    this.searchChangeEvent.emit(this.searchFormControl.value)
  }

  onKeyUp(event: KeyboardEvent){
    if(event.key === "Enter"){
      this.searchChanged()
    }
  }

}
