import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CustomHttpClientService } from "./custom-http-client.service";
import { IAPIResponse, ICompanyData, ISaveCompanyData } from "src/app/view-models/common.model";
import { IRequestOptions } from "src/app/view-models/request.options";

@Injectable({
    providedIn: 'root',
})

export class CompanyService {

    typeOfSavedData = new BehaviorSubject('');
    /**
     *
     *
     * @type {IRequestOptions}
     * @memberof CompanyService
     */
    reqOpt: IRequestOptions = {
        showLoadingIndicator: true
    }

    constructor(private http: CustomHttpClientService) { }

    /**
     *
     * @return {*} 
     * @memberof CompanyService
     */
    getCompanies(): Observable<IAPIResponse<ICompanyData[]>> {
        return this.http.GetExtended('GetAllCompanies').pipe() as  Observable<IAPIResponse<ICompanyData[]>>
    }

    getActiveModules(companyId: string): Observable<number[]> {
        return this.http.Get('GetActiveModules/' + companyId).pipe() as  Observable<number[]>
    }

    /**
     *
     *
     * @param {FormData} reqBody
     * @return {*}  {Observable<ICompanyData>}
     * @memberof CompanyService
     */
    saveData(reqBody: FormData): Observable<ICompanyData> {
        return this.http.Post<ICompanyData>('CreateCompany', reqBody, this.reqOpt).pipe();
    }
    
    /**
     *
     *
     * @param {FormData} reqBody
     * @param {number} itemId
     * @return {*} 
     * @memberof CompanyService
     */
    updateData(reqBody:FormData, companyId:number): Observable<ICompanyData>{
        return this.http.Put(`UpdateCompany/${companyId}`, reqBody, this.reqOpt).pipe() as  Observable<ICompanyData>
    }

    getUserCompanies(userId: string) {
        return this.http.Post(`User/${userId}/GetUserCompanies`, {
           showLoadingIndicator: true,
        }).pipe() as Observable<any>
    }

    getActiveProjects(companyCode: string): Observable<string[]> {
        return this.http.Get('getActiveProjects/' + companyCode).pipe() as  Observable<string[]>
    }
}