<div class="create-edit-dialog">
    <div mat-dialog-header fxLayoutAlign="space-between center">
        <span class="text-lg"> {{headerTitle}} </span>
        <mat-icon mat-ripple class="closeIcon" [mat-dialog-close]="false"> close </mat-icon>
    </div>
    <div mat-dialog-content fxLayout="column">
        <mat-form-field fxFlex="100" class="mt-2">
            <input matInput type="text" [(ngModel)]="barcodeValue" autocomplete="off" maxlength="100"
                (change)="inputChange()" (focus)="true" placeholder="Please click here and start scanning" />
        </mat-form-field>
    </div>
    <div mat-dialog-content fxLayout="column">
        <ag-grid-angular 
            [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
            class="ag-grid user-report-ag-grid" [rowData]="rowData" [statusBar]="statusBar" [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)" [columnDefs]="columnDefs" [headerHeight]="40" rowSelection="multiple">
        </ag-grid-angular>
    </div>
    <div mat-dialog-content fxLayout="column">
    </div>

    <div mat-dialog-actions fxLayoutAlign="end">
        <p *ngIf="!actionAllowed" class="checkInOutMessage">Check-Out is not allowed for Item(s) with available quantity less than 1</p>
        <button mat-raised-button matTooltip="Empty Cart" color="warn" (click)="clearCartData(true)" [disabled]="!this.rowData.length"><mat-icon>remove_shopping_cart</mat-icon></button>
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button class="confirm-button disabled:pointer-events-none" (click)="selectionFields('Check-In')" [disabled]="!this.rowData.length">Check-In</button>
        <button mat-raised-button class="confirm-button disabled:pointer-events-none" (click)="selectionFields('Check-Out')" [disabled]="!this.rowData.length || !actionAllowed">Check-Out</button>
    </div>
</div>