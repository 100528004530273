<mat-accordion multi>
    <mat-expansion-panel disabled class="sideBarNavClass assetRadius" expanded [routerLink]="['admin/category']"
        routerLinkActive="active-nav-item">
        <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
            <mat-panel-title class="font-medium sideNavContent">
                <mat-icon>assignment</mat-icon><span class="ml-3">Categories</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/location']"
        routerLinkActive="active-nav-item">
        <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
            <mat-panel-title class="font-medium sideNavContent">
                <mat-icon>my_location</mat-icon><span class="ml-3">Locations</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/status']"
        routerLinkActive="active-nav-item">
        <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
            <mat-panel-title class="font-medium sideNavContent">
                <mat-icon>category</mat-icon><span class="ml-3">Status</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/employees']"
        routerLinkActive="active-nav-item">
        <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
            <mat-panel-title class="font-medium sideNavContent">
                <mat-icon>supervisor_account</mat-icon><span class="ml-3">Employees</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/workorder']"
        routerLinkActive="active-nav-item">
        <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
            <mat-panel-title class="font-medium sideNavContent">
                <mat-icon>supervisor_account</mat-icon><span class="ml-3">Workorder</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/supplier']"
        routerLinkActive="active-nav-item">
        <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
            <mat-panel-title class="font-medium sideNavContent">
                <mat-icon>inventory</mat-icon><span class="ml-3">Supplier</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/manufacturer']"
    routerLinkActive="active-nav-item">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
        <mat-panel-title class="font-medium sideNavContent">
            <mat-icon>inventory</mat-icon><span class="ml-3">Manufacturer</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/condition']"
    routerLinkActive="active-nav-item">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
        <mat-panel-title class="font-medium sideNavContent">
            <mat-icon>question_answer</mat-icon><span class="ml-3">Condition</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/grouptype']"
    routerLinkActive="active-nav-item">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
        <mat-panel-title class="font-medium sideNavContent">
            <mat-icon>group_work</mat-icon><span class="ml-3">Group Type</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/document-v2-type']"
    routerLinkActive="active-nav-item">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
        <mat-panel-title class="font-medium sideNavContent">
            <mat-icon>file_copy</mat-icon><span class="ml-3">Document Type</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/document-v2-status']"
    routerLinkActive="active-nav-item">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
        <mat-panel-title class="font-medium sideNavContent">
            <mat-icon>streetview</mat-icon><span class="ml-3">Document Status</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled class="sideBarNavClass" expanded [routerLink]="['admin/user']"
        routerLinkActive="active-nav-item">
        <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
            <mat-panel-title class="font-medium sideNavContent">
                <mat-icon>person</mat-icon><span class="ml-3">User Management</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="isTrackemAdmin" disabled class="sideBarNavClass" expanded [routerLink]="['admin/company']"
    routerLinkActive="active-nav-item">
    <mat-expansion-panel-header class="bg-24dp paddingZero" routerLinkActive="active-nav-item">
        <mat-panel-title class="font-medium sideNavContent">
            <mat-icon>domain</mat-icon><span class="ml-3">Companies</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    </mat-expansion-panel>

</mat-accordion>
