import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { ICommonDropDownData } from 'src/app/view-models/common.model';
import { CustomHttpClientService } from '../common/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class WorkorderService {

  cachedWorkOrder$?: Observable<ICommonDropDownData[]>;  

  constructor(private http: CustomHttpClientService) { }

  /**
   * get workorder data
   *
   * @param {number} companyId
   * @return {*}  {Observable<ICommonDropDownData[]>}
   * @memberof WorkorderService
   */
  getWorkorderData(companyId: number): Observable<ICommonDropDownData[]> {
    if(!this.cachedWorkOrder$) {
      this.cachedWorkOrder$ = this.http.Get<ICommonDropDownData[]>(`Company/${companyId}/GetAllWorkordersWithoutPaging`).pipe(map((result) => {
        return result;
      }), shareReplay(1));
    }

    return this.cachedWorkOrder$;        
  }
}
