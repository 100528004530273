import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';
import { LoaderService } from 'src/app/services/common/loader.service';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { IItemFieldChangeModel, ITransactionItemDataModel } from 'src/app/view-models/transactions-model';
import { Subscription } from 'rxjs'
import * as moment from 'moment';

@Component({
  selector: 'app-transaction-details-dialog',
  templateUrl: './transaction-details-dialog.component.html',
})
export class TransactionDetailsDialogComponent implements OnInit, OnDestroy {

  columnDefs: ColDef[] = [
    {
      headerName: "Field",
      field: "fieldName",
      minWidth: 150
    },
    {
      headerName: "Old Value",
      field: "previousValue",
      flex:1,
      valueFormatter: (data)=>{
        if(moment(data.value, moment.ISO_8601, true).isValid()  && data.value.length !== 4) {        
          return moment(data.value).format("DD/MM/YYYY");     
        } else {        
          return data.value; 
        }
      },
    },
    {
      headerName: "New Value",
      field: "newValue",
      flex:1,
      valueFormatter: (data)=>{
        if(moment(data.value, moment.ISO_8601, true).isValid() && data.value.length !== 4) {        
          return moment(data.value).format("DD/MM/YYYY");     
        } else {        
          return data.value; 
        }
      },
    }
  ]

  subs = new Subscription();

  rowData:IItemFieldChangeModel[]=[];
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ITransactionItemDataModel, public loaderService: LoaderService, public transactionsService: TransactionsService) { 
    const transactionSub = this.transactionsService.getTransactionFieldChanges(dialogData.id).subscribe((res)=>{
      this.rowData = res;
    })

    this.subs.add(transactionSub);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void{
    this.subs.unsubscribe();
  }
}
