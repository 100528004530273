<div>
    <mat-form-field fxFlex>
        <mat-label>{{label}}</mat-label>
        <input #siteControl type="text" autocomplete="off" [placeholder]="label" matInput [formControl]="autocompleteControl"
            [matAutocomplete]="autocomplete" (input)="onInputChange()">
        <fa-icon matSuffix [icon]="faChevronDown" class="ml-2"></fa-icon>
        <mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete" [displayWith]="displayFn.bind(this)" (blur)="onTouched()">
            <mat-option *ngIf="isLoading">
                <mat-spinner [diameter]="20"></mat-spinner>
            </mat-option>
            <mat-option *ngIf="!filteredOptions?.length && !isLoading">No Results Found.</mat-option>
            <div *ngIf="!isLoading">
                <mat-option *ngFor="let option of filteredOptions" [value]="option" matTooltip="{{option[displayField]}}" matTooltipPosition="right" [matTooltipDisabled]="matTooltipDisabled">
                    {{ option[displayField] }}
                </mat-option>
            </div>
        </mat-autocomplete>
        <mat-error *ngIf="autocompleteControl?.hasError('required')">This field is required.
        </mat-error>
    </mat-form-field>

</div>