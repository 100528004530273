<div class="search-bar">
    <span>
        <fa-icon class="searchIcon printIcon" [icon]="faSearch"></fa-icon>
        <input type="text" class="commonSearchBar" (keyup)="onKeyUp($event)" [placeholder]="placeholder" [formControl]="searchFormControl">
    </span>

    <span class="top-0 absolute right-0" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon *ngIf="!!searchFormControl.value" class="clearIcon mr-1" (click)="onClearClick()" [ngClass]="{'disabled-icon': !searchFormControl.value}" matTooltip="Clear and show default view">clear</mat-icon>
        <button matTooltip="Search" class="search-button" (click)="searchChanged()" mat-flat-button
            [disabled]="!searchFormControl.value">
            <mat-icon class="search-arrow mb-2">keyboard_arrow_right</mat-icon>
        </button>
    </span>
</div>