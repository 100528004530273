import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { UserListService } from "src/app/services/user-list/user-list.service";

@Injectable({
    providedIn: 'root'
})

export class UserRoleGuard implements CanActivate {
    
    /** Creates an instance of AuthGuard. 
     * @param {Router} router navigate to other page 
     * @param {AuthService} authService authService is used to validate a login user is valid or not. 
     * @memberof AuthGuard 
    */
    
    constructor(private router: Router) { } 
    
    /** Here check if user is active or not 
     * @param {ActivatedRouteSnapshot} next 
     * @param {RouterStateSnapshot} state 
     * @returns {(Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree)} 
     * @memberof AuthGuard 
     */
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const activePage = localStorage.getItem('activePage');
        if (activePage === 'home') {
            this.router.navigate(['/']);
            return false;
        }
        return true; 
    }
}


@Injectable({
    providedIn: 'root'
})

export class TrackemAdminGuard implements CanActivate {
    
    /** Creates an instance of AuthGuard. 
     * @param {Router} router navigate to other page 
     * @param {AuthService} authService authService is used to validate a login user is valid or not. 
     * @memberof AuthGuard 
    */
    
    constructor(private router: Router) { } 
    
    /** Here check if user is active or not 
     * @param {ActivatedRouteSnapshot} next 
     * @param {RouterStateSnapshot} state 
     * @returns {(Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree)} 
     * @memberof AuthGuard 
     */
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const userRole = localStorage.getItem('userRole');
        if (userRole !== 'TrackemAdmin') {
            this.router.navigate(['admin/category']);
            return false;
        }
        return true; 
    }
}



@Injectable({
    providedIn: 'root'
})

export class MultiCompany implements CanActivate {
    
    constructor(private router: Router, private userService: UserListService) { } 
    
    /** Here check if user is active or not 
     * @param {ActivatedRouteSnapshot} next 
     * @param {RouterStateSnapshot} state 
     * @returns {(Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree)} 
     * @memberof AuthGuard 
     */
    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        var isTrue; 
        this.userService.isCompanySelected.subscribe(res => isTrue = res);
        if(localStorage.getItem('isCompanySelected') === 'true') {
          isTrue = true;
          }
        if (!isTrue) {
            this.router.navigate(['multi-company']);
            return false;
        }
        return true; 
    }
}

