import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderState } from '../../view-models/loader.state';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  themeToggleSubject = new BehaviorSubject<string>('');
  private counter: number = 0;

  /** active Page */
  activePage = new BehaviorSubject('home');

  constructor() { }

  private loaderSubject = new BehaviorSubject<LoaderState>({
    show: false
  });

  loaderState = this.loaderSubject.asObservable();

  show() {
    ++this.counter;
    this.toggle(true);
  }

  hide() {
    --this.counter;
    this.toggle(false);
  }

  private toggle(show: boolean) {
    if (show) {
      this.loaderSubject.next({ show: true });
    }
    else {
      this.loaderSubject.next({ show: false });
    }
  }
}
