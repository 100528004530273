<ng-container *ngIf="groupsData?.length && displayGroup">
    <span *ngFor="let group of groupsData, let last = last">
        <span> <a [href]="getGrpHref(group.groupDescription)" class="group-links no-underline cursor-pointer" target="_blank" > {{ group.groupDescription }} </a> <span *ngIf="!last">,</span></span>
    </span>
</ng-container>
<ng-container *ngIf="templateMapping && displayInspection">
    <span> <a [href]="getGrpHref(templateMapping.templateId, true)" class="group-links no-underline cursor-pointer" target="_blank" > {{ templateMapping.title }} </a></span>
</ng-container>
<ng-container *ngIf="documentsData?.length && displayDocument">
    <span *ngFor="let document of documentsData, let last = last">
        <span> <a [href]="getDocHref(document.documentName)" class="group-links no-underline cursor-pointer" target="_blank" > {{ document.documentName }} </a> <span *ngIf="!last">,</span></span>
    </span>
</ng-container>
<ng-container *ngIf="documentsV2Data?.length && displayDocumentV2">
    <span *ngFor="let document of documentsV2Data, let last = last">
        <span> <a [href]="getDocHref(document.documentName, true)" class="group-links no-underline cursor-pointer" target="_blank" > {{ document.documentName }} </a> <span *ngIf="!last">,</span></span>
    </span>
</ng-container>

<ng-container *ngIf="osdData?.length && displayOsd">
    <span *ngFor="let osd of osdData, let last = last">
        <span> <a [href]="getOsdHref(osd.osdId)" class="group-links no-underline cursor-pointer" target="_blank" > {{ osd.osdId }} </a> <span *ngIf="!last">,</span></span>
    </span>
</ng-container>