<div class="confirmDialog text-center">
    <div>
        <span fxLayoutAlign="center center" class="whitespace-pre-line text-base">{{ confirmMessage }}</span>
        <span fxLayoutAlign="center center" class="whitespace-pre-line text-sm m-t-7">{{ instructionMessage }}</span>
    </div>
    <div mat-dialog-actions fxLayoutGap="20px" fxLayoutAlign="center" class="m-t-7">
        <button mat-flat-button class="actionButton" [ngClass]="customActionButton"
         [mat-dialog-close]="getConfirmButtonText() === 'Cancel' || getConfirmButtonText() === 'Keep' ? false : true" cdkFocusInitial> {{ getConfirmButtonText() }} </button>
        <button mat-flat-button color="warn" [ngClass]="customCancelButton"
         [mat-dialog-close]="getCancelButtonText() === 'Cancel' || getCancelButtonText() === 'No' ? false : true"> 
         {{ getCancelButtonText() }} </button>
    </div>
</div>