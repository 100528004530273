import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { NavigationComponent } from '../common/navigation/navigation.component';
import * as moment from 'moment';

@Injectable()
export class AccessTokenValidInterceptor implements HttpInterceptor {

  constructor(private navigation: NavigationComponent) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    var accessToken = localStorage.getItem("token")
    var accessTokenExpiry = moment(localStorage.getItem("accessTokenExpiry"))

    if (!!accessToken) {
      var tokenExpired = accessTokenExpiry.isBefore(moment(Date.now()))
      if ( tokenExpired ) {
        this.navigation.logout()
        return EMPTY;
      }
    }
    return next.handle(request);
  }

}
