import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Renderer2, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, ReplaySubject, Subject, Subscription, tap, forkJoin, switchMap, Observable, of } from 'rxjs';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { ICommonDropDownData, IGridColumns } from 'src/app/view-models/common.model';
import { IEmployeeData } from 'src/app/view-models/employee-data-model';
import { ISiteModel } from 'src/app/view-models/site-model';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { CartService } from 'src/app/services/cart/cart.service';
import { CommonDropDownService } from 'src/app/services/common/common-dropdown.service';
import { IAssetTable, IBulkUpdateDataModel, ICartDataModel, IGroupItemsDataModel, IStockItemsTable, IUpdateableColumnsDataModel, updateColumnRequest, IMaterialsTable, IOSDItemsDataModel, IGroupTable, IDocumentTable, IDocumentItemsDataModel, IDocumentV2Table } from 'src/app/view-models/cart-data-model';
import { ColDef, GridApi, GridOptions, ValueParserParams } from 'ag-grid-community';
import { LoaderService } from 'src/app/services/common/loader.service';
import { DeleteCartDataComponent } from './delete-asset-cart/delete-cart-data.component';
import { CartInputFieldComponent } from './cart-input-field/cart-input-field.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../common/confirmation-dialog/confirmation-dialog.component';
import { faCartShopping, faChevronDown, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { IGroupDataList } from 'src/app/view-models/groups-model';
import { map, take, takeUntil } from 'rxjs/operators';
import { storeNames, FeatureFlags, moduleEnum } from 'src/app/common/utilities';
import { SnackBarService } from 'src/app/services/common/snackbar.service';
import { MatStepper } from '@angular/material/stepper';
import { BulkTransferComponent } from './bulk-transfer/bulk-transfer.component';
import { omit } from 'lodash';
import { IMaterials } from 'src/app/view-models/materials-model';
import { WorkorderService } from 'src/app/services/workorder/workorder.service';
import { SupplierService } from 'src/app/services/supplier/supplier.service';
import { ConfigService } from 'src/app/services/common/config.service';
import { CartMaterialOsdComponent } from './cart-material-osd/cart-material-osd.component';
import { MaterialNumberRendererComponent } from '../materials/material-number-renderer/material-number-renderer.component';
import { NumericValueOnlyComponent } from './numeric-value-only/numeric-value-only.component';
import { AzureMonitoringService } from 'src/app/common/logging/logging.service';
import { OsdActionsComponent } from '../osd-materials/osd-actions/osd-actions/osd-actions.component';
import { OsdAttachmentPhotosComponent } from './osd-attachment-photos/osd-attachment-photos.component';
import { IDocumentDataList } from 'src/app/view-models/documents-model';
import { DocumentsService } from 'src/app/services/documents/documents.service';
import * as moment from 'moment';
import { DocumentsV2Service } from 'src/app/services/documents-v-two/documents-v-two.service';
import { IActionDocumentV2Model, IDocumentV2ItemsDataModel } from 'src/app/view-models/documents-v-two-models';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html'
})
export class CartComponent implements OnInit {
  faCartShopping = faCartShopping;
  faChevronDown = faChevronDown;

  /** first form group of cart stepper */
  firstFormGroup!: FormGroup;

  /** second form group of cart stepper */
  secondFormGroup!: FormGroup;

  /** second form group of cart stepper */
  bulkTransferFormGroup!: FormGroup;

  /** update location form group of cart stepper */
  updateLocationFormGroup!: FormGroup;

    /** bulk update formgroup */
  bulkUpdateActionFieldsFormGroup!: FormGroup

  /**form group for status  */
  thirdFormGroup!: FormGroup;

  /**group form of cart stepper */
  groupFormGroup!: FormGroup;

  /** docyument form group */
  documentFormGroup!: FormGroup;

  /** status update */
  bulkUpdateStatusFormGroup!: FormGroup;

  /** add item to document */
  documentV2FormGroup!: FormGroup;

  /** action document */
  actionDocumentV2FormGroup!: FormGroup;

  osdFormGroup!: FormGroup;

  /** subscription handler */
  subs!: Subscription;

  /** site data */
  siteData!: ISiteModel;

  /** check cart data */
  isCartData = false;

  /** cart row data */
  assetRowData: IAssetTable[] = [];
  stockRowData: IStockItemsTable[] = [];
  materialRowData: IMaterialsTable[] = [];
  osdAttachmentData: any[] = [];
  groupRowData: IGroupTable[] = [];
  documentRowData: IDocumentTable[] = [];
  documentV2RowData: IDocumentV2Table[] = [];

  /** site data */
  allSitesInfo!: ISiteModel[];
  filteredSiteData: ISiteModel[] = [];

  /** site changed */
  siteChanged = false;

  /** selected data */
  selectedStatus!: ICommonDropDownData; 
  selectedLocation!: ICommonDropDownData; 
  selectedCustodian!: IEmployeeData; 
  selectedGroup!: IGroupDataList; 
  selectedDocument!: IDocumentDataList;
  selectedDocumentV2!: IDocumentDataList;
  selectedDocumentV2Status!: ICommonDropDownData;

  /** updateable column data */
  toggleAllCheckboxIndeterminate: boolean = false;
  toggleAllCheckboxChecked: boolean = false;
  noFieldsSelected: boolean = true;
  protected _onDestroy = new Subject<void>();
  allFieldNames: IUpdateableColumnsDataModel[] = [];
  allfieldsSize: number = 0;
  fieldsToUpdate: IUpdateableColumnsDataModel[] = [];
  fieldMultiCtrl: FormControl = new FormControl();
  fieldMultiFilterCtrl: FormControl = new FormControl();
  availableFields: ReplaySubject<IUpdateableColumnsDataModel[]> = new ReplaySubject<IUpdateableColumnsDataModel[]>(1);
  bulkUpdateReqBody!: IBulkUpdateDataModel;

  /** cart finalise message */
  cartMessage = '';

  /** default column definition */
  defaultColDef!: ColDef;

  /** grid api */
  assetGridApi!: GridApi;
  stockGridApi!: GridApi;
  materialGridApi!: GridApi;
  groupGridApi!: GridApi;
  osdAttachmentGridApi!: GridApi;
  documentGridApi!: GridApi;
  documentV2GridApi!: GridApi;

  /** ag grid status bar */
  statusBar!: {
    statusPanels: {
      statusPanel: string;
      align: string;
    }[];
  };

  /** column definitions */
  assetDefaultColDef!: ColDef[];
  stockDefaultColDef!: ColDef[];
  materialDefaultColDef!: ColDef[];
  osdAttachmentDefaultColDef!: ColDef[];
  groupColDef!: ColDef[];
  documentColDef!: ColDef[];
  documentV2ColDef!: ColDef[];
  

  /** reqBody for cartAction */
  reqBody!: ICartDataModel;
  groupReqBody!: IGroupItemsDataModel
  documentReqBody!: IDocumentItemsDataModel
  documentV2ReqBody!: IDocumentV2ItemsDataModel
  actionDocumentV2ReqBody!: IActionDocumentV2Model
  osdReqBody!: IOSDItemsDataModel

  agGrid = {
    afterExpansion: false
  }

  errorMessage: string = '';

  agGridHeight = {
    agGridHeight: false,
    cartGrid: true
  }

  stockGrid = {
    stockGridMargin: false,
    agGridHeight: false,
    cartGrid: true
  }

  materialGrid = {
    materialGridMargin: false,
    agGridHeight: false,
    cartGrid: true
  }
  /** grid column */
  gridColumn!: IGridColumns;

  isEditable = true;

  commentFC!: FormControl;

  /** stepper instance */
  @ViewChild('stepper') stepper!: MatStepper;

  /** updated fields value data */
  updatedFieldsData: updateColumnRequest[] = [];

  /** selected action message */
  selectedActionMessage!: string;

  /** selected action message */
  osdValidationMessage: string = '';
  osdFieldMandMessage: string = '';

  /** selected action */
  selectedAction!: string;

  /** dynamic fields valid */
  dynamicLatitudeValid = true;
  dynamicLongitudeValid = true;

  /** searched fields are available */
  searchedFieldsAvailable = true;  /** site changing indicator */


  /** OSD related form controls */
  docketNumber!: FormControl;
  poNumber!: FormControl;
  creatorPhone!: FormControl;
  supplierCompany!: FormControl;
  supplierContactPerson!: FormControl;
  supplierPhone!: FormControl;
  supplierContactEmail!: FormControl;
  isLockedFormControl!: FormControl;

  /** group data */
  groupData: IGroupDataList[] = [];
  filteredGroupData: IGroupDataList[] = [];

  /** document data */
  documentData: IDocumentDataList[] = [];
  filteredDocumentData: IDocumentDataList[] = [];

  /** document 2..0 data */
  documentV2Data: IDocumentDataList[] = [];
  filteredDocumentV2Data: IDocumentDataList[] = [];

  /** status data */
  statusData: ICommonDropDownData[] = [];
  filteredStatusData: ICommonDropDownData[] = [];

  /** location data */
  locationData: ICommonDropDownData[] = [];
  filteredLocationData: ICommonDropDownData[] = [];

  /** employee data */
  custodianData: IEmployeeData[] = [];
  filteredCustodianData: IEmployeeData[] = [];

  /** status data */
  documentV2StatusData: ICommonDropDownData[] = [];
  filteredDocumentV2StatusData: ICommonDropDownData[] = [];

  /** category data */
  categoryData!: ICommonDropDownData[];
  filteredCategoryData!: ICommonDropDownData[];

  /** worker data */
  workorderData!: ICommonDropDownData[];
  filterWorkrderData!: ICommonDropDownData[];

  /** supplier data */
  supplierData!: ICommonDropDownData[];
  filtersupplierDataData!: ICommonDropDownData[];

  /** condition data */
  conditionData!: ICommonDropDownData[];
  filteredConditionData!: ICommonDropDownData[];

  /** group data */
  groupTemplatedata!: ICommonDropDownData[];
  groupTypedata!: ICommonDropDownData[];

  /** manufacturer data */
  manufacturerData!: ICommonDropDownData[];
  filteredManufacturerData!: ICommonDropDownData[];


  disablesaveButton: boolean = true;

  gpsErrorMessage: string = '';

  /** loader */
  isWorkorderLoading = true;
  isSupplierLoading = true;
  isCategoryLoading = true;
  isConditionLoading = true;
  isManufacturerLoading = true;
  isGroupTemplateLoading = true;
  isGroupTypeLoading = true;
  isStatusLoading = true;
  isLocationLoading = true;
  isCustodianLoading = true;
  isGroupLoading = true;
  isColumnsLoading = true;
  isDocumentLoading = true;
  isDocumentV2Loading = true;
  isDocumentV2StatusLoading = true;
  
  /** OSD Tab visible */
  isOsdTabVisible: boolean = false;

  tempMaterialOsdData: any[] = [];

  frameworkComponents: any;

  isCheckGroup: boolean = true;

  isCheckDocument: boolean = true;

  selectedGroupMessage: string = '';

  selectedDocumentMessage: string = '';

  selectedDocumentV2Message: string = '';

  cartMaterialList!: IMaterials[];
  cartGroupdata!: IGroupTable[];
  cartDocumentdata!: IDocumentTable[];
  list: any;
  attachments: any;
  isAttachmentAllowed: boolean = false;
  minQty: any;
  maxQty: any;
  fromDate: any;
  toDate: any;
  qtyErrorMessage: string = '';
  dateErrorMessage: string = '';
  activeOsdErrorMesage: string = '';
  materialGridOptions: GridOptions = {
    domLayout: 'autoHeight'
  };

  isNegative: boolean = false;
  numericFieldValues: { [fieldName: string]: any } = {};

  materialQty: any;
  isFormValid: boolean = false;
  
  @ViewChild('CustodianScrollControl', { read: MatAutocompleteTrigger }) CustodianScrollControl!: MatAutocompleteTrigger;
  @ViewChild('LocationScrollControl', {read: MatAutocompleteTrigger}) LocationScrollControl!: MatAutocompleteTrigger;
  @ViewChild('CommonScrollControl', {read: MatAutocompleteTrigger}) CommonScrollControl!: MatAutocompleteTrigger

  /** location changed */
  locationChanged = false;

  constructor(private formBuilder: FormBuilder, private employeeService: EmployeeService, private commonDropDownService: CommonDropDownService,
    private configService: ConfigService, private cd: ChangeDetectorRef, private appInsights: AzureMonitoringService, private rendere: Renderer2,
    private dbService: NgxIndexedDBService, private cartService: CartService, public loaderService: LoaderService, private dialog: MatDialog,
    private groupService: GroupsService, private snackBarService: SnackBarService, private workorderService: WorkorderService, private supplierService: SupplierService,
    private documentService: DocumentsService, private documentV2Service: DocumentsV2Service) {
    this.subs = new Subscription();
    this.commentFC = new FormControl('');
    this.docketNumber = new FormControl('');
    this.poNumber = new FormControl('');
    this.creatorPhone = new FormControl('');
    this.supplierCompany = new FormControl('');
    this.supplierContactPerson = new FormControl('');
    this.supplierContactEmail = new FormControl('');
    this.supplierPhone = new FormControl('');
    this.isLockedFormControl = new FormControl(false);
    this.siteData = JSON.parse(sessionStorage.getItem('siteData') as string);
    this.allSitesInfo = JSON.parse(localStorage.getItem('allSitesInfo') as any);

    this.defaultColDef = {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: true
    };

    this.statusBar = {
      statusPanels: [
        { statusPanel: "agTotalRowCountComponent", align: "left" },
        { statusPanel: "agFilteredRowCountComponent", align: "right" },
        { statusPanel: "agSelectedRowCountComponent", align: "center" },
      ]
    };

    this.assetDefaultColDef = [
      {
        headerName: 'Action',
        colId: 'action',
        pinned: 'left',
        minWidth: 10,
        cellRendererFramework: DeleteCartDataComponent
      },
      {
        headerName: 'Asset Number',
        field: 'assetNumber',
        floatingFilter: true,
        colId: 'assetNumber'
      },
      {
        headerName: 'Description',
        field: 'description',
        floatingFilter: true,
        colId: 'description'
      },
      {
        headerName: 'Barcode',
        field: 'barcode',
        floatingFilter: true,
        colId: 'barcode'
      },
      {
        headerName: 'Available Qty',
        field: 'availableQuantity',
        floatingFilter: true,
        colId: 'availableQuantity',
      },
      {
        headerName: 'Hiring / Rate (Press enter/tab to save the details.)',
        colId: 'hiringAndRate',
        field: 'hiringAndRate',
        floatingFilter: true,
        editable: true,
        cellRendererFramework: CartInputFieldComponent,
        cellEditor: 'numericValueOnlyComponent',
        valueParser: this.actionQtyNumberParser,
        width: 310,
      }
    ]

    this.stockDefaultColDef = [
      {
        headerName: 'Action',
        colId: 'action',
        pinned: 'left',
        minWidth: 10,
        cellRendererFramework: DeleteCartDataComponent
      },
      {
        headerName: 'Item Number',
        field: 'itemNumber',
        floatingFilter: true,
        colId: 'itemNumber'
      },
      {
        headerName: 'Description',
        field: 'stockDescription',
        floatingFilter: true,
        colId: 'stockDescription'
      },
      {
        headerName: 'Barcode',
        field: 'stockBarcode',
        floatingFilter: true,
        colId: 'stockBarcode'
      },
      {
        headerName: 'Available Qty',
        field: 'availableQuantity',
        floatingFilter: true,
        colId: 'availableQuantity'
      },
      {
        headerName: 'Action Qty (Press enter/tab to save the details.)',
        field: 'actionQuantity',
        colId: 'actionQuantity',
        floatingFilter: true,
        editable: (() => this.firstFormGroup?.value?.action !== "bulkTransfer").bind(this),
        cellRendererFramework: CartInputFieldComponent,
        cellEditor: 'numericValueOnlyComponent',
        cellRendererParams: {
          getDisabled: (() => this.firstFormGroup?.value?.action === "bulkTransfer").bind(this)
        },
        valueParser: this.actionQtyNumberParser,
        width: 300,
        minWidth: 300
      }
    ]

    this.materialDefaultColDef = [
      {
        headerName: 'Action',
        colId: 'action',
        pinned: 'left',
        minWidth: 10,
        cellRendererFramework: DeleteCartDataComponent
      },
      {
        headerName: 'Material Number',
        field: 'materialNumber',
        floatingFilter: true,
        colId: 'materialNumber',
        cellRendererFramework: MaterialNumberRendererComponent
      },
      {
        headerName: 'Description',
        field: 'materialDescription',
        floatingFilter: true,
        colId: 'materialDescription'
      },

      {
        headerName: 'Quantity',
        field: 'quantity',
        floatingFilter: true,
        colId: 'quantity'
      },
      {
        headerName: 'OSD Type *',
        field: 'osdType',
        colId: 'osdType',
        floatingFilter: true,
        cellRendererFramework: CartMaterialOsdComponent,
        width: 300,
        hide: true,
        valueGetter: (data) => { this.osdRowValidation(data?.data) }
      }
    ]

    this.osdAttachmentDefaultColDef = [
      {
        headerName: 'Action',
        colId: 'action',
        pinned: 'left',
        minWidth: 10,
        cellRenderer: this.actionsRenderer.bind(this)
      },
      {
        headerName: 'Attachment',
        field: 'name',
        floatingFilter: true,
        colId: 'name'
      },
    ]

    this.groupColDef = [
      {
        headerName: 'Action',
        colId: 'action',
        pinned: 'left',
        minWidth: 10,
        cellRendererFramework: DeleteCartDataComponent
      },
      {
        headerName: 'Group Name',
        field: 'groupName',
        floatingFilter: true,
        colId: 'groupName'
      },
      {
        headerName: 'Description',
        field: 'groupDescription',
        floatingFilter: true,
        colId: 'groupDescription'
      },
      {
        headerName: 'Group Barcode',
        field: 'barcode',
        floatingFilter: true,
        colId: 'barcode'
      }
    ]

    this.documentColDef = [
      {
        headerName: 'Action',
        colId: 'action',
        pinned: 'left',
        minWidth: 10,
        cellRendererFramework: DeleteCartDataComponent
      },
      {
        headerName: 'Document Name',
        field: 'documentName',
        floatingFilter: true,
        colId: 'documentName'
      },
      {
        headerName: 'Description',
        field: 'documentDescription',
        floatingFilter: true,
        colId: 'documentDescription'
      },
      {
        headerName: 'Document Barcode',
        field: 'barcode',
        floatingFilter: true,
        colId: 'barcode'
      }
    ]

    this.documentV2ColDef = [
      {
        headerName: 'Action',
        colId: 'action',
        pinned: 'left',
        minWidth: 10,
        cellRendererFramework: DeleteCartDataComponent
      },
      {
        headerName: 'Document Name',
        field: 'documentName',
        floatingFilter: true,
        colId: 'documentName'
      },
      {
        headerName: 'Document Template',
        field: 'documentTemplateName',
        floatingFilter: true,
        colId: 'documentTemplateName'
      },
      {
        headerName: 'Document Barcode',
        field: 'barcode',
        floatingFilter: true,
        colId: 'barcode'
      },
      {
        headerName: 'Document Status',
        field: 'documentStatus',
        floatingFilter: true,
        colId: 'documentStatus'
      }  
    ]
    
    this.frameworkComponents = {
      numericValueOnlyComponent: NumericValueOnlyComponent,
    };
  }

  actionsRenderer(params: any) {
    const button = this.rendere.createElement('button');
    button.innerText = 'X';
    this.rendere.addClass(button, 'osdDeleteButton')
    this.rendere.listen(button, 'click', (event) => {
      this.deleteOSDAttachment(event, params);
    });
    return button
  }

  onCellEditingStarted(event: any) {
    this.cartService.setCellEditorValue(true);
  }

  osdRowValidation(data: any) {
    this.osdValidationMessage = "";
    this.cd.detectChanges();

    //set mandatory field validation message//
    let checkOsdTypeValues = this.materialRowData?.filter(m => +m.osdType === 0);
    if (!checkOsdTypeValues?.length) {
      this.osdFieldMandMessage = ''
    };

    //check if material has active osd//
    if (this.materialRowData?.length) {
      this.materialRowData.forEach(m => {
        if (this.list?.length) {
          let filterOsdMat = this.list.filter((cm: any) => +cm.materialIds === +m.materialIds);
          if (filterOsdMat.length) {
            let filterOsdMatIndex = filterOsdMat[0].osdTypeIds?.filter((fm: any) => +fm === +m.osdType)
            if (filterOsdMatIndex?.length) {
              this.osdValidationMessage = 'Material has active OSD with same OSD Type'
              this.materialGridOptions.rowClass = 'osdType-cell';
              this.cd.detectChanges();
            }
          }
        }
      })
    }

    let materialIndex = this.tempMaterialOsdData?.findIndex(m => +m.materialId === +data?.materialIds);
    if (materialIndex >= 0) {
      this.tempMaterialOsdData.splice(materialIndex, 1);
    }

    this.tempMaterialOsdData.push({
      materialId: +data?.materialIds,
      osdType: +data?.osdType
    })

  }

  ngAfterViewInit() {
    this.cartService.selectedValueOfDisableButton$.subscribe((value) => {
      this.disablesaveButton = value;
    })
  }

  clearChanged(fieldName: string, checkedVal: boolean): void {
    delete this.numericFieldValues[fieldName];
    this.isNegative = Object.values(this.numericFieldValues).some(val => val < 0);
    const fieldNameIndex = this.updatedFieldsData.findIndex((val: any) => val.fieldName === fieldName);
    if (fieldNameIndex < 0) {
      const newField = {
        fieldName: fieldName,
        fieldType: '',
        value: '',
        clear: checkedVal
      };
      this.updatedFieldsData.push(newField);
    } else {
      if (this.updatedFieldsData[fieldNameIndex].value == undefined) {
        this.updatedFieldsData[fieldNameIndex].value = '';
      }
      this.updatedFieldsData[fieldNameIndex].clear = checkedVal;
    }

    if (fieldName === "location" || fieldName === "status" || fieldName === "custodian" || fieldName === "manufacturer" ||
      fieldName === "workOrder" || fieldName === "supplier" || fieldName === "condition" || fieldName === "groupTemplate" || 
      fieldName === "category" ) {
      if (checkedVal) {
        this.bulkUpdateActionFieldsFormGroup.controls[fieldName + 'Id'].setValue(null);
        this.bulkUpdateActionFieldsFormGroup.controls[fieldName + 'Id'].disable();
      } else {
        this.bulkUpdateActionFieldsFormGroup.controls[fieldName + 'Id'].enable();
      }
    }
  }
  valueChangedData(fieldName: string, event: { fieldVal: string | number | boolean, fieldType: string }) {
    this.cd.detectChanges();
    if (fieldName === 'minimumQuantity' || fieldName === 'maximumQuantity' || fieldName === 'minimum' || fieldName === 'maximum') {
      if (fieldName === 'minimum' || fieldName === 'minimumQuantity') {
        this.minQty = event.fieldVal;
      } else if (fieldName === 'maximum' || fieldName === 'maximumQuantity') {
        this.maxQty = event.fieldVal;
      }
      if (this.maxQty !== null) {
        if (+this.maxQty < +this.minQty) {
          this.qtyErrorMessage = 'Min Qty must be less than or equal to Max Qty'
        } else {
          this.qtyErrorMessage = ''
        }
      } else {
        this.qtyErrorMessage = ''
      }
    }

    if (fieldName === 'hireContractExpiryDate' || fieldName === 'onHireDate') {
      if (fieldName === 'hireContractExpiryDate') {
        this.toDate = event.fieldVal;
      } else if (fieldName === 'onHireDate') {
        this.fromDate = event.fieldVal;
      }

      if (this.toDate !== undefined && this.fromDate !== undefined) {
        if (moment(this.fromDate).isAfter(this.toDate)) {
          this.dateErrorMessage = 'Hire Contract Expiry Date must be after or equal to On Hire Date'
        } else {
          this.dateErrorMessage = ''
        }
      } else {
        this.dateErrorMessage = ''
      }
    }
    if (fieldName == 'qty') {
      this.materialQty = event.fieldVal;
    }
    const fieldNameIndex = this.updatedFieldsData.findIndex((val: any) => val.fieldName === fieldName);
    if (fieldNameIndex < 0) {
      const newField = {
        fieldName: fieldName,
        fieldType: event.fieldType,
        value: event.fieldVal,
        clear: false
      };
      this.updatedFieldsData.push(newField);
    } else {
      this.updatedFieldsData[fieldNameIndex].value = event.fieldVal;
    }

    if (fieldName == 'gpsLatitude' || fieldName == 'gpsLongitude') {
      this.checkIfFieldIsValid();
    } else {
      if (event.fieldType == 'number') {
        this.numericFieldValues[fieldName] = event.fieldVal;
        this.isNegative = Object.values(this.numericFieldValues).some(val => val < 0);
      }
    }
  }

  ngOnInit(): void {
    this.configService.getFeatureFlag(FeatureFlags.Osd).then(result => {
      this.isOsdTabVisible = result.enabled;
    });
    const stores = storeNames.map(storeName => this.dbService.count(storeName));
    this.subs.add(forkJoin(stores).subscribe(res => this.isCartData = res.some(element => element > 0)));

    this.dbService.getAll('materialsTable').subscribe(res => {
      this.list = res;
    });

    this.setCartData();

    this.siteData = JSON.parse(sessionStorage.getItem('siteData') as string);
    this.createForm();
    window.addEventListener('scroll',this.scrollEvent, true);
  }

  scrollEvent = (): void =>{
     this.CustodianScrollControl?.updatePosition();
     this.LocationScrollControl?.updatePosition();
     this.CommonScrollControl?.updatePosition();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * parse action qty value to number
   *
   * @param {ValueParserParams} params
   * @return {*}  {number}
   * @memberof CartComponent
   */
  actionQtyNumberParser(params: ValueParserParams): number {
    return isNaN(Number(params.newValue)) ? 0 : Number(params.newValue);
  }

  /**
    * display selected id's description in dropdown
    *
    * @param {ICommonDropDownData} dropDownData
    * @return {*}  {string}
    * @memberof CartComponent
    */
  display(dropDownData: ICommonDropDownData | IGroupDataList | IDocumentDataList | IEmployeeData): string {
    return (dropDownData as ICommonDropDownData)?.description || (dropDownData as IGroupDataList)?.groupName || (dropDownData as IDocumentDataList)?.documentName || (dropDownData as IEmployeeData)?.employeeName || '';
  }

  /**
   * create cart formGroup
   */
  createForm() {
    this.firstFormGroup = this.formBuilder.group({
      action: [null, Validators.required]
    });
    this.groupFormGroup = this.formBuilder.group({
      groupId: [null, Validators.required]
    });
    this.documentFormGroup = this.formBuilder.group({
      documentId: [null, Validators.required]
    });
    this.bulkUpdateStatusFormGroup = this.formBuilder.group({
      statusId: [null, Validators.required]
    });

    this.documentV2FormGroup = this.formBuilder.group({
      documentV2Id: [null, Validators.required]
    });

    this.secondFormGroup = this.formBuilder.group({
      custodianId: [null],
      locationId: [null]
    });

    this.bulkTransferFormGroup = this.formBuilder.group({
      siteId: [null, Validators.required],
    })
    this.updateLocationFormGroup = this.formBuilder.group({
      locationId: [null, Validators.required],
    })
    this.thirdFormGroup = this.formBuilder.group({
      statusId: [null]
    });

    this.actionDocumentV2FormGroup = this.formBuilder.group({
      documentV2StatusId: [null, Validators.required],
      isLocked: false
    })

    const siteValueChanged = this.bulkTransferFormGroup.get('siteId')?.valueChanges.pipe(
      tap(() => this.filteredSiteData = this.allSitesInfo)
    ).subscribe((val: string | ISiteModel) => {
      this.siteChanged = false;
      const siteName = typeof val === 'string' ? val : val?.siteName;
      this.filteredSiteData = this.allSitesInfo.filter(site => site.siteName.toLowerCase().includes(siteName?.toLowerCase().trim()))
    });
    this.subs.add(siteValueChanged);
    this.osdFormGroup = this.formBuilder.group({
      custodianId: [null],
    });

    const bulklocationValueChanged = this.updateLocationFormGroup.get('locationId')?.valueChanges.pipe(
      tap(() => this.filteredLocationData = this.locationData)
    ).subscribe((val: string | ICommonDropDownData) => {
      this.locationChanged = false;
      const locationName = typeof val === 'string' ? val : val?.description;
      if(locationName?.length){
        this.filteredLocationData = this.locationData.filter(location => location.description.toLowerCase().includes(locationName?.toLowerCase().trim()))
      }else{
        this.filteredLocationData = this.locationData
      }
    });
    this.subs.add(bulklocationValueChanged);

    const custodianValueChanged = this.secondFormGroup.get('custodianId')?.valueChanges.pipe(
      tap(() => this.filteredCustodianData = this.custodianData)
    ).subscribe((val: string | IEmployeeData) => {
      const custodianName = typeof val === 'string' ? val : val?.employeeName;
      this.filteredCustodianData = this.custodianData.filter(custodian => custodian.employeeName.toLowerCase().includes(custodianName?.toLowerCase().trim()))
    });
    this.subs.add(custodianValueChanged);

    const locationValueChanged = this.secondFormGroup.get('locationId')?.valueChanges.pipe(
      tap(() => this.filteredLocationData = this.locationData)
    ).subscribe((val: string | ICommonDropDownData) => {
      const locationName = typeof val === 'string' ? val : val?.description;
      this.filteredLocationData = this.locationData.filter(location => location.description.toLowerCase().includes(locationName?.toLowerCase().trim()))
    });
    this.subs.add(locationValueChanged);

    const statusValueChanged = this.thirdFormGroup.get('statusId')?.valueChanges.pipe(
      tap(() => this.filteredStatusData = this.statusData)
    ).subscribe((val: string | ICommonDropDownData) => {
      const statusName = typeof val === 'string' ? val : val?.description;
      this.filteredStatusData = this.statusData.filter(status => status.description.toLowerCase().includes(statusName?.toLowerCase().trim()))
    });
    this.subs.add(statusValueChanged);

    const groupValueChanged = this.groupFormGroup.get('groupId')?.valueChanges.pipe(
      tap(() => this.filteredGroupData = this.groupData)
    ).subscribe((val: string | IGroupDataList) => {
      const groupName = typeof val === 'string' ? val : val?.groupName;
      if(groupName?.length){
        this.filteredGroupData = this.groupData.filter(group => group.groupName.toLowerCase().includes(groupName?.toLowerCase().trim()))
      }else{
        this.filteredGroupData = this.groupData
      }
    });
    this.subs.add(groupValueChanged);

    const documentValueChanged = this.documentFormGroup.get('documentId')?.valueChanges.pipe(
      tap(() => this.filteredDocumentData = this.documentData)
    ).subscribe((val: string | IDocumentDataList) => {
      const documentName = typeof val === 'string' ? val : val?.documentName;
      this.filteredDocumentData = this.documentData.filter(document => document.documentName.toLowerCase().includes(documentName?.toLowerCase().trim()))
    });
    this.subs.add(documentValueChanged);

    const statusUpdateValueChanged = this.bulkUpdateStatusFormGroup.get('statusId')?.valueChanges.pipe(
      tap(() => this.filteredStatusData = this.statusData)
    ).subscribe((val: string | ICommonDropDownData) => {
      const statusName = typeof val === 'string' ? val : val?.description;
      if(statusName?.length){
      this.filteredStatusData = this.statusData.filter(status => status.description.toLowerCase().includes(statusName?.toLowerCase().trim()))
      }else{
        this.filteredStatusData = this.statusData
      }
    });
    this.subs.add(statusUpdateValueChanged);

    const documentV2ValueChanged = this.documentV2FormGroup.get('documentV2Id')?.valueChanges.pipe(
      tap(() => this.filteredDocumentV2Data = this.documentV2Data)
    ).subscribe((val: string | IDocumentDataList) => {
      const documentName = typeof val === 'string' ? val : val?.documentName;
      if(documentName?.length){
        this.filteredDocumentV2Data = this.documentV2Data.filter(document => document.documentName.toLowerCase().includes(documentName?.toLowerCase().trim()))
      }else{
        this.filteredDocumentV2Data = this.documentV2Data
      }
    });
    this.subs.add(documentV2ValueChanged);

    const actionDocumentV2FormGroup = this.actionDocumentV2FormGroup.get('documentV2StatusId')?.valueChanges.pipe(
      tap(() => this.filteredDocumentV2StatusData = this.documentV2StatusData)
    ).subscribe((val: string | ICommonDropDownData) => {
      const documentV2Status = typeof val === 'string' ? val : val?.description;
      if(documentV2Status?.length){
        this.filteredDocumentV2StatusData = this.documentV2StatusData.filter(document => document.description.toLowerCase().includes(documentV2Status?.toLowerCase().trim()))
      }else{
        this.filteredDocumentV2StatusData = this.documentV2StatusData
      }
    });
    this.subs.add(actionDocumentV2FormGroup);


    this.bulkUpdateActionFieldsFormGroup = this.formBuilder.group({
      categoryId: [],
      locationId: [],
      statusId: [],
      custodianId: [],
      manufacturerId: [],
      workOrderId: [],
      supplierId: [],
      conditionId: [],
      groupTemplateId: [],
      groupTypeId: []
    });
  }
  
  ngDoCheck() {
    this.checkFormValidity();
  }

  /**
   * Look up fields next button validation check
   * @memberof CartComponent
   */
  checkFormValidity(): void {
    const controls = this.bulkUpdateActionFieldsFormGroup.controls;
    const updateFieldControls: any[] = [];

    Object.keys(controls).forEach(controlName => {
      const control = controls[controlName];
      const formField = this.fieldsToUpdate.find(x => x.fieldName.concat('Id') === controlName);
      if (formField) {
        updateFieldControls.push({ control, formField });
      }
    });

    var allFieldsAreFilled  = this.fieldsToUpdate.filter( i => !updateFieldControls.map(x => x.formField.fieldName).includes(i.fieldName));
    var filterUpdatedFieldsData = this.updatedFieldsData.filter(i => !updateFieldControls.map(x => x.formField.fieldName).includes(i.fieldName))
    var updatedFieldsDataIsValid =  allFieldsAreFilled.length != filterUpdatedFieldsData.length ? false : filterUpdatedFieldsData.every(x => x.value !== '' || x.clear === true);
    this.isFormValid = updateFieldControls.every(x => (x.control.valid && x.control.value?.id) || x.formField.clear === true)  && updatedFieldsDataIsValid;
  }
  /**
   * cart action selection change handler
   *
   * @param {string} selectedValue
   * @memberof CartComponent
   */
  actionSelectionChanged(selectedValue: string): void {
    this.disablesaveButton = true;
    this.fieldsToUpdate = [];
    this.toggleAllCheckboxIndeterminate = false;
    this.toggleAllCheckboxChecked = false;
    this.osdFieldMandMessage = '';
    this.qtyErrorMessage = '';
    this.dateErrorMessage = '';
    this.activeOsdErrorMesage = '';
    this.documentData = []; 
    this.groupData = [];
    this.documentV2Data = [];
    if (this.selectedGroup?.id !== 0 && this.selectedGroup !== undefined) {
      this.selectedGroup.id = 0;
      this.selectedGroup.siteId = 0;
      this.selectedGroup.groupName = '';
      this.selectedGroup.siteName = '';
    }
    if (this.selectedDocument?.id !== 0 && this.selectedDocument !== undefined) {
      this.selectedDocument.id = 0;
      this.selectedDocument.siteId = 0;
      this.selectedDocument.documentName = '';
      this.selectedDocument.siteName = '';
    } 
    if(this.selectedDocumentV2?.id !== 0 && this.selectedDocumentV2 !== undefined){
      this.selectedDocumentV2.id = 0;
      this.selectedDocumentV2.siteId = 0;
      this.selectedDocumentV2.documentName = '';
      this.selectedDocumentV2.siteName = '';
    } 
    this.stockGridApi?.refreshCells({ force: true, columns: ['actionQuantity'] })
  
    if (selectedValue === 'bulkTransfer') {
        let grouplist:any = []
        let documentlist:any = []
        const handleBulkTransfer = async () => {
        await this.chechkGroupbulkTransfer().then(result => {
          grouplist = result;
        })
        await this.chechkDocumentbulkTransfer().then(result => {
          documentlist = result;
        })
      }
      this.osdMatTransferValidation(this.materialRowData);
 
      handleBulkTransfer().then(res => {
      if(grouplist.res.length == 0 && documentlist.res.length == 0){
          const reqbody = {
            groupIds: grouplist.groupIds,
            documentIds: documentlist.documentIds
        }
        this.checkActiveOsdMaterial(reqbody);
      }
     })
   
      if (this.materialGridApi?.getColumnDef('osdType')) {
        this.materialGridOptions.columnApi?.setColumnVisible('osdType', false);
      }
      this.stockRowData.forEach(x => x.actionQuantity = x.availableQuantity);

    } else {
      this.isCheckGroup = true;
      this.isCheckDocument = true;
      this.selectedGroupMessage = "";
      this.selectedDocumentMessage = "";
      this.selectedDocumentV2Message = "";
      this.osdValidationMessage = "";
    }

    if (selectedValue === 'createOSDMaterials') {
      this.assignOsdValue();
      this.materialGridOptions.columnApi?.setColumnVisible('osdType', true);
      this.osdFieldMandMessage = '* Please select OSD Type';
    } else {
      this.materialGridOptions.columnApi?.setColumnVisible('osdType', false);
    }

    if (selectedValue === 'bulkUpdateAsset' || selectedValue === 'bulkUpdateStockItem' || selectedValue === 'bulkUpdateMaterial' || selectedValue === "bulkUpdateGroup") {
      this.selectedActionMessage = 'Bulk Edit can only be completed against one item type at a time.(Asset, Stock Item, Material, Bulk Material, Group)';
    } else if (+selectedValue == 0 || +selectedValue == 1) {
      if (this.stockRowData.length > 0 || this.assetRowData.length > 0) {
        this.quantityUpdateNote(true);
      } else {
        this.quantityUpdateNote(false)
      };
    } else {
      this.selectedActionMessage = '';
    }

    this.checkAvalableQty(+selectedValue);
  }
  
  checkActiveOsdMaterial(reqBody: any){
    let transferData: any[] = [];
    let documentTransferedData: any[] = [];
    let materialOsdData: any[] = [];
    this.cartService.checkActiveOsdMaterial(reqBody).subscribe((res: any) => {
      if(res.length > 0){
      materialOsdData = res;
      const resetDialog = this.dialog.open(BulkTransferComponent, {
      height: 'auto',
      width: '60%',
      data: { data: {transferData, documentTransferedData, materialOsdData}, siteId: this.siteData.id, bulkData: materialOsdData },
      disableClose: true
      }).afterClosed().subscribe((res) => {
       if (!res) {
         this.activeOsdErrorMesage = 'Transfer is not allowed for Material with active OSD in Group/Document'
       } else {
        this.activeOsdErrorMesage = '';
       }
      });
     } else {
       this.activeOsdErrorMesage = ''
     }
    })
  }
  
  chechkGroupbulkTransfer(id?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let groupIds: number[] = [];
  
      if (this.groupRowData.length) {
        this.groupGridApi.forEachNode(nodeData => {
          const data = nodeData.data;
          groupIds?.push(data.groupId);
        });
      }
  
      if(id){
        let index = groupIds.indexOf(id);
        if (index !== -1) { 
          groupIds.splice(index, 1);
        }
      }
  
      if (groupIds.length > 0) {
        this.cartService.checkGroupBulkTransfer(this.siteData.id, groupIds).subscribe(res => {
          this.cartGroupdata = res;
          if (res.length > 0) {
            this.isCheckGroup = false;
            this.selectedGroupMessage = `Add groups to the cart in which the selected group's items are present`;
          } else {
            this.isCheckGroup = true;
            this.selectedGroupMessage = '';
          }
          resolve({ res: this.cartGroupdata, groupIds: groupIds }); // Resolve the promise with the result
        }, error => {
          reject(error); 
        });
      } else {
        this.isCheckGroup = true;
        this.cartGroupdata = [];
        this.selectedGroupMessage = '';
        resolve({ res: this.cartGroupdata, groupIds: groupIds }); // Resolve the promise with an empty result
      }
    });
  }
  
  chechkDocumentbulkTransfer(id?: number) : Promise<any> {
    return new Promise((resolve, reject) => {
    let documentIds: number [] = [];
    
    if (this.documentRowData.length){
        this.documentGridApi.forEachNode(nodeData => {
          const data = nodeData.data;
          documentIds?.push(data.documentId)
        })
    }
    if (id) {
      let index = documentIds.indexOf(id);
      documentIds.splice(index, 1);
    }
    if (documentIds.length > 0) {
      this.cartService.checkDocumentBulkTransfer(this.siteData.id, documentIds).subscribe(res => {
        this.cartDocumentdata = res;
        if (res.length > 0) {
          this.isCheckDocument = false;
          this.selectedDocumentMessage = `Add documents to the cart in which the selected document's items are present`
        } else {
          this.isCheckDocument = true;
          this.selectedDocumentMessage = '';
        }
        resolve({ res: this.cartDocumentdata, documentIds: documentIds }); // Resolve the promise with the result
        }, error => {
        reject(error); 
      })
    } else {
      this.isCheckDocument = true;
      this.cartDocumentdata = [];
      this.selectedDocumentMessage = '';
      resolve({ res: this.cartDocumentdata, documentIds: documentIds }); // Resolve the promise with an empty result
    }
  });
  }

  addGroupToCart() {

    if (this.cartGroupdata?.length > 0) {
      this.groupRowData = [...this.groupRowData, ...this.cartGroupdata];
      let groups = this.cartGroupdata.map((group: any) => this.dbService.add('groupsTable', {
        groupName: group.groupName,
        groupId: group.groupId,
        groupDescription: group.groupDescription,
        barcode: group.barcode,
        siteId: group.siteId
      }))
      this.subs.add(forkJoin(groups).subscribe());
    }

    if (this.cartDocumentdata?.length > 0) {
      this.documentRowData = [...this.documentRowData, ...this.cartDocumentdata];
      let documents = this.cartDocumentdata.map((document: any) => this.dbService.add('documentTable', {
        documentName: document.documentName,
        documentId: document.documentId,
        documentDescription: document.documentDescription,
        barcode: document.barcode,
        siteId: document.siteId
      }))
      this.subs.add(forkJoin(documents).subscribe());
    }

    const storeNames$ = storeNames.map(element => this.dbService.count(element))
      this.subs.add(forkJoin(storeNames$).subscribe((res) => {
        const totalCount = res.reduce((prev, curr) => prev + curr, 0);
        this.cartService.cart.next(totalCount);
        localStorage.setItem('cartCount', JSON.stringify(totalCount));
      }));
    
     const reqBody = {
      groupIds: this.groupRowData.map(res => res.groupId),
      documentIds: this.documentRowData.map(res => res.documentId)
     } 
     this.checkActiveOsdMaterial(reqBody);
    this.isCheckDocument = true;  
    this.isCheckGroup = true;
    this.selectedGroupMessage = '';
    this.selectedDocumentMessage = '';
    this.selectedDocumentV2Message = '';
  }

  /**
   * Stock item note for qty in group 
   * @memberof CartComponent
   */
  quantityUpdateNote(data: any) {
    if (data) {
      this.selectedActionMessage = "Changing available qty will not affect Group's Item(s) Quantity";
    } else {
      this.selectedActionMessage = '';
    }
  }

  /**
   * material bulk transfer validation for osd 
   * @memberof CartComponent
   */
  osdMatTransferValidation(data: IMaterialsTable[]) {
    this.osdValidationMessage = ""
    if (data?.length) {
      data.forEach(m => {
        if (m?.osdTypeIds?.length) {
          this.osdValidationMessage = "Transfer is not allowed for Material with active OSD"
        }
      })
    }
  }

  /**
   * check available quantity 
   * @memberof CartComponent
   */

  checkAvalableQty(selectedValue: number) {
    if (+selectedValue === +1) {
      let filteredAssetData = this.assetRowData.filter((el: { availableQuantity: number; }) => el.availableQuantity < 1);
      if (filteredAssetData.length > 0) {
        this.errorMessage = "Check-Out is not allowed for Asset/Item(s) with available quantity less than 1"
      } else {
        let filteredStockData = this.stockRowData.filter((el: { availableQuantity: number; }) => el.availableQuantity < 1);
        if (filteredStockData.length > 0) {
          this.errorMessage = "Check-Out is not allowed for Asset/Item(s) with available quantity less than 1"
        } else {
          this.errorMessage = '';
        }
      }
    } else {
      this.errorMessage = '';
    }
  }

  /**
   * set cart data
   *
   * @memberof CartComponent
   */
  setCartData() {
    let assetCartData: IAssetTable[] = [];
    let stockItemsCartData: IStockItemsTable[] = [];
    let materialsCartData: IMaterialsTable[] = [];
    let groupsCartData: IGroupTable[] = [];
    let documentsCartData: IDocumentTable[] = [];
    let documentsV2CartData: IDocumentV2Table[] = [];

    this.subs.add(this.dbService.getAll('assetTable').pipe(
      switchMap((assetData: unknown[]) => {
        assetCartData = assetData as IAssetTable[];
        this.assetRowData = assetCartData;

        this.checkAvalableQty(this.firstFormGroup.value.action);

        return this.dbService.getAll('stockItemsTable').pipe(
          tap((stockData: unknown[]) => {
            stockItemsCartData = stockData as IStockItemsTable[];
            this.stockRowData = stockItemsCartData;
            this.checkAvalableQty(this.firstFormGroup.value.action);
          }));
      }),
      switchMap(() => {
        return this.dbService.getAll('materialsTable').pipe(
          tap((materialData: unknown[]) => {
            materialsCartData = materialData as IMaterialsTable[];
            this.materialRowData = materialsCartData;

            if (materialsCartData?.length == 0) {
              this.osdValidationMessage = "";
              this.osdFieldMandMessage = '';
              localStorage.removeItem('cartMaterialsList');
            }

            if (this.firstFormGroup.value.action === 'bulkTransfer') {
              this.osdMatTransferValidation(this.materialRowData);
            } else if (this.firstFormGroup.value.action === 'createOSDMaterials') {
              this.assignOsdValue();
            };
          }));
      }),
      switchMap(() => {
        return this.dbService.getAll('groupsTable').pipe(
          tap((groupData: unknown[]) => {
            groupsCartData = groupData as IGroupTable[];
            this.groupRowData = groupsCartData;
          }));
        }),
        switchMap(() => {
          return this.dbService.getAll('documentTable').pipe(
            tap((documentData: unknown[]) => {
              documentsCartData = documentData as IDocumentTable[];
              this.documentRowData = documentsCartData;
            }));
          }),
          switchMap(() => {
            return this.dbService.getAll('documentsV2Table').pipe(
              tap((documentV2Data: unknown[]) => {
                documentsV2CartData = documentV2Data as IDocumentV2Table[];
                this.documentV2RowData = documentsV2CartData;
              }));
            }),
        
    ).subscribe(() => {
      if (assetCartData.length !== 0 && stockItemsCartData.length !== 0 && materialsCartData.length !== 0 && groupsCartData.length !== 0 && documentsV2CartData.length !== 0) {
        this.agGridHeight.agGridHeight = true;
        this.agGridHeight.cartGrid = false;
        this.stockGrid.stockGridMargin = true;
        this.stockGrid.agGridHeight = true;
        this.stockGrid.cartGrid = false;
        this.materialGrid.cartGrid = false;
        this.materialGrid.agGridHeight = true;
        this.materialGrid.materialGridMargin = true;
      } else {
        this.agGridHeight.agGridHeight = false;
        this.agGridHeight.cartGrid = true;
        this.stockGrid.stockGridMargin = false;
        this.stockGrid.agGridHeight = false;
        this.stockGrid.cartGrid = true;
        this.materialGrid.cartGrid = true;
        this.materialGrid.agGridHeight = false;
        this.materialGrid.materialGridMargin = false;
      }
    }));
  }

  onBack() {
    this.isAttachmentAllowed = false;
  }
  /**
   * set selected osdtype after removal of row
   *
   * @memberof CartComponent
   */
  assignOsdValue() {
    let checkOsdTypeValues = this.materialRowData?.filter(m => +m.osdType === 0);
    if (!checkOsdTypeValues?.length) {
      this.osdFieldMandMessage = ''
    };

    if (this.materialRowData?.length) {
      this.materialRowData.forEach(m => {

        let material = this.tempMaterialOsdData.filter(fm => +fm.materialId === +m.materialIds);
        if (material?.length) {
          m.osdType = material[0].osdType.toString();
        }

        let filterMat = this.list.filter((cm: any) => +cm.materialIds === +m.materialIds);
        if (filterMat?.length) {
          m.osdTypeIds = filterMat[0]?.osdTypeIds
        }
      })
    }
  }

  /**
   * load drop down data when needed
   *
   * @memberof CartComponent
   */
  loadDropdownData(): void {
    if ((this.firstFormGroup.value.action == '0' || this.firstFormGroup.value.action == '1') && (!this.custodianData.length || !this.locationData.length || !this.statusData.length)) {
      this.getEmployeeData();
      this.getLocationData();
      this.getStatusData();
    } else if (this.firstFormGroup.value.action === 'addToGroup' && !this.groupData.length) {
      this.getGroupData();
    } else if (this.firstFormGroup.value.action === 'addToDocument' && !this.documentData.length) {
      this.getDocumentData();
    } else if (this.firstFormGroup.value.action === 'bulkUpdateStatus' && !this.statusData.length) {
      this.getStatusData();
    }  else if (this.firstFormGroup.value.action === 'addToDocumentV2' && !this.documentData.length) {
      this.getDocumentV2Data();
    } else if (this.firstFormGroup.value.action === 'actionDocumentV2' && !this.documentV2StatusData.length) {
      this.getDocumentV2StatusData();
    } else if ((this.firstFormGroup.value.action === 'bulkUpdateAsset' || this.firstFormGroup.value.action === 'bulkUpdateStockItem' || this.firstFormGroup.value.action === 'bulkUpdateMaterial' || this.firstFormGroup.value.action === "bulkUpdateGroup") &&
      (!this.allFieldNames.length || this.selectedAction !== this.firstFormGroup.value.action)) {
      var moduleId = 0
      switch (this.firstFormGroup.value.action) {
        case "bulkUpdateAsset":
          moduleId = moduleEnum.asset
          break
        case "bulkUpdateMaterial":
          moduleId = moduleEnum.material
          break
        case "bulkUpdateStockItem":
          moduleId = moduleEnum.stockitem
          break
        case "bulkUpdateGroup":
          moduleId = moduleEnum.group
      }
      this.selectedAction = this.firstFormGroup.value.action
      this.getUpdateableColumnsData(moduleId);
    } else if (this.firstFormGroup.value.action === 'bulkTransfer') {
      this.allSitesInfo = JSON.parse(localStorage.getItem('allSitesInfo') as any);
      this.filteredSiteData = JSON.parse(localStorage.getItem('allSitesInfo') as any);
    } else if (this.firstFormGroup.value.action === 'updateLocation') {
      this.getLocationData();
    }
    else if (this.firstFormGroup.value.action === 'createOSDMaterials') {
      this.isAttachmentAllowed = true;
      if (!this.custodianData.length) this.getEmployeeData();
    }
  }

  /**
  * get category
  */
  getCategoryData() {
    const getCategory = this.commonDropDownService.getDropDownData('category', +this.siteData.companyId).subscribe(res => {
      this.isCategoryLoading = false;
      this.categoryData = res;
      this.filteredCategoryData = res
    });
    this.subs.add(getCategory);
  }

  /**
  * get Manufacturer
  */
  getManufacturerData() {
    const getManufacturer = this.commonDropDownService.getDropDownData('manufacturer', +this.siteData.companyId).subscribe(res => {
      this.isManufacturerLoading = false;
      this.manufacturerData = res;
      this.filteredManufacturerData = res
    });
    this.subs.add(getManufacturer);
  }

  /**
   * get employee data
   *
   * @memberof CartComponent
   */
  getEmployeeData(): void {
    const getCustodianData = this.employeeService.getEmployeeData(+this.siteData.companyId).subscribe(res => {
      this.isCustodianLoading = false;
      this.custodianData = res;
      this.filteredCustodianData = res;
    });
    this.subs.add(getCustodianData);
  }

  /**
   * get location data
   *
   * @memberof CartComponent
   */
  getLocationData(): void {
    const getLocationData = this.commonDropDownService.getDropDownData('location', +this.siteData.companyId).subscribe(res => {
      this.isLocationLoading = false;
      this.locationData = res;
      this.filteredLocationData = res;
    });
    this.subs.add(getLocationData);
  }

  /**
    * get satus data
    *
    * @memberof CartComponent
    */
  getStatusData(): void {
    const getStatusData = this.commonDropDownService.getDropDownData('status', +this.siteData.companyId).subscribe(res => {
      this.isStatusLoading = false;
      this.statusData = res;
      this.filteredStatusData = res;
    });
    this.subs.add(getStatusData);
  }

  /**
    * get group data
    *
    * @memberof CartComponent
  */
  getGroupData(): void {
    const getGroupData = this.groupService.getAllGroupData(+this.siteData.id).subscribe(res => {
      this.isGroupLoading = false;
      this.groupData = res;
      this.filteredGroupData = res;
    });
    this.subs.add(getGroupData);
  }

  getDocumentData(): void {
    const getDocumentData = this.documentService.getAllDocumentData(+this.siteData.id).subscribe(res => {
      this.isDocumentLoading = false;
      this.documentData = res;
      this.filteredDocumentData = res;
    });
    this.subs.add(getDocumentData);
  }

  getDocumentV2Data(): void {
    const getDocumentData = this.documentV2Service.getAllDocumentV2Data(+this.siteData.id).subscribe(res => {
      this.isDocumentV2Loading = false;
      this.documentV2Data = res;
      this.filteredDocumentV2Data = res;
    });
    this.subs.add(getDocumentData);
  }

  getDocumentV2StatusData(): void {
    const getDocumentStatusData = this.documentV2Service.getAllDocumentV2TypeData(+this.siteData.companyId, 'Status').subscribe(res => {
      this.isDocumentV2StatusLoading = false;
      this.documentV2StatusData = res;
      this.filteredDocumentV2StatusData = res;
    });
    this.subs.add(getDocumentStatusData);
  }

  /**
    * get work order
    *
    * @memberof CartComponent
  */
  getWorkorderData(): void {
    const getWorkorderData = this.workorderService.getWorkorderData(+this.siteData.companyId).subscribe(res => {
      this.isWorkorderLoading = false;
      this.workorderData = res;
    });
    this.subs.add(getWorkorderData);
  }

  /**
    * get supplier
    *
    * @memberof CartComponent
  */
  getSupplierData(): void {
    const getSupplierData = this.supplierService.getSupplierData(+this.siteData.companyId).subscribe(res => {
      this.isSupplierLoading = false;
      this.supplierData = res;
    })
    this.subs.add(getSupplierData)
  }

  /**
    * get Condition
    *
    * @memberof CartComponent
  */
  getConditionData(): void {
    const getConditionData = this.commonDropDownService.getDropDownData('condition', +this.siteData.companyId).subscribe(res => {
      this.isConditionLoading = false;
      this.conditionData = res;
    })
    this.subs.add(getConditionData)
  }

  /**
   * get Template id
   *
   * @memberof CartComponent
 */
  getTemplateData(): void {
    const getTemplateData = this.groupService.getGroupTemplates(+this.siteData.companyId).subscribe(res => {
      this.isGroupTemplateLoading = false;
      this.groupTemplatedata = res;
    })
    this.subs.add(getTemplateData);
  }

  getGroupTypeData(): void {
    const getGroupTypeData = this.groupService.getGroupTypeData(+this.siteData.companyId).subscribe(res => {
      this.isGroupTypeLoading = false;
      this.groupTypedata = res;
    })
  }
  /**
   * show validation error
   *
   * @param {string} errType
   * @memberof CartComponent
   */
  showError(errType: string): void {
    this.snackBarService.showMessage(`Please select valid ${errType}.`, 'errorSnack');
  }

  /**
   * set cart message based on action
   *
   * @memberof CartComponent
   */
  setCartMessage() {
    let custodian, location, group, document;
    this.reqBody = {
      assetList: [],
      stockItemList: [],
      materialList: [],
      groupList: [],
      documentList: []
    }
    const checkAction = this.firstFormGroup.value.action === '0' ? 'checked in' : 'checked out';
    const toFrom = this.firstFormGroup.value.action === '0' ? 'to' : 'from';
    const formData = this.secondFormGroup.getRawValue();

    if (formData.custodianId && !formData.custodianId?.hasOwnProperty('id')) {
      custodian = this.custodianData.find(custodian => custodian.employeeName === formData.custodianId);
      if (!custodian) {
        this.showError('custodian');
        return;
      }
    }

    if (formData.locationId && !formData.locationId?.hasOwnProperty('id')) {
      location = this.locationData.find(location => location.description === formData.locationId);
      if (!location) {
        this.showError('location');
        return;
      }
    }

    if (this.thirdFormGroup.value?.statusId && !this.thirdFormGroup.value?.statusId?.hasOwnProperty('id') || this.bulkUpdateStatusFormGroup.value?.statusId && !this.bulkUpdateStatusFormGroup.value?.statusId?.hasOwnProperty('id')) {
      if (this.thirdFormGroup.value?.statusId) {
        this.selectedStatus = this.statusData.find(status => status.description === this.thirdFormGroup.value?.statusId) as ICommonDropDownData;
      } else {
        this.selectedStatus = this.statusData.find(status => status.description === this.bulkUpdateStatusFormGroup.value?.statusId) as ICommonDropDownData;
      }

      if (!this.selectedStatus) {
        this.showError('status');
        return;
      }
    }

    if (this.firstFormGroup.value.action === 'addToGroup') {
      const groupValue = this.groupFormGroup.value?.groupId;
      if (groupValue && !groupValue?.hasOwnProperty('id')) {
        group = this.groupData.find(group => group.groupName === groupValue);
        if (!group) {
          this.showError('group');
          return;
        }
      }

      if (groupValue) {
        this.selectedGroup = group ? group : groupValue;
      }

      this.cartMessage = `added to ${this.selectedGroup.groupName}`;
      this.stepper.next();
    }
    else if (this.firstFormGroup.value.action === 'addToDocument') {
      const documentValue = this.documentFormGroup.value?.documentId;

      if (documentValue && !documentValue?.hasOwnProperty('id')) {
        document = this.documentData.find(document => document.documentName === documentValue);

        if (!document) {
          this.showError('document');
          return;
        }
      }

      if (documentValue) {
        this.selectedDocument = document ? document : documentValue;
      }

      this.cartMessage = `added to ${this.selectedDocument.documentName}`;
      this.stepper.next();
    } 
    else if (this.firstFormGroup.value.action === 'addToDocumentV2') {
      const documentV2Value = this.documentV2FormGroup.value?.documentV2Id;
      
      if (documentV2Value && !documentV2Value?.hasOwnProperty('id')) {
        
        document = this.documentV2Data.find(document => document.documentName === documentV2Value);
           
        if (!document) {
          this.showError('document');
          return;
        }
      }

      if (documentV2Value) {
        this.selectedDocumentV2 = document ? document : documentV2Value;
      }

      this.cartMessage = `added to ${this.selectedDocumentV2.documentName}`;
      this.stepper.next();
    } else if (this.firstFormGroup.value.action === 'documentV2StatusId') {
      const documentV2Status = this.actionDocumentV2FormGroup.value?.documentV2Id;
      
      if (documentV2Status && !documentV2Status?.hasOwnProperty('id')) {
        
        document = this.documentV2StatusData.find(document => document.description === documentV2Status);
           
        if (!document) {
          this.showError('document status');
          return;
        }
      }

      if (documentV2Status) {
        this.selectedDocumentV2Status = document ? document : documentV2Status;
      }

      this.cartMessage = `added to ${this.selectedDocumentV2Status.description}`;
      this.stepper.next();
    }
    else {
      if (formData.custodianId) {
        this.selectedCustodian = custodian ? custodian : formData.custodianId;
      }

      if (formData.locationId) {
        this.selectedLocation = location ? location : formData.locationId;
      }

      this.cartMessage = formData.custodianId ? formData.locationId ?
        `${checkAction + ' by ' + this.selectedCustodian.employeeName + ' ' + toFrom + ' ' + this.selectedLocation.description}` :
        `${checkAction + ' by ' + this.selectedCustodian.employeeName}` : `${checkAction + ' ' + toFrom + ' ' + this.selectedLocation?.description}`
      this.stepper.next();
    }

    if (this.assetRowData.length) {
      this.assetGridApi.forEachNode(nodeData => {
        const data = nodeData.data;
        this.reqBody.assetList?.push({ id: data.assetIds, isHiring: +data.hiringAndRate > 0 ? true : false, rate: data.hiringAndRate || 0, quantity: !data.availableQuantity ? data.availableQuantity = 0 : data.availableQuantity })
      })
    }

    if (this.stockRowData.length) {
      this.stockGridApi.forEachNode(nodeData => {
        const data = nodeData.data;
        this.reqBody.stockItemList?.push({ id: data.stockIds, quantity: !data.actionQuantity ? data.actionQuantity = 0 : data.actionQuantity })
      })
    }

    if (this.materialRowData.length) {
      this.materialGridApi.forEachNode(nodeData => {
        const data = nodeData.data;
        this.reqBody.materialList?.push({ id: data.materialIds, quantity: !data.quantity ? data.quantity = 0 : data.quantity, osdType: data.osdType })
      })
    }

    if (this.groupRowData.length) {
      this.groupGridApi.forEachNode(nodeData => {
        const data = nodeData.data;
        this.reqBody.groupList?.push({ id: data.groupId })
      })
    }

    if (this.documentRowData.length) {
      this.documentGridApi.forEachNode(nodeData => {
        const data = nodeData.data;
        this.reqBody.documentList?.push({ id: data.documentId })
      })
    }

    if (this.firstFormGroup.value.action === 'bulkUpdateAsset'
      || this.firstFormGroup.value.action == 'bulkUpdateStockItem'
      || this.firstFormGroup.value.action === 'bulkUpdateMaterial'
      || this.firstFormGroup.value.action === 'bulkUpdateGroup') {

      const bulkFormData = this.bulkUpdateActionFieldsFormGroup.value;

      this.fieldsToUpdate.forEach(field => {
        if (field.fieldName === 'category') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'category' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.categoryId?.id, 'category')
          }
        }
        if (field.fieldName === 'location') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'location' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.locationId?.id, 'location')
          }
        }
        if (field.fieldName === 'status') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'status' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.statusId?.id, 'status')
          }
        }
        if (field.fieldName === 'manufacturer') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'manufacturer' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.manufacturerId?.id, 'manufacturer')
          }
        }
        if (field.fieldName === 'custodian') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'custodian' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.custodianId?.id, 'custodian')
          }
        }
        if (field.fieldName === 'workOrder') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'workOrder' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.workOrderId?.id, 'workOrder')
          }
        }
        if (field.fieldName === 'supplier') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'supplier' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.supplierId?.id, 'supplier')
          }
        }
        if (field.fieldName === 'condition') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'condition' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.conditionId?.id, 'condition')
          }
        }
        if (field.fieldName === 'groupTemplate') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'groupTemplate' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.groupTemplateId?.id, 'groupTemplate')
          }
        }
        if (field.fieldName === 'groupType') {
          var fieldIndex = this.updatedFieldsData.findIndex(d => d.fieldName == 'groupType' && d.clear == true)
          if (fieldIndex < 0) {
            this.updatedActionFieldsData(bulkFormData.groupTypeId?.id, 'groupType')
          }
        }
      })
    }
  }

  /**
   * submit cart data
   *
   * @memberof CartComponent
   */
  submitData() {

    if (this.reqBody !== undefined) {
      if (this.firstFormGroup.value.action === 'addToGroup') {
        this.groupReqBody = {
          assets: this.reqBody?.assetList,
          stockItems: this.reqBody.stockItemList,
          materials: this.reqBody.materialList,
          comment: this.commentFC.value
        }
        this.appInsights.trackEvent("AddToGroup", this.siteData.siteName, {})
        const addGroupItemsMapping = this.groupService.AddGroupItemsMapping(this.selectedGroup.id, this.groupReqBody).pipe(
          switchMap(() => this.emptyCartData(true))
        ).subscribe();
        this.subs.add(addGroupItemsMapping);

      }
      else if (this.firstFormGroup.value.action === 'addToDocument') {
        this.documentReqBody = {
          materials: this.reqBody.materialList,
          comment: this.commentFC.value
        };

        this.appInsights.trackEvent("AddToDocument", this.siteData.siteName, {})
        const addDocumentItemsMapping = this.documentService.AddDocumentItemsMapping(this.selectedDocument.id, this.documentReqBody).pipe(
          switchMap(() => this.emptyCartData(true))
        ).subscribe();
        this.subs.add(addDocumentItemsMapping);

      }
      else if (this.firstFormGroup.value.action === 'addToDocumentV2') {
        this.documentV2ReqBody = {          
          assets: this.reqBody.assetList,   
          stockItems: this.reqBody.stockItemList,   
          materials: this.reqBody.materialList,   
          groups: this.reqBody.groupList,   
          comment: this.commentFC.value
        };

        this.appInsights.trackEvent("AddToDocumentV2", this.siteData.siteName, {})
        const addDocumentV2ItemsMapping = this.documentV2Service.AddDocumentV2ItemsMapping(this.selectedDocumentV2.id, this.documentV2ReqBody).pipe(
          switchMap(() => this.emptyCartData(true))
        ).subscribe();
        this.subs.add(addDocumentV2ItemsMapping);

      } 
       else if (this.firstFormGroup.value.action === 'createOSDMaterials') {
        if (this.osdFieldMandMessage === '' && this.osdValidationMessage === '') {
          let materialOSDRecords = (this.reqBody.materialList as { id: number, osdType: number }[]).map(x => ({ id: x.id, osdType: x.osdType }));
          this.osdReqBody = {
            comments: this.commentFC.value,
            poNumber: this.poNumber.value,
            docketNumber: this.docketNumber.value,
            creatorPhone: this.creatorPhone.value,
            supplierCompany: this.supplierCompany.value,
            supplierContactEmail: this.supplierContactEmail.value,
            supplierContactPerson: this.supplierContactPerson.value,
            supplierPhone: this.supplierPhone.value
          }
          var reqBody = this.getFormData(this.osdReqBody, materialOSDRecords);
          this.appInsights.trackEvent("CreateOSD ", this.siteData.siteName, {})
          const createOSDItemsMapping = this.cartService.createOSDMaterialsMapping(this.siteData.id, reqBody).pipe(
            switchMap(() => this.emptyCartData())
          ).subscribe(() => {
            this.snackBarService.showMessage("OSD created succesfully", 'successSnack');
          }
          );
          this.subs.add(createOSDItemsMapping);
        } else {
          if (this.osdFieldMandMessage !== '') {
            this.snackBarService.showMessage(this.osdFieldMandMessage, 'errorSnack');
          }

          if (this.osdValidationMessage !== '') {
            this.snackBarService.showMessage(this.osdValidationMessage, 'errorSnack');
          }
        }
      } 
      else if (this.firstFormGroup.value.action === 'bulkUpdateAsset'
        || this.firstFormGroup.value.action === 'bulkUpdateStockItem'
        || this.firstFormGroup.value.action === 'bulkUpdateMaterial'
        || this.firstFormGroup.value.action === 'bulkUpdateGroup') {
       const updatedFieldsData = this.updatedFieldsData.filter(res => res.value == '' || res.value == null);
        if (this.updatedFieldsData.filter(d => d.value == undefined && d.clear == false).length > 0 || this.gpsErrorMessage !== '') {
          this.snackBarService.showMessage("Invalid lookup value entered", 'errorSnack');
        }
        else {
          var moduleId = 0
          var itemList: any[] | undefined = []

          switch (this.firstFormGroup.value.action) {
            case "bulkUpdateAsset":
              moduleId = moduleEnum.asset
              itemList = this.reqBody.assetList
              break;
            case "bulkUpdateMaterial":
              moduleId = moduleEnum.material
              itemList = this.reqBody.materialList
              break;
            case "bulkUpdateStockItem":
              moduleId = moduleEnum.stockitem
              itemList = this.reqBody.stockItemList
              break;
            case "bulkUpdateGroup":
              moduleId = moduleEnum.group
              itemList = this.reqBody.groupList
              break;
          }
           this.bulkUpdateReqBody = {
             moduleId: moduleId,
             comment: this.commentFC.value,
             updateColumnRequest: this.updatedFieldsData.filter(d => d.value !== undefined).map(field => omit(field, ['fieldType'])),
             itemIds: (itemList as { id: number }[]).map(item => item.id)
            }
            
            if(this.bulkUpdateReqBody.updateColumnRequest.length === this.fieldsToUpdate.length){ 
              this.updateScannedData();   
              this.appInsights.trackEvent("BUlkUpdate ",this.siteData.siteName, {})         
              const cartAction = this.cartService.bulkUpdate(this.siteData.id, this.bulkUpdateReqBody).pipe(switchMap(() => this.emptyCartData(true))).subscribe();
              this.updatedFieldsData = [];
              this.subs.add(cartAction);
            } 
            else {
              this.snackBarService.showMessage("Empty Field Value", 'errorSnack');
            }
        }
      } else if (this.firstFormGroup.value.action === 'bulkTransfer') {
        let transferData: any[] = [];
        let documentTransferedData: any[] = [];
        let materialOsdData: any[] = [];
        if (this.osdValidationMessage === '') {
          const userId = localStorage.getItem('uId') as string;
          const site = this.filteredSiteData.filter((site) => site.siteName === this.bulkTransferFormGroup.value?.siteId)[0];
          const checkBulkTransferReqBody = {
            assetList: this.assetRowData.map((asset) => asset.assetIds),
            stockItemList: this.stockRowData.map((stock) => stock.stockIds),
            materialList: this.materialRowData.map((material) => material.materialIds),
            groupList: this.groupRowData.map((group) => group.groupId),
            documentList: this.documentRowData.map((document) => document.documentId),
            newSiteId: site.id,
            comment: this.commentFC.value,
            userId: Number(userId)
          }
          this.appInsights.trackEvent("BulkTransfer ", this.siteData.siteName, {})
          const bulkTransferSub = this.cartService.checkItemBelongsToGroupThenTransfer(this.siteData.id, checkBulkTransferReqBody).pipe(
            tap((res: any) => {
              if (!res['groupItemResponses']?.length && !res['documentV2ItemResponse']?.length) {
                this.subs.add(this.emptyCartData(false).subscribe(
                  () => {
                    this.updateScannedData();
                    this.snackBarService.showMessage("Item(s) transferred succesfully", 'successSnack');
                  })
                )
              }
              else {
                let bulkTransferGroupReqBody: any;
                let bulkTransferDocumentReqBody: any;

                res['groupItemResponses'].map((element: any) => {

                  bulkTransferGroupReqBody = {
                    assetList: element.assets.map((asset: any) => asset.item1),
                    stockItemList: element.stockItems.map((stockItem: any) => stockItem.item1),
                    materialList: element.materials.map((material: any) => material.item1),
                    groupList: [],
                    documentList: [],
                    newSiteId: site.id,
                    comment: this.commentFC.value,
                    userId: Number(userId)
                  };

                  transferData.push({
                    group: element.group,
                    stockItem: element.stockItems.map((stock: any) => stock.item2).toString(),
                    assetList: element.assets.map((asset: any) => asset.item2).toString(),
                    materialList: element.materials.map((material: any) => material.item2).toString(),
                  });
                })

                res['documentV2ItemResponse'].map((element: any) => {

                  bulkTransferDocumentReqBody = {
                    assetList: element.assets?.map((asset: any) => asset.item1),
                    stockItemList: element.stockItems?.map((stockItem: any) => stockItem.item1),
                    materialList: element.materials?.map((material: any) => material.item1),
                    newSiteId: site.id,
                    groupList: element.groups?.map((group: any)=>group.item1),
                    documentList: [],
                    comment: this.commentFC.value,
                    userId: Number(userId)
                  };

                  documentTransferedData.push({
                    document: element.document,
                    assetList: element.assets?.map((asset: any) => asset.item2).toString(),
                    stockItemList: element.stockItems?.map((stockItem: any) => stockItem.item2).toString(),
                    materialList: element.materials?.map((material: any) => material.item2).toString(),
                    groupList: element.groups?.map((group: any) => group.item2).toString(),
                  })
                })

                let materialList = this.combineAndDistinctMaterialLists(bulkTransferDocumentReqBody, bulkTransferGroupReqBody);
                let assetList = this.combineAndDistinctAssetLists(bulkTransferDocumentReqBody, bulkTransferGroupReqBody);
                let stockitemList = this.combineAndDistinctStockItemLists(bulkTransferDocumentReqBody, bulkTransferGroupReqBody);
                let reqBody : any;

                if (bulkTransferGroupReqBody == undefined) {
                  bulkTransferDocumentReqBody.materialList = materialList;
                  bulkTransferDocumentReqBody.assetList = assetList;
                  bulkTransferDocumentReqBody.stockItemList = stockitemList;
                  reqBody = bulkTransferDocumentReqBody;
                } else {
                  bulkTransferGroupReqBody.materialList = materialList;
                  bulkTransferGroupReqBody.assetList = assetList;
                  bulkTransferGroupReqBody.stockItemList = stockitemList;
                  reqBody = bulkTransferGroupReqBody;
                }

                if(bulkTransferDocumentReqBody?.groupList){
                  reqBody.groupList = bulkTransferDocumentReqBody.groupList
                }
                const resetDialog = this.dialog.open(BulkTransferComponent, {
                  height: 'auto',
                  width: '60%',
                  data: { data: {transferData, documentTransferedData, materialOsdData}, siteId: site.id, bulkData: reqBody },
                  disableClose: true
                }).afterClosed().subscribe((res) => {
                  if (!!res) {
                    this.subs.add(this.emptyCartData(false).subscribe(
                      () => {
                        this.snackBarService.showMessage("Item(s) transferred succesfully", 'successSnack');
                      }
                    ))
                  }
                });
                this.subs.add(resetDialog);
              }
            })
          ).subscribe();
          this.subs.add(bulkTransferSub);
        } else {
          this.snackBarService.showMessage(this.osdValidationMessage, 'errorSnack');
        }
      } else if (this.firstFormGroup.value.action === 'bulkUpdateStatus') {
        const reqBody = {
          assetList: this.assetRowData.map((asset) => asset.assetIds),
          stockItemList: this.stockRowData.map((stock) => stock.stockIds),
          materialList: this.materialRowData.map((material) => material.materialIds),
          groupList: this.groupRowData.map((group) => group.groupId),
          statusId: this.bulkUpdateStatusFormGroup.value.statusId.id,
          comment: this.commentFC.value
        };
        this.cartService.bulkUpdateStatus(this.siteData.id, reqBody).pipe(
          switchMap(() => this.emptyCartData(true))
        ).subscribe();
      }
      else if (this.firstFormGroup.value.action === 'actionDocumentV2') {

        const reqBody = {
          documentV2Ids: this.documentV2RowData.map(document => document.documentV2Id),
          documentStatusId: this.actionDocumentV2FormGroup.value.documentV2StatusId.id,
          isLocked: this.actionDocumentV2FormGroup.value.isLocked,
          comment: this.commentFC.value
        }   

        this.appInsights.trackEvent("DocumentV2Status", this.siteData.siteName, {})
        const actionDocumentV2 = this.documentV2Service.DocumentV2UpdateStatus(this.siteData.id, reqBody).pipe(
          switchMap(() => this.emptyCartData(false))
        ).subscribe();
        this.subs.add(actionDocumentV2);

      } else if (this.firstFormGroup.value.action === 'updateLocation') {
        const userId = localStorage.getItem('uId') as string;
        const checkUpdateLocationReqBody = {
          siteId: this.siteData.id,
          assetList: this.assetRowData.map((asset) => asset.assetIds),
          stockItemList: this.stockRowData.map((stock) => stock.stockIds),
          materialList: this.materialRowData.map((material) => material.materialIds),
          groupList: this.groupRowData.map((group) => group.groupId),
          newLocationId: this.updateLocationFormGroup.controls['locationId']?.value?.id,
          comment: this.commentFC.value,
          userId: Number(userId)
        }
        this.appInsights.trackEvent("UpdateLocation ", this.siteData.siteName, {})
        const updateLocationSub = this.cartService.updateLocationItems(this.siteData.id, checkUpdateLocationReqBody).pipe(switchMap(() => this.emptyCartData(true))).subscribe();
        this.subs.add(updateLocationSub);

      } else {
        if (this.thirdFormGroup.value?.statusId) {
          this.selectedStatus = this.selectedStatus ? this.selectedStatus : this.thirdFormGroup.value?.statusId;
        }

        const requestBody = {
          action: this.firstFormGroup.value.action,
          assetList: this.reqBody.assetList,
          stockItemList: this.reqBody.stockItemList,
          locationId: this.selectedLocation?.id,
          custodianId: this.selectedCustodian?.id,
          statusId: this.selectedStatus?.id,
          comment: this.commentFC.value,
        }

        if (+this.firstFormGroup.value.action === +1 && this.errorMessage !== "") {
          this.snackBarService.showMessage(this.errorMessage, 'errorSnack');
        } else {
          this.appInsights.trackEvent("CheckIn/CheckOut", this.siteData.siteName, {})
          const cartAction = this.cartService.cartAction(+this.siteData.id, requestBody).pipe(
            switchMap(() => this.emptyCartData(true))
          ).subscribe();
          this.subs.add(cartAction);
        }
      }
    } else {
      this.snackBarService.showMessage('Please fill in details on previous step before submit', 'errorSnack');
    }
  }

  combineAndDistinctMaterialLists(documentResponse?: any, groupResponse?: any) {
    if (documentResponse == undefined && groupResponse != undefined) {
      return groupResponse.materialList;
    }
    if (documentResponse != undefined && groupResponse == undefined) {
      return documentResponse.materialList;
    }
    const combinedList = documentResponse.materialList.concat(groupResponse.materialList);
    const distinctList = Array.from(new Set(combinedList));
    return distinctList;
  }
  
  combineAndDistinctAssetLists(documentResponse?: any, groupResponse?: any) {
    if (documentResponse == undefined && groupResponse != undefined) {
      return groupResponse.assetList;
    }
    if (documentResponse != undefined && groupResponse == undefined) {
      return documentResponse.assetList;
    }
    const combinedList = documentResponse.assetList.concat(groupResponse.assetList);
    const distinctList = Array.from(new Set(combinedList));
    return distinctList;
  }
  
  combineAndDistinctStockItemLists(documentResponse?: any, groupResponse?: any) {
    if (documentResponse == undefined && groupResponse != undefined) {
      return groupResponse.stockItemList;
    }
    if (documentResponse != undefined && groupResponse == undefined) {
      return documentResponse.stockItemList;
    }
    const combinedList = documentResponse.stockItemList.concat(groupResponse.stockItemList);
    const distinctList = Array.from(new Set(combinedList));
    return distinctList;
  }

  updatedActionFieldsData(value: number, fieldName: string) {
    var checkField = this.updatedFieldsData.findIndex(field => field.fieldName == fieldName);
    if (checkField < 0) {
      this.updatedFieldsData.push({
        fieldName: fieldName,
        fieldType: '',
        value: value,
        clear: false,
      })
    } else {
      this.updatedFieldsData[checkField].value = value
    }
  }

  /**
   * empty cart data
   *
   * @return {*}
   * @memberof CartComponent
   */
  emptyCartData(askForConfirmation: boolean = false): Observable<boolean[]> {

    localStorage.removeItem('cartMaterialsList');

    const storeNames$ = storeNames.map(element => this.dbService.clear(element));
    if (askForConfirmation) {
      const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: 'auto',
        width: 'fit-content',
        disableClose: true
      });

      confirmDialogRef.componentInstance.confirmMessage = 'Transaction Completed.\nDo you want to empty the cart?';

      const dialogClosedSub = confirmDialogRef.afterClosed().pipe(
        tap(() => {
          this.selectedActionMessage = '';
          this.fieldMultiCtrl.reset();
          this.bulkUpdateActionFieldsFormGroup.reset();
          this.bulkUpdateActionFieldsFormGroup.enable();
          this.fieldsToUpdate = [];
          this.commentFC.reset();
          this.secondFormGroup.reset();
          this.thirdFormGroup.reset();
          this.updateLocationFormGroup.reset();

        }),
        switchMap((res) => {
          if (!res) {
            this.getEmployeeData();
            this.getLocationData();
            this.getStatusData();

            this.assetRowData.map(asset => {

              if (this.firstFormGroup.value.action === '0') {
                this.updateScannedData();
                asset.availableQuantity = 1
              } else if (this.firstFormGroup.value.action === '1') {
                this.updateScannedData();
                asset.availableQuantity = 0
              };

              this.dbService.update('assetTable', asset).pipe(
                map(x => {
                  this.assetRowData.map(y => {
                    if (y.assetIds === x.assetIds)
                      y.availableQuantity = x.availableQuantity
                  });
                  this.assetGridApi.refreshCells({ columns: ['availableQuantity'] })
                })
              ).subscribe();
            });

            this.stockRowData.map(stockItem => {
              if (this.firstFormGroup.value.action === '0') {
                stockItem.availableQuantity = +(stockItem.availableQuantity ?? 0) + +stockItem.actionQuantity
              } else if (this.firstFormGroup.value.action === '1') {
                stockItem.availableQuantity = +(stockItem.availableQuantity ?? 0) - +stockItem.actionQuantity
              };

              this.dbService.update('stockItemsTable', stockItem).pipe(
                map(x => {
                  this.stockRowData.map(y => {
                    if (y.stockIds === x.stockIds)
                      y.availableQuantity = x.availableQuantity
                  });
                  this.stockGridApi.refreshCells({ columns: ['availableQuantity'] })
                })
              ).subscribe();
            })

            this.materialRowData.map(material => {

              if (this.firstFormGroup.value.action === 'bulkUpdateMaterial') {
                material.quantity = this.materialQty;
                this.dbService.update('materialsTable', material).pipe(
                  map(x => {
                    this.materialRowData.map(y => {
                      y.quantity = this.materialQty
                    })
                    this.materialGridApi.refreshCells({ columns: ['quantity'] })
                  })
                ).subscribe();
              }
            })

            this.documentV2RowData.map(document => {
              if (this.firstFormGroup.value.action === 'actionDocumentV2') {
                document.documentStatus = this.actionDocumentV2FormGroup.value.documentV2StatusId.description;
                this.dbService.update('documentsV2Table', document).pipe(
                  map(x => {
                    this.documentV2RowData.map(y => {
                      y.documentStatus = x.documentStatus
                    })
                    this.documentV2GridApi.refreshCells({ columns: ['documentStatus'] })
                  })
                ).subscribe();
              }
            })
            return of([]);
          }
          else {
            return forkJoin(storeNames$).pipe(
              tap(() => {
                this.isCartData = false;
                this.cartService.cart.next(0);
                localStorage.setItem('cartCount', JSON.stringify(0));
              })
            )
          }
        })
      ).subscribe(() => this.stepper.reset());
      this.subs.add(dialogClosedSub);
    } else {
      return forkJoin(storeNames$).pipe(
        tap(() => {
          this.isCartData = false;
          this.cartService.cart.next(0);
          localStorage.setItem('cartCount', JSON.stringify(0));
        })
      );
    }
    return of([]);
  }

  /**
    * delete cart data
    *
    * @memberof CartComponent
    */
  clearCartData() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'fit-content',
      disableClose: true,
      data: 'emptyCart'
    });
    dialogRef.componentInstance.confirmMessage = 'Empty Cart?';

    const dialogClosed = dialogRef.afterClosed().pipe(
      filter(res => res),
      switchMap(() => this.emptyCartData())
    ).subscribe();
    this.subs.add(dialogClosed);
  }

  /**
   * delete cart data
   *
   * @param {number} id
   * @memberof CartComponent
   */
  deleteData(id: number, itemType: string) {
    const deleteCartByKey = this.dbService.deleteByKey(`${itemType}Table`, id).subscribe();
    this.subs.add(deleteCartByKey);
    this.setCartData();

    const observables = [
      this.dbService.getAll('materialsTable'),
      this.dbService.getAll('stockItemsTable'),
      this.dbService.getAll('assetTable'),
      this.dbService.getAll('groupsTable'),
      this.dbService.getAll('documentsV2Table'),
      ];

    forkJoin(observables).subscribe(results => {
    const totalCount = results.reduce((acc, res) => acc + res.length, 0);
      if (totalCount === 0) {
        this.isCartData = false;
        this.cartService.cart.next(0);
        localStorage.setItem('cartCount', JSON.stringify(0));
      }
    })
    
    this.handleAction(itemType, this.firstFormGroup.value.action);

    if(this.firstFormGroup.value.action == 'addToGroup' && (itemType == "materials" || itemType == "stockItems" || itemType == "asset"))
    {
      const observables = [
      this.dbService.getAll('materialsTable'),
      this.dbService.getAll('stockItemsTable'),
      this.dbService.getAll('assetTable')
      ];

      forkJoin(observables).subscribe(results => {
      const totalCount = results.reduce((acc, res) => acc + res.length, 0);
        if (totalCount === 0) {
          this.firstFormGroup.get('action')?.setValue(null);
        }
      })
    };

    if(this.firstFormGroup.value.action == 'bulkTransfer' || this.firstFormGroup.value.action == 'addToDocumentV2' || this.firstFormGroup.value.action == 'updateLocation' || this.firstFormGroup.value.action == 'bulkUpdateStatus' && (itemType == "materials" || itemType == "stockItems" || itemType == "asset" || itemType == "groups"))
      {
        const observables = [
        this.dbService.getAll('materialsTable'),
        this.dbService.getAll('stockItemsTable'),
        this.dbService.getAll('assetTable'),
        this.dbService.getAll('groupsTable')
        ];
  
        forkJoin(observables).subscribe(results => {
        const totalCount = results.reduce((acc, res) => acc + res.length, 0);
          if (totalCount === 0) {
            this.firstFormGroup.get('action')?.setValue(null);
          }
        })
    };

    if(itemType == 'groups' && this.firstFormGroup.value.action == 'bulkTransfer'){
      let grouplist:any = []
      const handleBulkTransfer = async () => {
        await this.chechkGroupbulkTransfer(id).then(result => {
          grouplist = result;
        })
     }
 
     handleBulkTransfer().then(result => {
      if(grouplist.res.length == 0){
        const reqbody = {
          groupIds: grouplist.groupIds,
          documentIds: this.documentRowData.map(res => res.documentId)
      }
      this.checkActiveOsdMaterial(reqbody);
      }
    })
    }
    if(itemType == 'document' && this.firstFormGroup.value.action == 'bulkTransfer'){
      let documentlist:any = []
      const handleBulkTransfer = async () => {
        await this.chechkDocumentbulkTransfer(id).then(result => {
          documentlist = result;
        })
      }
      handleBulkTransfer().then(result => {
       if(documentlist.res.length == 0){
        const reqbody = {
          documentIds: documentlist.documentIds,
          groupIds: this.groupRowData.map(res => res.groupId)
       }
      this.checkActiveOsdMaterial(reqbody);
      }
    })
    }
    const stores = storeNames.map(storeName => this.dbService.count(storeName));
    this.subs.add(forkJoin(stores).subscribe(cartData => {
      const totalItems = cartData.reduce((prev, curr) => prev + curr, 0);

      this.cartService.cart.next(totalItems);
      localStorage.setItem('cartCount', JSON.stringify(totalItems));
      if (!cartData) {
        this.isCartData = false;
      }
    }));
  }

  handleAction(itemType: string, action: string): void {
    const actionMap : any = {
      'asset': 'bulkUpdateAsset',
      'materials': ['bulkUpdateMaterial', 'createOSDMaterials'],
      'stockItems': 'bulkUpdateStockItem',
      'documentsV2' : 'actionDocumentV2',
      'groups' : 'bulkUpdateGroup'
    };
  
    if (actionMap[itemType] && (
        Array.isArray(actionMap[itemType])
          ? actionMap[itemType].includes(action)
          : actionMap[itemType] === action
      )){
      this.dbService.getAll(`${itemType}Table`).subscribe((res) => {
        if (!res.length) {
          this.firstFormGroup.get('action')?.setValue(null);
        }
      });
    }
  }
  /**
   * get updatable columns data
   *
   * @param {number} moduleId
   * @memberof CartComponent
   */
  getUpdateableColumnsData(moduleId: number): void {
    this.allFieldNames.length = 0;
    const getData = this.cartService.getUpdateableColumns(+this.siteData.id, moduleId).subscribe(res => {
      const allfieldNames = [...res].sort((f1, f2) => this.SortFieldModel(f1, f2));
      this.isColumnsLoading = false;
      this.allFieldNames.push(...allfieldNames);
      this.availableFields.next(this.allFieldNames.slice());
      this.allfieldsSize = this.allFieldNames.length;
      this.fieldMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterFieldsMulti();
        });

      this.fieldMultiCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.checkAndEnableAddFieldsButtonAndCheckBoxes();
        });
    });
    this.subs.add(getData);
  }

  SortFieldModel(a1: IUpdateableColumnsDataModel, a2: IUpdateableColumnsDataModel): number {
    if (a1.fieldDisplayName > a2.fieldDisplayName) {
      return 1;
    }
    if (a1.fieldDisplayName < a2.fieldDisplayName) {
      return -1;
    }

    return 0;
  }

  protected filterFieldsMulti() {
    if (!this.allFieldNames) {
      return;
    }
    // get the search keyword
    let search = this.fieldMultiFilterCtrl.value;
    if (!search) {
      this.searchedFieldsAvailable = true;
      this.availableFields.next(this.allFieldNames.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the banks
    this.availableFields.next(
      this.allFieldNames.filter(field => field.fieldDisplayName.toLowerCase().indexOf(search) > -1)
    );

    this.searchedFieldsAvailable = this.allFieldNames.filter(field => field.fieldDisplayName.toLowerCase().includes(search)).length > 1 ? true : false;
  }

  checkAndEnableAddFieldsButtonAndCheckBoxes() {
    const fieldsSelected = this.fieldMultiCtrl.value ? this.fieldMultiCtrl.value.length > 0 ? true : false : false;
    this.noFieldsSelected = !fieldsSelected;
    this.toggleAllCheckboxIndeterminate = this.fieldMultiCtrl.value ? this.fieldMultiCtrl.value.length > 0 && this.fieldMultiCtrl.value.length < this.allfieldsSize ? true : false : false;
    this.toggleAllCheckboxChecked = this.fieldMultiCtrl.value ? this.fieldMultiCtrl.value.length === this.allfieldsSize ? true : false : false;
  }

  addOrRemoveFields(fields: IUpdateableColumnsDataModel[]) {

    if (fields.length >= this.fieldsToUpdate.length) {
      fields.forEach(field => {
        const fieldIndex = this.fieldsToUpdate.findIndex((v1) => v1.fieldName === field.fieldName);
        if (fieldIndex < 0) {
          field.value = '';
          this.fieldsToUpdate.push(field);
          if (field.fieldName == 'category') {
            this.getCategoryData();
          } else if (field.fieldName == 'status') {
            this.getStatusData();
          } else if (field.fieldName == 'location') {
            this.getLocationData();
          } else if (field.fieldName == 'workOrder') {
            this.getWorkorderData();
          } else if (field.fieldName == 'supplier') {
            this.getSupplierData();
          } else if (field.fieldName == 'condition') {
            this.getConditionData();
          } else if (field.fieldName == 'manufacturer') {
            this.getManufacturerData();
          } else if (field.fieldName == 'custodian') {
            this.getEmployeeData();
          } else if (field.fieldName == 'groupTemplate') {
            this.getTemplateData();
          } else if (field.fieldName == 'groupType') {
            this.getGroupTypeData();
          }
        }
      });
    } else {
      const fieldToRemove = this.fieldsToUpdate.filter(fieldVal => fields.indexOf(fieldVal) === -1);
      this.fieldsToUpdate.splice(this.fieldsToUpdate.indexOf(fieldToRemove[0]), 1);
    }


    // selectedFields.forEach((v: IUpdateableColumnsDataModel) => {
    // console.log('fieldsToUpdate------------', this.fieldsToUpdate);

    // });

    // this.fieldsToUpdate.forEach((v) => {
    //   if(selectedFields.findIndex((v1: any) => v1.fieldDisplayName === v.fieldDisplayName) < 0){
    //     const fieldIndex = this.fieldsToUpdate.indexOf(v);
    //     this.fieldsToUpdate.splice(fieldIndex, 1);
    //   }
    // });
    this.fieldsToUpdate.sort((f1, f2) => this.SortFieldModel(f1, f2));

  }

  removeField(targetField: any) {
    this.bulkUpdateActionFieldsFormGroup.controls[`${targetField.fieldName}Id`]?.reset();
    delete this.numericFieldValues[targetField.fieldName];
    this.isNegative = Object.values(this.numericFieldValues).some(val => val < 0);
    (targetField.fieldName === 'onHireDate' || targetField.fieldName === 'hireContractExpiryDate') ? this.dateErrorMessage = '' : '';
    (targetField.fieldName === 'minimum' || targetField.fieldName === 'maximum' || targetField.fieldName === 'minimumQuantity' || targetField.fieldName === 'maximumQuantity') ? this.qtyErrorMessage = '' : '';
    const fieldIndex = this.fieldsToUpdate.indexOf(targetField);
    this.fieldsToUpdate.splice(fieldIndex, 1);
    const updatedFieldRemovalIndex = this.updatedFieldsData.findIndex(field => field.fieldName === targetField.fieldName);
    updatedFieldRemovalIndex > -1 ? this.updatedFieldsData.splice(updatedFieldRemovalIndex, 1) : '';
    const selectedFields = this.fieldMultiCtrl.value.filter((v: any) => v.fieldDisplayName !== targetField.fieldDisplayName);
    this.fieldMultiCtrl.setValue(selectedFields);
    this.checkIfFieldIsValid();
  }

  /**
   * check if field is valid for bulk edit
   *
   * @memberof CartComponent
   */
  checkIfFieldIsValid(): void {
    this.dynamicLatitudeValid = true;
    this.dynamicLongitudeValid = true;

    if (this.updatedFieldsData.length) {
      const subs = this.updatedFieldsData.filter(data => {
        if (data.fieldName == 'gpsLatitude') {
          if (+data?.value >= -90 && +data.value <= 90) {
            this.dynamicLatitudeValid = true;
            this.gpsErrorMessage = '';
          } else {
            this.gpsErrorMessage = 'Please Enter valid value of gps co-ordinates';
            this.dynamicLatitudeValid = false;
          }
        } else if (data.fieldName == 'gpsLongitude') {
          if (+data?.value >= -180 && +data.value <= 180) {
            this.dynamicLongitudeValid = true;
            this.gpsErrorMessage = '';
          } else {
            this.dynamicLongitudeValid = false;
            this.gpsErrorMessage = 'Please Enter valid value of gps co-ordinates';
          }
        }
      })
    } else {
      this.dynamicLatitudeValid = true;
      this.dynamicLongitudeValid = true;
    }
    this.cd.detectChanges();
  }

  /**
   * toggle select all dropdown menu
   *
   * @param {boolean} selectAllValue
   * @memberof CartComponent
   */
  toggleSelectAll(selectAllValue: boolean) {
    this.availableFields.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val: IUpdateableColumnsDataModel[]) => {
      if (selectAllValue) {
        this.getCategoryData();
        this.getLocationData();
        this.getStatusData();
        this.getConditionData();
        this.getManufacturerData();
        this.getWorkorderData();
        this.getSupplierData();
        this.getEmployeeData();
        this.getTemplateData();
        this.getGroupTypeData();
        this.fieldMultiCtrl.patchValue(val);
        val.forEach(availableField => {
          const availableFieldIndex = this.fieldsToUpdate.findIndex((field) => field.fieldName === availableField.fieldName);
          if (availableFieldIndex < 0) {
            this.fieldsToUpdate.push(availableField);
          }
        });
      } else {
        this.fieldMultiCtrl.patchValue([]);
        this.fieldsToUpdate = [];
      }
    });
  }

  /**
  * asset grid ready
  *
  * @param {*} params
  * @memberof CartComponent
  */
  onAssetGridReady (params: any) {
    this.assetGridApi = params.api;
  }

  /**
   * stock grid ready
   *
   * @param {*} params
   * @memberof CartComponent
   */
  onStockGridReady (params: any) {
    this.stockGridApi = params.api;
  }

  /**
   * on material grid ready
   *
   * @param {*} params
   * @memberof CartComponent
   */
  onMaterialGridReady (params: any) {
    this.materialGridApi = params.api;
  }

  onGroupGridReady (params: any) {
     this.groupGridApi = params.api;
  }

  onDocumnetGridReady (params: any) {
    this.documentGridApi = params.api;
  }

  onDocumnetV2GridReady (params: any){
    this.documentV2GridApi = params.api;
  }

  onOSDAttachmentGridReady (params: any) {
    this.osdAttachmentGridApi = params.api;
  }

  updateScannedData() {
    let scannedData = JSON.parse(localStorage.getItem('scannedAssetData') as any);
    if (!!scannedData) {
      if (this.firstFormGroup.value.action === 'bulkTransfer') {
        scannedData = scannedData.filter((el: any) => this.assetRowData.every(a => +a.assetIds !== +el.id));
        localStorage.setItem('scannedAssetData', JSON.stringify(scannedData));
      } else if (this.firstFormGroup.value.action === 'bulkUpdateAsset') {
        let getScannedassets = scannedData.filter((el: any) => this.assetRowData.every(a => +a.assetIds === +el.id));
        if (!!getScannedassets) {
          this.assetRowData.forEach((data: IAssetTable) => {
            let indexToUpdate = scannedData.findIndex((a: any) => a.id == data.assetIds);
            scannedData[indexToUpdate].description = this.updatedFieldsData?.filter(d => d.value !== undefined && d.fieldName == 'description')[0]?.value;
          })
          localStorage.setItem('scannedAssetData', JSON.stringify(scannedData));
        }
      } else if (this.firstFormGroup.value.action === '0') {
        this.updateScannedDataQty(scannedData, 1);
      } else if (this.firstFormGroup.value.action === '1') {
        this.updateScannedDataQty(scannedData, 0);
      }
    }
  }

  updateScannedDataQty(scannedData: any, qty: number) {
    let getScannedassets = scannedData.filter((el: any) => this.assetRowData.every(a => +a.assetIds === +el.id));
    if (!!getScannedassets) {
      getScannedassets.forEach((data: any) => {
        let indexToUpdate = scannedData.findIndex((a: any) => a.id == data.id);
        scannedData[indexToUpdate].availableQuantity = qty;
      })
      localStorage.setItem('scannedAssetData', JSON.stringify(scannedData));
    }

  }

  osdAttachment() {
    const modalData = {
      title: "Add Attachments",
      action: "Confirm",
      valid: false,
      comments: "",
      attachments: this.osdAttachmentData
    }

    let dialogRef = this.dialog.open(OsdAttachmentPhotosComponent, {
      width: '50%',
      panelClass: 'app-full-bleed-dialog',
      data: modalData
    });

    const dialogSubscription =
      dialogRef.afterClosed().subscribe(async result => {
        dialogSubscription.unsubscribe();
        if (result) {
          this.attachments = result;
          this.osdAttachmentData = [...this.osdAttachmentData, ...this.attachments.files]
        }
      });
  }


  getFormData(reqBody: any, materialOSDRecords: any): FormData {
    const fd = new FormData();
    Object.keys(reqBody).forEach(key => fd.append(key, String(reqBody[key as keyof typeof reqBody])));

    for (let r = 0; r < materialOSDRecords.length; r++) {
      fd.append(`MaterialOSDRecords[${r}].Id`, materialOSDRecords[r].id);
      fd.append(`MaterialOSDRecords[${r}].osdType`, materialOSDRecords[r].osdType);
    }
    this.osdAttachmentData.forEach((val: any, index: any) => {
      fd.append(`Attachments[${index}].file`, val);
      fd.append(`Attachments[${index}].type`, val.type);
    });
    return fd;
  }

  deleteOSDAttachment(event: any, params: any) {
    let osdRowData = this.osdAttachmentData.filter(res => res.name !== params.data.name)
    this.osdAttachmentData = osdRowData;
  }
}
