<mat-card class="mat-elevation-z6 bg-01dp mx-2 mb-2" fxLayout="row" *ngIf="checkUserAccess()">
    <ng-container>
        <button class="grid-search-icon mr-10px" mat-raised-button matTooltip="Export all Transcation" (click)="exportData()" [disabled]="exportDisabled">
            <mat-icon>cloud_download</mat-icon>
        </button>
    </ng-container>
  </mat-card>
<div class="ag-grid-angular mat-elevation-z6 mx-2 mt-2">
    <ag-grid-angular
        [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
        class="ag-grid transaction-ag-grid"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [headerHeight]="40"
        [rowData]="rowData"
        [statusBar]="statusBar"
        >
    </ag-grid-angular>

    <div class="float-left"></div>
    <div [ngClass]="{'text-white': loaderService.themeToggleSubject.value === 'dark', 'text-black':  loaderService.themeToggleSubject.value === 'light'}" class="pt-2 text-xs float-right pr-2 load-more-box" *ngIf="!!rowData?.length" >
        {{rowData?.length}} of total {{totalCount}} items
        <span *ngIf="totalCount > 100 && rowData.length !== totalCount">
            <a (click)="!disableLoad ? getTransactionData(100, 100, true) : null" class="pl-3 text-blue-600 load-more-button">Load more</a>
        </span>
    </div>
</div>
