import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {

  /** confimation message to be asked */
  public confirmMessage!: string;

  /** instruction message */
  public instructionMessage = '';
  customActionButton!: string
  customCancelButton!: string
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.confirmMessage.includes('Unassign') || this.data === 'deleteGroupSubItem' || this.data === 'resetForm' || this.data === 'deleteItem' || this.data === 'emptyCart' ? (this.customActionButton = 'unassignButton' , this.customCancelButton = 'unassignCancel') 
    : this.data === 'changeSiteForItem' ? (this.customActionButton = 'changeItemButton', this.customCancelButton = 'unassignCancel') 
    : this.customActionButton = 'actionButton'
  }

  /**
   * get button text to display cancel or leave
   *
   * @return {*}  {string}
   * @memberof ConfirmationDialogComponent
   */
  getCancelButtonText(): string {
    return (this.confirmMessage.includes('Transaction')) ? 'Empty' : 'Cancel';
  }

  /**
   * get button text to display confirm or save & leave
   *
   * @return {*}  {string}
   * @memberof ConfirmationDialogComponent
   */
  getConfirmButtonText(): string {
    return this.data === 'resetForm' ? 'Discard' : 
    this.confirmMessage.includes('Transaction') ? 'Keep' : 
    this.confirmMessage.includes('Unassign') ? 'Unassign' : 
    this.data === 'deleteGroupSubItem' ? 'Remove' :
    this.data === 'deleteItem' ? 'Delete' :
    this.data === 'changeSiteForItem' ? 'Change' : 
    this.data === 'emptyCart' ? 'Empty':
    'Confirm';
  }
}
