<app-maintenance *ngIf="maintenanceFlag"> </app-maintenance>
<ng-container *ngIf="!isCompanySelected && loginDisplay && maintenanceFlag == false ; else loadData">
    <app-multi-company-dialog></app-multi-company-dialog>
</ng-container>
<ng-template #loadData>
    <div *ngIf="loginDisplay && maintenanceFlag == false" class="h-full">
        <app-navigation></app-navigation>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="w-50 side-nav mt-2 ml-3 fixed"
            [ngClass]="sideNavCss">
            <div fxLayout="column" fxLayoutAlign="space-between stretch" class="side-nav-height bg-24dp">
                <ng-container *ngIf="activePage === 'home'; else adminTemplate">
                    <app-side-navigation (collapseSideNavigationMenu)="expansionToggle($event)" [isDisable]="isDisable"></app-side-navigation>
                </ng-container>
                <ng-template #adminTemplate>
                    <app-admin></app-admin>
                </ng-template>
                <div [ngClass]="angleIcon">
                    <fa-icon class="iconHover" [icon]="faAngleDouble" (click)="expansionToggle(isDisable ? false : true)"> 
                  </fa-icon>
                </div>
                <!-- <app-footer *ngIf="!isDisable"></app-footer> -->
            </div>
        </div>

        <div class="bg-00dp assetMatToolBar mt-2 overflow-auto" [ngClass]="tableContent">
            <router-outlet></router-outlet>
        </div>
        <div *ngIf="isLoading" class="overlay">
            <!-- <div class="mat-spinner">
                <img src="../assets/images/multiConstructionMan.webp" border="0"/>
                <mat-icon mat-ripple class="closeIcon mr-2 mt-2" (click)="loaderService.hide()"> close </mat-icon>
            </div> -->

            <div class="loader">
                <mat-spinner></mat-spinner>
                <div class="loadingIcon cursor-pointer">
                    <fa-icon [icon]="faTimes" (click)="loaderService.hide()"></fa-icon>
                </div>
            </div>
        </div>
    </div>
</ng-template>
