<div mat-dialog-title class="text-lg">{{dialogData.itemNumber}} - Transaction No.{{dialogData.transactionId}} Details</div>
<mat-dialog-content>
    <div class="ag-grid-angular" fxFlexFill>
        <ag-grid-angular
            [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
            class="ag-grid"
            [columnDefs]="columnDefs"
            [rowData]="rowData"
            domLayout="autoHeight"
            >
        </ag-grid-angular>
    </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
    <button mat-flat-button class="actionButton" mat-dialog-close>Close</button>
</div>