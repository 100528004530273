import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SnackBarService } from '../services/common/snackbar.service';
import { NavigationComponent } from '../common/navigation/navigation.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../common/message-dialog/message-dialog.component';
@Injectable()
export class CommonInterceptorInterceptor implements HttpInterceptor {

  snackBarError = '';

  text: number = 2;

  constructor(private snackBarService: SnackBarService, private navigation: NavigationComponent, private matDialog: MatDialog) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        accept: '*/*',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        // 'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey
      }
    });
    return next.handle(request).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          if (res.status === 201 || res.status === 204) {
            this.text = 1;
            const message = `Record ${request.method === 'POST' ? 'Created' : request.method === 'PUT' ? 'Updated' : 'Deleted'} Successfully.`;
            this.snackBarService.showMessage(message, 'successSnack');
          } else if(res.status === 206) {
            this.matDialog.open(MessageDialogComponent,{
              width : "500px",
              data : {
                title : "CSV Import",
                content: "Some items from your sheet have not been uploaded. They can be found in the downloaded file along with the reasons why they were not uploaded. Please review the downloaded file."
              }
            })
          }
        }
      }),
      catchError(responseError => {
        if ((request.method !== 'DELETE' || responseError.status !== 404)) {
          if(responseError.error instanceof Blob){
            responseError.error.text().then((errorObject:string)=>{ 
              try{
                responseError.error = JSON.parse(errorObject)
              }
              catch{
                responseError.error = null
              }
              this.handleResponseError(responseError)
            })
          }
          else{
            this.handleResponseError(responseError)
          }

        }
        return throwError(responseError);
      })
    )
  }

  private handleResponseError(responseError: any) {
    if (responseError.status === 401)
            responseError.error ? this.snackBarError = responseError.error : this.snackBarError = 'Unauthorized', this.navigation.logout();

          else {
            this.snackBarError = responseError.error?.Message ? responseError.error.Message : responseError.error;
          }
          this.snackBarService.showMessage(this.snackBarError || 'Something went wrong', 'errorSnack');
    }
}
