import { Injectable } from '@angular/core';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { CustomHttpClientService } from '../common/custom-http-client.service';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAPIResponse, IUserModel } from 'src/app/view-models/common.model';
import { IPagination } from 'src/app/view-models/stock-items-model';

@Injectable({
  providedIn: 'root'
})
export class UserListService {

  companyId: number = 1;
  
  siteData!: any;
  userGridRefresh = new BehaviorSubject(false);
  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  /** searched User value */
  searchedUserValue!: string;

  private disableSaveButton = new BehaviorSubject<any>(false);
  disableReportsSaveButton = this.disableSaveButton.asObservable();
  
  setDisableSaveButton(isDisable: boolean=false){
    this.disableSaveButton.next(isDisable);
  }

  private companySelected = new BehaviorSubject<any>(false);
  isCompanySelected = this.companySelected.asObservable();
  
  setCompanySelected(isDisable: boolean=false){
    this.companySelected.next(isDisable);
  }
  /**
  * set searched User value
  *
  * @param {string} searchedValue
  * @memberof UserListService
  */
  setSearchedUser(searchedValue: string): void {
    this.searchedUserValue = searchedValue;
  }

  /**
  * get searched User value
  *
  * @return {*}  {string}
  * @memberof UserListService
  */
  getSearchedUser(): string {
    return this.searchedUserValue;
  }

  constructor(private http: CustomHttpClientService) { }

  /**
   *
   *
   * @param {boolean} [mappingFlag=false]
   * @return {*} 
   * @memberof UserListService
   */
  getSearchUser(reqBody: IPagination) {
    const options: IRequestOptions = {
      params: {
        showLoadingIndicator: true
      }
    }
    this.siteData = JSON.parse(sessionStorage.getItem('siteData') || '{}');
    this.companyId = this.siteData['companyId']
    return this.http.Post(`company/${this.companyId}/searchUsers`, reqBody, this.reqOpt) as Observable<any>;
  }

  /**
   * get updated user
   *
   * @param {number} id
   * @return {*} 
   * @memberof UserListService
   */
  getUpdateUser(id: number, companyId: number){
    const params = new HttpParams().set('companyId', companyId);
    return this.http.Get(`User/${id}`, {
      showLoadingIndicator: true,
      params
    });
  }

  /**
   * delete user
   *
   * @param {number} userId
   * @return {*}  {Observable<any>}
   * @memberof UserListService
   */
  deleteUser(userId: number): Observable<any> {
   return this.http.Delete(`User/${userId}`)
  }

  /**
   * add user
   *
   * @param {*} data
   * @return {*}  {Observable<any>}
   * @memberof UserListService
   */
  addUser(data:any): Observable<any>{
    return this.http.Post(`customRegistration`,data, this.reqOpt).pipe() as Observable<any>
  }

  /**
   * update user
   *
   * @param {number} userId
   * @param {*} data
   * @return {*} 
   * @memberof UserListService
   */
  updateUser(userId:number, data:any){
   return this.http.Put(`User/${userId}`, data, this.reqOpt)
  }

  /**
   * export user list
   *
   * @return {*}  {Observable<any>}
   * @memberof UserListService
   */
  exportUser(): Observable<any>{
    this.siteData = JSON.parse(sessionStorage.getItem('siteData') || '{}');
    this.companyId = this.siteData['companyId']
    return this.http.Post(`company/${this.companyId}/requestUserExport`,this.reqOpt);
  }
}
