<div class="create-edit-dialog">
    <h3 mat-dialog-title>{{title}}</h3>
    <mat-dialog-content class="mat-typography">
        <div *ngIf="groupRowData.length > 0" class="ag-grid-angular mat-elevation-z6 mx-2 mt-2">
            <ag-grid-angular 
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid user-report-ag-grid" [rowData]="groupRowData" [statusBar]="statusBar" [defaultColDef]="defaultColDef"
                (gridReady)="onGroupGridReady($event)" [columnDefs]="groupColumnDefs" [headerHeight]="40"
                rowSelection="multiple">
            </ag-grid-angular>
          </div>
          <div *ngIf="documentRowData.length > 0" class="ag-grid-angular mat-elevation-z6 mx-2 mt-2">
            <ag-grid-angular 
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid user-report-ag-grid" [rowData]="documentRowData" [statusBar]="statusBar" [defaultColDef]="defaultColDef"
                (gridReady)="onDocumentGridReady($event)" [columnDefs]="documentColumnDefs" [headerHeight]="40"
                rowSelection="multiple">
            </ag-grid-angular>
          </div>  
          <div *ngIf="osdMaterialRowData.length > 0" class="ag-grid-angular mat-elevation-z6 mx-2 mt-2">
            <ag-grid-angular 
                [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
                class="ag-grid user-report-ag-grid" [rowData]="osdMaterialRowData" [statusBar]="statusBar" [defaultColDef]="defaultColDef"
                (gridReady)="onOsdMaterialGridReady($event)" [columnDefs]="osdMaterialColumnDefs" [headerHeight]="40"
                rowSelection="multiple">
            </ag-grid-angular>
          </div> 
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" [disabled]="disableButton" (click)="closeDialogRef()">Cancel</button>
    <button mat-raised-button *ngIf="osdMaterialRowData.length === 0" class="confirm-button disabled:pointer-events-none" [disabled]="disableButton" (click)="transferItem()" cdkFocusInitial>Transfer</button>
    <button mat-raised-button *ngIf="osdMaterialRowData.length > 0" class="confirm-button disabled:pointer-events-none" [disabled]="disableButton" (click)="detachOsdMaterial()" cdkFocusInitial>Detach Material</button>
    </mat-dialog-actions>
</div>