<!-- <div class="footer text-center trackem-grey pb-1 pt-1 mb-1, text-xs">
  &copy; 2014 - {{ currYear }} Trackem Pty. Ltd.
  <div>(Build Version: {{version}})</div>
</div> -->
<!-- <div class="text-center trackem-grey py-1 mb-1, text-xs">
  &copy; 2014 - {{ currYear }} Trackem Pty. Ltd.
  <div>(Build Version: {{version}})</div>
</div> -->
<div class="confirmDialog">
  <span mat-dialog-title class="text-lg" fxLayoutAlign="left center">About</span>
  <span mat-dialog-content class="text-center trackem-grey py-2 text-xs">
    &copy; 2014 - {{ currYear }} Trackem Pty. Ltd.
    <div>(Build Version: {{version}})</div>
  </span>
  <span mat-dialog-actions fxLayoutAlign="center">
      <button mat-flat-button class="actionButton" mat-dialog-close> Ok </button>
  </span>
</div>