import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { AppComponent } from '../app.component';
import { ConfigService } from '../services/common/config.service';


@Injectable()
export class CheckMaintenanceModeInterceptor implements HttpInterceptor {

    /**
     *
     */
    constructor(private configService: ConfigService) {
        
    }
     intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.configService.checkMaintenanceSub.next(true);
        return next.handle(request)
    }

}