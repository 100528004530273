import { AssetService } from 'src/app/services/assets/asset.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { faAdd, faCartPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { LoaderService } from 'src/app/services/common/loader.service';
import { AssetsDetailsComponent } from '../assets-details/assets-details.component';
import { IAssetDataModel } from 'src/app/view-models/asset-data-model';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { CartService } from 'src/app/services/cart/cart.service';
import { Subject, Subscription, debounceTime, distinctUntilChanged, filter, forkJoin, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ISiteModel } from 'src/app/view-models/site-model';
import { UserRolesService } from 'src/app/services/user-roles/user-roles.service';
import { moduleEnum } from 'src/app/common/utilities';
import { ExportService } from 'src/app/services/export/export.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'src/app/common/message-dialog/message-dialog.component';
import { storeNames } from 'src/app/common/utilities';
import { SitesService } from 'src/app/services/sites/sites.service';
import { ConfirmationDialogComponent } from 'src/app/common/confirmation-dialog/confirmation-dialog.component';
import { SnackBarService } from 'src/app/services/common/snackbar.service';
import { ReportsService } from 'src/app/services/reports/reports.service';

@Component({
  selector: 'app-assets-search',
  templateUrl: './assets-search.component.html',
})

export class AssetsSearchComponent implements OnInit, OnDestroy {
  faAdd = faAdd;
  faSearch = faSearch;
  faCartShopping = faCartPlus;
  canSearch: boolean = false;
  assetsNumber: string = '';
  isCartCheck = false;
  exportDisabled: boolean = false;
  loadExportIcon: boolean = true;

  private destroy$ = new Subject<void>();
  /** asset details */
  assetsDetails!: IAssetDataModel[];

  /** search asset data form control */
  searchAssetsFC!: FormControl;

  /** selected asset data */
  selectedAssetsData!: IAssetDataModel[];

  /** search button disabled */
  searchButtonDisabled = true;

  /** site data */
  siteData!: ISiteModel;

  selectedRow = 0;

  /** subscription handler */
  subs!: Subscription;

  /** boolean value to set select all button enable or disable*/
  toggle = false;

  /** check if the api is called once or not */
  apiCalled = false;
  assessmentApiCalled = false;

  /** asset searched indicator */
  assetSearched = true;
  assetSearchedWithParams = false;

  /** column moved value */
  columnMoved = {
    reset: false,
    save: false
  };

  /** clear selected rows */
  clearSelectedRows!: boolean;

  /** total count */
  totalCount: number = 0; 

  isDisabled!: boolean;

  /** start row */
  startRow: number = 0;

  /** end row */
  endRow: number = 0;
  
  /** disable load */
  disableLoad: boolean = false;

  cheackSearchInputField = false;
  
  constructor(public assetService: AssetService, public loaderService: LoaderService, public assetdetail: AssetsDetailsComponent, private siteService: SitesService, private snackBarService: SnackBarService,
    private dbService: NgxIndexedDBService, private cartService: CartService, private userRolesService: UserRolesService, public exportService: ExportService, private matDialog: MatDialog, private userReportService: ReportsService) {
    this.searchAssetsFC = new FormControl('');
    this.subs = new Subscription();
  }

  ngOnInit(): void {
    this.siteData = JSON.parse(sessionStorage.getItem('siteData') as string);
   const subInterval = setInterval(() => {
      if (!this.siteData) {    
        this.siteData = JSON.parse(sessionStorage.getItem('siteData') as string);
      } else {
       clearInterval(subInterval);
        this.isDisabled = true;
        if (!!this.assetService.getSearchedAsset()) {
          this.searchAssetsFC.setValue(this.assetService.getSearchedAsset());
          this.searchButtonDisabled = false;      
        }
        this.isDisabled = true;
        
        this.getSearchedAssetData();
        const sub = this.assetService.refreshAsset.subscribe((res) => {
          if(Boolean(res)) {
            this.getSearchedAssetData();
          }
          
          if (this.siteData && !this.assessmentApiCalled) {
            this.assessmentApiCalled = true;
          }
        });
      }
      }, 1000);
      
    this.subs.add(this.assetService.saveOrResetSub.pipe(filter(res => res === 'resetData')).subscribe(() => {
      this.siteData = JSON.parse(sessionStorage.getItem('siteData') as string);
    }));

    this.userReportService.moduleReportSub.next(moduleEnum.asset)
    this.searchAssetsFC.valueChanges
    .pipe(debounceTime(500), distinctUntilChanged())
    .subscribe(() => {
      this.cheackSearchInputField ? this.getSearchedAssetData() : null;
    })

    this.assetService.AssetRowData
    .pipe(takeUntil(this.destroy$))
    .subscribe((res : any) => {
      this.selectedAssetsData = res.filter((x : any) => x.selected == true);
      this.selectedRow = this.selectedAssetsData.length;
      if (this.selectedAssetsData.length) {
        this.isCartCheck = true;
      } else {
        this.isCartCheck = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * add assets to cart
   *
   * @memberof AssetsSearchComponent
   */
  addAssetsToCart(): void {
    this.selectedRow = 0;
    this.loaderService.show()
    let assetData = this.selectedAssetsData;
    this.assetService.deselectAsset.next(true)
    const assetDetails = assetData.map(asset => this.dbService.add('assetTable', {
      assetNumber: asset.assetNumber,
      description: asset.description,
      barcode: asset.barcode,
      assetIds: asset.id,
      hiringAndRate: 0,
      availableQuantity: asset.availableQuantity,
      siteId: asset.siteId
    }));
    this.subs.add(forkJoin(assetDetails).subscribe());

    const storeNames$ = storeNames.map(element => this.dbService.count(element));
    this.subs.add(forkJoin(storeNames$).subscribe((res) => {
      const totalCount = res.reduce((prev, curr) => prev + curr, 0);
      this.cartService.cart.next(totalCount);
      localStorage.setItem('cartCount', JSON.stringify(totalCount));
      this.loaderService.hide()
    }));
    this.clearSelectedRows = true;
    this.isCartCheck = false;
  }

  /**
   * clear searched value
   *
   * @memberof AssetsSearchComponent
   */
  clearSearchedValue() {
    this.searchAssetsFC.setValue(null);
    this.clearSearchedAsset();
    this.getSearchedAssetData();
  }
 
  /**
   * column moved
   *
   * @memberof AssetsSearchComponent
   */
  columnMovedEvent(movedEventValue: {reset: boolean, save: boolean}) {
    movedEventValue.save? this.isDisabled = false : this.isDisabled = true;
    setTimeout(() => {
      this.columnMoved = movedEventValue;   
    }, 1000);
  }

  /**
   * check keys to call search api
   * @param event
   */
  onKeyUp(event: KeyboardEvent): void {
    if (!!this.searchAssetsFC.value) {
      this.searchButtonDisabled = false;
      if (event.keyCode === 13) {
        this.getSearchedAssetData();
      }  
    } else {
      this.searchButtonDisabled = true;
    }
    (event.keyCode == 8 || event.keyCode == 46) ? this.cheackSearchInputField = true : this.cheackSearchInputField = false;
  }

  /**
   * search stock items data
   *
   * @memberof StockItemsSearchComponent
   */
  getSearchedAssetData(): void {
    this.startRow = 0;
    this.endRow = 100;
    this.disableLoad = true;
    this.assetSearched = true;
    let reqBody : any = {
      startRow: this.startRow,
      endRow: this.endRow
      }
    if (this.searchAssetsFC.value) {
      this.assetSearchedWithParams = true;
      this.startRow = 0;
      this.endRow = 100;
      reqBody['search'] = this.searchAssetsFC.value;
    }
    this.assetService.setSearchedAsset(this.searchAssetsFC.value);
    const searchedAssets = this.assetService.getSearchAsset(this.siteData.id, reqBody).subscribe((res: any) => {
      this.totalCount = res.count;
      this.assetsDetails = res.rows;
      this.disableLoad = false;
      if(this.selectedAssetsData != undefined){
        this.selectedAssetsData.forEach(r => {
          var checkAsset = this.assetsDetails.findIndex(a => a.id === r.id)
          if(checkAsset >= 0){
            this.assetsDetails.splice(checkAsset, 1);
          }
          if(r.siteId === this.siteData.id){
            this.assetsDetails.unshift(r)
          }
        })
      }
      this.assetSearched = false;
    });
    this.subs.add(searchedAssets);
  }

  /**
   * clear searched assets data
   *
   * @memberof AssetsSearchComponent
   */
  clearSearchedAsset(): void {
    this.assetSearchedWithParams ? this.getSearchedAssetData() : '';
    this.assetSearchedWithParams = false;
    this.assetService.setSearchedAsset('');
  }

  /**
   * check if user has access of this module
   *
   * @return {*}  {boolean}
   * @memberof AssetsSearchComponent
   */
  checkUserAccess(): boolean {
    return this.userRolesService.checkModuleAccess(moduleEnum.asset);
  }
  
  checkAdminRole(){
    return this.userRolesService.checkAdminRole();
  }
  
  exportData(): void {
    this.loadExportIcon = false;
    this.exportDisabled = true;
    this.assetService.requestCsvExport(this.siteData.id).subscribe(()=>{
      this.matDialog.open(MessageDialogComponent,{
        width : "350px",
        data : {
          title : "Asset CSV Export",
          content: "Your CSV request has been submitted. You will receive an email with the data shortly."
        }
      })
      this.loadExportIcon = true;
      this.exportDisabled = false;
    })
  }

  exportIconLoader(value: boolean){
    this.exportDisabled = value;
  }

  deleteAsset(){
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: 'fit-content',
      disableClose: true,
      data: 'deleteItem'
    });

    dialogRef.componentInstance.confirmMessage = `Delete Asset?`
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const selectedAssetsList = this.selectedAssetsData;
        const assetIds = selectedAssetsList.map((asset: any) => asset?.id);

        const archivedAsset = this.siteService.deleteItems(assetIds, moduleEnum.asset).subscribe(result => {
          assetIds.forEach((assetId: number) => {
            var assetIndex = selectedAssetsList.findIndex((a: IAssetDataModel) => +(a.id as number) == +assetId);
            if (assetIndex >= 0) {
              let prevScannedData = JSON.parse(localStorage.getItem('scannedAssetData') as any);

              if (prevScannedData !== null) {
                let scannedAssetIndex = prevScannedData.findIndex((q: any) => q.id == +assetId);
                if(scannedAssetIndex >= 0){
                  prevScannedData.splice(scannedAssetIndex, 1)
                  localStorage.setItem('scannedAssetData', JSON.stringify(prevScannedData))
                }
              }
              
              selectedAssetsList.splice(assetIndex, 1);
              this.selectedAssetsData = selectedAssetsList;

              const deleteCartByKey = this.dbService.deleteByKey('assetTable', assetId).subscribe();
              this.subs.add(deleteCartByKey);
          
              const stores = storeNames.map(storeName => this.dbService.count(storeName));
              forkJoin(stores).subscribe(cartData => {
                const totalItems = cartData.reduce((prev, curr) => prev + curr, 0);
                this.cartService.cart.next(totalItems);
                localStorage.setItem('cartCount', JSON.stringify(totalItems));
              })
          
              this.getSearchedAssetData()
            }else{
              this.getSearchedAssetData()
            }
            this.assetService.deselectAsset.next(true);              
          });
          this.snackBarService.showMessage('Asset(s) deleted successfully', 'successSnack');
         });
        this.subs.add(archivedAsset);
        this.isCartCheck = false;
      }
    })
  }
}
