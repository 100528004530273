import { Component, OnInit } from '@angular/core';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';
import { CartComponent } from '../cart.component';

@Component({
  selector: 'app-delete-cart-data',
  templateUrl: './delete-cart-data.component.html'
})
export class DeleteCartDataComponent implements OnInit {
  faTrash = faTrash;

  /** agGrid Params */
  agParams!: ICellRendererParams;

  /** cart data id */
  cartDataId!: number;

  /** item type to delete */
  itemType!: string;

  constructor(public cart: CartComponent) { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {    
    this.agParams = params;

    var cartData = params.node.data;
    if( cartData.hasOwnProperty('assetIds') ){
      this.cartDataId = cartData.assetIds
      this.itemType = 'asset'
    }
    else if ( cartData.hasOwnProperty('materialIds') ) {
      this.cartDataId = cartData.materialIds
      this.itemType = 'materials'
    }
    else if ( cartData.hasOwnProperty('stockIds')) {
      this.cartDataId = cartData.stockIds
      this.itemType = 'stockItems'
    }
    else if ( cartData.hasOwnProperty('groupId')) {
      this.cartDataId = cartData.groupId
      this.itemType = 'groups'
    }
    else if ( cartData.hasOwnProperty('documentId')) {
      this.cartDataId = cartData.documentId
      this.itemType = 'document'
    } 
    else if ( cartData.hasOwnProperty('documentV2Id')) {
      this.cartDataId = cartData.documentV2Id
      this.itemType = 'documentsV2'
    }
  }
}
