import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, shareReplay } from 'rxjs';
import { ISiteModel } from 'src/app/view-models/site-model';
import { CustomHttpClientService } from '../common/custom-http-client.service';
import { IRequestOptions } from 'src/app/view-models/request.options';

@Injectable({
  providedIn: 'root'
})
export class SitesService {

  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  cachedSite$?: Observable<ISiteModel[]>;

  constructor(private http: CustomHttpClientService) { }

  getSites(companyId: string): Observable<ISiteModel[]> {

    if (!this.cachedSite$) {
      this.cachedSite$ = this.http.Get<ISiteModel[]>(`Company/${companyId}/GetAllSitesWithoutPaging`).pipe(map((result) => {
        return result;
      }), shareReplay(1));
    }

    return this.cachedSite$;
  }

  deleteItems(itemIds: number[], moduleId: number): Observable<any> {
    return this.http.Put(`Module/${moduleId}/DeleteItems`, itemIds, this.reqOpt)
  }

}
