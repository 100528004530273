import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxPowerBiService } from 'ngx-powerbi';
import { IEmbedConfiguration, models } from 'powerbi-client';
import { AzureMonitoringService } from 'src/app/common/logging/logging.service';
import { moduleEnum } from 'src/app/common/utilities';
import { LoaderService } from 'src/app/services/common/loader.service';
import { SnackBarService } from 'src/app/services/common/snackbar.service';
import { ReportsService } from 'src/app/services/reports/reports.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],

})

export class DashboardComponent implements OnInit {

  private powerBiService: NgxPowerBiService;
  private pbiContainerElement!: HTMLElement;

  /** module id */
  moduleId!: number;

  /** user id */
  userId!: string;

  constructor(
    private reportService: ReportsService,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private appInsights: AzureMonitoringService
  ) {
    this.powerBiService = new NgxPowerBiService();
  }

  ngOnInit(): void {
    let moduleName: string;
    this.route.queryParams.subscribe(params => {
      if (params['module'].toLowerCase() == 'assets') {
        this.moduleId = moduleEnum.asset
        moduleName = "Asset"
      } else if (params['module'].toLowerCase() == 'materials') {
        this.moduleId = moduleEnum.material
        moduleName = "Material"
      }
      this.pbiContainerElement = <HTMLElement>(document.getElementById('reportContainer'));
      this.userId = localStorage.getItem('userId') as string;
      let siteData = JSON.parse(localStorage.getItem('siteData') as string)
      this.appInsights.trackEvent("ViewAssetDashboard", siteData.siteName, {})
      this.reportService.getAllUserPbiReports(this.userId).subscribe(r => {
        let reports = r.filter(el => el.isDashboard == true && +el.moduleId === +this.moduleId);
        if (reports.length > 0) {
          const companyName = localStorage.getItem('companyName') as string;
          this.reportService.getEmbedReport(reports[0].powerBIReportGuid, companyName, moduleName).then(re => {
            const config: IEmbedConfiguration = {
              type: 'report',
              id: re.id,
              embedUrl: re.embedUrl,
              accessToken: re.embedToken.token,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    visible: false
                  }
                }
              },
            };
            this.powerBiService.embed(this.pbiContainerElement, config);
            this.loaderService.hide();
          })
        } else {
          this.loaderService.hide();
          const message = `You are not authorized to access this page.`;
          this.snackBarService.showMessage(message, 'errorSnack');
        }
      });
    });
  }
}