<div class="ag-grid-angular mat-elevation-z6 mx-2 mt-2">
  <ag-grid-angular
    [ngClass]="{'ag-theme-balham-dark': loaderService.themeToggleSubject.value === 'dark', 'ag-theme-balham':  loaderService.themeToggleSubject.value === 'light'}"
    class="ag-grid" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [statusBar]="statusBar" [headerHeight]="40" rowSelection="multiple"
    (gridReady)="onGridReady($event)" [suppressRowClickSelection]="true"  (rowSelected)="rowSelected($event)">
  </ag-grid-angular>

  <div>
    <div class="float-left"></div>
    <div [ngClass]="{'text-white': loaderService.themeToggleSubject.value === 'dark', 'text-black':  loaderService.themeToggleSubject.value === 'light'}" class="pt-2 text-xs float-right pr-2 load-more-box" *ngIf="!!rowData?.length" >
        {{rowData?.length}} of total {{totalCount}} items
        <span *ngIf="totalCount > 100 && rowData.length !== totalCount">
            <a (click)="!disableLoad ? loadAsset() : null" class="pl-3 text-blue-600 load-more-button">Load more</a>
            <a (click)="!disableLoad ? loadAsset(true) : null" matTooltip="Loading more data may slow down the search. This depends on the data size." class="pl-3 text-green-600 load-more-button">Load All</a>
        </span>
    </div>
    <div class="clear-both"></div>
  </div>

  <button *ngIf="checkUserAccess && checkInOutTab" mat-flat-button matTooltip="Check-In/Check-Out" class="actionButton sideGridTab" (click)="checkInOut()">Asset Items ({{scannedCount}})</button>
</div>