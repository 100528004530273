import { Component, OnInit } from '@angular/core';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-material-number-renderer',
  templateUrl: './material-number-renderer.component.html'
})
export class MaterialNumberRendererComponent implements OnInit {

  data: any;

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.data = params; 
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

  }

  constructor(
  ) {
  }

  ngOnInit() {
  }
}
