import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';
import { CartService } from 'src/app/services/cart/cart.service';
import { IOsdTypes } from 'src/app/view-models/materials-model';

@Component({
  selector: 'app-cart-material-osd',
  templateUrl: './cart-material-osd.component.html'
})
export class CartMaterialOsdComponent implements OnInit {

  /** agGrid Params */
  agParams!: ICellRendererParams;

  errorMessage: string = '';

  disableNextButton: boolean = false;
  
  selectedOsdType!: number;


  constructor(private cartServie: CartService) {
  }

  ngOnInit(): void {
    this.disableNextButton = false;
  }
  agInit(params: ICellRendererParams): void {
    this.agParams = params;
    this.selectedOsdType = this.agParams?.data?.osdType.toString();
    this.osdValidation(+this.selectedOsdType);
  }

  actionSelectionChanged(selectedValue: string): void {
    this.osdValidation(+selectedValue);

    this.agParams.setValue(selectedValue);
    this.agParams.refreshCell()

    this.agParams.data.osdType = selectedValue;
    this.cartServie.setDisableValue(false);
  }

  osdValidation(selectedValue: number){
    if (this.agParams?.data?.osdTypeIds?.findIndex((fm: string | number) => +fm === +selectedValue) >= 0 ) {
      this.errorMessage = 'Invalid'
    } else {
      this.errorMessage = ''
    }
  }
}
