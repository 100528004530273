import { Component, OnInit } from '@angular/core';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-cart-input-field',
  templateUrl: './cart-input-field.component.html',
})
export class CartInputFieldComponent implements OnInit  {
  /** faEdit icon */
  faEdit = faEdit;
  disabled = false;

  /** agGrid Params */
  agParams!: ICellRendererParams;

  constructor() {
  }

  ngOnInit(): void { }

  agInit(params: any): void {
    this.agParams = params;
    this.disabled = params.getDisabled ? params.getDisabled() : params.disabled
  }

  /**
   *enable editable feature in cell
   *
   * @memberof CartInputFieldComponent
   */
  editQty() {
    this.agParams.api.startEditingCell({
      rowIndex: this.agParams.rowIndex,
      colKey: this.agParams.column!.getId()
    });
  }

  refresh(params: any) {
    this.disabled = params.getDisabled ? params.getDisabled() : params.disabled ;
    this.agParams.api.stopEditing();
    return true;
  }
}
