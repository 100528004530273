import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { IDocumentItemsDataModel } from 'src/app/view-models/cart-data-model';
import { IAPIResponse, IDocumentTypeData } from 'src/app/view-models/common.model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { environment } from 'src/environments/environment';
import { CustomHttpClientService } from '../common/custom-http-client.service';
import { IDocument, IDocumentDataList, ISaveUpdateDocument } from 'src/app/view-models/documents-model';
import { CreateDocumentRequest } from 'src/app/view-models/create-document-model';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  refreshDocumentSubGrid = new BehaviorSubject('');

  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  /** save/reset column layout */
  saveResetColumnLayout = new BehaviorSubject('');

  /** searched document value */
  private searchedDocumentValue!: string;

  private isValid = new BehaviorSubject<boolean>(true);
  isValidForm = this.isValid.asObservable();
  
  edit(isValid:any = true){
    this.isValid.next(isValid); 
  }

  constructor(private http: CustomHttpClientService) { }

  /**
   * set searched value
   *
   * @param {string} searchedValue
   * @memberof DocumentsService
   */
  setSearchDocumentValue(searchedValue: string): void {
    this.searchedDocumentValue = searchedValue;
  }

  /**
   * get searched document value
   *
   * @return {*}  {string}
   * @memberof documentsService
   */
  getSearchedValue(): string {
    return this.searchedDocumentValue;
  }
  
  createDocument(siteId: number, reqBody: CreateDocumentRequest): Promise<void> {
    return this.http.Post<void>(`site/${siteId}/createDocument`, reqBody, this.reqOpt).pipe(
      map(() => {
        return;
      })
    ).toPromise();
  }

  updateDocument(documentId: number, reqBody: ISaveUpdateDocument, siteId: number): Observable<IDocument> {
    return this.http.Put(`site/${siteId}/updateDocument/${documentId}`, reqBody, this.reqOpt) as Observable<IDocument>;
  }

  /**
   * get searched documents data
   *
   * @return {*}  {Observable<any>}
   * @memberof DocumentsService
   */
  getSearchedDocumentsData(siteId: number, reqbody: any): Observable<IAPIResponse<any>> {
    return this.http.Post(`site/${siteId}/searchDocuments`, reqbody, {
      showLoadingIndicator: true,
    }) as Observable<IAPIResponse<IDocument[]>>;
  }

  getDocumentDataById(documentId: number): Observable<IDocument> {
    return this.http.Get(`Document/${documentId}`, this.reqOpt) as Observable<IDocument>;
  }

  getAllDocumentData(siteId: number): Observable<IDocumentDataList[]> {
    return this.http.Get(`site/${siteId}/GetAllDocumentWithoutPaging`, this.reqOpt) as Observable<IDocumentDataList[]>;
  }

  AddDocumentItemsMapping(documentId: number, reqBody: IDocumentItemsDataModel): Observable<IDocumentItemsDataModel> {
    return this.http.Post(`document/${documentId}/AddDocumentItemsMapping`, reqBody, this.reqOpt) as Observable<IDocumentItemsDataModel>;
  }

  getDocumentTypeData(companyId: number): Observable<IDocumentTypeData[]> {
    return this.http.Get(`company/${companyId}/GetAllDocumentType`, this.reqOpt) as Observable<IDocumentTypeData[]>;
  }

  /**
   * delink document item mapping by id
   * 
   * @param {number} id 
   * @returns 
   */
  delinkDocumentItemsMappingById(id: number): Observable<any> {
    return this.http.Put(`DelinkDocumentMaterialMapping/${id}`, this.reqOpt)
  }

  archiveDocuments(ids: number[]): Observable<any> {
    return this.http.Put(`ArchiveDocuments`, ids, this.reqOpt)
  }

  printDocument(documentId: number, userId: string): Observable<any> {
    var options: IRequestOptions = {
      responseType: 'text' as 'json',
      showLoadingIndicator: true,
      fullPath: true,
      params: {
        documentId: documentId,
        userId: userId,
        code: environment.printFunction.key
      }
    }
    
    return this.http.Get<any>(`${environment.printFunction.apiUrl}/PrintDocument`, options);
  }
}
