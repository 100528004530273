import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import { finalize, Subscription, tap } from 'rxjs';
import { CartService } from 'src/app/services/cart/cart.service';
import { LoaderService } from 'src/app/services/common/loader.service';
import { IBulkTransferItem, IBulkTransferItemTable } from 'src/app/view-models/cart-data-model';
import { IGridColumns } from 'src/app/view-models/common.model';
import { ISiteModel } from 'src/app/view-models/site-model';

@Component({
  selector: 'app-bulk-transfer-details',
  templateUrl: './bulk-transfer-details.component.html'
})
export class BulkTransferDetailsComponent implements OnInit {
  /** default column definition */
  defaultColDef: ColDef;

  /** ag grid status bar */
  statusBar!: {
    statusPanels: any[];
  };

  /** column definitions */
  groupColumnDefs!: ColDef[];

  /** column definitions */
  documentColumnDefs!: ColDef[];

  /** column definations */
  osdMaterialColumnDefs!: ColDef[];

  /** materials ag grid headers */
  bulkTransferHeader!: ColDef[];

  /** column document data */
  documentDataHeader!: ColDef[];

  /** column material data */
  osdMaterialDataHeader!: ColDef[];

  /** get materials details to display in grid */
  @Input() bulkTransferDetails: any;

  /** row data */
  groupRowData: any[] = [];

  /** row data */
  documentRowData: any[] = [];

  /** material row data */
  osdMaterialRowData: any[] = []

  /** grid api */
  gridApi!: GridApi;

  /** grid column api */
  gridColumnApi!: ColumnApi;

  /** grid column */
  gridColumn!: IGridColumns;

  /** Subscription Handler */
  subs!: Subscription;

  /** clear selected rows */
  @Input() clearSelectedRows = false;

  /** disable button */
  disableButton = false;

  /** site data */
  siteData!: ISiteModel;

  title: string = 'Do you want to detach the item from the Group/Document and continue with the Transfer?'

  constructor(@Inject(MAT_DIALOG_DATA) public modalData: {data: IBulkTransferItemTable, bulkData: IBulkTransferItem}, public dialogRef: MatDialogRef<BulkTransferDetailsComponent>, public loaderService: LoaderService, private cartService: CartService) {
    this.subs = new Subscription();
    this.siteData = JSON.parse(localStorage.getItem('siteData') as string);

    this.defaultColDef = {
      flex: 1,
      initialWidth: 100,
      resizable: true,
      sortable: false,
      filter: false
    };

    this.statusBar = {
      statusPanels: [
        { statusPanel: "agTotalRowCountComponent", align: "left" }
      ]
    };
   }

  ngOnInit(): void {
    this.groupColumnDefs = this.bulkTransferHeader;
    this.documentColumnDefs = this.documentDataHeader; 
    this.osdMaterialColumnDefs = this.osdMaterialDataHeader;
  }

  ngOnChanges(): void {
    this.bulkTransferHeader = [
      {
        headerName: 'Group',
        field: 'group',
        minWidth: 100,
        floatingFilter: true
      },
      {
        headerName: 'Assets',
        field: 'assetList',
        minWidth: 100,
        floatingFilter: true
      },
      {
        headerName: 'Stock Items',
        field: 'stockItem',
        minWidth: 100,
        floatingFilter: true
      },
      {
        headerName: 'Materials',
        field: 'materialList',
        minWidth: 100,
        floatingFilter: true
      }
    ]   

    this.documentDataHeader = [
      {
        headerName: 'Document',
        field: 'document',
        minWidth: 100,
        floatingFilter: true
      },
      {
        headerName: 'Assets',
        field: 'assetList',
        minWidth: 100,
        floatingFilter: true
      },
      {
        headerName: 'Stock Items',
        field: 'stockItemList',
        minWidth: 100,
        floatingFilter: true
      },
      {
        headerName: 'Materials',
        field: 'materialList',
        minWidth: 100,
        floatingFilter: true
      },
      {
        headerName: 'Groups',
        field: 'groupList',
        minWidth: 100,
        floatingFilter: true
      },
    ] 

    this.osdMaterialDataHeader = [
      {
        headerName: 'Material',
        field: 'materialNumbers',
        minWidth: 100,
        floatingFilter: true
      },
      {
        headerName: 'Group',
        field: 'groups',
        minWidth: 100,
        floatingFilter: true,
        cellRenderer: this.groupCellRendrere
      },
      {
        headerName: 'Document',
        field: 'documents',
        minWidth: 100,
        floatingFilter: true,
        cellRenderer: this.documentCellRendrere
      }
    ] 
    this.gridApi?.setColumnDefs(this.documentDataHeader)
    this.gridApi?.setColumnDefs(this.bulkTransferHeader);
    this.gridApi?.setColumnDefs(this.osdMaterialDataHeader);

    if (!!this.bulkTransferDetails) {
      this.groupRowData = this.bulkTransferDetails?.transferData;
      this.documentRowData = this.bulkTransferDetails?.documentTransferedData;
      this.osdMaterialRowData = this.bulkTransferDetails?.materialOsdData;

      if(this.osdMaterialRowData.length > 0){
        this.title = 'These materials have active Osd. Do you want to detach from the group/document?'
      }
    }    

    if (this.clearSelectedRows) {
      this.gridApi.deselectAll();
    }
  }

  documentCellRendrere(params: any){
    if(Array.isArray(params.value)){
      return params.value.map((document: any) => document.documentName).join(', ')
    }
    return params.value
  }
  
  groupCellRendrere(params: any){
    if(Array.isArray(params.value)){
      return params.value.map((group: any) => group.groupName).join(', ')
    }
    return params.value
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * on grid ready
   *
   * @param {GridReadyEvent} params
   * @memberof BulkTransferDetailsComponent
   */
  onGroupGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }

   /**
   * on grid ready
   *
   * @param {GridReadyEvent} params
   * @memberof BulkTransferDetailsComponent
   */
    onDocumentGridReady(params: GridReadyEvent) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.hideOverlay();
    }

   /**
   * on grid ready
   *
   * @param {GridReadyEvent} params
   * @memberof BulkTransferDetailsComponent
   */
     onOsdMaterialGridReady(params: GridReadyEvent) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.hideOverlay();
    }
   
    
   /**
   * transfer item
   *
   * @memberof BulkTransferDetailsComponent
   */
  detachOsdMaterial(): void {
    this.disableButton = true;
    const transferSub = this.cartService.detachOsdMaterial(this.siteData.id,this.modalData.bulkData).pipe(
      tap(() => {
        this.disableButton = false;        
        this.dialogRef.close(true);
      }),
      finalize(() => this.disableButton = false)
    ).subscribe();
    this.subs.add(transferSub);
  }

  closeDialogRef(){
    this.dialogRef.close(false);
  }

   /**
   * transfer item
   *
   * @memberof BulkTransferDetailsComponent
   */
  transferItem(): void {
    this.disableButton = true;
    const transferSub = this.cartService.bulkTransferItems(this.siteData.id, this.modalData.bulkData).pipe(
      tap(() => {
        this.disableButton = false;        
        this.dialogRef.close(true);
      }),
      finalize(() => this.disableButton = false)
    ).subscribe();
    this.subs.add(transferSub);
  }
}
