import { TransactionsService } from './../../services/transactions/transactions.service';
import { ITransactionItemDataModel } from 'src/app/view-models/transactions-model';
import { Component, OnInit } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { LoaderService } from 'src/app/services/common/loader.service';
import { TransactionActionCellRendererComponent } from './transaction-action-cell-renderer/transaction-action-cell-renderer.component';
import * as moment from 'moment';
import { ISiteModel } from 'src/app/view-models/site-model';
import { finalize, tap } from 'rxjs';
import { MessageDialogComponent } from 'src/app/common/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { moduleEnum } from 'src/app/common/utilities';
import { UserRolesService } from 'src/app/services/user-roles/user-roles.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html'
})
export class TransactionsComponent implements OnInit {

  
  columnDefs!:ColDef[];

  rowData: ITransactionItemDataModel[] = []

  siteData!:ISiteModel
  
  defaultColDef:ColDef = {
    filter:false,
    resizable: true,
  }

  gridApi!: GridApi

  /** total count */
  totalCount: any = 0;

  /** start row */
  startRow: number = 0;
    
  /** end row */
  endRow: number = 100;
    
  /** disable load */
  disableLoad: boolean = false;

  exportDisabled: boolean = false;

  /** status bar */
  statusBar!: {
    statusPanels: any[];
  };
  

  constructor(public loaderService:LoaderService,
    public transactionService: TransactionsService,
    private matDialog: MatDialog,
    private userRolesService: UserRolesService) {
    
    this.statusBar = {
      statusPanels: [
        { statusPanel: "agTotalRowCountComponent", align: "left" },
      ]
    };
    }

  ngOnInit(): void {
    
    this.siteData = JSON.parse(sessionStorage.getItem("siteData") as string)
    this.columnDefs = [
      {
        headerName: "Action",
        field: "",
        pinned:"left",
        cellRendererFramework: TransactionActionCellRendererComponent,
        minWidth:80,
        width:80
      },
      {
        headerName: "Item Number",
        field: "itemNumber",
        pinned:"left"
        
      },
      {
        headerName: "Transaction No.",
        field: "transactionId",
        pinned:"left",
        width: 130,
        minWidth: 130
      },
      {
        headerName: "Transaction Type",
        field: "transactionType",
        width: 140,
        minWidth: 140
      },
      {
        headerName: "Transaction To",
        field: "transactionTo",
        valueFormatter: (data) => {
          var transactionType = data?.data?.transactionType
          return (transactionType == "CHECKIN" || transactionType == "CHECKOUT") ? data.value : ""
        }
      },
      {
        headerName: "Transaction By",
        field: "transactionBy",
      },
      {
        headerName: "Transaction Date/Time",
        field: "transactionDate",
        valueFormatter: (data)=>{
          return moment(data.value).format("DD/MM/YYYY HH:mm:ss")
        },
        minWidth: 200,
      },
      {
        headerName: "Module",
        field: "module",
        width: 100,
        minWidth: 100
      },
      {
        headerName: "Source",
        field: "transactionSourceType",
        width: 90,
        minWidth: 90
      },
      {
        headerName: "Comments",
        field: "comments",
        flex: 1,
        minWidth: 160
      }
    ]
    this.getTransactionData();
  }

  getTransactionData(startRow: number = 0, endRow: number = 0, loadmore?: boolean){
    this.startRow = this.startRow +  startRow
    this.endRow = this.endRow + endRow
    let reqBody = {
      startRow: this.startRow,
      endRow: this.endRow
    }
    this.disableLoad = true;
    this.transactionService.getPagedTransactions(this.siteData.id, reqBody).pipe(
      tap((res) => {
        this.disableLoad = false;
        loadmore ? this.rowData = [...res.rows, ...this.rowData] : this.rowData = res.rows;
        this.totalCount = res.count
      }),finalize(() => this.disableLoad = false)
    ).subscribe(); 
  }

  checkUserAccess(): boolean {
    return this.userRolesService.checkModuleAccess(moduleEnum.document);
   }

  exportData(): void {
    this.exportDisabled = true;
    this.transactionService.requestCsvExport(this.siteData.id).subscribe(()=>{
      this.matDialog.open(MessageDialogComponent,{
        width : "350px",
        data : {
          title : "Transcation CSV Export",
          content: "Your CSV request has been submitted. You will receive an email with the data shortly."
        }
      })
      this.exportDisabled = false;
    })
  }
}
