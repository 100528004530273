import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAllAssetStockItem, IBulkTransferItem, IBulkTransferItemTable, IBulkUpdateDataModel, ICartDataModel, IUpdateableColumnsDataModel, IOSDItemsDataModel, IGroupTable, IDocumentTable, ICheckItemBelongsToGroupAndDocument, IUpdateStatusData, IUpdateLocationItem, IUpdateLocationItemTable } from 'src/app/view-models/cart-data-model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { ISiteModel } from 'src/app/view-models/site-model';
import { CustomHttpClientService } from '../common/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  /** check cart value */
  cart = new BehaviorSubject(0);

  private disableButton$ = new BehaviorSubject<any>({});
  selectedValueOfDisableButton$ = this.disableButton$.asObservable();
  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  private Celleditor$ = new BehaviorSubject<any>({});
  selectedValueOfCellEditor$ = this.Celleditor$.asObservable();

  setCellEditorValue(val: boolean) {
    this.Celleditor$.next(val);
  }
  constructor(private http: CustomHttpClientService) { }

  setDisableValue(val: boolean) {
    this.disableButton$.next(val);
  }
  getSites(companyId: string): Observable<ISiteModel[]> {
    return this.http.Get(`Company/${companyId}/GetAllSitesWithoutPaging`).pipe() as Observable<ISiteModel[]>;
  }

  cartAction(siteId: number, cartData: ICartDataModel): Observable<ICartDataModel> {
    return this.http.Post(`site/${siteId}/CartAction`, cartData, this.reqOpt) as Observable<ICartDataModel>;
  }

  getAllAssetStocks(siteId: number, searchValue: string): Observable<IAllAssetStockItem> {
    return this.http.Get(`site/${siteId}/GetAllAssetsAndStockItems?Search=${searchValue}`) as Observable<IAllAssetStockItem>
  }

  getUpdateableColumns(siteId: number, moduleId: number,): Observable<IUpdateableColumnsDataModel[]> {
    return this.http.Get(`site/${siteId}/getUpdateableColumns/${moduleId}`) as Observable<IUpdateableColumnsDataModel[]>
  }
  bulkUpdate(siteId: number, itemsData: IBulkUpdateDataModel): Observable<IBulkUpdateDataModel> {
    return this.http.Post(`site/${siteId}/updateBulkItems`, itemsData, this.reqOpt) as Observable<IBulkUpdateDataModel>;
  }

  /**
   * check item belongs to group
   * 
   * @param {number} siteId
   * @param {IBulkTransferItem} itemsData
   * @return {*}  {Observable<IBulkTransferItemTable>}
   * @memberof CartService
   */
  checkItemBelongsToGroupThenTransfer(siteId: number, itemsData: IBulkTransferItem): Observable<ICheckItemBelongsToGroupAndDocument[]> {
    return this.http.Post(`site/${siteId}/checkItemBelongsToGroupThenTransfer`, itemsData, this.reqOpt) as Observable<ICheckItemBelongsToGroupAndDocument[]>;
  }

  /**
   * bulk transfer assets, materials and stock items
   *
   * @param {number} siteId
   * @param {IBulkTransferItem} itemsData
   * @return {*}  {Observable<IBulkTransferItemTable[]>}
   * @memberof CartService
   */
  bulkTransferItems(siteId: number, itemsData: IBulkTransferItem): Observable<IBulkTransferItemTable[]> {
    return this.http.Post(`site/${siteId}/BulkTransferItems`, itemsData, this.reqOpt) as Observable<IBulkTransferItemTable[]>;
  }

  createOSDMaterialsMapping(siteId: number, itemsData: FormData): Observable<IOSDItemsDataModel> {
    return this.http.Post(`site/${siteId}/createOSDMaterialsMapping`, itemsData, this.reqOpt) as Observable<IOSDItemsDataModel>;
  }

  checkGroupBulkTransfer(siteId: number, itemsData: number[]) {
    return this.http.Post(`site/${siteId}/checkGroupBulkTransfer`, itemsData, this.reqOpt) as Observable<IGroupTable[]>;
  }

  checkDocumentBulkTransfer(siteId: number, itemsData: number[]) {
    return this.http.Post(`site/${siteId}/checkDocumentBulkTransfer`, itemsData, this.reqOpt) as Observable<IDocumentTable[]>;
  }

  bulkUpdateStatus(siteId: number, itemsData : IUpdateStatusData){
    return this.http.Post(`site/${siteId}/bulkUpdateStatus`, itemsData, this.reqOpt) as Observable<IUpdateStatusData[]>; 
  }

  checkActiveOsdMaterial(reqBody: any){
    return this.http.Post(`CartAction/CheckMaterialWithActiveOsd`, reqBody, this.reqOpt)
  }

  detachOsdMaterial(siteId: number, reqBody: any) {
    return this.http.Post(`site/${siteId}/DetachMaterial`, reqBody, this.reqOpt)
  }

  updateLocationItems(siteId: number, itemsData: IUpdateLocationItem): Observable<IUpdateLocationItemTable[]> {
    return this.http.Post(`site/${siteId}/UpdateLocation`, itemsData, this.reqOpt) as Observable<IUpdateLocationItemTable[]>;
  }
}