import { CommonInterceptorInterceptor } from './interceptors/common-interceptor.interceptor';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './common/shared.module';
import { SideNavigationComponent } from './common/side-navigation/side-navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './common/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavigationComponent } from './common/navigation/navigation.component';
import { MatIconModule } from '@angular/material/icon';
import { MsalRedirectComponent, MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalBroadcastService, MsalService, MSAL_INTERCEPTOR_CONFIG, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { LoginComponent } from './components/login/login.component';
import { msalConfig, loginRequest } from './auth-config';
// import { ErrorHandlerService } from './common/error-handler-service';
import { CartComponent } from './components/cart/cart.component';
// import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { AssetsSearchComponent } from './components/assets/assets-search/assets-search.component';
import { AssetsDetailsComponent } from './components/assets/assets-details/assets-details.component';
import { TransactionsModule } from './components/transactions/transactions.module';
import { AdminComponent } from './admin/admin.component';
import { ExportService } from './services/export/export.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PbiReportComponent } from './components/pbi-reports/pbi-reports.component';
import { AccessTokenValidInterceptor } from './interceptors/access-token-valid.interceptor';
import { AddXFrameOptionsInterceptor } from './common/add-x-frame-interceptor';
import { MultiCompanyDialogComponent } from './components/multi-company/multi-company.component';

const dbConfig: DBConfig = {
  name: 'trackemDB',
  version: 1,
  objectStoresMeta: [{
    store: 'assetTable',
    storeConfig: { name: 'assetIds', keyPath: 'assetIds', autoIncrement: false, options: { unique: true } },
    storeSchema: [
      { name: 'assetNumber', keypath: 'assetNumber', options: { unique: true } },
      { name: 'assetIds', keypath: 'assetIds', options: { unique: true } },
      { name: 'description', keypath: 'description', options: { unique: false } },
      { name: 'barcode', keypath: 'barcode', options: { unique: false } },
      { name: 'hiringAndRate', keypath: 'hiringAndRate', options: { unique: false } },
      { name: 'availableQuantity', keypath: 'availableQuantity', options: { unique: false } },
      { name: 'siteId', keypath: 'siteId', options: { unique: false } }
    ]
  },
  {
    store: 'stockItemsTable',
    storeConfig: { name: 'stockIds', keyPath: 'stockIds', autoIncrement: false, options: { unique: true } },
    storeSchema: [
      { name: 'itemNumber', keypath: 'itemNumber', options: { unique: true } },
      { name: 'stockIds', keypath: 'stockIds', options: { unique: true } },
      { name: 'stockDescription', keypath: 'stockDescription', options: { unique: false } },
      { name: 'stockBarcode', keypath: 'stockBarcode', options: { unique: false } },
      { name: 'availableQuantity', keypath: 'availableQuantity', options: { unique: false } },
      { name: 'actionQuantity', keypath: 'actionQuantity', options: { unique: false } },
      { name: 'siteId', keypath: 'siteId', options: { unique: false } }
    ]
  },
  {
    store: 'materialsTable',
    storeConfig: { name: 'materialIds', keyPath: 'materialIds', autoIncrement: false, options: { unique: true } },
    storeSchema: [
      { name: 'materialNumber', keypath: 'materialNumber', options: { unique: false } },
      { name: 'materialIds', keypath: 'materialIds', options: { unique: true } },
      { name: 'materialDescription', keypath: 'materialDescription', options: { unique: false } },
      { name: 'materialBarcode', keypath: 'materialBarcode', options: { unique: false } },
      { name: 'quantity', keypath: 'quantity', options: { unique: false } },
      { name: 'actionQuantity', keypath: 'actionQuantity', options: { unique: false } },
      { name: 'osdType', keypath: 'osdType', options: { unique: false } },
      { name: 'hasPendingOSD', keypath: 'hasPendingOSD', options: { unique: false } },
      { name: 'siteId', keypath: 'siteId', options: { unique: false } }
    ]
  },
  {
    store: 'groupsTable',
    storeConfig: { name: 'groupId', keyPath: 'groupId', autoIncrement: false, options: { unique: true } },
    storeSchema: [
      { name: 'groupName', keypath: 'groupsName', options: { unique: true } },
      { name: 'groupId', keypath: 'groupId', options: { unique: true } },
      { name: 'groupDescription', keypath: 'groupDescription', options: { unique: false } },
      { name: 'barcode', keypath: 'barcode', options: { unique: false } },
      { name: 'siteId', keypath: 'siteId', options: { unique: false } }
    ]
  },
  {
    store: 'documentTable',
    storeConfig: { name: 'documentId', keyPath: 'documentId', autoIncrement: false, options: { unique: true } },
    storeSchema: [
      { name: 'documentName', keypath: 'documentsName', options: { unique: true } },
      { name: 'documentId', keypath: 'documentId', options: { unique: true } },
      { name: 'documentDescription', keypath: 'documentDescription', options: { unique: false } },
      { name: 'barcode', keypath: 'barcode', options: { unique: false } },
      { name: 'siteId', keypath: 'siteId', options: { unique: false } }
    ]
  },
  {
    store: 'documentsV2Table',
    storeConfig: { name: 'documentV2Id', keyPath: 'documentV2Id', autoIncrement: false, options: { unique: true } },
    storeSchema: [
      { name: 'documentName', keypath: 'documentsName', options: { unique: true } },
      { name: 'documentV2Id', keypath: 'documentV2Id', options: { unique: true } },
      { name: 'documentTemplateName', keypath: 'documentTemplateName', options: { unique: false } },
      { name: 'barcode', keypath: 'barcode', options: { unique: false } },
      { name: 'documentStatus', keypath: 'documentStatus', options: {unique: false} },
      { name: 'siteId', keypath: 'siteId', options: { unique: false } }
    ]
  }
]
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SideNavigationComponent,
    FooterComponent,
    NavigationComponent,
    LoginComponent,
    CartComponent,
    AdminComponent,
    DashboardComponent,
    PbiReportComponent,
    MultiCompanyDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    MatIconModule,
    MsalModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    TransactionsModule,
    NgxPowerBiModule,
    ScrollingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenValidInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonInterceptorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddXFrameOptionsInterceptor,
      multi: true
    },
    // { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    SideNavigationComponent,
    NavigationComponent,
    AssetsDetailsComponent,
    AssetsSearchComponent,
    CartComponent,
    ExportService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
