import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, filter, tap } from 'rxjs';
import { LoaderService } from 'src/app/services/common/loader.service';
import { AssetService } from 'src/app/services/assets/asset.service';
import { ISiteModel } from 'src/app/view-models/site-model';
import { GridApi, ColumnApi, ColDef, ValueParserParams } from 'ag-grid-community';
import { IAssetDataModel } from 'src/app/view-models/asset-data-model';
import { SnackBarService } from 'src/app/services/common/snackbar.service';
import { CartInputFieldComponent } from 'src/app/components/cart/cart-input-field/cart-input-field.component';
import { DeleteCheckInCheckOutComponent } from './delete-check-in-check-out/delete-check-in-check-out.component';
import { CheckInCheckOutSelectionFieldsComponent } from './check-in-check-out-selection-fields/check-in-check-out-selection-fields.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

interface modalData {
  headerTitle: string,
  returnValue: any;
}

@Component({
  selector: 'app-check-in-check-out',
  templateUrl: './check-in-check-out.component.html'
})
export class CheckInCheckOutComponent implements OnInit, OnDestroy {

  /** Subscription Handler */
  subs!: Subscription;

  /** site data */
  siteData!: ISiteModel;

  barcodeValue!: any;

  headerTitle!: string;

  gridApi!: GridApi;

  /** grid column api */
  gridColumnApi!: ColumnApi;

  /** default column definition */
  defaultColDef: ColDef;

  /** column definitions */
  columnDefs!: ColDef[];

  /** ag grid status bar */
  statusBar: {
    statusPanels: {
      statusPanel: string;
      align: string;
      key?: string;
    }[];
  }

  actionAllowed: boolean = true;
  /** row data */
  rowData: IAssetDataModel[] = [];

  scannedData: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA)
  public modalData: modalData,
    public dialogRef: MatDialogRef<CheckInCheckOutComponent>,
    public loaderService: LoaderService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private assetService: AssetService) {
    this.subs = new Subscription();

    this.defaultColDef = {
      initialWidth: 150,
      resizable: true,
      sortable: true,
      filter: true
    };

    this.statusBar = {
      statusPanels: [
        { statusPanel: "agTotalRowCountComponent", align: "left" },
      ]
    };

  }
  ngOnDestroy(): void {
    this.dialogRef.close(this.modalData.returnValue);
  }

  ngOnInit(): void {
    this.headerTitle = this.modalData.headerTitle;
    if (!this.siteData) {
      this.siteData = JSON.parse(sessionStorage.getItem('siteData') as string);
    }

    this.getScannedData();

    this.columnDefs = [
      {
        headerName: 'Action',
        colId: 'action',
        pinned: 'left',
        width: 80,
        minWidth: 10,
        cellRendererFramework: DeleteCheckInCheckOutComponent,
        cellRendererParams: {
          clicked: (id: any) => {
            this.deleteScannedEntry(id);
          }
        },
      }, {
        headerName: 'Barcode',
        field: 'barcode',
        floatingFilter: true,
        pinned: 'left',
        minWidth: 90,
        colId: 'barcode'
      },
      {
        headerName: 'Asset Number',
        field: 'assetNumber',
        floatingFilter: true,
        minWidth: 120,
        colId: 'assetNumber'
      },
      {
        headerName: 'Description',
        field: 'description',
        floatingFilter: true,
        minWidth: 120,
        colId: 'description'
      },
      {
        headerName: 'Available Qty.',
        colId: 'availableQuantity',
        field: 'availableQuantity',
        floatingFilter: true,
        minWidth: 120,
      },
      {
        headerName: 'Hiring / Rate (Press enter/tab to save the details.)',
        colId: 'hiringAndRate',
        field: 'hiringAndRate',
        floatingFilter: true,
        editable: true,
        cellRendererFramework: CartInputFieldComponent,
        valueParser: this.actionQtyNumberParser,
        width: 310,
        minWidth: 310
      }

    ]
    this.gridApi?.setColumnDefs(this.columnDefs);

  }

  inputChange() {
    if (this.barcodeValue.trim() !== '') {
      let checkEnteredBarcode = this.rowData.findIndex(r => r.barcode.trim() == this.barcodeValue.trim());
      if (checkEnteredBarcode < 0) {
        let reqBody = {
          startRow: 0,
          endRow: 100,
          search: this.barcodeValue
        }
        this.assetService.getSearchAsset(this.siteData.id, reqBody).subscribe((res: any) => {
          if (res.rows?.length > 0) {
            this.barcodeValue = '';
            if (res.rows[0].availableQuantity < 1) {
              this.actionAllowed = false;
            }
            if(!this.rowData.some(x => x.barcode === res.rows[0].barcode && x.assetNumber === res.rows[0].assetNumber)){
              let newRowData = this.rowData.slice();
              let newRow = res.rows[0];
              newRowData.push(newRow);
              localStorage.setItem('scannedAssetData', JSON.stringify(newRowData))
              this.rowData = newRowData;
            }
          } else {
            this.snackBarService.showMessage(`${this.barcodeValue} Invalid barcode or asset doesn't exit`, 'errorSnack');
            this.barcodeValue = '';
          }
        })
      } else {
        this.barcodeValue = '';
        this.snackBarService.showMessage(`Barcode already added`, 'errorSnack');
      }
    }
  }

  async onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }

  actionQtyNumberParser(params: ValueParserParams): number {
    return isNaN(Number(params.newValue)) ? 0 : Number(params.newValue);
  }

  deleteScannedEntry(id: number) {
    let newRowData = this.rowData.slice();
    let itemIndex = newRowData.findIndex(r => r.id == +id)
    if (itemIndex >= 0) {
      newRowData.splice(itemIndex, 1)
      let findZeroQty = newRowData.findIndex(q => q.availableQuantity == 0);
      findZeroQty >= 0 ? this.actionAllowed = false : this.actionAllowed = true;

      this.rowData = newRowData;
      localStorage.setItem('scannedAssetData', JSON.stringify(newRowData))
    }
  }

  selectionFields(title: string) {
    if (title == 'Check-In') {
      this.submitData(title)
    } else {
      if (this.actionAllowed) {
        this.submitData(title)
      } else {
        this.snackBarService.showMessage(`Check-Out is not allowed for Item(s) with available quantity less than 1`, 'errorSnack');
      }
    }
  }

  getScannedData() {

    let prevScannedData = JSON.parse(localStorage.getItem('scannedAssetData') as any);

    if (prevScannedData !== null) {
      let findZeroQty = prevScannedData.findIndex((q: any) => q.availableQuantity == 0);
      findZeroQty >= 0 ? this.actionAllowed = false : this.actionAllowed = true;
      this.rowData = prevScannedData;
    } else {
      this.clearCartData();
    }
  }

  clearCartData(isClear: boolean = false) {
    if (isClear){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: 'auto',
        width: 'fit-content',
        disableClose: true,
        data: 'emptyCart'
      });
     dialogRef.componentInstance.confirmMessage = 'Empty Cart?';
      
     const dialogClosed = dialogRef.afterClosed().pipe(
      filter(res => res),
      ).subscribe(() => this.emptyCartData());
      this.subs.add(dialogClosed);
    } else {
      this.emptyCartData();
    }
   
  }

  emptyCartData(){
    localStorage.removeItem('scannedAssetData');
    this.rowData = [];
    this.actionAllowed = true;
  }

  submitData(title: string){
    const modalData = {
      headerTitle: title,
      returnValue: "",
      assetList: this.rowData
    };

    const dialogRef = this.dialog.open(CheckInCheckOutSelectionFieldsComponent, {
      height: 'auto',
      width: 'fit-content',
      data: modalData,
      disableClose: true
    });

    const dialogClosed = dialogRef.afterClosed().pipe(
      tap((res) => {
        this.modalData.returnValue = res
        this.getScannedData();
      })
    ).subscribe();
    this.subs.add(dialogClosed);
  }

  closeDialog(res: any){
    this.dialogRef.close(res);
  }
}
