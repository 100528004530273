import { Injectable, ElementRef } from '@angular/core';

const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/csv';

@Injectable()
export class ExportService {
  constructor() { }

  /**
   * Creates an array of data to csv. It will optionally automatically generate title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns display names for column headers
   */
   public exportToCsv(rows: any[], fileName: string, columns: string[]): void {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';

    
    var keys:string[] = [];

    if (columns.length){
      keys = columns;
    }
    else{      
      keys = Array.from(rows[0]);
    }
    
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(x => {
        return Object.values(x).toString()
      }).join('\n');

    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
    
  }
 /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const blob = new Blob([buffer], { type: fileType});
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
  }
  
}
