import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ICartDataModel, IGroupItemsDataModel } from 'src/app/view-models/cart-data-model';
import { IAPIResponse, ICommonDropDownData } from 'src/app/view-models/common.model';
import { IGroup, IGroupDataList, ISaveUpdateGroup } from 'src/app/view-models/groups-model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { environment } from 'src/environments/environment';
import { CustomHttpClientService } from '../common/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  refreshGroupSubGrid = new BehaviorSubject('');

  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  /** save/reset column layout */
  saveResetColumnLayout = new BehaviorSubject('');

  /** searched group value */
  private searchedGroupValue!: string;
  
  groupRowData = new Subject()

  deselectGroup = new Subject();

  constructor(private http: CustomHttpClientService) { }

  /**
   * set searched value
   *
   * @param {string} searchedValue
   * @memberof GroupsService
   */
  setSearchGroupValue(searchedValue: string): void {
    this.searchedGroupValue = searchedValue;
  }

  /**
   * get searched group value
   *
   * @return {*}  {string}
   * @memberof GroupsService
   */
  getSearchedValue(): string {
    return this.searchedGroupValue;
  }

  /**
   * save group data
   *
   * @param {number} siteId
   * @param {*} reqBody
   * @return {*}  {Observable<any>}
   * @memberof GroupsService
   */
  saveGroup(siteId: number, reqBody: ISaveUpdateGroup): Observable<IGroup> {
    return this.http.Post(`site/${siteId}/createGroup`, reqBody, this.reqOpt) as Observable<IGroup>;
  }

  /**
   * update group data
   *
   * @param {number} groupId
   * @param {ISaveUpdateGroup} reqBody
   * @return {*}  {Observable<IGroup>}
   * @memberof GroupsService
   */
  updateGroup(groupId: number, reqBody: ISaveUpdateGroup, siteId: number): Observable<IGroup> {
    return this.http.Put(`site/${siteId}/updateGroup/${groupId}`, reqBody, this.reqOpt) as Observable<IGroup>;
  }

  /**
   * get searched groups data
   *
   * @return {*}  {Observable<any>}
   * @memberof GroupsService
   */
  getSearchedGroupData(siteId: number, reqbody: any): Observable<IAPIResponse<any>> {
    return this.http.Post(`site/${siteId}/searchGroups`, reqbody, {
      showLoadingIndicator: true,
    }) as Observable<IAPIResponse<IGroup[]>>;
  }

  /**
   * get group data by group id
   *
   * @param {number} groupId
   * @return {*}  {Observable<IGroup>}
   * @memberof GroupsService
   */
  getGroupDataById(groupId: number): Observable<IGroup> {
    return this.http.Get(`Group/${groupId}`, this.reqOpt) as Observable<IGroup>;
  }

  /**
  * get group data by group id
  *
  * @param {number} siteId
  * @return {*}  {Observable<IGroup>}
  * @memberof GroupsService
  */
  getAllGroupData(siteId: number): Observable<IGroupDataList[]> {
    return this.http.Get(`site/${siteId}/GetAllGroupWithoutPaging`, this.reqOpt) as Observable<IGroupDataList[]>;
  }

  /**
  * save groupItem into group
  *
  * @param {number} groupId
  * @param {*} reqBody
  * @return {*}  {Observable<any>}
  * @memberof GroupsService
  */
  AddGroupItemsMapping(groupId: number, reqBody: IGroupItemsDataModel): Observable<IGroupItemsDataModel> {
    return this.http.Post(`group/${groupId}/AddGroupItemsMapping`, reqBody, this.reqOpt) as Observable<IGroupItemsDataModel>;
  }

  /**
   * get all group type
   *
   * @param {number} companyId
   * @return {*}  {Observable<IGroup>}
   * @memberof GroupsService
   */
  getGroupTypeData(companyId: number): Observable<ICommonDropDownData[]> {
    return this.http.Get(`company/${companyId}/GetAllGroupType`, this.reqOpt) as Observable<ICommonDropDownData[]>;
  }

  /**
   * get all group templates
   *
   * @param {number} companyId
   * @return {*}  {Observable<IGroup>}
   * @memberof GroupsService
   */
  getGroupTemplates(companyId: number): Observable<ICommonDropDownData[]> {
    return this.http.Get(`company/${companyId}/GetAllGroupTemplates`, this.reqOpt) as Observable<ICommonDropDownData[]>;
  }

  printGroup(groupId: number): Observable<any> {
    var options: IRequestOptions = {
      showLoadingIndicator: true,
      fullPath: true,
      params: {
        groupId: groupId,
        code: environment.printFunction.key,
        clientId: environment.printFunction.clientId
      }
    }
    return this.http.GetBlob(`${environment.printFunction.apiUrl}/PrintGroup`, options)
  }

  /**
  * get all group item mapping by item and module type
  *
  * @param {number} companyId
  * @return {*}  {Observable<IGroup>}
  * @memberof GroupsService
  */
  getGroupItemsMappingByItem(moduleId: number, itemId: number): Observable<any[]> {
    return this.http.Get(`GetGroupItemsMappingByItem/module/${moduleId}/item/${itemId}`, this.reqOpt) as Observable<any[]>;
  }

  /**
  * delink group item mapping by id
  *
  * @param {number} id
  * @return {*}  {Observable<any>}
  * @memberof GroupsService
  */
  delinkGroupItemsMappingById(id: number): Observable<any> {
    return this.http.Put(`DelinkGroupItemsMappingById/${id}`, this.reqOpt)
  }

  /**
  * Archive group list by ids
  *
  * @param {number[]} ids
  * @return {*}  {Observable<any>}
  * @memberof GroupsService
  */
  archiveGroups(ids: number[]): Observable<any> {
    return this.http.Put(`ArchiveGroups`, ids, this.reqOpt)
  }
}
