import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IAPIResponse, IGridPagination } from 'src/app/view-models/common.model';
import { IMapModel } from 'src/app/view-models/map-model';
import { IRequestOptions } from 'src/app/view-models/request.options';
import { IPagination, ISaveStockItems, IStockItems, IUpdateQuantityModel } from 'src/app/view-models/stock-items-model';
import { CustomHttpClientService } from '../common/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class StockItemsService {
  /** request options */
  reqOpt: IRequestOptions = {
    showLoadingIndicator: true
  }

  /** searched stock item value */
  searchedStockItem!: string;

  /** stock item grid pagination data */
  stockItemsPaginationData: BehaviorSubject<IGridPagination> = new BehaviorSubject({ pageNumber: 1, pageSize: 8 });

  /** save/reset column layout */
  saveResetColumnLayout = new BehaviorSubject('');

  /** refresh grid */
  refreshStockItem = new BehaviorSubject('');

  stockItemRowData = new Subject()

  deselectStockItem = new Subject();

  constructor(private http: CustomHttpClientService) { }

  /**
   * set searched stock item value
   *
   * @param {string} searchedValue
   * @memberof StockItemsService
   */
  setSearchedStockItem(searchedValue: string): void {
    this.searchedStockItem = searchedValue;
  }

  /**
   * get searched stock item value
   *
   * @return {*}  {string}
   * @memberof StockItemsService
   */
  getSearchedStockItem(): string {
    return this.searchedStockItem;
  }

  /**
   * get sites data
   *
   * @param {IServerSideGetRowsRequest} gridRequest
   * @param {number} stockItemId
   * @return {*}  {Observable<any>}
   * @memberof StockItemsService
   */
  getSearchedStockItems(siteId: number, reqBody: IPagination): Observable<any> {
    const options: IRequestOptions = {
      params : {
        showLoadingIndicator : true
      }
    }

    return this.http.Post(`site/${siteId}/searchStockItems`, reqBody, options) as Observable<any>;
  }

  /**
   * get stock item data for update
   *
   * @param {number} stockItemId
   * @return {*}  {Observable<IStockItems>}
   * @memberof StockItemsService
   */
  getStockItemDataById(stockItemId: number): Observable<IStockItems> {
    return this.http.Get(`StockItem/${stockItemId}`, this.reqOpt).pipe() as Observable<IStockItems>;
  }

  /**
   * Update stock item
   *
   * @param {IStockItems} reqBody
   * @memberof StockItemsService
   */
  updateStockItem(stockItemId: number, siteId: number, reqBody: ISaveStockItems): Observable<ISaveStockItems> {
    return this.http.Put(`site/${siteId}/updateStockItem/${stockItemId}`, reqBody, this.reqOpt).pipe() as Observable<ISaveStockItems>;
  }

  /**
   * Save stock item
   *
   * @param {IStockItems} reqBody
   * @memberof StockItemsService
   */
  saveStockItem(siteId: number, reqBody: ISaveStockItems): Observable<ISaveStockItems> {
    return this.http.Post(`site/${siteId}/createStockItem`, reqBody, this.reqOpt).pipe() as Observable<ISaveStockItems>;
  }

  /**
   * get all Stock Items for a site
   *
   * @param {number} siteId
   * @return {Observable<IAssetDataModel[]}  {Observable<IAssetDataModel[]}
   * @memberof StockItemsService
   */
  getAllStockItems(siteId: number): Observable<IStockItems[]> {
    return this.http.Get(`site/${siteId}/getAllStockItems`, {
      withCredentials: true
    }) as Observable<IStockItems[]>
  }

  /**
   * reset stock item quantity
   *
   * @param {number} stockItemId
   * @param {HttpParams} params
   * @return {*}  {Observable<any[]>}
   * @memberof StockItemsService
   */
  resetStockItemQuantity(stockItemId: number, params: HttpParams): Observable<any[]> {
    return this.http.Put(`resetStockItemQuantity/${stockItemId}`, {}, { params: params, showLoadingIndicator: true }) as Observable<any[]>
  }

  /**
 * get gps details for stockItems
 *
 * @param {number} siteId
 * @return {*}  {Observable<IMapModel[]>}
 * @memberof StockItemsService
 */
  getGpsDetailsStockItems(siteId: number): Observable<IMapModel[]> {
    return this.http.Get(`site/${siteId}/GetGpsDetailsForStockItems`, this.reqOpt) as Observable<IMapModel[]>;
  }

  updateStockItemAvailableQuantity(stockItemId: number, reqBody: IUpdateQuantityModel): Observable<any> {
    return this.http.Put(`updateStockItemAvailableQuantity/${stockItemId}`, reqBody, this.reqOpt)
  }

  archiveStockItem(id: any): Observable<any> {
    return this.http.Delete<any>(`StockItem/${id}`)
  }

  requestCsvExport(siteId:number) : Observable<any> {
    return this.http.Post(`site/${siteId}/RequestStockItemExport`,{},this.reqOpt)
  }
}
