<button *ngIf="checkUserAccess()" [routerLink]="['edit-assets', assetId]">
  <fa-icon matTooltip="Edit Asset" [icon]="faEdit" class="mat-icon text-base edit-icon"></fa-icon>
</button>
<button *ngIf="checkUserAccess()" [routerLink]="['view-files', 'assets', assetId, assetNumber]">
  <fa-icon matTooltip="View Files" matTooltipPosition="right" [icon]="faFolder" class="mat-icon text-base edit-icon"></fa-icon>
</button>
<button *ngIf="agParams.data.availableQuantity < 1 && checkUserAccess()" (click)="onForceCheckInClick($event)">
  <fa-icon matTooltip="Force Check-In" matTooltipPosition="right" [icon]="faShuffle" class="mat-icon text-base edit-icon"></fa-icon>
</button>
<button *ngIf="agParams.data?.gpsDeviceInfo && agParams.data?.gpsDeviceInfo?.battStatus ">
  <fa-icon *ngIf="agParams.data?.gpsDeviceInfo?.battStatus === 'Replace'" matTooltip="Low" matTooltipPosition="right" [icon]="faBatteryLow" class="mat-icon text-base edit-icon low-battery-icon"></fa-icon>
  <fa-icon *ngIf="agParams.data?.gpsDeviceInfo?.battStatus === 'Good'" matTooltip="Good" matTooltipPosition="right" [icon]="faBatteryFull" class="mat-icon text-base edit-icon full-battery-icon"></fa-icon>
</button>
