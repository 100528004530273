import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy, AfterViewInit } from '@angular/core';
import { faAdd } from '@fortawesome/pro-solid-svg-icons';
import { AssetService } from 'src/app/services/assets/asset.service';
import { AssetsActionComponent } from '../assets-create/assets-action/assets-action.component';
import { LoaderService } from 'src/app/services/common/loader.service';
import { faCartShopping } from '@fortawesome/pro-solid-svg-icons';
import { GridService } from 'src/app/services/common/grid.service';
import { HttpParams } from '@angular/common/http';
import { ISiteModel } from 'src/app/view-models/site-model';
import { filter, finalize, Subscription, tap } from 'rxjs';
import { ColDef, ColumnApi, ColumnState, GridApi, ICellRendererParams, RowSelectedEvent } from 'ag-grid-community';
import { IAssetDataModel } from 'src/app/view-models/asset-data-model';
import { IGridColumns } from 'src/app/view-models/common.model';
import { moduleEnum } from 'src/app/common/utilities';
import * as moment from 'moment';
import { DisplayGroupNameComponent } from 'src/app/common/display-group-name/display-group-name.component';
import { MatDialog } from '@angular/material/dialog';
import { CheckInCheckOutComponent } from 'src/app/common/check-in-check-out/check-in-check-out.component';
import { AzureMonitoringService } from 'src/app/common/logging/logging.service';
import { Params } from '@angular/router';

interface cellRendererInterface {
  data: IAssetDataModel;
}

@Component({
  selector: 'app-assets-details',
  templateUrl: './assets-details.component.html'
})
export class AssetsDetailsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  faCartShopping = faCartShopping
  faAdd = faAdd;

  /** default column definition */
  defaultColDef: ColDef;

  /** row data */
  rowData: IAssetDataModel[] = [];

  /** get asset details to display in grid */
  @Input() assetsDetails!: IAssetDataModel[];

  /** grid api */
  gridApi!: GridApi;

  /** grid column api */
  gridColumnApi!: ColumnApi;

  /** user id */
  userId!: string;

  /** ag grid status bar */
  statusBar: {
    statusPanels: {
      statusPanel: string;
      align: string;
      key?: string;
    }[];
  }

  /** asset ag grid headers */
  itemHeaders!: ColDef[];

  /** column definitions */
  columnDefs!: ColDef[];

  agGrid = {
    afterExpansion: false
  }

  /** grid column */
  gridColumn!: IGridColumns;

  /** Subscription Handler */
  subs!: Subscription;

  /** site data */
  siteData!: ISiteModel;

  /**  boolean value for select-deselect all assets  */
  toggle = false;

  columnState!: ColumnState[];

  scannedCount: number = 0;

  @Output() selectedAssetsdata = new EventEmitter<IAssetDataModel[]>();

  @Output() clearSearchedAssetValue = new EventEmitter<number | null>();

  @Output() exportIconLoader = new EventEmitter<boolean | false>();

  /** event emitter when grid column moved */
  @Output() columnMoved = new EventEmitter<{
    reset: boolean,
    save: boolean
  }>();

  columnStateChange = false;

  /** check user access of this module */
  @Input() checkUserAccess!: boolean;

  /** asset searched indicator to hide grid overlay */
  @Input() assetSearched!: boolean;

  /** clear selected rows */
  @Input() clearSelectedRows = false;

  /** check if the api is called once or not */
  apiCalled = false;

  count = 0;

  /** total count */
  @Input() totalCount!: number;

  /** start row */
  @Input() startRow!: number;

  /** end row */
  @Input() endRow!: number;

  /** disable load more */
  @Input() disableLoad!: boolean;

  defaultColumnState!: ColumnState[];

  checkInOutTab: boolean = true;

  constructor(private assetService: AssetService, public loaderService: LoaderService,
    private dialog: MatDialog,
    private gridService: GridService,
    private appInsights: AzureMonitoringService) {
    this.subs = new Subscription();
    this.defaultColDef = {
      initialWidth: 150,
      resizable: true,
      sortable: true,
      filter: true
    };

    this.statusBar = {
      statusPanels: [
        { statusPanel: "agTotalRowCountComponent", align: "left" },
        { statusPanel: "agFilteredRowCountComponent", align: "right" },
        { statusPanel: "agSelectedRowCountComponent", key: 'tempCountKey', align: "center" },
      ]
    };
  }

  ngOnInit(): void {
    this.columnDefs = this.itemHeaders
    this.userId = localStorage.getItem('userId') as string;
    this.scannedCount = JSON.parse(localStorage.getItem('scannedAssetData') as any)?.length || 0;
  }

  ngAfterViewInit(): void {
    setInterval(() => {
      if (!this.siteData) {
        this.siteData = JSON.parse(localStorage.getItem('siteData') as string);
      }

      if (this.siteData && !this.apiCalled) {
        this.apiCalled = true;
        const httpParams = new HttpParams().set('userId', this.userId).set('moduleId', moduleEnum.asset);
        const getColumnState = this.gridService.getGridColumn(this.siteData.id, httpParams).subscribe(res => {
          this.columnState = res.columns;
          this.gridColumnApi.setColumnState(this.columnState);
        });
        this.subs.add(getColumnState);
      }
    }, 1000);


    const saveOrResetSub = this.assetService.saveOrResetSub.pipe(
      filter(val => !!val),
      tap(val => {
        if (val === 'save') {
          this.saveColumnState();
        }
        else if (val === 'resetData') {
          this.rowData = [];
          this.siteData = JSON.parse(localStorage.getItem('siteData') as string);
          this.assetService.saveOrResetSub.next('');
          this.clearSearchedAssetValue.emit(null);
          const httpParams = new HttpParams().set('userId', this.userId).set('moduleId', moduleEnum.asset);
          const getColumnState = this.gridService.getGridColumn(this.siteData.id, httpParams).subscribe(res => {
            this.columnState = res.columns;
            this.gridColumnApi.setColumnState(this.columnState);
          });
          this.subs.add(getColumnState);
        }
        else if (val === 'reset') {
          this.revertSavedColumnState();

        }
      }),
    ).subscribe();
    this.subs.add(saveOrResetSub)
    this.assetService.deselectAsset.subscribe((x) => this.deselectall())
  }

  ngOnChanges(): void {
    this.scannedCount = JSON.parse(localStorage.getItem('scannedAssetData') as any)?.length || 0;

    this.itemHeaders = [
      {
        headerName: 'Asset Number',
        field: 'assetNumber',
        pinned: 'left',
        floatingFilter: true,
        minWidth: 120,
        colId: 'assetNumber'
      },
      {
        headerName: 'Description',
        field: 'description',
        pinned: 'left',
        floatingFilter: true,
        minWidth: 120,
        colId: 'description'
      },
      {
        headerName: 'Barcode',
        field: 'barcode',
        pinned: 'left',
        floatingFilter: true,
        minWidth: 90,
        colId: 'barcode'
      },
      {
        headerName: 'Group',
        field: 'groupDescription',
        minWidth: 130,
        colId: 'groupDescription',
        cellRendererFramework: DisplayGroupNameComponent,
        cellRendererParams: {
          displayGroup: true
        },
        valueGetter: (data) => this.getGroupsName(data),
        filterValueGetter: (data) => this.getGroupsName(data),
        floatingFilter: true,
      },
      {
        headerName: 'Document',
        field: 'documentName',
        minWidth: 130,
        colId: 'documentName',
        cellRendererFramework: DisplayGroupNameComponent,
        cellRendererParams: {
          displayDocumentV2: true
        },
        valueGetter: (data) => this.getDocumentsName(data),
        filterValueGetter: (data) => this.getDocumentsName(data),
        floatingFilter: true,
      },
      {
        headerName: 'Hired',
        colId: 'hired',
        // pinned: null,
        minWidth: 80,
        floatingFilter: true,
        filterValueGetter: (params: cellRendererInterface) => this.filterValueGetter(params.data.isHiring as boolean, true),
        cellRenderer: (params: cellRendererInterface) => this.filterValueGetter(params.data.isHiring as boolean)
      },
      {
        headerName: 'Rate',
        colId: 'rate',
        field: 'rate',
        floatingFilter: true,
        minWidth: 80,
        // pinned: null
      },
      {
        headerName: 'Last Hire date',
        colId: 'lastHireDate',
        field: 'lastHireDate',
        floatingFilter: true,
        minWidth: 125,
        // pinned: null,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.lastHireDate as string, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.lastHireDate as string)
      },
      {
        headerName: 'Custodian',
        colId: 'custodianName',
        field: 'custodianName',
        floatingFilter: true,
        minWidth: 135,
        // pinned: null
      },
      {
        headerName: 'Location',
        colId: 'locationDescription',
        field: 'locationDescription',
        floatingFilter: true,
        minWidth: 100,
        // pinned: null
      },
      {
        headerName: 'Status',
        colId: 'statusDescription',
        field: 'statusDescription',
        floatingFilter: true,
        minWidth: 140,
        // pinned: null
      },
      {
        headerName: 'Category',
        colId: 'categoryDescription',
        field: 'categoryDescription',
        floatingFilter: true,
        minWidth: 100,
        // pinned: null
      },
      {
        headerName: 'Workorder',
        colId: 'workorderDescription',
        field: 'workorderDescription',
        floatingFilter: true,
        minWidth: 100,
        // pinned: null
      },
      {
        headerName: 'Supplier',
        colId: 'supplierDescription',
        field: 'supplierDescription',
        floatingFilter: true,
        minWidth: 100,
        // pinned: null
      },
      {
        headerName: 'Condition',
        colId: 'conditionDescription',
        field: 'conditionDescription',
        floatingFilter: true,
        minWidth: 100,
        // pinned: null
      },
      {
        headerName: 'Model No',
        colId: 'modelNo',
        field: 'modelNo',
        floatingFilter: true,
        minWidth: 105,
        // pinned: null
      },
      {
        headerName: 'Registration No',
        colId: 'registrationNo',
        field: 'registrationNo',
        floatingFilter: true,
        minWidth: 130,
        // pinned: null
      },
      {
        headerName: 'Serial No',
        colId: 'serialNo',
        field: 'serialNo',
        floatingFilter: true,
        minWidth: 100,
        // pinned: null
      },
      {
        headerName: 'Design Pressure',
        colId: 'designPressure',
        field: 'designPressure',
        floatingFilter: true,
        minWidth: 130,
        // pinned: null
      },
      {
        headerName: 'Design Registration No',
        colId: 'designRegistrationNo',
        field: 'designRegistrationNo',
        floatingFilter: true,
        minWidth: 170,
        // pinned: null
      },
      {
        headerName: 'Capacity',
        colId: 'capacity',
        field: 'capacity',
        floatingFilter: true,
        minWidth: 90,
        // pinned: null
      },
      {
        headerName: 'Actual Fuel',
        colId: 'actualFuel',
        field: 'actualFuel',
        floatingFilter: true,
        minWidth: 110,
        // pinned: null
      },
      {
        headerName: 'Actual Mileage',
        colId: 'actualMileage',
        field: 'actualMileage',
        floatingFilter: true,
        minWidth: 125,
        // pinned: null
      },
      {
        headerName: 'Area',
        colId: 'area',
        field: 'area',
        floatingFilter: true,
        minWidth: 70,
        // pinned: null
      },
      {
        headerName: 'Available Qty.',
        colId: 'availableQuantity',
        field: 'availableQuantity',
        floatingFilter: true,
        minWidth: 120,
        // pinned: null
      },
      {
        headerName: 'Last Check-in Date',
        floatingFilter: true,
        minWidth: 145,
        field: 'lastCheckinDate',
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.lastCheckinDate as string, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.lastCheckinDate as string)
      },
      {
        headerName: 'Last Check-out Date',
        floatingFilter: true,
        minWidth: 155,
        field: 'lastCheckoutDate',
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.lastCheckoutDate as string, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.lastCheckoutDate as string)
      },
      {
        headerName: 'Comments',
        colId: 'comments',
        field: 'comments',
        floatingFilter: true,
        minWidth: 100,
        // pinned: null
      },
      {
        headerName: 'Fuel Inspection Due',
        colId: 'fuelInspectionDue',
        field: 'fuelInspectionDue',
        floatingFilter: true,
        minWidth: 155,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.fuelInspectionDue, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.fuelInspectionDue)
      },
      {
        headerName: 'GPS Latitude',
        colId: 'gpsLatitude',
        field: 'gpsLatitude',
        floatingFilter: true,
        minWidth: 120,
        // pinned: null
      },
      {
        headerName: 'GPS Longitude',
        colId: 'gpsLongitude',
        field: 'gpsLongitude',
        floatingFilter: true,
        minWidth: 125,
        // pinned: null
      },
      {
        headerName: 'Manufacturer',
        field: 'manufacturerDescription',
        floatingFilter: true,
        minWidth: 130,
        // pinned: null
      },
      {
        headerName: 'Hazard Level',
        colId: 'hazardLevel',
        field: 'hazardLevel',
        floatingFilter: true,
        minWidth: 110,
        // pinned: null
      },
      {
        headerName: 'Mileage Inspection Due',
        colId: 'mileageInspectionDue',
        field: 'mileageInspectionDue',
        floatingFilter: true,
        minWidth: 170,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.mileageInspectionDue, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.mileageInspectionDue)
      },
      {
        headerName: 'Volume',
        colId: 'volume',
        field: 'volume',
        floatingFilter: true,
        minWidth: 90,
        // pinned: null
      },
      // ---------------------------------- newly added columns---------------------------
      {
        headerName: 'Tag Id',
        colId: 'tagId',
        field: 'tagId',
        floatingFilter: true,
        minWidth: 90
      },
      {
        headerName: 'Owner',
        colId: 'owner',
        field: 'owner',
        floatingFilter: true,
        minWidth: 90
      },
      {
        headerName: 'Hire Category',
        colId: 'hireCategory',
        field: 'hireCategory',
        floatingFilter: true,
        minWidth: 128
      },
      {
        headerName: 'Hire Company',
        colId: 'hireCompany',
        field: 'hireCompany',
        floatingFilter: true,
        minWidth: 125
      },
      {
        headerName: 'Purchase Cost',
        colId: 'purchaseCost',
        field: 'purchaseCost',
        floatingFilter: true,
        minWidth: 130
      },
      {
        headerName: 'Monthly Rate',
        colId: 'monthlyRate',
        field: 'monthlyRate',
        floatingFilter: true,
        minWidth: 125
      },
      {
        headerName: 'Weekly Rate',
        colId: 'weeklyRate',
        field: 'weeklyRate',
        floatingFilter: true,
        minWidth: 115
      },
      {
        headerName: 'Day Rate',
        colId: 'dayRate',
        field: 'dayRate',
        floatingFilter: true,
        minWidth: 100
      },
      {
        headerName: 'Remaining Days on Contract',
        colId: 'remainingDaysOnContract',
        field: 'remainingDaysOnContract',
        floatingFilter: true,
        minWidth: 200
      },
      {
        headerName: 'On Hire Date',
        colId: 'onHireDate',
        field: 'onHireDate',
        floatingFilter: true,
        minWidth: 123,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.onHireDate, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.onHireDate)
      },
      {
        headerName: 'Off Hire Date',
        colId: 'offHireDate',
        field: 'offHireDate',
        floatingFilter: true,
        minWidth: 125,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.offHireDate, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.offHireDate)
      },
      {
        headerName: 'Date Entered',
        colId: 'dateEntered',
        field: 'dateEntered',
        floatingFilter: true,
        minWidth: 190,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.dateEntered, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.dateEntered)
      },
      {
        headerName: 'Plant No',
        colId: 'plantNumber',
        field: 'plantNumber',
        floatingFilter: true,
        minWidth: 100
      },
      {
        headerName: 'Billing Description',
        colId: 'billingDescription',
        field: 'billingDescription',
        floatingFilter: true,
        minWidth: 150
      },
      {
        headerName: 'Billing Category',
        colId: 'billingCategory',
        field: 'billingCategory',
        floatingFilter: true,
        minWidth: 140
      },
      {
        headerName: 'KM Allowance',
        colId: 'kmAllowance',
        field: 'kmAllowance',
        floatingFilter: true,
        minWidth: 130
      },
      {
        headerName: 'Next Service in KM',
        colId: 'nextServiceInKM',
        field: 'nextServiceInKM',
        floatingFilter: true,
        minWidth: 150
      },
      {
        headerName: 'Last Inspection Date',
        colId: 'lastInspectionDate',
        field: 'lastInspectionDate',
        floatingFilter: true,
        minWidth: 160,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.lastInspectionDate as string, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.lastInspectionDate as string)
      },
      {
        headerName: 'Next Inspection Date',
        colId: 'nextInspectionDate',
        field: 'nextInspectionDate',
        floatingFilter: true,
        minWidth: 162,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.nextInspectionDate as string, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.nextInspectionDate as string)
      },
      {
        headerName: 'Certified By',
        colId: 'certifiedBy',
        field: 'certifiedBy',
        floatingFilter: true,
        minWidth: 150
      },
      {
        headerName: 'Certification Date',
        colId: 'certificationDate',
        field: 'certificationDate',
        floatingFilter: true,
        minWidth: 123,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.certificationDate as string, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.certificationDate as string)
      },
      {
        headerName: 'Purchase Order Number',
        colId: 'purchaseOrderNumber',
        field: 'purchaseOrderNumber',
        floatingFilter: true,
        minWidth: 150
      },
      {
        headerName: 'Purchase Order Date',
        colId: 'purchaseOrderDate',
        field: 'purchaseOrderDate',
        floatingFilter: true,
        minWidth: 123,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.purchaseOrderDate as string, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.purchaseOrderDate as string)
      },
      {
        headerName: 'Manufactured By',
        colId: 'manufacturedBy',
        field: 'manufacturedBy',
        floatingFilter: true,
        minWidth: 150
      },
      {
        headerName: 'Manufacture Date',
        colId: 'manufactureDate',
        field: 'manufactureDate',
        floatingFilter: true,
        minWidth: 123,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.manufactureDate as string, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.manufactureDate as string)
      },
      {
        headerName: 'Next Preservation Date',
        colId: 'nextPreservationDate',
        field: 'nextPreservationDate',
        floatingFilter: true,
        minWidth: 180,
        filterValueGetter: (data: cellRendererInterface) => this.filterValueGetter(data.data.nextPreservationDate, true),
        cellRenderer: (data: cellRendererInterface) => this.filterValueGetter(data.data.nextPreservationDate)
      },
      {
        headerName: 'GPS Device Serial No',
        colId: 'deviceSerNo',
        field: 'deviceSerNo',
        floatingFilter: true,
        minWidth: 150,
        filterValueGetter: (data: cellRendererInterface) => data.data.gpsDeviceInfo?.deviceSerNo as string,
        cellRenderer: (data: cellRendererInterface) => data.data.gpsDeviceInfo?.deviceSerNo as string
      },
    ];

    this.itemHeaders.unshift({
      headerName: '',
      colId: 'action',
      pinned: 'left',
      minWidth: this.checkUserAccess ? 150 : 30,
      checkboxSelection: this.checkUserAccess,
      headerCheckboxSelection: this.checkUserAccess,
      headerCheckboxSelectionFilteredOnly: this.checkUserAccess,
      cellRendererFramework: AssetsActionComponent
    });
    this.count = 0;

    this.gridApi?.setColumnDefs(this.itemHeaders);

    if(this.defaultColumnState === undefined)
      this.defaultColumnState = this.gridColumnApi?.getColumnState();

    if (this.columnState) {
      this.gridColumnApi?.setColumnState(this.columnState)
    }

    if (this.assetSearched) {
      this.gridApi?.hideOverlay();
    }
    if (!!this.assetsDetails) {
       this.rowData = this.assetsDetails;
      this.gridApi.setRowData(this.rowData)
      this.exportIconLoader.emit(false);
      this.startRow = 0;
      this.endRow = 100;
    } else {
      this.exportIconLoader.emit(true)
    }

    if (this.clearSelectedRows) {
      this.gridApi.deselectAll();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  deselectall(){
    this.rowData.forEach((x) => {
      x.selected = false;
    });
    this.gridApi.setRowData(this.rowData);
    this.gridApi.deselectAll()
    this.gridApi.refreshCells({force: true});
  }
  /**
   * filter value display in the grid
   *
   * @param {(string | boolean)} data
   * @param {boolean} [filterValues=false]
   * @return {*}  {string}
   * @memberof AssetsDetailsComponent
   */
  filterValueGetter(data: string | boolean, filterValues: boolean = false): string {
    if (typeof data === 'string') {
      if (filterValues && data) {
        return moment(data).format('DD/MM/YYYY');
      } else {
        return data ? moment(data).format('DD/MM/YYYY') : '';
      }
    } else if (typeof data === 'boolean') {
      return data ? 'Yes' : 'No';
    }
    return '';
  }

  saveColumnState() {
    this.loaderService.show();
    this.gridColumn = {
      userId: this.userId,
      moduleId: moduleEnum.asset,
      columnsJSON: ''
    };

    const colData: string[] = [];
    /** set grid columndata request payload */
    this.gridColumnApi?.getColumnState()?.map((gridColumnData: any) => {
      delete gridColumnData.flex;
      delete gridColumnData.pivot;
      delete gridColumnData.rowGroup;
      delete gridColumnData.sort;
      delete gridColumnData.sortIndex;
      colData.push(gridColumnData);
    });

    this.gridColumn.columnsJSON = JSON.stringify(colData);
    this.assetService.saveOrResetSub.next('');
    const updateGridColumn = this.gridService.updateGridColumn(this.siteData.id, this.gridColumn).subscribe(() => this.columnMoved.emit({
      reset: true,
      save: false
    }));
    this.subs.add(updateGridColumn);
    this.columnStateChange = false;
    this.gridService.clearAssetUserProfileCache();
  }

  movedColumn() {
    this.count++
    if (this.count == 1 || this.columnStateChange) {
      this.columnStateChange = false
      this.columnMoved.emit({ reset: false, save: false })
    } else {
      this.columnMoved.emit({ reset: true, save: true })
    }
  }

  revertSavedColumnState() {
    this.gridColumn = {
      userId: this.userId,
      moduleId: moduleEnum.asset
    }
    this.assetService.saveOrResetSub.next('');
    const resetGrid = this.gridService.resetGridColumn(this.siteData.id, this.gridColumn).subscribe(() => {
      this.gridColumnApi.resetColumnState();
      this.gridApi.ensureIndexVisible(0); 
      var lastPinnedIndex = this.findLastPinnedColumn();
      this.gridApi.ensureColumnVisible(this.gridColumnApi.getAllColumns()[lastPinnedIndex + 1]);
      const gridContainer = document.querySelector('.ag-root') as HTMLElement;
      if (gridContainer) {
        gridContainer.scrollTop = 0;
        gridContainer.scrollLeft = 0;
      }
      this.gridColumnApi.setColumnState(this.defaultColumnState)
      this.columnMoved.emit({
        reset: false,
        save: false
      });
    });
    this.subs.add(resetGrid);
    this.columnStateChange = true
  }

  findLastPinnedColumn() : number{
    if (this.gridColumnApi) {
      const allColumns = this.gridColumnApi.getAllColumns();
      const pinnedColumns = allColumns.filter((col: any) => col.getColDef().pinned);
      if (pinnedColumns.length > 0) {
        const lastPinnedColumn = pinnedColumns[pinnedColumns.length - 1];
        return allColumns.indexOf(lastPinnedColumn);
      }
    }
    return -1;
  }
  getGroupsName(data: Params) {
    var groups: any[] = []
    if (!!data['data'].groups) {
      data['data'].groups.forEach((g: any) => {
        groups.push(g.groupDescription)
      })
    }
    return groups.length > 0 ? groups : null
  }

  getDocumentsName(data: Params) {
    var documents: any[] = []
    if (!!data['data'].documentV2) {
      data['data'].documentV2.forEach((d: any) => {
        documents.push(d.documentName)
      })
    }
    return documents.length > 0 ? documents : null
  }

  selectAllAssets(toggleValue: string) {
    this.toggle = toggleValue === 'false' ? false : true;
    this.gridApi.forEachNode(node => {
      node.setSelected(this.toggle);
    });
  }

  async onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();
    localStorage.removeItem('selectedAssetsList');
  }

  loadAsset(isLoadAll: boolean = false) {

    var selectedAssets = this.rowData?.filter(x => x.selected == true)
    this.appInsights.trackEvent("ViewAssetsList", this.siteData.siteName, {})
    this.startRow = this.startRow + 100;
    isLoadAll == true ? this.endRow = this.totalCount : this.endRow = this.endRow + 100;
    const reqBody = {
      startRow: this.startRow,
      endRow: this.endRow
    }
    this.disableLoad = true;
    const searchedAssets = this.assetService.getSearchAsset(this.siteData.id, reqBody).pipe(
      tap((res) => {
        this.assetsDetails = [];
        this.disableLoad = false;
        this.rowData = [...res.rows,...this.rowData]
        this.gridApi.setRowData(this.rowData)
        this.gridApi.forEachNode(node => {
          if(selectedAssets.some(x => x.id == node.data.id)){
            node.setSelected(true);
          }
        })
        this.gridApi.refreshCells({force: true})
        this.assetsDetails = this.rowData;
      
      }),
      finalize(() => this.disableLoad = false)
    ).subscribe();
    this.subs.add(searchedAssets);
    this.gridApi.redrawRows()
  }

  rowSelected(event: RowSelectedEvent) {
    const { node, data } = event;
    data.selected = node.isSelected();
    this.assetService.AssetRowData.next(this.rowData);
  }

  checkInOut() {
    this.checkInOutTab = false;
    const modalData = {
      headerTitle: 'Check-In/Check-Out',
      returnValue: ""
    };

    const dialogRef = this.dialog.open(CheckInCheckOutComponent, {
      height: 'auto',
      width: '1050px',
      data: modalData,
      disableClose: true,
      autoFocus: true
    });

    const dialogClosed = dialogRef.afterClosed().pipe(
      tap((res) => {
        this.scannedCount = JSON.parse(localStorage.getItem('scannedAssetData') as any)?.length || 0;
        if (!!res?.length) {
          res.map((x: IAssetDataModel) => {
            this.rowData.map((y: IAssetDataModel) => {
              if (x.id === y.id) {
                y.availableQuantity = x.availableQuantity;
                y.custodianId = x.custodianId;
                y.custodianName = x.custodianName
                y.locationId = x.locationId;
                y.locationDescription = x.locationDescription;
                y.statusId = x.statusId;
                y.statusDescription = x.statusDescription;
              }
            })
          })
          this.gridApi.refreshCells({ columns: ['availableQuantity', 'custodianId', 'custodianName', 'locationId', 'locationDescription', 'statusId', 'statusDescription'] })
        }
      })
    ).subscribe(() => this.checkInOutTab = true);
    this.subs.add(dialogClosed);
  }
}
