import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey("CompanyName=Trackem Pty Ltd,LicensedGroup=Multi / 2020 / 1 MA 1 DS REN,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-010440,ExpiryDate=28_October_2021_[v2]_MTYzNTM3NTYwMDAwMA==5bb67976d2e7cd78e27dac4e0ff3ad0a");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

