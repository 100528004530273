import { Component, OnInit } from '@angular/core';
import { faEdit, faFolder, faShuffle, faBatteryLow, faBatteryFull} from '@fortawesome/pro-solid-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { InputTextboxDialogComponent } from 'src/app/common/input-textbox-dialog/input-textbox-dialog.component';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { IForceCheckInModel } from 'src/app/view-models/transactions-model';
import { moduleEnum } from 'src/app/common/utilities';
import { IAssetDataModel } from 'src/app/view-models/asset-data-model';
import { UserRolesService } from 'src/app/services/user-roles/user-roles.service';

@Component({
  selector: 'app-assets-action',
  templateUrl: './assets-action.component.html'
})
export class AssetsActionComponent implements OnInit {

  faEdit = faEdit;
  faBatteryLow = faBatteryLow;
  faBatteryFull = faBatteryFull;
  /** faFolder icon */
  faFolder = faFolder;

  /** Force Check in icon */
  faShuffle = faShuffle;

  /** agGrid Params */
  agParams!: ICellRendererParams;

  /** asset id */
  assetId!: number;

  /** asset number */
  assetNumber!: number;

  constructor(private dialog: MatDialog, private transactionService: TransactionsService, private userRolesService: UserRolesService) {}

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    this.agParams = params;
    this.assetId = this.agParams.data.id;
    this.assetNumber = this.agParams.data.assetNumber;

    if(this.agParams.data.selected)
      this.agParams.node.setSelected(true);
    else
      this.agParams.node.setSelected(false);
  }
  
  checkUserAccess(): boolean {
    return this.userRolesService.checkModuleAccess(moduleEnum.asset);
  }

  getSelectedAssets(): any[] {
    return JSON.parse(localStorage.getItem('selectedAssetsList') as any);
  }

  onForceCheckInClick(event: Event) {
    event.stopPropagation();
    var modalData = {
      headerTitle: 'Force Check-In - ' + this.agParams.data.assetNumber,
      label: 'Please specify Force Check-In reason',
      returnValue: "",
    };

    const dialogRef = this.dialog.open(InputTextboxDialogComponent, {
      height: 'auto',
      width: '600px',
      data: modalData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let forceCheckIn: IForceCheckInModel = {
          id: this.agParams.data.id,
          reason: modalData.returnValue,
          moduleId: moduleEnum.asset
        }

        let scannedData = JSON.parse(localStorage.getItem('selectedAssetsList') as any);

        if (!!scannedData && scannedData.length > 0) {
          let indexToUpdate = scannedData.findIndex((a: any) => a.id == this.assetId);
          scannedData[indexToUpdate].availableQuantity = 1;
          localStorage.setItem('selectedAssetsList', JSON.stringify(scannedData))
        }

        this.transactionService.forceCheckIn(this.agParams.data.siteId, forceCheckIn).subscribe(async result => {
          let currentDate = new Date().toISOString(); 
          this.agParams.node.setDataValue('lastCheckinDate', currentDate);
          this.agParams.data.availableQuantity = 1
          this.agParams.data.custodianName = ''
          this.agParams.api.refreshCells({ columns: ['availableQuantity'] })
          this.agParams.api.refreshCells({ columns: ['custodianName'] })

        });
      }
    });
  }

  loadSelectedRowsCount(data: any) {
    const statusBarComponent = this.agParams.api.getStatusPanel(
      'tempCountKey'
    ) as any;

    if (data.length > 0) {
      statusBarComponent.displayed = true;
      statusBarComponent.eGui.className = 'ag-status-name-value ag-status-panel ag-status-panel-selected-row-count'
      statusBarComponent.eValue.innerHTML = data.length.toString();
      statusBarComponent.eValue.innerText = data.length.toString();
    } else {

      statusBarComponent.displayed = false;
      statusBarComponent.eGui.className = 'ag-status-name-value ag-status-panel ag-status-panel-selected-row-count ag-hidden'

    }
  }
}
