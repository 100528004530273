import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { IRequestOptions } from "src/app/view-models/request.options";
import { CustomHttpClientService } from "./custom-http-client.service";
import { IAssetImport, IGetFile, IGetFolders, IStockItemImport, IMaterialImport } from 'src/app/view-models/common.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class FileUploadService {

    /** request options */
    reqOpt: IRequestOptions = {
        showLoadingIndicator: true
    }

    /** selected files */
    selectedFiles = new BehaviorSubject<IGetFile[]>([]);

    /** selected folder */
    private folderName = new BehaviorSubject<any>({});
    folderNameValue = this.folderName.asObservable();

    constructor(private http: CustomHttpClientService) { }

    setfolderNameValue(fName:string){
        this.folderName.next(fName);
      }

    /**
     * upload file
     *
     * @param {number} siteId
     * @param {FormData} fileFormData
     * @return {*}  {Observable<any>}
     * @memberof FileUploadService
     */
    uploadFile(siteId: number, fileFormData: FormData): Observable<IGetFile> {
        return this.http.Post(`site/${siteId}/uploadFile`, fileFormData, this.reqOpt) as Observable<IGetFile>;
    }

    /**
     * delete file
     *
     * @param {number} fileId
     * @return {*}  {Observable<[]>}
     * @memberof FileUploadService
     */
    deleteFile(fileId: number): Observable<[]> {
        return this.http.Delete(`File/${fileId}/ArchiveFile`, this.reqOpt) as Observable<[]>;
    }

    /**
     * get folders data
     *
     * @param {number} siteId
     * @return {*}  {Observable<IGetFolders[]>}
     * @memberof FileUploadService
     */
    getFolders(siteId: number, params: HttpParams): Observable<IGetFolders[]> {
        return this.http.Get(`site/${siteId}/GetFolders`, {
            params,
            showLoadingIndicator: true
        }) as Observable<IGetFolders[]>
    }


    /**
* get folders data
*
* @param {number} siteId
* @return {*}  {Observable<IGetFolders[]>}
* @memberof FileUploadService
*/
    getOSDFolder(siteId: number, params: HttpParams): Observable<IGetFolders[]> {
        return this.http.Get(`site/${siteId}/GetOSDFolder`, {
            params,
            showLoadingIndicator: true
        }) as Observable<IGetFolders[]>
    }


    // csvbox function

    /**
     * import items via csvbox
     * @param params
     * @param reqBody
     * @returns
     */
    importItems(params: HttpParams, reqBody: IAssetImport[] | IStockItemImport[] | IMaterialImport[], importUrl: string): Observable<any> {
        return this.http.Post(importUrl, reqBody, {
            params,
            fullPath: true,
            showLoadingIndicator: true
        }) as Observable<any>
    }
}
