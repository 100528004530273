import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionsRoutingModule } from './transactions-routing.module';
import { TransactionsComponent } from './transactions.component';
import { SharedModule } from 'src/app/common/shared.module';
import { ItemTransactionsComponent } from './item-transactions/item-transactions.component';
import { TransactionDetailsDialogComponent } from './transaction-details-dialog/transaction-details-dialog.component';
import { TransactionActionCellRendererComponent } from './transaction-action-cell-renderer/transaction-action-cell-renderer.component';


@NgModule({
  declarations: [
    TransactionsComponent,
    ItemTransactionsComponent,
    TransactionDetailsDialogComponent,
    TransactionActionCellRendererComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TransactionsRoutingModule
  ],
  exports: [
    ItemTransactionsComponent
  ]
})
export class TransactionsModule { }
